import create from 'zustand'

import { getCategories, getFeatured, getSearchResults, getMerchant, getStore } from 'services'

const useMerchantStore = create((set, get) => ({
  isLoading: {
    categories: false,
    featured: false,
    searchResults: false,
    merchant: false,
    store: false,
  },
  response: {
    categories: null,
    featured: null,
    searchResults: null,
    merchant: null,
    store: null,
  },
  errors: {
    categories: null,
    featured: null,
    searchResults: null,
    merchant: null,
    store: null,
  },
  resetStates: dataType => {
    set({
      isLoading: { ...get().isLoading, [dataType]: false },
      response: { ...get().response, [dataType]: null },
      errors: { ...get().errors, [dataType]: null },
    })
  },
  fetchSearchResults: async (searchTerm, category, countryCode) => {
    try {
      set({ isLoading: { ...get().isLoading, searchResults: true } })
      const res = await getSearchResults(searchTerm, category, countryCode)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, searchResults: false },
          response: { ...get().response, searchResults: res.data },
          errors: { ...get().errors, searchResults: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, searchResults: false },
          response: { ...get().response, searchResults: null },
          errors: { ...get().errors, searchResults: res.error },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, searchResults: false },
        response: { ...get().response, searchResults: null },
        errors: {
          ...get().errors,
          searchResults: err && err.response && err.response.data.error,
        },
      })
    }
  },
  fetchCategories: async () => {
    try {
      set({ isLoading: { ...get().isLoading, categories: true } })
      const res = await getCategories()

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, categories: false },
          response: { ...get().response, categories: res.data },
          errors: { ...get().errors, categories: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, categories: false },
          response: { ...get().response, categories: null },
          errors: { ...get().errors, categories: res.error },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, categories: false },
        response: { ...get().response, categories: null },
        errors: {
          ...get().errors,
          categories: err && err.response && err.response.data.error,
        },
      })
    }
  },
  fetchFeatured: async countryCode => {
    try {
      set({ isLoading: { ...get().isLoading, featured: true } })
      const res = await getFeatured(countryCode)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, featured: false },
          response: { ...get().response, featured: res.data },
          errors: { ...get().errors, featured: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, featured: false },
          response: { ...get().response, featured: null },
          errors: { ...get().errors, featured: res.error },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, featured: false },
        response: { ...get().response, featured: null },
        errors: {
          ...get().errors,
          featured: err && err.response && err.response.data.error,
        },
      })
    }
  },
  fetchMerchant: async slug => {
    try {
      set({ isLoading: { ...get().isLoading, merchant: true } })
      const res = await getMerchant(slug)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, merchant: false },
          response: { ...get().response, merchant: res.data },
          errors: { ...get().errors, merchant: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, merchant: false },
          response: { ...get().response, merchant: null },
          errors: { ...get().errors, merchant: res.error },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, merchant: false },
        response: { ...get().response, merchant: null },
        errors: {
          ...get().errors,
          merchant: err && err.response && err.response.data.error,
        },
      })
    }
  },
  fetchStore: async storeId => {
    try {
      set({ isLoading: { ...get().isLoading, store: true } })
      const res = await getStore(storeId)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, store: false },
          response: { ...get().response, store: res.data },
          errors: { ...get().errors, store: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, store: false },
          response: { ...get().response, store: null },
          errors: { ...get().errors, store: res.error },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, store: false },
        response: { ...get().response, store: null },
        errors: {
          ...get().errors,
          store: err && err.response && err.response.data,
        },
      })
    }
  },
}))

export default useMerchantStore
