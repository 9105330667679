import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { Flex, RoundedContainer, Spacer } from 'components'

const LoadingRow = () => {
  return (
    <Flex justifyContent="space-between">
      <Flex mt={3}>
        <Skeleton circle height={56} width={56} />
        <Flex flexDirection="column" justifyContent="center" mx={2}>
          <Skeleton height={15} width={100} />
          <Skeleton height={15} width={120} />
        </Flex>
      </Flex>
      <Flex alignItems="center" marginRight={0} pt={2}>
        <Skeleton height={30} width={80} />
      </Flex>
    </Flex>
  )
}

const LoadingUpcomingPayments = () => {
  return (
    <>
      <Spacer height={10} />
      <RoundedContainer>
        <Flex flexDirection="column">
          <Skeleton height={20} width={200} />
          {LoadingRow()}
          {LoadingRow()}
        </Flex>
      </RoundedContainer>
    </>
  )
}

export default LoadingUpcomingPayments
