import React, { useState } from 'react'

import { PageWrapper, NavigateBack } from 'components'
import { theme } from 'styles/theme'

import ContactUsForm from './ContactUsForm/ContactUsForm'
import ContactUsSuccess from './ContactUsSuccess/ContactUsSuccess'

const ContactUs = () => {
  const [feedBackSuccess, setFeedBackSuccess] = useState(false)

  return (
    <PageWrapper withTopNav backgroundColor={feedBackSuccess ? 'white' : theme.colors.background}>
      <NavigateBack withBottomBorder>Contact Us</NavigateBack>

      {feedBackSuccess ? (
        <ContactUsSuccess />
      ) : (
        <ContactUsForm setFeedBackSuccess={setFeedBackSuccess} />
      )}
    </PageWrapper>
  )
}

export default ContactUs
