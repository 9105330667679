import styled from 'styled-components'
import { space, color, typography, layout, flexbox, border, position, compose } from 'styled-system'

import { theme } from 'styles/theme'

export const StyledSpan = styled.span`
  font-family: ${theme.typography.fontFamily};
  text-align: ${props => props.centered && 'center'};

  ${compose(space, color, typography, layout, flexbox, border, position)};
`
