import React from 'react'

import { Heading as H, Paragraph as P, Spacer } from 'components'
import { PaymentPlanCard, PaymentPlanCardLoading } from 'web/buyers/pages'

import TransactionReceipts from './TransactionReceipts/TransactionReceipts'

const TransactionDetails = ({ data }) => {
  if (!data) return <></>

  return (
    <>
      <H variant="h4" textAlign="left">
        Order ID
      </H>
      <Spacer height={5} />
      <P>{data.code}</P>

      <Spacer height={30} />
      <H variant="h4" textAlign="left">
        Selected plan
      </H>
      {data ? (
        <PaymentPlanCard onClick={() => {}} active={false} data={data.selected_payment_plan} />
      ) : (
        <PaymentPlanCardLoading />
      )}

      <Spacer height={20} />
      <H variant="h4" textAlign="left">
        Transaction receipts
      </H>
      {data && data.merchant && data.repayments && <TransactionReceipts data={data} />}
    </>
  )
}

export default TransactionDetails
