import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router'
import { useSwipeable } from 'react-swipeable'
import Skeleton from 'react-loading-skeleton'

import { useOrderStore } from 'stores'
import {
  PageWrapper,
  Flex,
  CircularProgress,
  NavigateBack,
  SlideToggle,
  Heading as H,
  Paragraph as P,
  Spacer,
  TriggerPopup,
} from 'components'
import { formatAsCurrency, logUserInteractionGA } from 'utils'
import { theme } from 'styles'

import TransactionPayment from './TransactionPayment/TransactionPayment'
import TransactionDetails from './TransactionDetails/TransactionDetails'

const Transaction = () => {
  const history = useHistory()
  const location = useLocation().search
  const orderCode = new URLSearchParams(location).get('code')
  const dataType = new URLSearchParams(location).get('type') || 'payment'

  const [activeIndex, setActiveIndex] = useState(dataType === 'payment' ? 0 : 1)

  const {
    isLoading: { order: orderIsLoading },
    response: { order: orderResponse },
    errors: { order: orderError },
    resetStates,
    fetchOrder,
  } = useOrderStore()

  // *Methods
  const handleGotoDetails = () => {
    if (dataType === 'payment') {
      logUserInteractionGA('Click: Transaction Details Tab')
      history.replace(`/transaction/order?code=${orderCode}&type=details`)
      setActiveIndex(1)
    }
  }

  const handleGotoPayment = () => {
    if (dataType === 'details') {
      logUserInteractionGA('Click: Transaction Payment Tab')
      history.replace(`/transaction/order?code=${orderCode}&type=payment`)
      setActiveIndex(0)
    }
  }

  // *Tab options
  const TRANSACTION_TAB_OPTIONS = [
    {
      label: 'Payment',
      callback: handleGotoPayment,
    },
    {
      label: 'Details',
      callback: handleGotoDetails,
    },
  ]

  const swipeHandlers = useSwipeable({
    onSwiped: eventData => {
      const { dir } = eventData

      switch (dir) {
        case 'Left':
          handleGotoDetails()
          break
        case 'Right':
          handleGotoPayment()
          break
        default:
          return
      }
    },
  })

  // *Effects
  useEffect(() => {
    fetchOrder(orderCode)
    return () => resetStates('order')
  }, [])

  // *JSX
  return (
    <div {...swipeHandlers}>
      {/* Error handling */}
      <TriggerPopup
        showPopup={orderError && orderError.message}
        message={orderError && orderError.message}
        callback={() => resetStates('order')}
      />

      <PageWrapper withTopNav>
        <NavigateBack noBackground />
        <Flex stretch centered flexDirection="column">
          {orderResponse && !orderIsLoading ? (
            <>
              <CircularProgress
                strokeWidth="5"
                sqSize="86"
                invisiblePercentageLong
                percentageTotal={orderResponse.repayments_total_mount}
                percentageLong={orderResponse.repayments_total_mount}
                percentageShort={orderResponse.repayments_remaining_amount}
                logo={orderResponse.merchant && orderResponse.merchant.logo}
                logoSize={45}
                invert
              />
              <P variant="small" style={{ fontWeight: 700 }}>
                {orderResponse.store.name}
              </P>
              <H variant="h1" style={{ fontSize: 32 }}>
                {formatAsCurrency(orderResponse.repayments_remaining_amount)}
              </H>
              <P
                variant="small"
                color={theme.colors.typography.gray2}
                style={{ fontWeight: 600 }}
              >{`of ${formatAsCurrency(orderResponse.repayments_total_mount)} remaining`}</P>
            </>
          ) : (
            <>
              <Skeleton height={86} width={86} circle />
              <Spacer height={5} />
              <Skeleton height={36} width={150} />
              <Spacer height={2} />
              <Skeleton height={21} width={160} />
            </>
          )}
        </Flex>
        <Spacer height={20} />

        <Flex stretch px={2} flexDirection="column">
          <SlideToggle options={TRANSACTION_TAB_OPTIONS} activeIndex={activeIndex} />
          <Spacer height={30} />

          {activeIndex === 0 && (
            <TransactionPayment data={orderResponse && !orderIsLoading ? orderResponse : null} />
          )}
          {activeIndex === 1 && (
            <TransactionDetails data={orderResponse && !orderIsLoading ? orderResponse : null} />
          )}
        </Flex>
      </PageWrapper>
    </div>
  )
}

export default Transaction
