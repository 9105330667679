import styled from 'styled-components'
import { variant } from 'styled-system'
import { motion } from 'framer-motion'
import { theme } from 'styles/theme'

export const StyledContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 43px;
  border-radius: 21.5px;
  width: 100%;

  ${variant({
    variants: {
      default: {
        background: theme.colors.background,
      },
      transparent: {
        background: 'rgba(0, 0, 0, 0.2)',
      },
    },
  })};
`

export const ActiveStyledOption = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 21.5px;
  font-size: 14px;
  font-weight: 600;
  font-family: ${theme.typography.fontFamily};
  color: black;
  background: rgba(255, 255, 255, 1);

  ${variant({
    variants: {
      default: {
        boxShadow: '0px 2px 4px 0px #00000041',
      },
      transparent: {
        boxShadow: '0px 2px 4px 0px #00000080',
      },
    },
  })}
`

export const InactiveStyledOption = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 21.5px;
  font-size: 14px;
  font-weight: 600;
  font-family: ${theme.typography.fontFamily};
  background: transparent;

  ${variant({
    variants: {
      default: {
        color: 'black',
      },
      transparent: {
        color: 'white',
      },
    },
  })}
`
