import React, { useState } from 'react'
import { AnimatePresence } from 'framer-motion'

import {
  RoundedContainer,
  Image,
  Flex,
  Paragraph as P,
  Highlighted,
  Spacer,
  RowDivider,
} from 'components'
import { caretDownBlack } from 'assets'
import { theme } from 'styles/theme'

const BasicAccordion = ({ title, content, highlight, ...rest }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <AnimatePresence>
      <RoundedContainer width="100%" boxShadow="none" key={title} {...rest}>
        <Flex justifyContent="space-between" alignItems="center" onClick={() => setIsOpen(!isOpen)}>
          <P variant="small" style={{ fontWeight: 700 }}>
            <Highlighted text={title} highlight={highlight} />
            {/* {title} */}
          </P>
          <Spacer width={15} />
          <Image
            src={caretDownBlack}
            width="16px"
            height="16px"
            alt="expand_accordion"
            initial={{
              rotate: 0,
              marginBottom: 5,
            }}
            animate={{
              rotate: isOpen ? 180 : 0,
              marginBottom: isOpen ? 0 : 5,
            }}
          />
        </Flex>

        {isOpen && (
          <Flex
            flexDirection="column"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ type: 'tween' }}
          >
            <Spacer height={10} />
            <RowDivider />
            <Spacer height={10} />
            <P
              variant="small"
              color={theme.colors.typography.gray1}
              style={{ whiteSpace: 'pre-line', fontWeight: 500 }}
            >
              <Highlighted text={content} highlight={highlight} />
              {/* {content} */}
            </P>
          </Flex>
        )}
      </RoundedContainer>
    </AnimatePresence>
  )
}

export default BasicAccordion
