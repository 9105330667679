import styled from 'styled-components'
import { space, color, typography, layout, flexbox, border, position, compose } from 'styled-system'

import { motion } from 'framer-motion'

export const StyledFlex = styled(motion.div)`
  display: flex;
  position: relative;
  box-sizing: border-box;
  width: ${props => (props.stretch ? '100%' : props.fitWidth ? 'calc(100vw - 32px)' : 'auto')};

  ${compose(space, color, typography, layout, flexbox, border, position)}
  ${({ centered }) =>
    centered &&
    `
    align-items: center;
    justify-content: center;
  `};

  ${({ full }) =>
    full &&
    `
    min-height: 100vh;
    // height: 100%;
    width: 100vw;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: scroll
  `}
`
