import { sortObject, advancedDayjs } from 'utils'

export const groupRepaymentsIntoMonths = repayments => {
  const results = {}
  repayments.map(repayment => {
    const year = advancedDayjs(repayment.due_at).year()
    const month = advancedDayjs(repayment.due_at).month()

    if (!results[year]) {
      return (results[year] = { [month]: [repayment] })
    }

    if (results[year] && !results[year][month]) {
      return (results[year] = sortObject({
        ...results[year],
        [month]: [repayment],
      }))
    }

    return (results[year] = sortObject({
      ...results[year],
      [month]: [...results[year][month], repayment],
    }))
  })

  return sortObject(results)
}
