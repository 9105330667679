import React, { useEffect } from 'react'

import { BASE_BACKEND_URL } from 'const'

const CatchAll = () => {
  useEffect(() => {
    const { pathname, search } = window.location

    window.location.href = `${BASE_BACKEND_URL}${pathname}${search}`
  }, [])

  return <></>
}

export default CatchAll
