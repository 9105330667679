import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'

import {
  Link,
  Flex,
  Button,
  Box,
  Heading as H,
  Paragraph as P,
  FormInput,
  FormSelect,
  NavigateBack,
  Spacer,
  Footer,
} from 'components'
import { countryCodeOptions, WHITELABEL } from 'const'
import { useAuthStore } from 'stores'
import { registration } from 'contents'
import { theme } from 'styles/theme'
import {
  setCountryCodeLocalDb,
  getCountryCodeLocalDb,
  findCountry,
  setMobilePrefixLocalDb,
  setMobileNumberLocalDb,
  findTosUrl,
  findPrivacyPolicyUrl,
} from 'utils'

const MobileNoForm = ({ showOtp }) => {
  const countryCode = getCountryCodeLocalDb()

  const tosUrl = findTosUrl(countryCode)
  const privacyPolicyUrl = findPrivacyPolicyUrl(countryCode)

  const [submittedMobile, setSubmittedMobile] = useState([])

  const {
    isLoading: { otpSend: otpSendIsLoading },
    response: { otpSend: otpSendResponse },
    errors: { otpSend: otpSendErrors },
    submitMobile,
  } = useAuthStore()

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors: formErrors },
    setError,
  } = useForm()

  // *Methods
  const onSelectCountry = countryCode => {
    setCountryCodeLocalDb(countryCode)
    window.location.href = `/${countryCode}/register/`
  }

  const checkValidNumber = () => {
    const currentCountry = findCountry(countryCode)
    return currentCountry.mobileNumberValidator(watch('mobile_number'))
  }

  const onSubmit = data => {
    const prefix = data.prefix.value
    const mobile = data.mobile_number

    let countryCode = ''
    if (prefix === '65') countryCode = 'sg'
    if (prefix === '60') countryCode = 'my'
    setCountryCodeLocalDb(countryCode)

    setSubmittedMobile([prefix, mobile])
    setMobilePrefixLocalDb(prefix)
    setMobileNumberLocalDb(mobile)
    submitMobile(prefix, mobile)
  }

  // *Effects
  useEffect(() => {
    if (otpSendResponse) {
      showOtp(submittedMobile)
    }
  }, [otpSendResponse])

  useEffect(() => {
    if (otpSendErrors) {
      for (const [key, value] of Object.entries(otpSendErrors)) {
        setError(key, { message: value[0] }, true)
      }
    }
  }, [otpSendErrors])

  // *JSX
  return (
    <>
      <NavigateBack hide>{registration.navigation.otp_verification}</NavigateBack>
      <Box mb={4}>
        <H variant="h1">Enter your phone number</H>
      </Box>
      <Flex width="100%" flex="1 1 auto">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex pr={10}>
            <Controller
              name="prefix"
              control={control}
              defaultValue={findCountry(countryCode)}
              render={({ field }) => (
                <FormSelect
                  {...field}
                  options={countryCodeOptions}
                  defaultValue={findCountry(countryCode)}
                  value={countryCodeOptions.find(c => c.value === field.value)}
                  isSearchable={false}
                  isDisabled={WHITELABEL === 'ghl'}
                  onChange={e => onSelectCountry(e.code)}
                  style={{ width: 100 }}
                  hideChevron
                />
              )}
            />
            <Spacer width={10} />

            <FormInput
              placeholder={countryCode === 'sg' ? '91234567' : '165123456'}
              label="mobile"
              register={register}
              id="mobile_number"
              type="number"
              disabled={otpSendIsLoading}
              error={formErrors.mobile_number}
              style={{ paddingTop: 12.5, paddingBottom: 12.5 }}
            />
          </Flex>

          <Spacer height={5} />

          {/* hidden input for submission in mobile */}
          <input type="submit" style={{ display: 'none' }} disabled={!checkValidNumber()} />

          <Footer flexDirection="column" centered>
            <P color={theme.colors.lockGray} style={{ fontSize: 10 }}>
              By proceeding, I agree to Abnk&apos;s{' '}
              <Link href={tosUrl} target="_blank">
                Terms of Service
              </Link>{' '}
              and{' '}
              <Link href={privacyPolicyUrl} target="_blank">
                Privacy Policy
              </Link>
            </P>

            <Button isLoading={otpSendIsLoading} type="submit" disabled={!checkValidNumber()}>
              PROCEED
            </Button>
          </Footer>
        </form>
      </Flex>
    </>
  )
}

MobileNoForm.propTypes = {
  showOtp: PropTypes.func.isRequired,
}

export default MobileNoForm
