import styled from 'styled-components'
import { motion } from 'framer-motion'

import { theme } from 'styles/theme'

export const StyledCategoryItem = styled(motion.div)`
  display: flex;
  background-color: white;
  border: 1px solid ${theme.colors.buttons.lightGray};
  border-radius: 22px;
  padding: 10px 15px;
`
