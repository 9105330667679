import React from 'react'

import { Flex, Paragraph as P } from 'components'
import { PROFILE_OPTIONS } from 'const'
import { theme } from 'styles/theme'
import { getCountryCodeLocalDb, logUserInteractionGA } from 'utils'

import ProfileOptionRow from './ProfileOptionRow/ProfileOptionRow'
import { signOut, clearLocalDb } from 'utils'

const ProfileOptions = () => {
  const countryCode = getCountryCodeLocalDb()

  return (
    <Flex flexDirection="column">
      {PROFILE_OPTIONS.map(option => {
        if (
          countryCode === 'sg' &&
          (option.text === 'Test Magiclink MY' || option.text === 'Test Store Slug MY')
        ) {
          return null
        }
        if (
          countryCode === 'my' &&
          (option.text === 'Test Magiclink SG' || option.text === 'Test Store Slug SG')
        ) {
          return null
        }

        return <ProfileOptionRow data={option} key={option.text} />
      })}
      <Flex
        height={60}
        alignItems="center"
        pl={16}
        onClick={() => {
          logUserInteractionGA('Click: Logout')
          clearLocalDb()
          signOut()
        }}
      >
        <P color={theme.colors.typography.red1} style={{ fontWeight: 600 }}>
          Log out
        </P>
      </Flex>
    </Flex>
  )
}

export default ProfileOptions
