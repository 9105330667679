import React from 'react'

import { theme } from 'styles/theme'
import { PageWrapper, NavigateBack } from 'components'

import SettingsContainer from './SettingsContainer/SettingsContainer'

const Settings = () => {
  return (
    <PageWrapper withTopNav backgroundColor={theme.colors.background}>
      <NavigateBack withBottomBorder>Settings</NavigateBack>
      <SettingsContainer />
    </PageWrapper>
  )
}

export default Settings
