import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import Skeleton from 'react-loading-skeleton'

import {
  PageWrapper,
  Flex,
  NavigateBack,
  RoundedContainer,
  Image,
  Spacer,
  Heading as H,
  Footer,
  Button,
  TriggerPopup,
} from 'components'
import {
  getStoreIdLocalDb,
  getFromStoreIdLocalDb,
  setDraftOrderLocalDb,
  logUserInteractionGA,
} from 'utils'
import { theme } from 'styles/theme'
import { useOrderStore } from 'stores'

import ManualAmount from './ManualAmount/ManualAmount'

const PayForOrderManual = () => {
  const history = useHistory()
  const storeId = getStoreIdLocalDb()
  const store = getFromStoreIdLocalDb()

  const [amount, setAmount] = useState('')
  const [isValidAmount, setIsValidAmount] = useState(false)
  const [error, setError] = useState('')

  // *Stores
  const {
    isLoading: { orderForStore: orderForStoreIsLoading },
    response: { orderForStore: orderForStoreResponse },
    errors: { orderForStore: orderForStoreError },
    resetStates: resetStatesOrder,
    submitOrderForStore,
  } = useOrderStore()

  // *Methods
  const handleCreateOrder = () => {
    if (storeId && amount) {
      submitOrderForStore(storeId, amount)
    }
  }

  // *Effects
  useEffect(() => {
    return () => {
      resetStatesOrder('orderForStore')
    }
  }, [])

  useEffect(() => {
    if (error) {
      logUserInteractionGA(`Error - ${error}`)
    }
  }, [error])

  useEffect(() => {
    if (orderForStoreResponse) {
      setDraftOrderLocalDb(orderForStoreResponse)
      if (orderForStoreResponse.code) {
        return history.push(`/order/pay?code=${orderForStoreResponse.code}`)
      } else {
        return history.push('/order/pay')
      }
    }
  }, [orderForStoreResponse])

  useEffect(() => {
    if (orderForStoreError) {
      if (orderForStoreError.store_id) {
        setError('Store is not found.')
      } else if (orderForStoreError.amount && orderForStoreError.amount.length > 0) {
        setError(orderForStoreError.amount[0])
      } else if (orderForStoreError) {
        setError(orderForStoreError)
      } else {
        setError('An error occurred when creating the order. Please try again.')
      }
    }
  }, [orderForStoreError])

  // *JSX
  return (
    <PageWrapper withTopNav>
      {/* Error handling */}
      <TriggerPopup
        showPopup={error.length > 0}
        message={error}
        buttonText={orderForStoreError && orderForStoreError.amount ? 'OKAY' : 'RETURN HOME'}
        callback={() => {
          resetStatesOrder('orderForStore')
          if (orderForStoreError && orderForStoreError.amount) {
            setError('')
          }
        }}
        stayAtCurrentPage={orderForStoreError && !!orderForStoreError.amount}
      />

      <NavigateBack onClick={() => history.push('/home')}>Pay Merchant</NavigateBack>

      <Flex stretch centered flexDirection="column">
        <RoundedContainer
          width={150}
          height={150}
          justifyContent="center"
          boxShadow="0px 2px 7px 0px #00000019"
          borderRadius={12}
        >
          {store && store.merchant && store.merchant.logo ? (
            <Image src={store.merchant.logo} alt="merchant_logo" width={110} height={110} />
          ) : (
            <Skeleton height={100} width={110} />
          )}
        </RoundedContainer>
        <Spacer height={25} />

        {store && store.merchant && 'min_in_store_checkout_order_grand_total' in store.merchant && (
          <ManualAmount
            setAmount={setAmount}
            onSubmit={handleCreateOrder}
            isValidAmount={isValidAmount}
            setIsValidAmount={setIsValidAmount}
            minOrderAmount={store.merchant.min_in_store_checkout_order_grand_total}
          />
        )}
        <Spacer height={20} />

        {store ? (
          <H variant="h4" color={theme.colors.typography.gray8} fontWeight={600} centered>
            {store.name}
          </H>
        ) : (
          <Skeleton height={40} width={110} />
        )}

        <Footer withTopBorder centered flexDirection="column">
          <Spacer height="6px" />
          <Button
            mb={10}
            onClick={handleCreateOrder}
            isLoading={orderForStoreIsLoading}
            disabled={!amount || !isValidAmount}
          >
            CONTINUE
          </Button>
        </Footer>
      </Flex>
    </PageWrapper>
  )
}

export default PayForOrderManual
