import React from 'react'
import PropTypes from 'prop-types'

import { Flex, Paragraph as P, Image, Span } from 'components'
import { theme } from 'styles/theme'
import { visaLogo, mastercardLogo, cardIcon, threeDots } from 'assets'
import { secureCardNumber } from 'utils'

import { StyledCardContainer, StyledCardIcon } from './paymentMethodCardStyle'
import PaymentMethodCardOptions from './PaymentMethodCardOptions/PaymentMethodCardOptions'

const PaymentMethodCard = ({
  brand,
  endingDigits,
  funding,
  paymentMethodId,
  isDefault,
  canTerminate,
  optionsIsOpenFor,
  setOptionsIsOpenFor,
  invalidMessage,
  ...rest
}) => {
  // *Methods
  const renderIcon = () => {
    switch (brand) {
      case 'visa':
        return (
          <Flex height={47}>
            <Image src={visaLogo} alt="visa_logo" style={{ width: 47 }} />
          </Flex>
        )
      case 'mastercard':
      case 'mc':
        return (
          <Flex height={47}>
            <Image src={mastercardLogo} alt="mastercard_logo" style={{ width: 47 }} />
          </Flex>
        )
      default:
        return (
          <StyledCardIcon>
            <Image src={cardIcon} alt="card_icon" />
          </StyledCardIcon>
        )
    }
  }

  const handleShowOptions = () => {
    if (optionsIsOpenFor === paymentMethodId) {
      setOptionsIsOpenFor('')
    } else {
      setOptionsIsOpenFor(paymentMethodId)
    }
  }

  // *JSX
  return (
    <>
      <StyledCardContainer {...rest}>
        <Flex>
          {renderIcon()}
          <Flex>
            <Flex flexDirection="column" justifyContent="center" ml={3}>
              <P style={{ fontWeight: 700 }}>
                {funding === 'credit' ? 'Credit Card' : 'Debit Card'}
                {isDefault && (
                  <Span
                    style={{
                      marginLeft: 10,
                      color: theme.colors.buttons.marineBlue,
                    }}
                  >
                    Default
                  </Span>
                )}
              </P>
              <P color={theme.colors.typography.gray7}>{secureCardNumber(endingDigits)}</P>
            </Flex>
          </Flex>
        </Flex>
        <Flex justifySelf="flex-end" onClick={handleShowOptions}>
          <Image src={threeDots} alt="open_options" />
        </Flex>
        {optionsIsOpenFor === paymentMethodId && (
          <PaymentMethodCardOptions
            paymentMethodId={paymentMethodId}
            setOptionsIsOpenFor={setOptionsIsOpenFor}
            disableSetAsDefault={invalidMessage}
            canTerminate={canTerminate}
          />
        )}
      </StyledCardContainer>
      {invalidMessage && (
        <P
          variant="subcopy"
          color={theme.colors.actions.failureRed}
          style={{
            fontSize: 10,
            fontWeight: 400,
            marginTop: -5,
            paddingLeft: 3,
          }}
        >
          {invalidMessage}
        </P>
      )}
    </>
  )
}

PaymentMethodCard.propTypes = {
  brand: PropTypes.string.isRequired,
  endingDigits: PropTypes.string.isRequired,
  funding: PropTypes.string.isRequired,
  paymentMethodId: PropTypes.string.isRequired,
  isDefault: PropTypes.bool.isRequired,
  canTerminate: PropTypes.bool.isRequired,
  optionsIsOpenFor: PropTypes.string.isRequired,
  setOptionsIsOpenFor: PropTypes.func.isRequired,
}

export default PaymentMethodCard
