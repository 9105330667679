import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'

import { PageWrapper, NavigateBack, TriggerPopup } from 'components'
import { addBankTransfer } from 'assets'
import {
  pushPaymentMethodsHistory,
  getPaymentMethodRedirectLocalDb,
  removePaymentMethodRedirectLocalDb,
  getShouldRedirectToCredit,
} from 'utils'

import AddPaymentMethodRow from './AddPaymentMethodRow/AddPaymentMethodRow'

const AddPaymentMethod = () => {
  const history = useHistory()
  const location = useLocation().search
  const status = new URLSearchParams(location).get('status')

  const [addMethodStatus, setAddMethodStatus] = useState('')

  // *Methods
  const handleAddPaymentMethodSuccess = () => {
    const redirectUrl = getPaymentMethodRedirectLocalDb()
    if (redirectUrl) {
      // if user is coming from an order
      removePaymentMethodRedirectLocalDb()
      if (redirectUrl.split('?').length === 1) {
        window.location.replace(`${redirectUrl}?use_new_payment_method=true`)
      } else {
        window.location.replace(`${redirectUrl}&use_new_payment_method=true`)
      }
    } else {
      // if user should return to credit screen
      if (getShouldRedirectToCredit()) {
        return history.push('/verify/success?type=paymentmethod')
      } else {
        pushPaymentMethodsHistory(history, '/verify/success?type=paymentmethod')
      }
    }
  }

  const handleAddPaymentMethodFailure = () => {
    return history.push('/paymentmethods/new/bank')
  }

  // *Effects
  useEffect(() => {
    if (status) {
      // use timeout for smoother animation
      setTimeout(() => {
        if (status === 'success') {
          setAddMethodStatus('success')
        } else {
          setAddMethodStatus('failure')
        }
      }, 250)
    }
  }, [status])

  // *JSX
  return (
    <PageWrapper withTopNav>
      {/* handle status */}
      {addMethodStatus === 'failure' && (
        <TriggerPopup
          showPopup
          message="We encountered an error while adding your payment method. Please try again."
          buttonText="OKAY"
          stayAtCurrentPage
          callback={handleAddPaymentMethodFailure}
        />
      )}

      {addMethodStatus === 'success' && (
        <TriggerPopup
          showPopup
          message="Payment method added successfully!"
          buttonText="OKAY"
          stayAtCurrentPage
          callback={handleAddPaymentMethodSuccess}
        />
      )}

      <NavigateBack withBottomBorder>New payment method</NavigateBack>

      {/*<AddPaymentMethodRow*/}
      {/*  icon={addCreditCard}*/}
      {/*  text="Credit/Debit card"*/}
      {/*  onClick={() => history.push('/paymentmethods/new/credit')}*/}
      {/*/>*/}

      <AddPaymentMethodRow
        icon={addBankTransfer}
        text="eGIRO"
        onClick={() => history.push('/paymentmethods/new/bank')}
      />
    </PageWrapper>
  )
}

export default AddPaymentMethod
