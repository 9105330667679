import create from 'zustand'

const useScreenSize = () => {
  const screenSizeStore = create(set => ({
    isMobile: window.innerWidth < 768,
    isTablet: window.innerWidth >= 768 && window.innerWidth < 992,
    isDesktop: window.innerWidth >= 992,
    isLargeMobile: window.innerWidth >= 576 && window.innerWidth < 768,
    isLandscapeTablet: window.innerWidth >= 992 && window.innerWidth < 1025,
    isWideDesktop: window.innerWidth >= 1200,
    updateValues: () => {
      set(state => {
        const windowWidth = window.innerWidth
        return {
          ...state,
          ...{
            isMobile: windowWidth < 768,
            isLargeMobile: windowWidth >= 576 && windowWidth < 768,
            isTablet: windowWidth >= 768 && windowWidth < 992,
            isDesktop: windowWidth >= 992,
            isWideDesktop: windowWidth >= 1200,
          },
        }
      })
    },
  }))

  return { screenSize: screenSizeStore.getState, screenSizeStore }
}

export default useScreenSize
