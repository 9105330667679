import styled from 'styled-components'
import { space, color, typography, layout, flexbox, border, position, compose } from 'styled-system'
import { motion } from 'framer-motion'

export const AnimatedContainer = styled(motion.div)`
  position: relative;
`

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  background-color: white;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.098066);
  margin: 0;
  width: calc(100% - 20px);
  padding: 20px;
  padding-left: 40px;
  margin: 10px 0;
  margin-left: 15px;
  box-sizing: border-box;
  border-radius: 12px;

  ${compose(space, color, typography, flexbox, border, position, layout)}
`

export const StyledNumbering = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  margin-top: -28px;
  left: -12px;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  border-width: 4px;
  border-style: solid;

  ${compose(space, color, typography, flexbox, border, position, layout)}
`
