import React from 'react'
import BankMandateForm from './BankMandateForm'
import { useHistory } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import { useBuyerStore } from 'stores'

const TerminateBankMandate = () => {
  const history = useHistory()
  const { data, paymentMethodId } = history.location.state

  const methods = useForm({ mode: 'all' })
  const { removePaymentMethod } = useBuyerStore()

  const handleSubmit = async () => {
    if (!paymentMethodId) return

    const res = await removePaymentMethod(paymentMethodId)
    return res
  }

  return (
    <FormProvider {...methods}>
      <BankMandateForm
        backButtonText="Terminate mandate"
        buttonText="CONFIRM TERMINATION"
        buttonVariant="secondary"
        path="terminate"
        editableFields={[]}
        handleSubmit={handleSubmit}
        defaultData={data}
        paymentMethodId={paymentMethodId}
      />
    </FormProvider>
  )
}

export default TerminateBankMandate
