import React from 'react'
import { useHistory } from 'react-router'

import { Flex, Paragraph as P, Spacer, Image, Link } from 'components'
import { rightCaretGray } from 'assets'
import { findTosUrl, getCountryCodeLocalDb, logUserInteractionGA } from 'utils'

const ProfileOptionRow = ({ data }) => {
  const { icon, text, destination } = data
  const history = useHistory()
  const countryCode = getCountryCodeLocalDb()

  // handle terms of service
  if (destination === '/termsofservice') {
    const url = findTosUrl(countryCode)

    return (
      <Link href={url} target="_blank" alt="tos" style={{ color: 'black', textDecoration: 'none' }}>
        <Flex
          justifyContent="space-between"
          style={{
            width: '100vw',
            borderBottom: '1px solid #DCDCDC',
            marginLeft: '-16px',
            padding: '14px 28px 14px 32px',
          }}
        >
          <Flex stretch>
            <Image src={icon} alt={text} width={20} height={20} />
            <Spacer width={10} />

            <P style={{ fontWeight: 600 }}>{text}</P>
          </Flex>
          <Flex>
            <Image src={rightCaretGray} alt="right_caret" />
          </Flex>
        </Flex>
      </Link>
    )
  }

  return (
    <Flex
      justifyContent="space-between"
      style={{
        width: '100vw',
        borderBottom: '1px solid #DCDCDC',
        marginLeft: '-16px',
        padding: '14px 28px 14px 32px',
      }}
      onClick={() => {
        logUserInteractionGA(`Click: ${text}`)
        history.push(destination)
      }}
    >
      <Flex stretch>
        <Image src={icon} alt={text} width={20} height={20} />
        <Spacer width={10} />
        <P style={{ fontWeight: 600 }}>{text}</P>
      </Flex>
      <Flex>
        <Image src={rightCaretGray} alt="right_caret" />
      </Flex>
    </Flex>
  )
}

export default ProfileOptionRow
