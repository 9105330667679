import React from 'react'
import PropTypes from 'prop-types'

import { StyledParagraph } from './paragraphStyle'

const Paragraph = ({ centered, variant, children, ...rest }) => {
  return (
    <StyledParagraph centered={centered} variant={variant} {...rest}>
      {children}
    </StyledParagraph>
  )
}

Paragraph.propTypes = {
  centered: PropTypes.bool,
  variant: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Paragraph.defaultProps = {
  centered: false,
  variant: 'normal',
}

export default Paragraph
