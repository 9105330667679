import React, { useState } from 'react'
import AutosizeInput from 'react-input-autosize'

import {
  Flex,
  Heading as H,
  Paragraph as P,
  Span,
  RoundedContainer,
  Spacer,
  Image,
  RowDivider,
  StyledSlider,
  Button,
} from 'components'
import {
  fetchCurrencySymbol,
  formatAsCurrency,
  limitInputToTwoDecimals,
  logUserInteractionGA,
} from 'utils'
import { theme } from 'styles'
import { dashedLine } from 'assets'

import PayRemainingAmountLoading from './PayRemainingAmountLoading'
import PayRemainingAmountRow from './PayRemainingAmountRow/PayRemainingAmountRow'

const PayRemainingAmount = ({
  isLoading,
  activeCategory,
  togglePaymentSchedule,
  repaymentsRemainingAmount,
  selectedRepaymentPlan,
  balanceToPay,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [partialRepaymentAmount, setPartialRepaymentAmount] = useState([20])

  // *Methods
  // const toggleEdit = () => {
  //     setIsEditing(!isEditing)
  // }

  const handleTogglePaymentSchedule = () => {
    // setTutorialStep(0);
    logUserInteractionGA('Click: View Adjusted Payment Schedule')
    togglePaymentSchedule()
  }

  const handleChangeAmount = e => {
    const value = e.target.value
    const amount = limitInputToTwoDecimals(value)
    if (parseFloat(amount) > 40) return
    setPartialRepaymentAmount([limitInputToTwoDecimals(amount)])
  }

  const handleSubmitRepaymentAmount = () => {
    setIsEditing(false)
  }

  // *JSX
  return (
    <Flex flexDirection="column" fitWidth>
      <Flex>
        <P style={{ fontWeight: 700 }}>Payment amount</P>
        <Spacer width={5} />
        {/* {repaymentsRemainingAmount && selectedRepaymentPlan && (
          <HelpIcon onClick={handleTutorial} />
        )} */}
      </Flex>

      <RoundedContainer id="pay_early_highlight">
        {repaymentsRemainingAmount && selectedRepaymentPlan && !isLoading ? (
          <Flex flexDirection="column">
            <PayRemainingAmountRow
              title="Balance to pay"
              value={balanceToPay}
              tutorial_id="pay_early_1"
            />

            {isEditing && activeCategory === 'Partial repayment' && (
              <Flex
                flexDirection="column"
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                transition={{ type: 'tween', duration: 0.3 }}
              >
                <Flex alignItems="center">
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      border: `1px solid ${theme.colors.buttons.marineBlue}`,
                      borderRadius: 4,
                      padding: '8px 0px 8px 18px',
                      marginRight: 5,
                    }}
                  >
                    <H variant="h4">{fetchCurrencySymbol()}</H>
                    <AutosizeInput
                      autoFocus
                      type="number"
                      name="manual_amount"
                      step="0.01"
                      placeholder="0"
                      value={partialRepaymentAmount}
                      onChange={handleChangeAmount}
                      inputStyle={{
                        fontSize: 18,
                        fontFamily: 'Poppins',
                        fontWeight: 700,
                        border: 'none',
                        marginRight: 3,
                      }}
                    />
                  </Flex>
                  <P style={{ fontWeight: 700 }}>
                    <Span style={{ fontWeight: 500 }}> out of </Span>
                    {formatAsCurrency(40)}
                  </P>
                </Flex>

                <Spacer height={10} />
                <Flex>
                  <StyledSlider
                    values={partialRepaymentAmount}
                    setValues={setPartialRepaymentAmount}
                    min={0}
                    max={40}
                  />
                </Flex>
                <Button
                  variant="tutorial"
                  style={{
                    color: theme.colors.buttons.marineBlue,
                    border: `1px solid ${theme.colors.buttons.marineBlue}`,
                    alignSelf: 'flex-end',
                  }}
                  onClick={handleSubmitRepaymentAmount}
                >
                  Done
                </Button>
              </Flex>
            )}

            {selectedRepaymentPlan &&
              selectedRepaymentPlan.savings_amount &&
              selectedRepaymentPlan.savings_amount !== '0.00' && (
                <PayRemainingAmountRow
                  title="Early payment savings"
                  value={selectedRepaymentPlan.savings_amount}
                  color={theme.colors.progressbar.barGreen1}
                  tutorial_id="pay_early_2"
                  isNegativeValue
                />
              )}

            <Image src={dashedLine} alt="dashed_line" style={{ marginTop: 12, marginBottom: 12 }} />

            <PayRemainingAmountRow
              title="Today's payment"
              value={selectedRepaymentPlan.early_repayment_amount}
              tutorial_id="pay_early_3"
            />

            <RowDivider style={{ margin: '10px 0px 15px 0px' }} />

            <P
              textAlign="center"
              color={theme.colors.buttons.marineBlue}
              style={{ fontWeight: 700 }}
              onClick={handleTogglePaymentSchedule}
              id="pay_early_4"
            >
              View adjusted payment schedule
            </P>
          </Flex>
        ) : (
          <PayRemainingAmountLoading />
        )}
      </RoundedContainer>
    </Flex>
  )
}

export default PayRemainingAmount
