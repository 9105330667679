import React from 'react'
import PropTypes from 'prop-types'

import { FormLabel, FormError, Flex } from 'components'

import { StyledTextArea } from './formTextAreaStyle'

const FormTextArea = React.forwardRef(({ showLabel, label, error, placeholder, ...rest }, ref) => {
  return (
    <Flex flexDirection="column" width="100%">
      {showLabel && <FormLabel>{label}</FormLabel>}
      <StyledTextArea ref={ref} placeholder={placeholder} error={error} {...rest} />
      {error && (
        <FormError>
          {error.type === 'required' ? 'This field is required' : error.message}
        </FormError>
      )}
    </Flex>
  )
})

FormTextArea.displayName = 'FormTextArea'

FormTextArea.propTypes = {
  showLabel: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
  placeholder: PropTypes.string,
}

export default FormTextArea
