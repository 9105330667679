import React from 'react'
import PropTypes from 'prop-types'

import { StyledRowDivider } from './rowDividerStyle'

const RowDivider = ({ width, show, ...rest }) => {
  return <StyledRowDivider width={width} show={show} {...rest} />
}

RowDivider.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

RowDivider.defaultProps = {
  width: '100%',
}

export default RowDivider
