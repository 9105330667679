import React from 'react'
import { useHistory } from 'react-router'

import { Flex, Box, Paragraph as P, Image } from 'components'
import {
  home,
  homeActive,
  buy,
  buyActive,
  scanQr,
  transaction,
  transactionActive,
  profile,
  profileActive,
} from 'assets'
import { theme } from 'styles/theme'

import { StyledContainer, StyledNavItem } from './bottomBarStyle'
import { logUserInteractionGA } from 'utils'

const NavigationItem = ({ icon, text, active, onClick }) => {
  return (
    <Flex flexDirection="column" flexBasis="20%" maxWidth="20%" centered onClick={onClick}>
      <StyledNavItem>
        <Image src={icon} alt="navigation-icon" height={22} width={22} />
        <P
          variant="subcopy"
          mt="3px"
          style={{
            fontWeight: 500,
            color: active ? theme.colors.abnkBlue : theme.colors.typography.gray3,
          }}
        >
          {text}
        </P>
      </StyledNavItem>
    </Flex>
  )
}

const BottomBar = () => {
  const history = useHistory()
  const currentUrl = window.location.pathname

  // *Methods
  const checkIsActive = pathsArr => {
    return pathsArr.find(path => currentUrl.includes(path))
  }

  return (
    <StyledContainer>
      <Flex flexWrap="wrap" height={85}>
        <NavigationItem
          icon={checkIsActive(['/home', '/credit']) ? homeActive : home}
          text="Home"
          active={checkIsActive(['/home', '/credit'])}
          onClick={() => {
            logUserInteractionGA('Navigate: Home Tab')
            history.push('/home')
          }}
        />
        <NavigationItem
          icon={checkIsActive(['/buy']) ? buyActive : buy}
          text="Buy"
          active={checkIsActive(['/buy'])}
          onClick={() => {
            logUserInteractionGA('Navigate: Buy Tab')
            history.push('/buy')
          }}
        />
        <Flex flexBasis="20%" centered>
          <Box style={{ position: 'absolute', bottom: '15px' }}>
            <StyledNavItem>
              <Image
                src={scanQr}
                alt="scan-qr-icon"
                onClick={() => {
                  logUserInteractionGA('Navigate: QR Scanner')
                  history.push('/qrscanner')
                }}
              />
            </StyledNavItem>
          </Box>
        </Flex>
        <NavigationItem
          icon={checkIsActive(['/transactions']) ? transactionActive : transaction}
          text="Transaction"
          active={checkIsActive(['/transactions'])}
          onClick={() => {
            logUserInteractionGA('Navigate: Transactions Tab')
            history.push('/transactions')
          }}
        />
        <NavigationItem
          icon={checkIsActive(['/profile']) ? profileActive : profile}
          text="Profile"
          active={checkIsActive(['/profile'])}
          onClick={() => {
            logUserInteractionGA('Navigate: Profile Tab')
            history.push('/profile')
          }}
        />
      </Flex>
    </StyledContainer>
  )
}

export default BottomBar
