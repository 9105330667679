import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { Flex } from 'components'

import { StyledDropdownOption } from './paymentMethodDropdownStyle'

const PaymentMethodDropdownLoading = () => {
  return (
    <StyledDropdownOption style={{ alignItems: 'center' }}>
      <Skeleton height={50} width={50} style={{ borderRadius: 12 }} />
      <Flex ml={3} flexDirection="column">
        <Skeleton height={15} width={100} />
        <Skeleton height={15} width={130} />
      </Flex>
    </StyledDropdownOption>
  )
}

export default PaymentMethodDropdownLoading
