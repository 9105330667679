import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { Flex, Spacer } from 'components'

const MerchantDetailsLoading = () => {
  return (
    <Flex my={3} mx={4} pb={100} flexDirection="column" style={{ height: '100%' }}>
      <Skeleton height={26} width={100} />
      <Spacer height={10} />

      <Skeleton height={36} width={80} />
      <Spacer height={20} />

      <Skeleton height={23} width="75vw" />
      <Skeleton height={23} width="70vw" />
      <Skeleton height={23} width="80vw" />
    </Flex>
  )
}

export default MerchantDetailsLoading
