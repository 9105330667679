export const theme = {
  colors: {
    background: '#F0F3F5',
    abnkBlue: '#1980CE',
    abnkDarkBlue: '#1E4093',
    charcoal: '#474747',
    borderGray: '#CDD0D1',
    borderGray2: '#CBCBCB',
    faintGreen: '#E8F6F0',
    lockGray: '#979797',
    faintGray: '#F0F0F0',
    faintGray2: '#E9E9F1',
    linkBlue: '#197CCA',
    warningRed: '#FF6363',
    tahitiGold: '#E99149',
    faintBlue: '#F0F7FC',
    progressbar: {
      background: '#D7E2E8',
      barGreen1: '#0BC175',
      barGreenLight1: '#71D1AF',
      barGreen2: '#4CD964',
    },
    buttons: {
      marineBlue: '#2B6CD8',
      lightGray: '#D3D3D3',
      darkTangerine: '#FFA912',
    },
    actions: {
      failureRed: '#FF5055',
      failureRed2: '#D82B6F',
      actionBlue: '#218BFF',
      successGreen1: '#FFB812',
      successGreen2: '#FFD15F',
      yellowWarning1: '#FFB812',
      yellowWarning2: '#FFD15F',
      yellowWarning3: '#FFFAD9',
      yellowWarning4: '#EBD9A3',
      yellowWarning5: '#E99657',
      lowCreditOrange: '#EFB45E',
      lightBlue: '#F1F7FF',
    },
    typography: {
      gray1: '#949494',
      gray2: '#ABABAB',
      gray3: '#858585',
      gray4: '#AEAEAE',
      gray5: '#989898',
      gray6: '#D8D8D8',
      gray7: '#A5A5A5',
      gray8: '#7A7A7A',
      gray9: '#8C8C8C',
      gray10: '#919191',
      gray11: '#848484',
      red1: '#D64C4C',
      darkGray: '#343C51',
      placeholder: '#C5C5C5',
    },
  },
  typography: {
    fontFamily: 'Poppins',
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
}
