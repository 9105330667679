import React from 'react'
import BankMandateForm from './BankMandateForm'
import { useHistory } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'

const ViewBankMandate = () => {
  const history = useHistory()
  const { data } = history.location.state

  const methods = useForm({ mode: 'all' })

  const handleSubmit = () => {
    history.push('/paymentmethods')
    return
  }

  return (
    <FormProvider {...methods}>
      <BankMandateForm
        backButtonText="View mandate"
        buttonText="RETURN"
        path="view"
        editableFields={[]}
        handleSubmit={handleSubmit}
        defaultData={data}
      />
    </FormProvider>
  )
}

export default ViewBankMandate
