import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Flex, NoResults, Paragraph as P } from 'components'
import { useMerchantStore } from 'stores'
import { getCountryCodeLocalDb } from 'utils'
import { theme } from 'styles/theme'

import SearchResultsLoading from './SearchResultsLoading'
import ResultRow from './ResultRow/ResultRow'

const SearchResults = ({ searchTerm, categories }) => {
  const countryCode = getCountryCodeLocalDb()
  const {
    isLoading: { searchResults: searchResultsIsLoading },
    response: { searchResults: searchResultsResponse },
    resetStates,
    fetchSearchResults,
  } = useMerchantStore()

  // *Effects
  useEffect(() => {
    if (searchTerm.length === 0) resetStates('searchResults')
    const category = categories && categories.length > 0 ? categories[0].external_id : ''
    fetchSearchResults(searchTerm, category, countryCode)
  }, [searchTerm, categories])

  // *JSX
  if (searchResultsIsLoading || !searchResultsResponse) {
    return <SearchResultsLoading />
  }

  if (
    searchResultsResponse &&
    searchResultsResponse.merchants &&
    searchResultsResponse.merchants.length === 0
  ) {
    return (
      <NoResults
        title="No matching results"
        actionItem={
          <P color={theme.colors.typography.gray1} textAlign="center">
            Please try searching for something else
          </P>
        }
      />
    )
  }

  return (
    <Flex flexDirection="column" mt={3} ml={2} fitWidth>
      {searchResultsResponse &&
        searchResultsResponse.merchants &&
        searchResultsResponse.merchants.map(merchant => {
          return <ResultRow merchant={merchant} key={merchant.slug} />
        })}
    </Flex>
  )
}

SearchResults.propTypes = {
  searchTerm: PropTypes.string.isRequired,
}

export default SearchResults
