import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { RoundedContainer, Flex } from 'components'

const TransactionLoading = () => {
  return (
    <RoundedContainer mt={25}>
      <Flex>
        <Skeleton height={47} width={47} style={{ borderRadius: 8 }} circle />
        <Flex>
          <Flex flexDirection="column" justifyContent="center" ml={3}>
            <Skeleton height={15} width={100} />
            <Skeleton height={15} width={180} />
          </Flex>
        </Flex>
      </Flex>
    </RoundedContainer>
  )
}

export default TransactionLoading
