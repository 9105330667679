import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router'

import {
  getDraftOrderLocalDb,
  logUserInteractionGA,
  setPaymentPlanLocalDb,
  removeTokenLocalDb,
} from 'utils'
import {
  PageWrapper,
  NavigateBack,
  Flex,
  Paragraph as P,
  Spacer,
  Footer,
  Button,
  Loading,
  TutorialWrapper,
  HelpIcon,
  TriggerPopup,
} from 'components'
import { useBuyerStore } from 'stores'
import { PAYMENT_TUTORIAL, GENERIC_ERR_MESSAGE } from 'const'
import { useOrderEligibilityCheck } from 'hooks'

import PaymentPlanCard from './PaymentPlanCard/PaymentPlanCard'
import PaymentHeader from '../PaymentHeader/PaymentHeader'
import CreditUsedFooter from '../CreditUsedFooter/CreditUsedFooter'

const PaymentPlans = () => {
  const history = useHistory()
  const { creditsIsLoading, eligibleForOrder } = useOrderEligibilityCheck()

  const [activePlan, setActivePlan] = useState(null)
  const [tutorialStep, setTutorialStep] = useState(0)
  const [error, setError] = useState('')
  const [shouldCallInteractionApi, setShouldCallInteractionApi] = useState(false)

  const draftOrder = useRef(null)
  const stepRecorder = useRef(0)

  // *Stores
  const {
    response: { getOrCreate: getOrCreateResponse },
    errors: { getOrCreate: getOrCreateError },
    resetStates: resetStatesBuyer,
    submitGetOrCreate,
  } = useBuyerStore()

  // *Methods
  const handleTutorial = () => {
    if (tutorialStep === PAYMENT_TUTORIAL.length - 1) {
      setTutorialStep(0)
    } else {
      setTutorialStep(tutorialStep => tutorialStep + 1)
    }
  }

  const handleClickPaymentPlan = plan => {
    logUserInteractionGA(`Click: Payment Plan`, {
      id: plan.payment_plan_def_id,
    })
    setActivePlan(plan)
  }

  const handleSelectPaymentPlan = () => {
    const selectedPlan = activePlan
    logUserInteractionGA(`Select - Continue with Payment Plan`, {
      payment_plan_def_id: selectedPlan.payment_plan_def_id,
    })
    setPaymentPlanLocalDb(selectedPlan)
    history.push('/order/review')
  }

  const sortPaymentPlans = (a, b) => {
    if (parseFloat(a.instalment_amount) < parseFloat(b.instalment_amount)) {
      return 1
    } else {
      return -1
    }
  }

  // *Effects
  useEffect(() => {
    draftOrder.current = getDraftOrderLocalDb()
    stepRecorder.current = 1
    submitGetOrCreate()

    if (draftOrder && draftOrder.current && draftOrder.current.available_payment_plans) {
      if (draftOrder.current.available_payment_plans.length === 0) {
        setError('There is no available payment plan for this order.')
      }
    }

    return () => {
      resetStatesBuyer('getOrCreate')
    }
  }, [])

  useEffect(() => {
    if (draftOrder.current && draftOrder.current.available_payment_plans) {
      // Sort payment plans
      draftOrder.current.available_payment_plans =
        draftOrder.current.available_payment_plans.sort(sortPaymentPlans)

      setActivePlan(draftOrder.current.available_payment_plans[0])
    }
  }, [draftOrder])

  useEffect(() => {
    if (
      getOrCreateResponse &&
      getOrCreateResponse.interactions &&
      getOrCreateResponse.interactions.length >= 0
    ) {
      const interaction = getOrCreateResponse.interactions.filter(
        item => item.action === 'view_create_order_payment_plan',
      )

      if (interaction.length === 0)
        setTimeout(() => {
          setTutorialStep(1)
          setShouldCallInteractionApi(true)
        }, 250)
    }
  }, [getOrCreateResponse])

  // *JSX
  if (error.length > 0) {
    return <TriggerPopup showPopup message={error} callback={() => setError('')} />
  }

  if (!draftOrder || !activePlan) {
    return <Loading variant="fullscreen" />
  }

  return (
    <TutorialWrapper
      tutorial={PAYMENT_TUTORIAL}
      currentStep={tutorialStep}
      showTutorial={tutorialStep > 0}
      shouldCallInteractionApi={shouldCallInteractionApi}
      setShouldCallInteractionApi={setShouldCallInteractionApi}
      handleClick={handleTutorial}
    >
      <TriggerPopup
        message={(getOrCreateError && getOrCreateError.message) || GENERIC_ERR_MESSAGE}
        showPopup={!!getOrCreateError}
        buttonText="Return"
        callback={() => {
          resetStatesBuyer('getOrCreate')
          removeTokenLocalDb()
        }}
        nextDestination="/register"
        replaceHistory
      />

      <PageWrapper withTopNav>
        <NavigateBack noBackground />
        <PaymentHeader initial={stepRecorder.current} current={1} total={3} />
        <Spacer height={10} />

        <Flex mt={100} mb={130} flexDirection="column" width="100%">
          <Flex>
            <P style={{ fontWeight: 700 }}>Step 1 of 3: Select payment plan</P>
            <Spacer width={5} />
            <HelpIcon onClick={handleTutorial} />
          </Flex>

          {activePlan &&
            draftOrder.current &&
            draftOrder.current.available_payment_plans.length > 0 &&
            draftOrder.current.available_payment_plans.map((plan, i) => {
              // const isActive = plan.payment_plan_def_id === activePlanId;
              const isActive = plan.payment_plan_def_id === activePlan.payment_plan_def_id

              if (i === 0) {
                return (
                  <PaymentPlanCard
                    className="payment_card"
                    id="payment_highlight_1"
                    onClick={handleClickPaymentPlan}
                    active={isActive}
                    key={plan.payment_plan_def_id}
                    data={plan}
                  />
                )
              }

              return (
                <PaymentPlanCard
                  onClick={handleClickPaymentPlan}
                  active={isActive}
                  key={plan.payment_plan_def_id}
                  data={plan}
                />
              )
            })}
        </Flex>

        <Footer withTopBorder flexDirection="column">
          <Spacer height={15} />
          <CreditUsedFooter paymentPlan={activePlan} showProgressBar={tutorialStep === 0} />
          <Button
            onClick={handleSelectPaymentPlan}
            isLoading={creditsIsLoading}
            disabled={
              (draftOrder.current && draftOrder.current.available_payment_plans.length === 0) ||
              !eligibleForOrder
            }
          >
            CONTINUE
          </Button>
        </Footer>
      </PageWrapper>
    </TutorialWrapper>
  )
}

export default PaymentPlans
