import React from 'react'
import PropTypes from 'prop-types'
import { SpinnerCircular } from 'spinners-react'

import { theme } from 'styles/theme'
import { Image } from 'components'

import { StyledButton, RoundButton } from './buttonStyle'
import { voucher, jointPurchase, forward, check } from 'assets'

const Button = ({
  variant,
  icon,
  rightIcon,
  onClickRightIcon,
  isLoading,
  disabled,
  spinnerSize,
  children,
  ...rest
}) => {
  if (variant === 'round') {
    switch (icon) {
      case 'check':
        return (
          <RoundButton disabled={disabled || isLoading} {...rest}>
            {isLoading ? (
              <SpinnerCircular size={spinnerSize || 40} color="white" />
            ) : (
              <Image src={check} alt="check" style={{ width: '25px', paddingBottom: '5px' }} />
            )}
          </RoundButton>
        )
      default:
        return (
          <RoundButton disabled={disabled || isLoading} {...rest}>
            {isLoading ? (
              <SpinnerCircular size={spinnerSize || 40} color="white" />
            ) : (
              <Image src={forward} alt="forward" style={{ width: '25px' }} />
            )}
          </RoundButton>
        )
    }
  }

  let iconRendered = null
  if (variant === 'small') {
    if (icon === 'voucher') {
      iconRendered = (
        <Image
          src={voucher}
          alt="voucher-icon"
          style={{ paddingRight: '5px' }}
          width="32px"
          height="32px"
        />
      )
    } else {
      iconRendered = (
        <Image
          src={jointPurchase}
          alt="small-icon"
          style={{ paddingLeft: '3px', paddingRight: '7px' }}
          width="32px"
          height="32px"
        />
      )
    }
  }

  return (
    <StyledButton
      variant={variant}
      disabled={disabled || isLoading}
      isLoading={isLoading}
      {...rest}
    >
      {iconRendered && iconRendered}
      {isLoading ? (
        <SpinnerCircular
          style={{ paddingTop: 5 }}
          enabled={isLoading}
          size={spinnerSize || 32}
          color={variant === 'secondary' ? theme.colors.buttons.marineBlue : 'white'}
        />
      ) : (
        children
      )}
      {rightIcon && (
        <Image
          src={rightIcon}
          onClick={onClickRightIcon}
          alt="right-icon"
          style={{ paddingLeft: '5px', paddingBottom: '2px' }}
          width="18px"
          height="18px"
        />
      )}
    </StyledButton>
  )
}

Button.propTypes = {
  variant: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
}

Button.defaultProps = {
  icon: null,
  variant: 'primary',
  isLoading: false,
  disabled: false,
}

export default Button
