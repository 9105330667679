import React from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'

import { Flex, Heading as H, Paragraph as P, Spacer } from 'components'
import { confetti2 } from 'assets'
import { theme } from 'styles/theme'
import { formatAsCurrency } from 'utils'

const ReceiptAmount = ({ amount, merchantName, delay }) => {
  return (
    <Flex stretch centered flexDirection="column" my={4}>
      <motion.img
        src={confetti2}
        alt="confetti"
        key="confetti"
        style={{ position: 'absolute', x: 10, y: 15 }}
        transition={{
          delay: delay,
          duration: 0.2,
          type: 'spring',
          stiffness: 400,
        }}
        initial={{ scale: 0.1 }}
        animate={{ scale: 1.0 }}
      />
      <Spacer height={25} />
      <H variant="h1" fontSize={32}>
        {formatAsCurrency(amount)}
      </H>
      <P textAlign="center" color={theme.colors.typography.gray8} style={{ fontWeight: 600 }}>
        {merchantName}
      </P>
    </Flex>
  )
}

ReceiptAmount.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  merchantName: PropTypes.string.isRequired,
  delay: PropTypes.number.isRequired,
}

export default ReceiptAmount
