import React from 'react'

import { Flex, Paragraph as P } from 'components'
import { theme } from 'styles/theme'
import { formatAsCurrency } from 'utils'

const DetailRow = ({ title, amount, ...rest }) => {
  return (
    <Flex justifyContent="space-between" mb={2} alignItems="center" {...rest}>
      <P style={{ fontWeight: 500 }} color={theme.colors.typography.gray10}>
        {title}
      </P>
      <P style={{ fontWeight: 700 }} textAlign="right">
        {formatAsCurrency(amount)}
      </P>
    </Flex>
  )
}

export default DetailRow
