import React, { useEffect } from 'react'
import { useHistory } from 'react-router'

import {
  Flex,
  ResponsiveCircle,
  Spacer,
  Heading as H,
  Paragraph as P,
  Footer,
  Button,
  Image,
} from 'components'
import { theme } from 'styles'
import { newVerifyIdentity, successCircleGreen, successCircleInactive } from 'assets'
import { setShouldCheckDraftAtCreditScreen } from 'utils'

const OnboardingRequired = ({ onboardingStatus }) => {
  const history = useHistory()

  // *Methods
  const handleContinueOnboarding = () => {
    if (onboardingStatus) {
      if (onboardingStatus.identityKyc !== 'data_available')
        return history.push('/verify?type=identity')
      if (onboardingStatus.paymentMethodKyc !== 'data_available') {
        return history.push('/verify?type=paymentmethod')
      }
    }
  }

  const renderIcon = status => {
    return (
      <Image
        src={status === 'data_available' ? successCircleGreen : successCircleInactive}
        alt="tick"
        height="25px"
        width="25px"
      />
    )
  }

  const renderText = (text, status) => {
    return (
      <P
        variant="small"
        color={
          status === 'data_available'
            ? theme.colors.progressbar.barGreen1
            : theme.colors.typography.gray1
        }
      >
        {text}
      </P>
    )
  }

  // *Effects
  useEffect(() => {
    setShouldCheckDraftAtCreditScreen()
  }, [])

  return (
    <Flex alignItems="center" width="100%" flexDirection="column" pb={100} px={3}>
      <ResponsiveCircle circleColor={theme.colors.background} style={{ maxWidth: 100 }}>
        <Image src={newVerifyIdentity} alt="identity_card_icon" width="170px" height="170px" />
      </ResponsiveCircle>
      <Spacer height={20} />

      <H variant="h4" textAlign="center">
        Onboarding required
      </H>
      <Spacer height={10} />

      <P variant="small" color={theme.colors.typography.gray1} textAlign="center">
        You have to complete your onboarding before proceeding with purchases
      </P>
      <Spacer height={15} />

      {onboardingStatus && onboardingStatus.identityKyc && onboardingStatus.paymentMethodKyc && (
        <Flex width="100%" alignItems="flex-start" flexDirection="column">
          <Flex alignItems="center">
            {renderIcon(onboardingStatus.identityKyc)} <Spacer width={10} />{' '}
            {renderText('Verify your identity', onboardingStatus.identityKyc)}
          </Flex>
          <Spacer height={15} />

          <Flex alignItems="center">
            {renderIcon(onboardingStatus.paymentMethodKyc)} <Spacer width={10} />{' '}
            {renderText('Add payment method', onboardingStatus.paymentMethodKyc)}
          </Flex>
        </Flex>
      )}

      <Footer>
        <Flex flexDirection="column" fitWidth>
          <Button onClick={handleContinueOnboarding}>CONTINUE ONBOARDING</Button>
        </Flex>
      </Footer>
    </Flex>
  )
}

export default OnboardingRequired
