import React, { useState, useEffect } from 'react'

import { faq } from 'contents'
import { Flex, BasicAccordion, CategoryCard } from 'components'

const FAQCategories = ({ activeCategory, setActiveCategory }) => {
  const [categories, setCategories] = useState([])
  const [questions, setQuestions] = useState([])

  // *Effects
  useEffect(() => {
    const categoryList = faq.reduce((allCategories, category) => {
      return (allCategories = [...allCategories, category.categoryName])
    }, [])

    setCategories(categoryList)
  }, [faq])

  useEffect(() => {
    if (faq && activeCategory) {
      const categoryItems = faq.find(category => category.categoryName === activeCategory)

      setQuestions(categoryItems.items)
    }
  }, [activeCategory])

  // *Effects
  useEffect(() => {
    const container = document.getElementById('category-container')
    if (categories.indexOf(activeCategory) === 0) {
      container.scrollTo({
        left: 0,
        behavior: 'smooth',
      })
    }

    if (categories.indexOf(activeCategory) === categories.length - 1) {
      container.scrollTo({
        left: 1000,
        behavior: 'smooth',
      })
    }
  }, [activeCategory])

  // *JSX
  return (
    <Flex flexDirection="column">
      <Flex
        overflowX="scroll"
        style={{
          maxWidth: 'calc(100vw - 32px)',
          scrollbarWidth: 'none',
        }}
        id="category-container"
      >
        {categories.length > 0 &&
          categories.map(category => (
            <CategoryCard
              category={category}
              activeCategory={activeCategory}
              setActiveCategory={setActiveCategory}
              key={category}
            />
          ))}
      </Flex>

      <Flex fitWidth flexDirection="column" flex="1" overflowY="scroll">
        {questions.length > 0 &&
          questions.map(item => {
            const { question, answer } = item
            return (
              <BasicAccordion
                title={question}
                content={answer}
                key={question}
                initial={{ opacity: 0, y: -100 }}
                animate={{ opacity: 1, y: 0 }}
              />
            )
          })}
      </Flex>
    </Flex>
  )
}

export default FAQCategories
