import React from 'react'
import BankMandateForm from './BankMandateForm'
import { useForm, FormProvider } from 'react-hook-form'
import { useBuyerStore } from 'stores'

const AddBankMandate = () => {
  const methods = useForm({ mode: 'all' })
  const { submitPaymentMethod } = useBuyerStore()

  const editableFields = [
    'buyer_name',
    'buyer_nric',
    'buyer_phone_number',
    'buyer_email',
    'max_amount',
    'max_frequency',
    'frequency_mode',
    'purpose',
    'buyer_bank_id',
  ]

  const handleSubmit = async () => {
    let res
    await methods.handleSubmit(async data => {
      const emandate = {}
      emandate['external_id'] = data.external_id
      editableFields.map(field => {
        emandate[field] = data[field]
      })
      res = await submitPaymentMethod({ third_party_name: 'fpx', fpx: { emandate } })
    })()
    return res
  }

  return (
    <FormProvider {...methods}>
      <BankMandateForm
        backButtonText="Add mandate"
        buttonText="CONTINUE"
        path="new"
        editableFields={editableFields}
        handleSubmit={handleSubmit}
      />
    </FormProvider>
  )
}

export default AddBankMandate
