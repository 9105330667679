import React, { useState, useEffect } from 'react'
import Switch from 'react-ios-switch'

import { Flex, TriggerPopup, Footer, Button, Span, FormError } from 'components'
import { useBuyerStore } from 'stores'
import { theme } from 'styles'

import SettingsRow from './SettingsRow/SettingsRow'
import { StyledInput } from 'components/FormInput/formInputStyle'
import { StyledLabel } from 'components/FormLabel/formLabelStyle'
import { logUserInteractionGA } from 'utils'

const SettingsContainer = () => {
  // const [language, setLanguage] = useState('')
  // const [enableAppAlerts, setEnableAppAlerts] = useState(false)
  const [enableEmailReminders, setEnableEmailReminders] = useState(false)
  const [emailAddress, setEmailAddress] = useState('')
  const [changesMade, setChangesMade] = useState(false)

  const {
    isLoading: { settingsUpdate: settingsUpdateIsLoading },
    response: { settings: settingsResponse, settingsUpdate: settingsUpdateResponse },
    errors: { settingsUpdate: settingsUpdateError },
    resetStates: resetStatesBuyer,
    fetchSettings,
    updateSettings,
  } = useBuyerStore()

  // *Methods
  // const handleChangeLanguage = language => {
  //     setLanguage(language)
  //     setChangesMade(true)
  // }

  // const handleChangeAlerts = bool => {
  //     setEnableAppAlerts(bool)
  //     setChangesMade(true)
  // }

  const handleChangeReminders = bool => {
    setEnableEmailReminders(bool)
    setChangesMade(true)
  }

  const handleChangeEmail = e => {
    setEmailAddress(e.target.value)
    setChangesMade(true)
  }

  const handleSaveChanges = () => {
    logUserInteractionGA(`Submit - Save Changes`, {
      notification_email_enabled: enableEmailReminders,
      notification_email: emailAddress,
    })
    if (settingsResponse) {
      updateSettings({
        // some default values since certain settings are disabled
        language: 'eng',
        notification_in_app_enabled: true,
        notification_email_enabled: enableEmailReminders,
        notification_email: emailAddress,
      })
    }
  }

  // *Effects
  useEffect(() => {
    fetchSettings()
    return () => {
      resetStatesBuyer('settings')
      resetStatesBuyer('settingsUpdate')
    }
  }, [])

  useEffect(() => {
    if (settingsResponse) {
      // const languageUpperCase = settingsResponse.language.toUpperCase()
      // setLanguage({
      //     name: languageUpperCase,
      //     value: settingsResponse.language,
      // })
      // setEnableAppAlerts(settingsResponse.notification_in_app_enabled)

      setEnableEmailReminders(settingsResponse.notification_email_enabled)
      setEmailAddress(settingsResponse.notification_email)
    }
  }, [settingsResponse])

  useEffect(() => {
    if (settingsUpdateResponse) {
      setChangesMade(false)
    }
  }, [settingsUpdateResponse])

  // *JSX
  return (
    <Flex
      flexDirection="column"
      width="100vw"
      backgroundColor="white"
      mt="-6px"
      ml={-16}
      mr={-16}
      px={16}
    >
      {/* error handling */}
      <TriggerPopup
        showPopup={settingsUpdateError && !settingsUpdateError.notification_email}
        message="An error occured when updating your settings. Please try again."
        stayAtCurrentPage
        callback={() => {
          resetStatesBuyer('settingsUpdate')
          fetchSettings()
        }}
        buttonText="OKAY"
      />

      {/* 
      <SettingsRow
        isLoading={!settingsResponse}
        loadingTextWidth={80}
        title="Language"
        action={
          settingsResponse && language ? (
            <DropDown
              activeValue={language}
              options={LANGUAGE_OPTIONS}
              onClickOption={handleChangeLanguage}
            />
          ) : (
            <DropDownLoading
              skeletonWidth={40}
              style={{
                width: 110,
                padding: "12px 10px",
                marginBottom: 0,
                borderRadius: 12,
                paddingLeft: 15,
              }}
            />
          )
        }
      /> */}

      {/* <SettingsRow
        isLoading={!settingsResponse}
        loadingTextWidth={120}
        title="Enable app alerts"
        action={
          <Switch checked={enableAppAlerts} onChange={handleChangeAlerts} />
        }
      /> */}

      <SettingsRow
        isLoading={!settingsResponse}
        loadingTextWidth={140}
        title="Enable email reminders"
        item={
          <Flex flexDirection="column" mx={2}>
            <StyledLabel>
              Email Address <Span color={theme.colors.actions.failureRed}>*</Span>
            </StyledLabel>
            <StyledInput type="email" value={emailAddress} onChange={handleChangeEmail} />
            {settingsUpdateError && settingsUpdateError.notification_email && (
              <FormError>{settingsUpdateError.notification_email}</FormError>
            )}
          </Flex>
        }
        action={<Switch checked={enableEmailReminders} onChange={handleChangeReminders} />}
      />

      <Footer
        style={{
          backgroundColor: theme.colors.background,
        }}
      >
        <Button
          onClick={handleSaveChanges}
          isLoading={settingsUpdateIsLoading}
          disabled={!settingsResponse || !changesMade}
        >
          SAVE CHANGES
        </Button>
      </Footer>
    </Flex>
  )
}

export default SettingsContainer
