import React, { useEffect } from 'react'
import { Route, Redirect, useLocation, Switch } from 'react-router'

import {
  AssumeAccount,
  AssumeAccountHeader,
  Error401,
  PublicRoute,
  PrivateRoute,
  ErrorFallback,
  DesktopRedirect,
  PaymentReceipt,
  QrScanner,
  MyQrCode,
  CatchAll,
  BackToMerchantHeader,
} from 'components'
import { useTrackingGA, useTrackingSegment, useScreenSize, useTrackingFBPixel } from 'hooks'
import {
  getTokenLocalDb,
  captureAssumeAccountToken,
  getAssumeAccount,
  setDesktopQrCodeLink,
  getBackToMerchantLink,
} from 'utils'
import {
  Onboarding,
  Register,
  Home,
  Credit,
  Verify,
  VerifyIdentityForm,
  VerificationOutcome,
  Success,
  UpcomingPayments,
  Transactions,
  Transaction,
  TransactionPayNext,
  TransactionReceiptFull,
  Buy,
  Merchant,
  Profile,
  PaymentMethods,
  AddPaymentMethod,
  AddCreditCard,
  AddDebitCard,
  Notification,
  ContactUs,
  Settings,
  FAQ,
  FillWithMyInfo,
  VerifyHomeOwnershipForm,
  CbsReportAuto,
  CbsReportManual,
  CbsReportManualAnalysis,
  CbsReportOutcome,
  ManualReview,
  Order,
  PayForOrder,
  PayForOrderManual,
  PaymentMethodError,
  PaymentPlans,
  PaymentPlanReview,
  ConfirmOrder,
  PayRemainingBalance,
  AddEDDA,
  CPFHomeOwnership,
} from 'web/buyers/pages'

const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

const BuyerSG = () => {
  useTrackingGA()
  useTrackingSegment()
  useTrackingFBPixel()

  captureAssumeAccountToken()
  setDesktopQrCodeLink(window.location.href)

  const token = getTokenLocalDb()
  const currentScreenSize = useScreenSize().screenSize()

  return (
    <>
      <ScrollToTop />
      <AssumeAccountHeader show={!!getAssumeAccount()} />
      <BackToMerchantHeader show={!!getBackToMerchantLink()} />

      <Switch>
        {currentScreenSize.isDesktop && !getAssumeAccount() && (
          <PublicRoute component={DesktopRedirect} title="Desktop Qr Code" />
        )}

        <Redirect from="/" to={token ? '/home' : '/onboarding'} exact />
        <PublicRoute exact path="/assumeaccount" component={AssumeAccount} title="Assume Account" />
        <PublicRoute exact path="/unauthorized" component={Error401} title="Unauthorized" />
        <PublicRoute exact path="/onboarding" component={Onboarding} title="Onboarding" />
        <PublicRoute exact path="/register" component={Register} title="Register" />
        <PublicRoute exact path="/error" component={ErrorFallback} title="Error" />
        <PrivateRoute exact path="/verify" component={Verify} title="Verify" />
        <PrivateRoute
          exact
          path="/verify/identity"
          component={VerifyIdentityForm}
          title="Verify Identity"
        />
        <PrivateRoute
          exact
          path="/verify/homeownership"
          component={VerifyHomeOwnershipForm}
          title="Verify Homeownership"
        />
        <PrivateRoute
          exact
          path="/verify/myinfo"
          component={FillWithMyInfo}
          title="Verify MyInfo"
        />

        {/* Credit Report */}
        <PrivateRoute
          exact
          path="/verify/cbs/auto"
          component={CbsReportAuto}
          title="Retrieve Cbs Report"
        />
        <PrivateRoute
          exact
          path="/verify/cbs/manual"
          component={CbsReportManual}
          title="Upload Cbs Report"
        />
        <PrivateRoute
          exact
          path="/verify/cbs/manual/analysis"
          component={CbsReportManualAnalysis}
          title="Cbs Analysis"
        />
        <PrivateRoute
          exact
          path="/verify/cbs/outcome"
          component={CbsReportOutcome}
          title="Cbs Outcome"
        />

        {/*CPF Homeownership */}
        <PrivateRoute
          exact
          path="/verify/cpf_homeownership"
          component={CPFHomeOwnership}
          title="Verify CPF Homeownership"
        />

        <PrivateRoute
          exact
          path="/verify/manual"
          component={ManualReview}
          title="Credit Limit Review"
        />
        <PrivateRoute
          exact
          path="/verify/outcome"
          component={VerificationOutcome}
          title="Verification Outcome"
        />
        <PrivateRoute
          exact
          path="/verify/success"
          component={Success}
          title="Verification Successful"
        />
        <PrivateRoute
          exact
          path="/verify/paymentmethod/error"
          component={PaymentMethodError}
          title="Payment Method Error"
        />
        <PrivateRoute exact path="/credit" component={Credit} title="Credit" />
        <PrivateRoute exact path="/buy" component={Buy} title="Buy" />
        <PrivateRoute exact path="/merchant" component={Merchant} title="Merchant" />
        {/* not private so we can deeplink to native app without user logging in */}
        <PublicRoute exact path="/order" component={Order} title="Order" />
        <PrivateRoute
          exact
          path="/order/due"
          component={UpcomingPayments}
          title="Upcoming Payments"
        />
        <PrivateRoute exact path="/order/pay" component={PayForOrder} title="Pay for Order" />
        <PrivateRoute
          exact
          path="/order/pay/manual"
          component={PayForOrderManual}
          title="Pay for Order"
        />
        <PrivateRoute exact path="/order/plans" component={PaymentPlans} title="Payment Plans" />
        <PrivateRoute
          exact
          path="/order/review"
          component={PaymentPlanReview}
          title="Review Payment Plan"
        />
        <PrivateRoute exact path="/order/confirm" component={ConfirmOrder} title="Confirm Order" />
        <PrivateRoute
          exact
          path="/order/success"
          component={PaymentReceipt}
          title="Payment Successful"
        />
        <PrivateRoute exact path="/qrscanner" component={QrScanner} title="Qr Scanner" />
        <PrivateRoute exact path="/myqrcode" component={MyQrCode} title="My Code" />
        <PrivateRoute exact path="/transactions" component={Transactions} title="Transactions" />
        <PrivateRoute exact path="/transaction/order" component={Transaction} title="Transaction" />
        <PrivateRoute
          exact
          path="/transaction/paynext"
          component={TransactionPayNext}
          title="Pay Next"
        />
        <PrivateRoute
          exact
          path="/transaction/payremaining"
          component={PayRemainingBalance}
          title="Pay Remaining Balance"
        />
        <PrivateRoute
          exact
          path="/transaction/receipt"
          component={TransactionReceiptFull}
          title="Transaction Receipt"
        />
        <PrivateRoute
          exact
          path="/transaction/success"
          component={PaymentReceipt}
          title="Payment Successful"
        />
        <PrivateRoute exact path="/profile" component={Profile} title="Profile" />
        <PrivateRoute exact path="/notifications" component={Notification} title="Notifications" />
        <PrivateRoute exact path="/contactus" component={ContactUs} title="Contact Us" />
        <PrivateRoute exact path="/settings" component={Settings} title="Settings" />
        <PrivateRoute exact path="/faq" component={FAQ} title="FAQ" />
        <PrivateRoute
          exact
          path="/paymentmethods"
          component={PaymentMethods}
          title="Payment Methods"
        />
        <PrivateRoute
          exact
          path="/paymentmethods/new"
          component={AddPaymentMethod}
          title="Add Payment Method"
        />
        <PrivateRoute
          exact
          path="/paymentmethods/new/credit"
          component={AddCreditCard}
          title="Add Credit Card"
        />
        <PrivateRoute
          exact
          path="/paymentmethods/new/debit"
          component={AddDebitCard}
          title="Add Debit Card"
        />
        <PrivateRoute exact path="/paymentmethods/new/bank" component={AddEDDA} title="Add eGIRO" />
        {/*<PrivateRoute*/}
        {/*  exact*/}
        {/*  path="/paymentmethods/view/bank"*/}
        {/*  component={ViewBankMandate}*/}
        {/*  title="View eGIRO"*/}
        {/*/>*/}
        {/*<PrivateRoute*/}
        {/*  exact*/}
        {/*  path="/paymentmethods/manage/bank"*/}
        {/*  component={ManageBankMandate}*/}
        {/*  title="Manage eGIRO"*/}
        {/*/>*/}
        {/*<PrivateRoute*/}
        {/*  exact*/}
        {/*  path="/paymentmethods/terminate/bank"*/}
        {/*  component={TerminateBankMandate}*/}
        {/*  title="Terminate eGIRO"*/}
        {/*/>*/}
        <PrivateRoute exact path="/home" component={Home} title="Home" />

        <Route component={CatchAll} />
      </Switch>
    </>
  )
}

export default BuyerSG
