import styled from 'styled-components'

import { theme } from 'styles/theme'

const StyledTextArea = styled.textarea`
  background-color: white;
  font-family: ${theme.typography.fontFamily};
  border-radius: 8px;
  font-size: 16px;
  padding: 10.8px;
  padding-left: ${props => props.inputIcon && '35px'};
  font-weight: 500;
  width: 100%;
  border: ${props =>
    props.error
      ? `1px solid ${theme.colors.actions.failureRed}`
      : `1px solid ${theme.colors.borderGray}`};
  box-sizing: border-box;
  resize: none;

  ::placeholder {
    color: ${theme.colors.typography.placeholder};
  }

  &:focus {
    outline: none;
    border: 1px solid ${theme.colors.actions.actionBlue};
  }
`

export { StyledTextArea }
