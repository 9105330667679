import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { Flex } from 'components'

const BankMandateSkeleton = () => {
  return (
    <>
      <Flex flexDirection="column" stretch mb={10}>
        <Skeleton height={20} width={100} />
        <Skeleton height={45} width="100%" style={{ borderRadius: '8px' }} />
      </Flex>
      <Flex flexDirection="column" stretch mb={10}>
        <Skeleton height={20} width={100} />
        <Skeleton height={45} width="100%" style={{ borderRadius: '8px' }} />
      </Flex>
      <Flex flexDirection="column" stretch mb={10}>
        <Skeleton height={20} width={100} />
        <Skeleton height={45} width="100%" style={{ borderRadius: '8px' }} />
      </Flex>
      <Flex flexDirection="column" stretch mb={10}>
        <Skeleton height={20} width={100} />
        <Skeleton height={45} width="100%" style={{ borderRadius: '8px' }} />
      </Flex>
    </>
  )
}

export default BankMandateSkeleton
