import React from 'react'

import { Flex } from 'components'

const Header = ({ children, withBottomBorder, style, ...rest }) => {
  if (withBottomBorder)
    return (
      <Flex
        flexDirection="column"
        position="fixed"
        left="0"
        top="0"
        margin="auto"
        stretch
        zIndex={2}
        pt={4}
        backgroundColor="white"
        style={{
          boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.163981)',
          backgroundColor: 'white',
          ...style,
        }}
        {...rest}
      >
        {children}
      </Flex>
    )

  return (
    <Flex
      flexDirection="column"
      position="fixed"
      left="0"
      top="0"
      margin="auto"
      stretch
      zIndex={2}
      pt={4}
      backgroundColor="white"
      {...rest}
    >
      {children}
    </Flex>
  )
}

export default Header
