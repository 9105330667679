import React from 'react'
import { useHistory } from 'react-router'

import { RoundedContainer, Flex, Heading as H, Paragraph as P, Spacer, Image } from 'components'
import { theme } from 'styles/theme'
import { newNoTransaction } from 'assets'

const NoTransactions = ({ title, content }) => {
  const history = useHistory()

  // *Methods
  const handleClick = () => {
    history.push('/buy')
  }

  // *JSX
  return (
    <RoundedContainer boxShadow="0px 2px 20px 0px #00000019" onClick={handleClick}>
      <Flex flexDirection="column" centered textAlign="center">
        <Flex width="55%" centered>
          <Image src={newNoTransaction} alt="home_icon" width="95%" height="95%" />
        </Flex>
        <H variant="h4">{title}</H>
        <Spacer height={10} />
        <P color={theme.colors.typography.gray1}>{content}</P>
        <Spacer height={20} />
      </Flex>
    </RoundedContainer>
  )
}

export default NoTransactions
