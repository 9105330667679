import React from 'react'

import { Flex, CircularProgress, RowDivider, Paragraph as P, Span, Spacer } from 'components'
import { formatAsCurrency } from 'utils'
import { theme } from 'styles/theme'

const TransactionRow = ({ order }) => {
  const { store, merchant, repayments_remaining_amount, repayments_total_mount } = order

  return (
    <>
      <Flex alignItems="center">
        <CircularProgress
          strokeWidth="5"
          sqSize="58"
          invisiblePercentageLong
          percentageTotal={repayments_total_mount}
          percentageLong={repayments_total_mount}
          percentageShort={repayments_remaining_amount}
          logo={merchant && merchant.logo}
          invert
        />
        <Spacer width={10} />

        <Flex flexDirection="column">
          <P style={{ fontWeight: 700 }}>{store.name}</P>
          <P variant="small" style={{ fontWeight: 500 }} color={theme.colors.typography.gray7}>
            <Span style={{ fontSize: 16, fontWeight: 600 }} color="black">
              {formatAsCurrency(repayments_remaining_amount)}
            </Span>{' '}
            of {formatAsCurrency(repayments_total_mount)} remaining
          </P>
        </Flex>
      </Flex>
      <RowDivider width="100%" show />
    </>
  )
}

export default TransactionRow
