export function isValidEmail(email) {
  // eslint-disable-next-line max-len
  const re =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
  return re.test(String(email).toLowerCase())
}

export function isValidSGNric(nric) {
  const re = /^[STFG]\d{7}[A-Z]$/
  return re.test(String(nric))
}

export function isValidMYNric(nric) {
  const re = /^\d{6}\d{2}\d{4}$/
  return re.test(String(nric))
}

export function isValidSGMobile(number) {
  const re = /^(8|9)\d{7}$/
  return re.test(String(number))
}

export function isValidMYMobile(number) {
  // https://en.wikipedia.org/wiki/Telephone_numbers_in_Malaysia#Mobile_phone_codes_and_IP_telephony
  const re1 = /^(11|15)\d{8}$/
  const re2 = /^(10|12|13|14|16|17|18|19)\d{7}$/
  return re1.test(String(number)) || re2.test(String(number))
}

export function isValidOTP(number) {
  const re = /^\d{6}$/
  return re.test(String(number))
}
