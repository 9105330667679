import styled from 'styled-components'
import { motion } from 'framer-motion'

import { transactionsBackdrop, landingBackdrop } from 'assets'

export const StyledContainer = styled(motion.div)`
  max-width: calc(100% + 32px);
  padding-top: 60px;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  background: url(${transactionsBackdrop}) no-repeat;
  background-size: ${props => `${props.screenWidth}px`};
  width: calc(100% + 32px);
  min-height: 190px;
  margin-left: -16px;
  margin-right: -16px;
  margin-top: -45px;
  box-sizing: border-box;
  border-bottom-right-radius: 32px;
  border-bottom-left-radius: 32px;
`

export const StyledHomeContainer = styled(motion.div)`
  text-align: center;
  max-width: calc(100% + 32px);
  padding-top: 100px;
  padding-left: 20px;
  padding-right: 20px;
  background: url(${landingBackdrop}) no-repeat;
  background-size: ${props => `${props.screenWidth}px`};
  width: calc(100% + 32px);
  min-height: 280px;
  margin-left: -16px;
  margin-right: -16px;
  margin-top: -45px;
  box-sizing: border-box;
  border-bottom-right-radius: 32px;
  border-bottom-left-radius: 32px;
`
