export const MONTHS = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December',
}

export const CREDIT_TUTORIAL = [
  {
    content: '',
    anchor_id: '',
    highlight_id: '',
    button_text: '',
  },
  {
    content:
      'Your credit determines the amount you can use for instalments. It will be restored as you complete repayments.',
    anchor_id: 'credit_1',
    highlight_id: 'credit_1',
    button_text: 'Next',
  },
  {
    content:
      'For each section you complete, our system will be better able to evaluate and increase your credit score.',
    anchor_id: 'credit_2',
    highlight_id: 'credit_2',
    button_text: 'Next',
  },
  {
    content:
      'The data retrieved for each section only stays valid for a period of time, but you can always re-submit them again later to restore your credit.',
    anchor_id: 'credit_3',
    highlight_id: 'credit_2',
    button_text: 'Finish',
    action: 'view_credit',
  },
]

export const PAYMENT_TUTORIAL = [
  {
    content: '',
    anchor_id: '',
    highlight_id: '',
    button_text: '',
  },
  {
    content: 'The instalment amount that you have to pay.',
    anchor_id: 'payment_anchor_1',
    highlight_id: 'payment_highlight_1',
    button_text: 'Next',
  },
  {
    content: 'The duration of the plan, after which the purchase will be fully paid off.',
    anchor_id: 'payment_anchor_2',
    highlight_id: 'payment_highlight_1',
    button_text: 'Next',
  },
  {
    content: 'The downpayment is the portion of the purchase that you have to pay upfront.',
    anchor_id: 'payment_anchor_3',
    highlight_id: 'payment_highlight_1',
    button_text: 'Next',
  },
  {
    content: 'A fixed fee that provides for your flexibility of payment over instalments.',
    anchor_id: 'payment_anchor_4',
    highlight_id: 'payment_highlight_1',
    button_text: 'Next',
  },
  {
    content:
      'The full amount that you will have to pay over the plan - no surprises, no late fees.',
    anchor_id: 'payment_anchor_5',
    highlight_id: 'payment_highlight_1',
    button_text: 'Finish',
    action: 'view_create_order_payment_plan',
  },
]

export const PAY_EARLY_TUTORIAL = [
  {
    content: '',
    anchor_id: '',
    highlight_id: '',
    button_text: '',
  },
  {
    content: 'This is the amount your remaining balance will be reduced by. ',
    anchor_id: 'pay_early_1',
    highlight_id: 'pay_early_highlight',
    button_text: 'Next',
  },
  {
    content: 'The greater your repayment, the more savings you might be offered as an incentive.',
    anchor_id: 'pay_early_2',
    highlight_id: 'pay_early_highlight',
    button_text: 'Next',
  },
  {
    content: 'This is the amount you will pay after savings are included.',
    anchor_id: 'pay_early_3',
    highlight_id: 'pay_early_highlight',
    button_text: 'Next',
  },
  {
    content:
      'You can preview how your repayment schedule will be changed. There might be a reduction in your instalment amount, the number of instalments, or both.',
    anchor_id: 'pay_early_4',
    highlight_id: 'pay_early_highlight',
    button_text: 'Finish',
    action: 'view_pay_early',
  },
]
