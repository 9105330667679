import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { fetchMobileNo } from 'utils'

export default function initSentry() {
  const mobile = fetchMobileNo()

  Sentry.init({
    dsn: 'https://437b154dcb564440aed8a0fba51b1816@o271384.ingest.sentry.io/6234667',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1, // Be sure to lower this in production
    environment: 'production',
    release: process.env.REACT_APP_VERSION,
  })

  // capture user information if mobile is available
  if (mobile) {
    Sentry.configureScope(scope => {
      scope.setUser({ mobile: mobile })
    })
  }
}
