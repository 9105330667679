import { WHITELABEL } from 'const'
import { capitalizeAllFirstLetters } from 'utils'

export const onboarding = [
  {
    title: 'Buy now, pay over time',
    content: `Buy with ${
      WHITELABEL ? capitalizeAllFirstLetters(WHITELABEL) : 'Abnk'
    } and pay off your purchases in easy, fixed monthly payments`,
  },
  {
    title: 'No Hidden Cost',
    content: 'Simple payment plans. No catch, no late fee, no compounding interest',
  },
  {
    title: 'Safe and Secure',
    content: `${
      WHITELABEL ? capitalizeAllFirstLetters(WHITELABEL) : 'Abnk'
    } is carefully designed to prevent unauthorised use, and to protect your data and privacy`,
  },
]

export const registration = {
  navigation: {
    otp_verification: 'OTP Verification',
  },
  pages: {
    mobile: {
      content: "Enter your phone number, make sure it's your active number to send OTP.",
    },
    otp: {
      content: ['Please enter the OTP sent to ', "Didn't receive the code?", 'Resend'],
    },
  },
  buttons: {
    proceed: 'Proceed',
    resend: 'Resend',
  },
}
