import ReactGA from 'react-ga'

import {
  getCountryCodeLocalDb,
  captureIncomingQrCode,
  getAssumeAccount,
  getTokenLocalDb,
} from 'utils'
import { BASE_AREA51_URL } from 'const'
import {
  getBackToMerchantLink,
  getIsRepeatUser,
  setBackToMerchantLink,
  setCountryCodeLocalDb,
  setIsRepeatUser,
} from './localDbActions'

export function signOut() {
  const token = getTokenLocalDb()
  const countryCode = getCountryCodeLocalDb()
  const isRepeatUser = getIsRepeatUser()
  const isAssumeAccount = getAssumeAccount()

  // unset user id for GA user explorer
  ReactGA.set({ userId: null })

  // record certain localstorage keys
  const merchantRedirectUrl = getBackToMerchantLink()

  if (isAssumeAccount) {
    localStorage.clear()
    window.location.href = `${BASE_AREA51_URL}/ethics/v2/stop_assuming/?assume_token=${token}`
    return
  }

  localStorage.clear()
  setCountryCodeLocalDb(countryCode)
  if (isRepeatUser) setIsRepeatUser()

  // restore certain localstorage keys
  if (merchantRedirectUrl) setBackToMerchantLink(merchantRedirectUrl)

  captureIncomingQrCode()

  const { protocol, hostname, port } = window.location
  const portNo = port ? `:${port}` : ''
  if (isRepeatUser) {
    window.location.href = `${protocol}//${hostname}${portNo}/${countryCode}/register/`
  } else {
    window.location.href = `${protocol}//${hostname}${portNo}/${countryCode}/onboarding/`
  }

  return
}
