import React, { useEffect, useState } from 'react'

import { useBuyerStore } from 'stores'
import { PageWrapper, Spacer, StyledBackdrop, Flex, Footer, TriggerPopup } from 'components'
import { theme } from 'styles/theme'
import { getCountryCodeLocalDb, removeTokenLocalDb } from 'utils'
import { GENERIC_ERR_MESSAGE } from 'const'

import ProfileHeader from './ProfileHeader/ProfileHeader'
import ProfileHeaderLoading from './ProfileHeader/ProfileHeaderLoading'
import ProfileOptions from './ProfileOptions/ProfileOptions'

const Profile = () => {
  const countryCode = getCountryCodeLocalDb()

  const [principalName, setPrincipalName] = useState('')

  const {
    response: { getOrCreate: getOrCreateResponse },
    errors: { getOrCreate: getOrCreateError },
    resetStates: resetStatesBuyer,
    submitGetOrCreate,
    fetchCredits,
  } = useBuyerStore()

  // *Effects
  useEffect(() => {
    fetchCredits(countryCode)
    submitGetOrCreate()

    return () => resetStatesBuyer('credits')
  }, [])

  useEffect(() => {
    if (getOrCreateResponse) {
      if (getOrCreateResponse.name) {
        setPrincipalName(getOrCreateResponse.name)
      } else {
        setPrincipalName('unverified user')
      }
    }
  }, [getOrCreateResponse])

  // *JSX
  return (
    <PageWrapper withBottomBar backgroundColor={theme.colors.background}>
      <TriggerPopup
        message={(getOrCreateError && getOrCreateError.message) || GENERIC_ERR_MESSAGE}
        showPopup={!!getOrCreateError}
        buttonText="Return"
        callback={() => {
          resetStatesBuyer('getOrCreate')
          removeTokenLocalDb()
        }}
        nextDestination="/register"
        replaceHistory
      />

      <Flex flexDirection="column" width="100vw" backgroundColor="white" ml={-16} mr={-16} px={16}>
        <StyledBackdrop>
          {principalName ? <ProfileHeader name={principalName} /> : <ProfileHeaderLoading />}
        </StyledBackdrop>
        <Spacer height={20} />
        <ProfileOptions />
      </Flex>

      <Footer
        style={{
          height: 200,
          backgroundColor: theme.colors.background,
          zIndex: -1,
        }}
      />
    </PageWrapper>
  )
}

export default Profile
