import React from 'react'
import PropTypes from 'prop-types'

import { Flex, Paragraph as P, Image, Span } from 'components'
import { theme } from 'styles/theme'
import { threeDots } from 'assets'

import { StyledCardContainer } from '../PaymentMethodCard/paymentMethodCardStyle'
import PaymentMethodCardOptions from '../PaymentMethodCard/PaymentMethodCardOptions/PaymentMethodCardOptions'

const EDDARequestCard = ({
  data,
  paymentMethodId,
  paymentMethodStatus,
  isDefault,
  canUpdate,
  canTerminate,
  optionsIsOpenFor,
  setOptionsIsOpenFor,
  ...rest
}) => {
  const handleShowOptions = () => {
    if (optionsIsOpenFor === paymentMethodId) {
      setOptionsIsOpenFor('')
    } else {
      setOptionsIsOpenFor(paymentMethodId)
    }
  }

  return (
    <>
      <StyledCardContainer {...rest}>
        <Flex>
          {/*<Image src={citi} alt="citi_icon" style={{ marginLeft: 4, marginRight: 4 }} />*/}
          <Flex>
            <Flex flexDirection="column" justifyContent="center" ml={3}>
              <Flex alignItems="center">
                <P style={{ fontWeight: 700 }} mr={10}>
                  eGIRO ***{data.bank_acc_no_last_4}
                  {paymentMethodStatus === 'processing' ? (
                    <Span
                      style={{
                        marginLeft: 10,
                        color: theme.colors.buttons.darkTangerine,
                      }}
                    >
                      Processing
                    </Span>
                  ) : isDefault ? (
                    <Span
                      style={{
                        marginLeft: 10,
                        color: theme.colors.buttons.marineBlue,
                      }}
                    >
                      Default
                    </Span>
                  ) : null}
                </P>
              </Flex>
              <P color={theme.colors.typography.gray7}>{data.bank_name}</P>
            </Flex>
          </Flex>
        </Flex>
        <Flex justifySelf="flex-end" onClick={handleShowOptions}>
          <Image src={threeDots} alt="open_options" />
        </Flex>
        {optionsIsOpenFor === paymentMethodId && (
          <PaymentMethodCardOptions
            paymentMethodId={paymentMethodId}
            setOptionsIsOpenFor={setOptionsIsOpenFor}
            disableSetAsDefault={paymentMethodStatus === 'processing'}
            canUpdate={canUpdate}
            canTerminate={canTerminate}
            data={data}
            mode="edda"
          />
        )}
      </StyledCardContainer>
    </>
  )
}

EDDARequestCard.propTypes = {
  paymentMethodId: PropTypes.string.isRequired,
  paymentMethodStatus: PropTypes.string.isRequired,
  isDefault: PropTypes.bool.isRequired,
  canUpdate: PropTypes.bool.isRequired,
  canTerminate: PropTypes.bool.isRequired,
  optionsIsOpenFor: PropTypes.string.isRequired,
  setOptionsIsOpenFor: PropTypes.func.isRequired,
}

export default EDDARequestCard
