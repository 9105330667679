import {
  removeReferredCodeLocalDb,
  removeCheckoutIdLocalDb,
  removeStoreIdLocalDb,
  removeDraftOrderLocalDb,
  removeBackToMerchantLink,
} from 'utils'

export const clearLocalDb = () => {
  removeReferredCodeLocalDb()
  removeCheckoutIdLocalDb()
  removeStoreIdLocalDb()
  removeBackToMerchantLink()
  removeDraftOrderLocalDb()
}
