import React from 'react'

import { StyledOverlay, StyledContainer } from './undraggableOverlayStyle'

const UndraggableOverlay = ({
  children,
  containerMinHeight,
  containerMaxHeight,
  transparentOverlay,
  noOverlay,
  ...rest
}) => {
  if (noOverlay) {
    return (
      <StyledContainer
        containerMinHeight={containerMinHeight}
        containerMaxHeight={containerMaxHeight}
        {...rest}
      >
        {children}
      </StyledContainer>
    )
  }

  return (
    <StyledOverlay transparentOverlay={transparentOverlay}>
      <StyledContainer
        containerMinHeight={containerMinHeight}
        containerMaxHeight={containerMaxHeight}
        {...rest}
      >
        {children}
      </StyledContainer>
    </StyledOverlay>
  )
}

export default UndraggableOverlay
