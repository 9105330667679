import styled from 'styled-components'
import { space, color, typography, layout, flexbox, border, position, compose } from 'styled-system'

export const StyledBackNavigation = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-bottom: 10px;

  ${compose(space, color, typography, layout, flexbox, border, position)};
`

export const StyledRoundNavigation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  mix-blend-mode: normal;
  background-color: ${props => (props.noBackground ? 'transparent' : 'rgba(0, 0, 0, 0.26)')};
  cursor: pointer;
  z-index: 1;
`
