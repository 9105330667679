import React, { useLayoutEffect, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

import { Flex, Paragraph as P, Button, CustomIndicator, Image } from 'components'
import { useBuyerStore } from 'stores'
import { tutorialPointer } from 'assets'

import { StyledBubbleContainer } from './tutorialBubbleStyle'

const TutorialBubble = ({
  totalSteps,
  currentStep,
  anchorId,
  content,
  buttonText,
  action,
  shouldCallInteractionApi,
  setShouldCallInteractionApi,
  handleClick,
  ...rest
}) => {
  const [anchorBottom, setAnchorBottom] = useState(0)
  const [anchorMiddle, setAnchorMiddle] = useState(0)
  const [shouldUpdatePosition, setShouldUpdatePosition] = useState(false)

  useScrollPosition(({ prevPos, currPos }) => {
    if (prevPos.y !== currPos.y) {
      setShouldUpdatePosition(!shouldUpdatePosition)
    }
  })

  const { submitBuyerInteraction, resetStates } = useBuyerStore()

  // *Effects
  useLayoutEffect(() => {
    const element = document.getElementById(anchorId)
    if (element) {
      const rect = element.getBoundingClientRect()
      setAnchorBottom(rect.bottom)
      setAnchorMiddle((rect.left + rect.right) / 2)
    }
  }, [anchorId, shouldUpdatePosition])

  useEffect(() => {
    return () => resetStates('buyerInteraction')
  }, [])

  // *JSX
  if (anchorMiddle && anchorBottom === 0) return null

  return (
    <StyledBubbleContainer top={anchorBottom} middle={anchorMiddle} {...rest}>
      <Flex flexDirection="column">
        <Image
          src={tutorialPointer}
          alt="tutorial_pointer"
          style={{
            position: 'fixed',
            marginTop: -22,
            left: anchorMiddle,
          }}
        />
        <P variant="subcopy" color="white" style={{ fontWeight: 500 }}>
          {content}
        </P>
        <Flex justifyContent="space-between">
          <CustomIndicator steps={currentStep} totalSteps={totalSteps} />
          <Button
            variant="tutorial"
            onClick={() => {
              if (action && shouldCallInteractionApi) {
                submitBuyerInteraction(action)
                setShouldCallInteractionApi(false)
              }
              handleClick()
            }}
          >
            {buttonText}
          </Button>
        </Flex>
      </Flex>
    </StyledBubbleContainer>
  )
}

TutorialBubble.propTypes = {
  totalSteps: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  anchorId: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  action: PropTypes.string,
  shouldCallInteractionApi: PropTypes.bool.isRequired,
  setShouldCallInteractionApi: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
}

export default TutorialBubble
