import React, { useState, useEffect } from 'react'

import {
  Flex,
  Paragraph as P,
  DropDown,
  DropDownLoading,
  Spacer,
  FormTextArea,
  Footer,
  Button,
  TriggerPopup,
} from 'components'
import { theme } from 'styles/theme'
import { useBuyerStore } from 'stores'
import { logUserInteractionGA } from 'utils'

const ContactUsForm = ({ setFeedBackSuccess }) => {
  const [topic, setTopic] = useState(null)
  const [message, setMessage] = useState('')

  const {
    isLoading: { contactUsSubmit: contactUsSubmitIsLoading },
    response: {
      contactUsTopics: contactUsTopicsResponse,
      contactUsSubmit: contactUsSubmitResponse,
    },
    errors: { contactUsSubmit: contactUsSubmitError },
    resetStates: resetStatesBuyer,
    fetchContactUsTopics,
    submitContactUs,
  } = useBuyerStore()

  // *Methods
  const handleClickDropDownOption = topic => {
    setTopic(topic)
  }

  const handleSubmitFeedback = () => {
    const data = {
      topic: topic.value,
      message: message,
    }

    logUserInteractionGA(`Submit - Send Feedback`, { message: message })
    submitContactUs(data)
  }

  // *Effects
  useEffect(() => {
    fetchContactUsTopics()

    return () => {
      resetStatesBuyer('contactUsTopic')
      resetStatesBuyer('contactUsSubmit')
    }
  }, [])

  useEffect(() => {
    if (contactUsTopicsResponse) {
      setTopic(contactUsTopicsResponse.topics[0])
    }
  }, [contactUsTopicsResponse])

  useEffect(() => {
    if (contactUsSubmitResponse) {
      if (contactUsSubmitResponse.success) {
        resetStatesBuyer('contactUsSubmit')
        setFeedBackSuccess(true)
      }
    }
  }, [contactUsSubmitResponse])

  // *JSX
  return (
    <>
      {/* Error handling */}
      <TriggerPopup
        showPopup={contactUsSubmitError}
        message="An error occurred when sending your message. Please try again."
        stayAtCurrentPage
        buttonText="OKAY"
      />

      <Flex flexDirection="column" mt={3} stretch>
        <P variant="small" style={{ fontWeight: 600 }}>
          Topic
        </P>

        {contactUsTopicsResponse && contactUsTopicsResponse.topics && topic ? (
          <DropDown
            activeValue={topic}
            options={contactUsTopicsResponse.topics}
            onClickOption={handleClickDropDownOption}
          />
        ) : (
          <DropDownLoading />
        )}
        <Spacer height={20} />
        <P variant="small" style={{ fontWeight: 600 }}>
          Message
        </P>
        <FormTextArea
          placeholder="How can we help you?"
          rows="4"
          value={message}
          onChange={e => setMessage(e.target.value)}
          required
        />
      </Flex>
      <Footer
        style={{
          backgroundColor: theme.colors.background,
        }}
      >
        <Button
          disabled={message.length < 5 || !topic}
          isLoading={contactUsSubmitIsLoading}
          onClick={handleSubmitFeedback}
        >
          SEND FEEDBACK
        </Button>
      </Footer>
    </>
  )
}

export default ContactUsForm
