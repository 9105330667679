import React from 'react'

import { Flex, FormError } from 'components'
import { successCircleGreen } from 'assets'
import { theme } from 'styles'

import { StyledInput, StyledRightIcon } from 'components/FormInput/formInputStyle'

const VoucherEntryInput = ({ voucherCode, voucherCodeIsValid, handleChangeVoucherCode }) => {
  return (
    <Flex flexDirection="column" width="100%">
      <StyledInput
        type="email"
        value={voucherCode}
        onChange={handleChangeVoucherCode}
        placeholder="Voucher code"
        showRightIcon
        rightIcon={successCircleGreen}
      />
      {voucherCodeIsValid && <StyledRightIcon onClick={() => {}} icon={successCircleGreen} />}

      <Flex pt={45} style={{ position: 'absolute' }}>
        {voucherCode && !voucherCodeIsValid && <FormError>Invalid voucher code</FormError>}
        {voucherCode && voucherCodeIsValid && (
          <FormError style={{ color: theme.colors.progressbar.barGreen1 }}>
            Voucher available
          </FormError>
        )}
      </Flex>
    </Flex>
  )
}

export default VoucherEntryInput
