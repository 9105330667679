import React, { useEffect } from 'react'

import { useOrderStore } from 'stores'
import { NoTransactions, Spacer } from 'components'
import { getCountryCodeLocalDb } from 'utils'

import TransactionLoading from '../TransactionLoading/TransactionLoading'
import TransactionRow from '../TransactionRow/TransactionRow'

const CompletedTransactions = () => {
  const countryCode = getCountryCodeLocalDb()

  const {
    response: { fullyRepaid: fullyRepaidResponse },
    fetchOrderFullyRepaid,
  } = useOrderStore()

  // *Effects
  useEffect(() => {
    fetchOrderFullyRepaid(countryCode)
  }, [])

  // *JSX
  if (!fullyRepaidResponse) return <TransactionLoading />

  if (
    fullyRepaidResponse &&
    fullyRepaidResponse.orders &&
    fullyRepaidResponse.orders.length === 0
  ) {
    return <NoTransactions title="No completed transaction" content="" />
  }

  return (
    <>
      <Spacer height={20} />
      {fullyRepaidResponse.orders.map(order => {
        return <TransactionRow order={order} type="completed" key={order.code} />
      })}
    </>
  )
}

export default CompletedTransactions
