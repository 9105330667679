import React, { useEffect } from 'react'
import { AnimatePresence } from 'framer-motion'

import {
  PageWrapper,
  Flex,
  Heading as H,
  Spacer,
  BrandContainer,
  TriggerPopup,
  Image,
  Footer,
} from 'components'
import { theme } from 'styles/theme'
import { dashedLine } from 'assets'
import { getPaymentTransactionLocalDb, logUserInteractionGA } from 'utils'

import { StyledReceiptContainer, StyledPunchHole } from './paymentReceiptStyle'
import ReceiptAmount from './ReceiptAmount/ReceiptAmount'
import PaymentDetailsInstalment from './PaymentDetails/PaymentDetailsInstalment'
import PaymentDetailsPayLater from './PaymentDetails/PaymentDetailsPayLater'

const PaymentReceipt = () => {
  const paymentTransaction = getPaymentTransactionLocalDb()
  let order, repayment
  if (paymentTransaction.repayment) repayment = paymentTransaction.repayment
  if (paymentTransaction.order) order = paymentTransaction.order

  const currentUrl = window.location.pathname
  const transactionType = currentUrl.includes('/transaction') ? 'Payment' : 'Purchase'

  if (!paymentTransaction) {
    return <TriggerPopup showPopup={!paymentTransaction} message="No payment transaction found." />
  }

  useEffect(() => {
    logUserInteractionGA(`Success - Payment Successful`, {
      order: order ? order.code : 'NA',
      repayment: repayment ? repayment.id : 'NA',
    })
  }, [])

  return (
    <PageWrapper
      style={{
        background: theme.colors.abnkBlue,
      }}
    >
      <AnimatePresence>
        <Flex flexDirection="column" alignItems="center" width="100%">
          <Spacer height={10} />
          <H
            variant="h2"
            color="white"
            style={{ fontWeight: 700 }}
            initial={{ y: '100vh' }}
            animate={{ y: 0 }}
          >
            {`${transactionType} complete!`}
          </H>

          <StyledReceiptContainer
            initial={{ y: '100vh' }}
            animate={{ y: 0 }}
            transition={{ delay: 0.3 }}
          >
            {order && order.merchant && (
              <BrandContainer
                brandLogo={order.merchant.logo}
                size={80}
                style={{
                  margin: 'auto',
                  marginTop: '-50px',
                  marginBottom: '-20px',
                }}
              />
            )}

            {/* instalment */}
            {repayment &&
              repayment.payment_transaction &&
              repayment.payment_transaction.amount &&
              order &&
              order.store &&
              order.store.name &&
              order.selected_payment_plan &&
              order.selected_payment_plan.instalment_frequency !== 'end_of_month' && (
                <ReceiptAmount
                  amount={repayment.payment_transaction.amount}
                  merchantName={order.store.name}
                  delay={0.8}
                />
              )}

            {/* paylater */}
            {order &&
              order.repayments_total_mount &&
              order.store &&
              order.store.name &&
              order.selected_payment_plan &&
              order.selected_payment_plan.instalment_frequency === 'end_of_month' && (
                <ReceiptAmount
                  amount={order.repayments_total_mount}
                  merchantName={order.store.name}
                  delay={0.8}
                />
              )}

            <>
              <StyledPunchHole variant="left" />
              <StyledPunchHole variant="right" />
              <Image src={dashedLine} alt="dashed_line" style={{ marginTop: 20 }} />
            </>
            <Spacer height={40} />

            {/* instalment */}
            {paymentTransaction &&
              order &&
              order.selected_payment_plan &&
              order.selected_payment_plan.instalment_frequency !== 'end_of_month' && (
                <PaymentDetailsInstalment data={paymentTransaction} />
              )}

            {/* paylater */}
            {paymentTransaction &&
              order &&
              order.selected_payment_plan &&
              order.selected_payment_plan.instalment_frequency === 'end_of_month' && (
                <PaymentDetailsPayLater data={paymentTransaction} />
              )}
          </StyledReceiptContainer>
        </Flex>
      </AnimatePresence>

      <Footer
        style={{
          height: '200px',
          background: theme.colors.abnkBlue,
          zIndex: -1,
        }}
      />
    </PageWrapper>
  )
}

export default PaymentReceipt
