import React from 'react'
import EDDAForm from './EDDAForm'
import { useForm, FormProvider } from 'react-hook-form'
import { useBuyerStore } from 'stores'

const AddEDDA = () => {
  const methods = useForm({ mode: 'all' })
  const { submitPaymentMethod } = useBuyerStore()

  const editableFields = ['applicant_bank_code']

  const handleSubmit = async () => {
    let res
    await methods.handleSubmit(async data => {
      const edda_request = {}
      editableFields.map(field => {
        edda_request[field] = data[field]
      })
      res = await submitPaymentMethod({
        third_party_name: 'dbs_egiro',
        dbs_egiro: { edda_request },
      })
    })()
    return res
  }

  return (
    <FormProvider {...methods}>
      <EDDAForm
        backButtonText="Add eGIRO"
        buttonText="Submit eGIRO request"
        path="new"
        editableFields={editableFields}
        handleSubmit={handleSubmit}
      />
    </FormProvider>
  )
}

export default AddEDDA
