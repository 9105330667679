import React from 'react'

import { Flex, Heading as H } from 'components'
import { fetchMobileNo, getNameInitials, capitalizeAllFirstLetters } from 'utils'

const ProfileHeader = ({ name }) => {
  return (
    <Flex alignItems="center" mt={10} ml={10} flex="1">
      <Flex
        centered
        style={{
          borderRadius: '50%',
          backgroundColor: '#FFB352',
          minWidth: 60,
          minHeight: 60,
          border: '2px solid white',
        }}
      >
        <H variant="h1" color="white">
          {getNameInitials(name)}
        </H>
      </Flex>
      <Flex flexDirection="column" marginLeft={15}>
        <H
          variant="h1"
          color="white"
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            maxWidth: 'calc(100vw - 32px - 90px)',
          }}
        >
          {capitalizeAllFirstLetters(name)}
        </H>
        <H variant="h4" color="white" style={{ fontSize: 16 }}>
          {fetchMobileNo() || ''}
        </H>
      </Flex>
    </Flex>
  )
}

export default ProfileHeader
