import styled from 'styled-components'
import { variant } from 'styled-system'

export const StyledContainer = styled.div`
  ${variant({
    variants: {
      fullscreen: {
        position: 'fixed',
        height: '100%',
        width: '100%',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'white',
        zIndex: 2,
      },
    },
  })}
`
