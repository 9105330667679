import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import * as dayjs from 'dayjs'

import { Flex, Paragraph as P, Spacer, Button, Image } from 'components'
import { theme } from 'styles/theme'
import { dashedLine } from 'assets'
import { formatAsCurrency, secureCardNumber, fetchCardIcon } from 'utils'

const TransactionReceiptDetails = ({ data }) => {
  const history = useHistory()

  const pMethod = data.repayment.payment_transaction
    ? data.repayment.payment_transaction.payment_method
    : data.paymentMethod

  const [transactionDetails, setTransactionDetails] = useState([])

  // *Methods
  const renderPaymentDetailRow = (key, value) => {
    return (
      <Flex key={key} justifyContent="space-between" mb={2} alignItems="center">
        <P style={{ fontWeight: 500 }} color={theme.colors.typography.gray10}>
          {key}
        </P>
        <Spacer width={20} />
        <P
          style={{
            fontWeight: 700,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
          textAlign="right"
        >
          {value}
        </P>
      </Flex>
    )
  }

  // *Effects
  useEffect(() => {
    if (data) {
      setTransactionDetails([
        {
          key: 'Order ID',
          value: data.orderId,
        },
        { key: 'Merchant', value: data.store.name },
        {
          key: 'Transacted at',
          value: dayjs(
            data.repayment.payment_transaction
              ? data.repayment.payment_transaction.created_at
              : data.repayment.due_at,
          ).format('DD MMM YYYY, hh:mma'),
        },
      ])
    }
  }, [data])

  // *JSX
  return (
    <Flex flexDirection="column" px={3}>
      {transactionDetails &&
        transactionDetails.map(detail => {
          if (detail.key === 'Payment plan' || detail.key === 'Duration')
            return <Spacer height={10} key={detail.key} />

          return renderPaymentDetailRow(detail.key, detail.value)
        })}
      {data &&
        data.vouchers &&
        data.vouchers.length > 0 &&
        renderPaymentDetailRow('Voucher used', data.vouchers[0].code)}
      <>
        <Spacer height={10} />
        <Image src={dashedLine} alt="dashed_line" style={{ margin: '0 10px' }} />
        <Spacer height={10} />
      </>
      {renderPaymentDetailRow('Paid Amount', formatAsCurrency(data.repayment.total_amount))}
      <P style={{ fontWeight: 500 }} color={theme.colors.typography.gray10}>
        Payment method
      </P>
      <Flex justifyContent="space-between" py={2}>
        {pMethod.mode === 'card' && (
          <>
            <Image
              src={fetchCardIcon(pMethod.card.brand)}
              alt="payment_logo"
              style={{ width: 50 }}
            />
            <P style={{ fontWeight: 700 }} textAlign="right">
              {secureCardNumber(pMethod.card.ending_digits)}
            </P>
          </>
        )}
        {pMethod.mode === 'edda' && (
          <>
            <P>{pMethod.edda_request.bank_name}</P>
            <P style={{ fontWeight: 700 }} textAlign="right">
              {`*** ${pMethod.edda_request.bank_acc_no_last_4}`}
            </P>
          </>
        )}
      </Flex>

      <Spacer height={40} />
      <Button variant="secondary" onClick={() => history.goBack()}>
        RETURN
      </Button>
    </Flex>
  )
}

export default TransactionReceiptDetails
