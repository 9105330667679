import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { AnimatePresence } from 'framer-motion'
import isEmpty from 'lodash/isEmpty'

import {
  PageWrapper,
  Flex,
  NavigateBack,
  ProgressBar,
  FormInput,
  Button,
  Spacer,
  Paragraph as P,
  Heading as H,
  FormSelect,
  Modal,
  Drawer,
  Accordion,
  Footer,
  FormDatePicker,
  TriggerPopup,
} from 'components'
import {
  sexOptions,
  raceOptions,
  nationalityOptions,
  countryOfBirthOptions,
  homeOwnershipOptions,
  residentialStatusOptions,
  typeOfHousingOptions,
  typeOfHDBOptions,
  privatePropertyOwnershipOptions,
  highestEducationLevelOptions,
  maritalStatusOptions,
  VERIFY_IDENTITY_MAX_PAGES,
  VERIFY_IDENTITY_PAGES,
  SG_NRIC_REGEX,
  EMAIL_REGEX,
  SG_MOBILE_REGEX,
  SG_POSTAL_REGEX,
} from 'const'
import { useBuyerStore } from 'stores'
import {
  getCountryCodeLocalDb,
  removeIdentityFormStorage,
  getMobileNumberLocalDb,
  getMyinfoDataLocalDb,
  getSgVerifyIdentitySavedFormLocalDb,
  setSgVerifyIdentitySavedFormLocalDb,
  advancedDayjs,
  findIdentityKyc,
  getKycCreatedAt,
  logUserInteractionGA,
} from 'utils'

import { StyledTextArea } from 'components/FormTextArea/formTextAreaStyle'

const VerifyIdentityForm = () => {
  const history = useHistory()
  const location = useLocation().search
  const page = new URLSearchParams(location).get('page')
  const countryCode = getCountryCodeLocalDb()

  const [showExitModal, setShowExitModal] = useState(false)
  const [showFormErrorModal, setShowFormErrorModal] = useState(false)
  const [currentStep, setCurrentStep] = useState(parseInt(page))
  const [activeDrawer, setActiveDrawer] = useState('Personal Information')
  const [concatenatedAddress, setConcatenatedAddress] = useState('')
  const [generalError, setGeneralError] = useState('')

  const manualCpfContribution = useRef(false)
  const manualNoaHistory = useRef(false)
  const shouldUpdateLocalStorage = useRef(true)
  const defaultData = useRef({
    mobile_number: getMobileNumberLocalDb(),
    cpf_contribution_history: [{ month: '', date: '', employer: '', amount: '' }],
    noa_history: [
      {
        year_of_assessment: '',
        amount: '',
        employment: '',
        interest: '',
      },
    ],
  })

  const {
    isLoading: { sgPersonal: sgPersonalIsLoading, credits: creditsIsLoading },
    response: { sgPersonal: sgPersonalResponse, credits: creditsResponse },
    errors: { sgPersonal: sgPersonalErrors },
    resetStates: resetStatesBuyer,
    submitSgIdentity,
    fetchCredits,
  } = useBuyerStore()

  // *Form settings
  const myinfoData = getMyinfoDataLocalDb()
  const savedFormData = getSgVerifyIdentitySavedFormLocalDb()

  // important to transform null data into empty array so we can input them manually
  if (myinfoData) {
    if (!myinfoData.cpf_contribution_history) {
      manualCpfContribution.current = true
      myinfoData.cpf_contribution_history = [
        {
          month: '',
          date: '',
          employer: '',
          amount: '',
        },
      ]
    }

    if (!myinfoData.noa_history) {
      manualNoaHistory.current = true
      myinfoData.noa_history = [
        {
          year_of_assessment: '',
          amount: '',
          employment: '',
          interest: '',
        },
      ]
    }

    if (!myinfoData.mobile_number) {
      myinfoData.mobile_number = getMobileNumberLocalDb()
    }

    defaultData.current = myinfoData
  }

  if (savedFormData) {
    defaultData.current = savedFormData
  }

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors: formErrors, isDirty: formIsDirty, isValid: formIsValid },
    control,
    getValues,
    watch,
  } = useForm({
    mode: 'all',
    defaultValues: defaultData.current,
  })

  const {
    fields: cpfContributionFields,
    append: appendCpfContribution,
    remove: removeCpfContribution,
  } = useFieldArray({
    control,
    name: 'cpf_contribution_history',
  })

  const {
    fields: noaHistoryFields,
    append: appendNoaHistory,
    remove: removeNoaHistory,
  } = useFieldArray({
    control,
    name: 'noa_history',
  })

  const showHomeOwnershipOthers = watch('type') === 'OTHERS'
  const showHousingType = watch('type_of_hdb') === ''

  // *Methods
  const handleExpandDrawer = section => {
    if (activeDrawer === section) {
      setActiveDrawer('')
    } else {
      setActiveDrawer(section)
    }
    scrollTop()
  }

  const handleAcknowledgeError = () => {
    setShowFormErrorModal(false)
    resetStatesBuyer('sgPersonal')

    history.push('/home')
    history.push('/credit')
    return history.push('/verify?type=identity')
  }

  const handleClearGeneralError = () => {
    setGeneralError('')
    resetStatesBuyer('credits')
    resetStatesBuyer('sgPersonal')

    history.push('/home')
    history.push('/credit')
    return history.push('/verify?type=identity')
  }

  const submitForm = () => {
    logUserInteractionGA('Submit: MyInfo Form')
    handleSubmit(data => {
      let homeOwnershipData = {}
      if (data.type) homeOwnershipData['type'] = data.type
      if (data.others) homeOwnershipData['others'] = data.type
      if (isEmpty(homeOwnershipData)) homeOwnershipData = null

      // determine data to be submitted to backend
      // oauth_state is no longer returned to the frontend
      // we determine if this is a manual form by checking whether myinfo_data is in localStorage
      const myinfoData = getMyinfoDataLocalDb()

      let submitted_data = {}
      const isManual = myinfoData === null
      if (isManual) {
        submitted_data = { ...data }
        submitted_data.marital_status = submitted_data.marital_status || ''
        submitted_data.highest_education_level = submitted_data.highest_education_level || ''
      } else {
        submitted_data = {
          email: data.email,
          employment_sector: data.employment_sector,
          highest_education_level: data.highest_education_level,
          marital_status: data.marital_status,
        }
      }

      submitSgIdentity(homeOwnershipData, submitted_data)
    })()
  }

  const scrollTop = () => {
    document.getElementById('scrollable-form').scrollIntoView()
  }

  const onClickPrevious = () => {
    // if (currentStep > 1) {
    history.goBack()
    scrollTop()
    // }
  }

  const onClickNext = () => {
    if (currentStep === VERIFY_IDENTITY_MAX_PAGES) {
      submitForm()
    }
    if (currentStep < VERIFY_IDENTITY_MAX_PAGES) {
      history.push(`/verify/identity?page=${currentStep + 1}`)
    }
    scrollTop()
  }

  const concatenateAddress = () => {
    const data = watch()
    const {
      registered_address_floor: floor,
      registered_address_unit: unit,
      registered_address_block: block,
      registered_address_building: building,
      registered_address_street: street,
      registered_address_postal: postal,
    } = data

    const address = `${floor ? `#${floor}-` : ''}${unit ? `${unit}, ` : ''}${
      block ? `BLOCK ${block}, ` : ''
    }${building ? `${building}, ` : ''}${street ? `${street}, ` : ''}SINGAPORE ${postal}`
    setConcatenatedAddress(address)
  }

  // *Effects
  useEffect(() => {
    return () => resetStatesBuyer('sgPersonal')
  }, [])

  useEffect(() => {
    setCurrentStep(parseInt(new URLSearchParams(location).get('page')))
  }, [location])

  useEffect(() => {
    if (sgPersonalResponse) {
      fetchCredits(countryCode)
    }
  }, [sgPersonalResponse])

  // !credit assessment checkpoint start
  useEffect(() => {
    if (creditsResponse && creditsResponse.warning_message && countryCode === 'sg') {
      // warning message, redirect to verification outcome warning page
      resetStatesBuyer('sgPersonal')
      resetStatesBuyer('credits')
      removeIdentityFormStorage()
      shouldUpdateLocalStorage.current = false

      history.push('/home')
      history.push('/credit')
      return history.push('/verify/outcome')
    }

    if (creditsResponse && creditsResponse.kycs) {
      const identityKyc = findIdentityKyc(creditsResponse.kycs)
      if (identityKyc && identityKyc.status === 'data_error') {
        // check if the status is newly created
        const previousIdentityKyc = getKycCreatedAt('sg_identity')
        if (!previousIdentityKyc.created_at) {
          setGeneralError('An error occurred. Please try again.')
        }

        if (advancedDayjs(identityKyc.created_at).isAfter(previousIdentityKyc.created_at)) {
          setGeneralError('An error occurred. Please try again.')
        }
      }

      if (identityKyc && identityKyc.status !== 'data_error') {
        // no error, proceed to success page
        resetStatesBuyer('sgPersonal')
        resetStatesBuyer('credits')
        removeIdentityFormStorage()
        shouldUpdateLocalStorage.current = false

        history.push('/home')
        history.push('/credit')
        return history.push('/verify/success?type=verification')
      }
    }
  }, [creditsResponse])
  // !credit assessment checkpoint end

  useEffect(() => {
    if (sgPersonalErrors && sgPersonalErrors.errors) {
      if (sgPersonalErrors.errors.identity && sgPersonalErrors.errors.identity.manual_data) {
        for (const [key, value] of Object.entries(sgPersonalErrors.errors.identity.manual_data)) {
          setError(key, { message: value[0] }, true)
        }
      }

      if (sgPersonalErrors.errors && sgPersonalErrors.errors.home_ownership) {
        for (const [key, value] of Object.entries(sgPersonalErrors.errors.home_ownership)) {
          setError(key, { message: value[0] }, true)
        }
      }

      setShowFormErrorModal(true)
      setCurrentStep(1)
    }

    if (sgPersonalErrors && sgPersonalErrors.message) {
      setShowFormErrorModal(true)
    }
  }, [sgPersonalErrors])

  // save form state to localStorage
  useEffect(() => {
    concatenateAddress()

    if (formIsDirty && shouldUpdateLocalStorage.current) {
      setSgVerifyIdentitySavedFormLocalDb(watch())
    }
  }, [watch()])

  // *JSX
  return (
    <PageWrapper id="scrollable-form" withTopNav>
      {/* Modals */}
      {showExitModal && (
        <Modal
          messageArray={[
            'Are you sure you want to exit the "Verify Identity" process?',
            'You can continue filling up the form later.',
          ]}
          primaryButtonText="Stay"
          primaryButtonAction={() => setShowExitModal(false)}
          secondaryButtonText="Exit"
          secondaryButtonAction={() => {
            return history.push('/verify?type=identity')
          }}
        />
      )}

      {showFormErrorModal && sgPersonalErrors && sgPersonalErrors.errors && (
        <Modal
          messageArray={[
            'There are some input errors in the form.',
            'Please correct the errors before submitting again.',
          ]}
          primaryButtonText="OKAY"
          primaryButtonAction={() => setShowFormErrorModal(false)}
        />
      )}

      {showFormErrorModal && sgPersonalErrors && sgPersonalErrors.message && (
        <Modal
          messageArray={[sgPersonalErrors.message]}
          primaryButtonText="OKAY"
          primaryButtonAction={handleAcknowledgeError}
        />
      )}

      {generalError && generalError.length > 0 && (
        <TriggerPopup
          message={generalError}
          showPopup={generalError.length > 0}
          callback={handleClearGeneralError}
          stayAtCurrentPage
          buttonText="OKAY"
        />
      )}

      {/* Navigation */}
      <NavigateBack
        progressBar={
          <ProgressBar variant="narrow" current={currentStep} total={VERIFY_IDENTITY_MAX_PAGES} />
        }
      >
        {myinfoData ? 'Confirm your details' : 'Enter your details'}
      </NavigateBack>

      {/* Form */}
      <form onSubmit={submitForm} style={{ width: '100%' }}>
        <AnimatePresence>
          {currentStep === 1 && (
            <section key={currentStep}>
              <Flex pt={10} pb={50} px={2} flexDirection="column">
                <Flex pb={110} flexDirection="column" width="100%" overflowY="scroll">
                  <Drawer
                    title="Personal Information"
                    isOpen={activeDrawer === 'Personal Information'}
                    handleClick={() => handleExpandDrawer('Personal Information')}
                  >
                    <FormInput
                      variant={myinfoData && myinfoData.nric ? 'autofill' : ''}
                      readOnly={myinfoData && myinfoData.nric}
                      label="NRIC"
                      showLabel
                      register={register}
                      id="nric"
                      type="text"
                      error={formErrors.nric}
                      placeholder={countryCode === 'sg' ? 'S1234567J' : '900000081234'}
                      required={!myinfoData}
                      validationRules={{
                        pattern: {
                          value: SG_NRIC_REGEX,
                          message: 'Please provide a valid NRIC.',
                        },
                      }}
                    />
                    <Spacer height={15} />

                    <FormInput
                      variant={myinfoData && myinfoData.principal_name ? 'autofill' : ''}
                      readOnly={myinfoData && myinfoData.principal_name}
                      label="full name"
                      showLabel
                      register={register}
                      id="principal_name"
                      type="text"
                      error={formErrors.principal_name}
                      placeholder="Peter Pan"
                      required={!myinfoData}
                    />
                    <Spacer height={15} />

                    <Controller
                      name="sex"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <FormSelect
                          {...field}
                          required={!myinfoData}
                          label="sex"
                          showLabel
                          isSearchable={false}
                          options={sexOptions}
                          variant={myinfoData && myinfoData.sex ? 'autofill' : ''}
                          isDisabled={myinfoData && myinfoData.sex}
                          onChange={e => field.onChange(e.value)}
                          value={sexOptions.find(c => c.value === field.value)}
                          style={{ width: '100%' }}
                          error={formErrors.sex}
                        />
                      )}
                    />
                    <Spacer height={15} />

                    <Controller
                      name="race"
                      control={control}
                      rules={{ required: !myinfoData }}
                      render={({ field }) => (
                        <FormSelect
                          {...field}
                          label="race"
                          required={!myinfoData}
                          showLabel
                          // isSearchable={false}
                          options={raceOptions}
                          variant={myinfoData && myinfoData.race ? 'autofill' : ''}
                          isDisabled={myinfoData && myinfoData.race}
                          onChange={e => field.onChange(e.value)}
                          value={raceOptions.find(c => c.value === field.value)}
                          style={{ width: '100%' }}
                          error={formErrors.race}
                        />
                      )}
                    />
                    <Spacer height={15} />
                  </Drawer>

                  <Drawer
                    title="Income Information"
                    isOpen={activeDrawer === 'Income Information'}
                    handleClick={() => handleExpandDrawer('Income Information')}
                  >
                    <Controller
                      name="ownership_private_property"
                      control={control}
                      rules={{
                        required:
                          !myinfoData && getValues('ownership_private_property') === undefined,
                      }}
                      render={({ field }) => (
                        <FormSelect
                          {...field}
                          label="ownership of private property"
                          showLabel
                          required={
                            !myinfoData && getValues('ownership_private_property') === undefined
                          }
                          isSearchable={false}
                          options={privatePropertyOwnershipOptions}
                          variant={myinfoData ? 'autofill' : ''}
                          isDisabled={myinfoData}
                          onChange={e => field.onChange(e.value)}
                          value={privatePropertyOwnershipOptions.find(c => c.value === field.value)}
                          error={formErrors.ownership_private_property}
                        />
                      )}
                    />
                    <Spacer height={20} />

                    <H variant="h4">CPF Balances</H>
                    <Spacer height={10} />
                    <FormInput
                      variant={
                        myinfoData &&
                        myinfoData.cpf_balances &&
                        myinfoData.cpf_balances.ordinary_account
                          ? 'autofill'
                          : ''
                      }
                      readOnly={myinfoData}
                      label="CPF (OA)"
                      placeholder="0.00"
                      showLabel
                      register={register}
                      id="cpf_balances.ordinary_account"
                      type="number"
                      error={formErrors.cpf_balances && formErrors.cpf_balances['ordinary_account']}
                      required={!myinfoData}
                      disabled={myinfoData}
                    />
                    <Spacer height={15} />

                    <FormInput
                      variant={
                        myinfoData &&
                        myinfoData.cpf_balances &&
                        myinfoData.cpf_balances.medisave_account
                          ? 'autofill'
                          : ''
                      }
                      readOnly={myinfoData}
                      label="CPF (MA)"
                      placeholder="0.00"
                      showLabel
                      register={register}
                      id="cpf_balances.medisave_account"
                      type="number"
                      error={formErrors.cpf_balances && formErrors.cpf_balances['medisave_account']}
                      required={!myinfoData}
                      disabled={myinfoData}
                    />
                    <Spacer height={15} />

                    <FormInput
                      variant={
                        myinfoData &&
                        myinfoData.cpf_balances &&
                        myinfoData.cpf_balances.special_account
                          ? 'autofill'
                          : ''
                      }
                      readOnly={myinfoData}
                      label="CPF (SA)"
                      placeholder="0.00"
                      showLabel
                      register={register}
                      id="cpf_balances.special_account"
                      type="number"
                      error={formErrors.cpf_balances && formErrors.cpf_balances['special_account']}
                      required={!myinfoData}
                      disabled={myinfoData}
                    />
                    <Spacer height={15} />

                    <FormInput
                      variant={
                        myinfoData &&
                        myinfoData.cpf_balances &&
                        myinfoData.cpf_balances.retirement_account
                          ? 'autofill'
                          : ''
                      }
                      readOnly={myinfoData}
                      label="CPF (RA)"
                      placeholder="0.00"
                      showLabel
                      register={register}
                      id="cpf_balances.retirement_account"
                      type="number"
                      error={
                        formErrors.cpf_balances && formErrors.cpf_balances['retirement_account']
                      }
                      required={!myinfoData}
                      disabled={myinfoData}
                    />
                    <Spacer height={20} />

                    <H variant="h4">CPF Contribution History</H>
                    <Spacer height={10} />

                    {myinfoData &&
                    myinfoData.cpf_contribution_history &&
                    !manualCpfContribution.current ? (
                      myinfoData.cpf_contribution_history.map((contribution, i) => {
                        return (
                          <Accordion
                            accordion
                            key={`cpf_contribution_history[${i}].month`}
                            header={getValues(`cpf_contribution_history[${i}].month`)}
                          >
                            <Spacer height={10} />
                            <Controller
                              name={`cpf_contribution_history[${i}].month`}
                              control={control}
                              rules={{
                                required: !myinfoData,
                              }}
                              disabled={myinfoData}
                              render={({ field }) => {
                                return (
                                  <FormDatePicker
                                    variant="autofill"
                                    label="contribution month"
                                    showLabel
                                    readOnly
                                    required={!myinfoData}
                                    disabled={myinfoData}
                                    error={
                                      formErrors.cpf_contribution_history &&
                                      formErrors.cpf_contribution_history[i] &&
                                      formErrors.cpf_contribution_history[i].month
                                    }
                                    placeholderText="Select month"
                                    selected={field.value && new Date(field.value)}
                                    showMonthYearPicker
                                    onChange={date => {
                                      field.onChange(advancedDayjs(date).format('YYYY-MM'))
                                      setValue(
                                        `cpf_contribution_history[${i}].month`,
                                        advancedDayjs(date).format('YYYY-MM'),
                                      )
                                      setValue(
                                        `cpf_contribution_history[${i}].date`,
                                        advancedDayjs(date).format('YYYY-MM-DD'),
                                      )
                                    }}
                                    dateFormat="MMMM yyyy"
                                  />
                                )
                              }}
                            />
                            <Spacer height={15} />

                            <FormInput
                              variant="autofill"
                              readOnly={myinfoData}
                              label="employer"
                              showLabel
                              register={register}
                              id={`cpf_contribution_history[${i}].employer`}
                              type="text"
                              error={
                                formErrors.cpf_contribution_history &&
                                formErrors.cpf_contribution_history[i] &&
                                formErrors.cpf_contribution_history[i].employer
                              }
                              required={!myinfoData}
                              disabled={myinfoData}
                            />
                            <Spacer height={15} />

                            <FormInput
                              variant="autofill"
                              readOnly={myinfoData}
                              label="amount"
                              showLabel
                              register={register}
                              id={`cpf_contribution_history[${i}].amount`}
                              type="text"
                              error={
                                formErrors.cpf_contribution_history &&
                                formErrors.cpf_contribution_history[i] &&
                                formErrors.cpf_contribution_history[i].amount
                              }
                              required={!myinfoData}
                              disabled={myinfoData}
                            />
                            <Spacer height={10} />
                          </Accordion>
                        )
                      })
                    ) : (
                      // manual cpf contribution entry
                      <>
                        {cpfContributionFields.map((field, i) => (
                          <Accordion
                            defaultActiveKey="0"
                            key={`cpf_contribution_history[${i}].month`}
                            header={getValues(`cpf_contribution_history[${i}].month`)}
                          >
                            <Spacer height={10} />

                            <Controller
                              name={`cpf_contribution_history[${i}].month`}
                              control={control}
                              rules={{
                                required: !myinfoData,
                              }}
                              required={!myinfoData}
                              disabled={myinfoData}
                              render={({ field }) => {
                                return (
                                  <FormDatePicker
                                    label="contribution month"
                                    showLabel
                                    required={!myinfoData}
                                    disabled={myinfoData}
                                    error={
                                      formErrors.cpf_contribution_history &&
                                      formErrors.cpf_contribution_history[i] &&
                                      formErrors.cpf_contribution_history[i].month
                                    }
                                    placeholderText="Select month"
                                    selected={field.value && new Date(field.value)}
                                    showMonthYearPicker
                                    onChange={date => {
                                      field.onChange(advancedDayjs(date).format('YYYY-MM'))
                                      setValue(
                                        `cpf_contribution_history[${i}].month`,
                                        advancedDayjs(date).format('YYYY-MM'),
                                      )
                                      setValue(
                                        `cpf_contribution_history[${i}].date`,
                                        advancedDayjs(date).format('YYYY-MM-DD'),
                                      )
                                    }}
                                    dateFormat="MMMM yyyy"
                                  />
                                )
                              }}
                            />
                            <Spacer height={15} />

                            <FormInput
                              key={`${field.id}_employer`}
                              defaultValue={field.value}
                              label="employer"
                              showLabel
                              register={register}
                              id={`cpf_contribution_history[${i}].employer`}
                              type="text"
                              error={
                                formErrors.cpf_contribution_history &&
                                formErrors.cpf_contribution_history[i] &&
                                formErrors.cpf_contribution_history[i].employer
                              }
                              required={!myinfoData}
                              disabled={myinfoData}
                              placeholder="Company A"
                              onChange={e => {
                                setValue(`cpf_contribution_history[${i}].employer`, e.target.value)
                              }}
                            />
                            <Spacer height={15} />

                            <FormInput
                              key={`${field.id}_amount`}
                              defaultValue={field.value}
                              label="amount"
                              showLabel
                              register={register}
                              id={`cpf_contribution_history[${i}].amount`}
                              type="number"
                              error={
                                formErrors.cpf_contribution_history &&
                                formErrors.cpf_contribution_history[i] &&
                                formErrors.cpf_contribution_history[i].amount
                              }
                              required={!myinfoData}
                              disabled={myinfoData}
                              placeholder="0.00"
                              onChange={e => {
                                setValue(`cpf_contribution_history[${i}].amount`, e.target.value)
                              }}
                            />
                            <Spacer height={15} />
                            <Button
                              variant="secondary"
                              type="button"
                              onClick={() => removeCpfContribution(i)}
                              disabled={cpfContributionFields.length === 1}
                            >
                              REMOVE
                            </Button>
                          </Accordion>
                        ))}

                        {!myinfoData && (
                          <Button
                            type="button"
                            onClick={() =>
                              appendCpfContribution({
                                month: '',
                                date: '',
                                employer: '',
                                amount: '',
                              })
                            }
                          >
                            ADD NEW MONTH
                          </Button>
                        )}
                      </>
                    )}

                    <Spacer height={20} />

                    <H variant="h4">IRAS Notice of Assessment</H>
                    <Spacer height={10} />

                    {myinfoData && myinfoData.noa_history && !manualNoaHistory.current ? (
                      myinfoData.noa_history.map((contribution, i) => {
                        return (
                          <Accordion
                            accordion
                            key={`noa_history[${i}].year_of_assessment`}
                            header={getValues(`noa_history[${i}].year_of_assessment`)}
                          >
                            <Spacer height={10} />
                            <Controller
                              name={`noa_history[${i}].year_of_assessment`}
                              control={control}
                              rules={{
                                required: !myinfoData,
                              }}
                              required={!myinfoData}
                              disabled={myinfoData}
                              render={({ field }) => {
                                return (
                                  <FormDatePicker
                                    variant="autofill"
                                    label="year of assessment"
                                    showLabel
                                    readOnly
                                    required={!myinfoData}
                                    disabled={myinfoData}
                                    error={
                                      formErrors.noa_history &&
                                      formErrors.noa_history[i] &&
                                      formErrors.noa_history[i].year_of_assessment
                                    }
                                    placeholderText="Select year"
                                    selected={field.value && new Date(field.value)}
                                    showYearPicker
                                    onChange={date => {
                                      field.onChange(advancedDayjs(date).format('YYYY'))
                                      setValue(
                                        `noa_history[${i}].year_of_assessment`,
                                        advancedDayjs(date).format('YYYY'),
                                      )
                                    }}
                                    dateFormat="yyyy"
                                  />
                                )
                              }}
                            />

                            <Spacer height={15} />

                            <FormInput
                              variant="autofill"
                              readOnly={myinfoData}
                              label="amount"
                              showLabel
                              register={register}
                              id={`noa_history[${i}].amount`}
                              type="text"
                              error={
                                formErrors.noa_history &&
                                formErrors.noa_history[i] &&
                                formErrors.noa_history[i].amount
                              }
                              required={!myinfoData}
                              disabled={myinfoData}
                            />
                            <Spacer height={15} />

                            <FormInput
                              variant="autofill"
                              readOnly={myinfoData}
                              label="employment"
                              showLabel
                              register={register}
                              id={`noa_history[${i}].employment`}
                              type="text"
                              error={
                                formErrors.noa_history &&
                                formErrors.noa_history[i] &&
                                formErrors.noa_history[i].employment
                              }
                              required={!myinfoData}
                              disabled={myinfoData}
                            />
                            <Spacer height={15} />

                            <FormInput
                              variant="autofill"
                              readOnly={myinfoData}
                              label="interest"
                              showLabel
                              register={register}
                              id={`noa_history[${i}].interest`}
                              type="text"
                              error={
                                formErrors.noa_history &&
                                formErrors.noa_history[i] &&
                                formErrors.noa_history[i].interest
                              }
                              required={!myinfoData}
                              disabled={myinfoData}
                            />
                            <Spacer height={15} />
                          </Accordion>
                        )
                      })
                    ) : (
                      // manual noa history entry
                      <>
                        {noaHistoryFields.map((field, i) => (
                          <Accordion
                            defaultActiveKey="0"
                            key={`noa_history[${i}].year_of_assessment`}
                            header={getValues(`noa_history[${i}].year_of_assessment`)}
                          >
                            <Spacer height={10} />
                            <Controller
                              name={`noa_history[${i}].year_of_assessment`}
                              control={control}
                              rules={{
                                required: !myinfoData,
                              }}
                              required={!myinfoData}
                              disabled={myinfoData}
                              render={({ field }) => {
                                return (
                                  <FormDatePicker
                                    label="year of assessment"
                                    showLabel
                                    required={!myinfoData}
                                    disabled={myinfoData}
                                    error={
                                      formErrors.noa_history &&
                                      formErrors.noa_history[i] &&
                                      formErrors.noa_history[i].year_of_assessment
                                    }
                                    placeholderText="Select year"
                                    selected={field.value && new Date(field.value)}
                                    showYearPicker
                                    onChange={date => {
                                      field.onChange(advancedDayjs(date).format('YYYY'))
                                      setValue(
                                        `noa_history[${i}].year_of_assessment`,
                                        advancedDayjs(date).format('YYYY'),
                                      )
                                    }}
                                    dateFormat="yyyy"
                                  />
                                )
                              }}
                            />
                            <Spacer height={15} />

                            <FormInput
                              key={`${field.id}_amount`}
                              defaultValue={field.value}
                              label="amount"
                              showLabel
                              register={register}
                              id={`noa_history[${i}].amount`}
                              type="number"
                              error={
                                formErrors.noa_history &&
                                formErrors.noa_history[i] &&
                                formErrors.noa_history[i].amount
                              }
                              required={!myinfoData}
                              disabled={myinfoData}
                              placeholder="0.00"
                              onChange={e => {
                                setValue(`noa_history[${i}].amount`, e.target.value)
                              }}
                            />
                            <Spacer height={15} />

                            <FormInput
                              key={`${field.id}_employment`}
                              defaultValue={field.value}
                              label="employment"
                              showLabel
                              register={register}
                              id={`noa_history[${i}].employment`}
                              type="number"
                              error={
                                formErrors.noa_history &&
                                formErrors.noa_history[i] &&
                                formErrors.noa_history[i].employment
                              }
                              required={!myinfoData}
                              disabled={myinfoData}
                              placeholder="0.00"
                              onChange={e => {
                                setValue(`noa_history[${i}].employment`, e.target.value)
                              }}
                            />
                            <Spacer height={15} />

                            <FormInput
                              key={`${field.id}_interest`}
                              defaultValue={field.value}
                              label="interest"
                              showLabel
                              register={register}
                              id={`noa_history[${i}].interest`}
                              type="number"
                              error={
                                formErrors.noa_history &&
                                formErrors.noa_history[i] &&
                                formErrors.noa_history[i].interest
                              }
                              required={!myinfoData}
                              disabled={myinfoData}
                              placeholder="0.00"
                              onChange={e => {
                                setValue(`noa_history[${i}].interest`, e.target.value)
                              }}
                            />
                            <Spacer height={15} />

                            <Button
                              variant="secondary"
                              type="button"
                              onClick={() => removeNoaHistory(i)}
                              disabled={noaHistoryFields.length === 1}
                            >
                              Remove
                            </Button>
                          </Accordion>
                        ))}

                        {!myinfoData && (
                          <Button
                            type="button"
                            onClick={() =>
                              appendNoaHistory({
                                year_of_assessment: '',
                                amount: '',
                                employment: '',
                                interest: '',
                              })
                            }
                          >
                            ADD NEW YEAR
                          </Button>
                        )}
                      </>
                    )}
                    <Spacer height={20} />
                  </Drawer>

                  <Drawer
                    title="Other Information"
                    isOpen={activeDrawer === 'Other Information'}
                    handleClick={() => handleExpandDrawer('Other Information')}
                  >
                    <Controller
                      name="nationality"
                      control={control}
                      rules={{ required: !myinfoData }}
                      render={({ field }) => (
                        <FormSelect
                          {...field}
                          label="nationality"
                          showLabel
                          required={!myinfoData}
                          // isSearchable={false}
                          options={nationalityOptions}
                          variant={myinfoData && myinfoData.nationality ? 'autofill' : ''}
                          isDisabled={myinfoData && myinfoData.nationality}
                          onChange={e => field.onChange(e.value)}
                          value={nationalityOptions.find(c => c.value === field.value)}
                          error={formErrors.nationality}
                        />
                      )}
                    />
                    <Spacer height={15} />

                    <Controller
                      name="country_of_birth"
                      control={control}
                      rules={{ required: !myinfoData }}
                      render={({ field }) => (
                        <FormSelect
                          {...field}
                          label="country of birth"
                          showLabel
                          required={!myinfoData}
                          // isSearchable={false}
                          options={countryOfBirthOptions}
                          variant={myinfoData && myinfoData.country_of_birth ? 'autofill' : ''}
                          isDisabled={myinfoData && myinfoData.country_of_birth}
                          onChange={e => field.onChange(e.value)}
                          value={countryOfBirthOptions.find(c => c.value === field.value)}
                          error={formErrors.country_of_birth}
                        />
                      )}
                    />
                    <Spacer height={15} />

                    <Controller
                      name="date_of_birth"
                      control={control}
                      rules={{ required: !myinfoData }}
                      render={({ field }) => {
                        return (
                          <FormDatePicker
                            variant={myinfoData && myinfoData.date_of_birth ? 'autofill' : ''}
                            label="date of birth"
                            showLabel
                            required={!myinfoData}
                            readOnly={myinfoData && myinfoData.date_of_birth}
                            error={formErrors.date_of_birth}
                            placeholderText="Select date"
                            onChange={date =>
                              field.onChange(advancedDayjs(date).format('YYYY-MM-DD'))
                            }
                            selected={field.value && new Date(field.value)}
                          />
                        )
                      }}
                    />
                    <Spacer height={15} />

                    <Controller
                      name="residential_status"
                      control={control}
                      rules={{ required: !myinfoData }}
                      render={({ field }) => (
                        <FormSelect
                          {...field}
                          label="residential status"
                          showLabel
                          required={!myinfoData}
                          isSearchable={false}
                          options={residentialStatusOptions}
                          variant={myinfoData && myinfoData.residential_status ? 'autofill' : ''}
                          isDisabled={myinfoData && myinfoData.residential_status}
                          onChange={e => field.onChange(e.value)}
                          value={residentialStatusOptions.find(c => c.value === field.value)}
                          error={formErrors.residential_status}
                        />
                      )}
                    />
                    <Spacer height={15} />

                    <>
                      <Controller
                        name="type_of_hdb"
                        control={control}
                        rules={{ required: !myinfoData }}
                        render={({ field }) => (
                          <FormSelect
                            {...field}
                            label="HDB type"
                            showLabel
                            isSearchable={false}
                            options={typeOfHDBOptions}
                            required={!myinfoData}
                            variant={
                              myinfoData &&
                              myinfoData.type_of_hdb &&
                              myinfoData.type_of_hdb.length > 0
                                ? 'autofill'
                                : ''
                            }
                            isDisabled={myinfoData && myinfoData.type_of_hdb}
                            onChange={e => {
                              field.onChange(e.value)
                            }}
                            value={typeOfHDBOptions.find(c => c.value === field.value)}
                            error={formErrors.type_of_hdb}
                          />
                        )}
                      />
                      <Spacer height={15} />
                    </>

                    <H variant="h4">Residential Address</H>
                    <Spacer height={10} />
                    <Flex flex="1 2 auto">
                      <FormInput
                        variant={
                          myinfoData && myinfoData.registered_address_floor ? 'autofill' : ''
                        }
                        readOnly={myinfoData && myinfoData.registered_address_floor}
                        label="Floor"
                        showLabel
                        register={register}
                        id="registered_address_floor"
                        type="text"
                        placeholder="01"
                        error={formErrors.registered_address_floor}
                      />
                      <Spacer width={15} />

                      <FormInput
                        variant={myinfoData && myinfoData.registered_address_unit ? 'autofill' : ''}
                        readOnly={myinfoData && myinfoData.registered_address_unit}
                        label="Unit"
                        showLabel
                        register={register}
                        id="registered_address_unit"
                        type="text"
                        placeholder="23"
                        error={formErrors.registered_address_unit}
                        required={!myinfoData}
                      />
                    </Flex>
                    <Spacer height={15} />

                    <FormInput
                      variant={myinfoData && myinfoData.registered_address_block ? 'autofill' : ''}
                      readOnly={myinfoData && myinfoData.registered_address_block}
                      label="Block"
                      showLabel
                      register={register}
                      id="registered_address_block"
                      type="text"
                      placeholder="Blk 45"
                      error={formErrors.registered_address_block}
                    />
                    <Spacer height={15} />

                    <FormInput
                      variant={
                        myinfoData && myinfoData.registered_address_building ? 'autofill' : ''
                      }
                      readOnly={myinfoData && myinfoData.registered_address_building}
                      label="Building"
                      showLabel
                      register={register}
                      id="registered_address_building"
                      type="text"
                      placeholder="Building A"
                      error={formErrors.registered_address_building}
                    />
                    <Spacer height={15} />

                    <FormInput
                      variant={myinfoData && myinfoData.registered_address_street ? 'autofill' : ''}
                      readOnly={myinfoData && myinfoData.registered_address_street}
                      label="Street"
                      showLabel
                      register={register}
                      id="registered_address_street"
                      type="text"
                      placeholder="Street B"
                      error={formErrors.registered_address_street}
                      required={!myinfoData}
                    />
                    <Spacer height={15} />

                    <FormInput
                      variant={myinfoData && myinfoData.registered_address_postal ? 'autofill' : ''}
                      readOnly={myinfoData && myinfoData.registered_address_postal}
                      label="Postal"
                      showLabel
                      register={register}
                      id="registered_address_postal"
                      type="text"
                      error={formErrors.registered_address_postal}
                      required={!myinfoData}
                      placeholder="678910"
                      validationRules={{
                        pattern: {
                          value: SG_POSTAL_REGEX,
                          message: 'Please provide a valid postal code',
                        },
                      }}
                    />
                    <Spacer height={15} />

                    <FormInput
                      label="employment sector"
                      placeholder="Information Technology"
                      showLabel
                      register={register}
                      id="employment_sector"
                      type="text"
                      error={formErrors.employment_sector}
                    />
                    <Spacer height={15} />

                    <Controller
                      name="highest_education_level"
                      control={control}
                      render={({ field }) => (
                        <FormSelect
                          {...field}
                          label="highest education level"
                          showLabel
                          isSearchable={false}
                          options={highestEducationLevelOptions}
                          variant={
                            myinfoData && myinfoData.highest_education_level ? 'autofill' : ''
                          }
                          onChange={e => field.onChange(e.value)}
                          value={highestEducationLevelOptions.find(c => c.value === field.value)}
                          error={formErrors.highest_education_level}
                        />
                      )}
                    />
                    <Spacer height={15} />

                    <Controller
                      name="marital_status"
                      control={control}
                      render={({ field }) => (
                        <FormSelect
                          {...field}
                          label="marital status"
                          showLabel
                          isSearchable={false}
                          options={maritalStatusOptions}
                          variant={myinfoData && myinfoData.marital_status ? 'autofill' : ''}
                          onChange={e => field.onChange(e.value)}
                          value={maritalStatusOptions.find(c => c.value === field.value)}
                          error={formErrors.marital_status}
                        />
                      )}
                    />
                  </Drawer>
                </Flex>
              </Flex>
            </section>
          )}

          {currentStep === 2 && (
            <section key={currentStep}>
              <Flex pt={10} pb={50} px={2} flexDirection="column">
                <H variant="h3">{VERIFY_IDENTITY_PAGES[currentStep]}</H>
                <Flex pt={2} pb={110} width="100%" flexDirection="column" overflowY="scroll">
                  <>
                    {showHousingType && (
                      <>
                        <Controller
                          name="type_of_housing"
                          control={control}
                          render={({ field }) => (
                            <FormSelect
                              {...field}
                              label="housing type"
                              showLabel
                              isSearchable={false}
                              options={typeOfHousingOptions}
                              variant={myinfoData && myinfoData.type_of_housing ? 'autofill' : ''}
                              isDisabled={myinfoData && myinfoData.type_of_housing}
                              onChange={e => field.onChange(e.value)}
                              value={typeOfHousingOptions.find(c => c.value === field.value)}
                              error={formErrors.type_of_housing}
                            />
                          )}
                        />
                        <Spacer height={15} />
                      </>
                    )}

                    <P variant="small" style={{ fontWeight: 600 }}>
                      Residential Address
                    </P>
                    <StyledTextArea
                      readOnly
                      rows="3"
                      style={{
                        background: '#F0F3F5',
                        fontSize: 14,
                        fontWeight: 600,
                      }}
                      value={concatenatedAddress}
                    />
                    <Spacer height={15} />

                    <Controller
                      name="type"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <FormSelect
                          {...field}
                          label="home ownership status"
                          showLabel
                          required
                          isSearchable={false}
                          options={homeOwnershipOptions}
                          onChange={e => field.onChange(e.value)}
                          value={homeOwnershipOptions.find(c => c.value === field.value)}
                          style={{
                            width: 'calc(100vw - 48px)',
                          }}
                          error={formErrors.type}
                        />
                      )}
                    />

                    {showHomeOwnershipOthers && (
                      <>
                        <Spacer height={15} />
                        <FormInput
                          label="others"
                          showLabel
                          register={register}
                          id="others"
                          type="text"
                          error={formErrors.others}
                          required={getValues().type === 'OTHERS'}
                        />
                      </>
                    )}
                  </>
                  <Spacer height={20} />
                </Flex>
              </Flex>
            </section>
          )}

          {currentStep === 3 && (
            <section key={currentStep}>
              <Flex pt={10} pb={50} px={2} flexDirection="column">
                <H variant="h3">{VERIFY_IDENTITY_PAGES[currentStep]}</H>
                <Flex pt={2} pb={110} width="100%" flexDirection="column" overflowY="scroll">
                  <FormInput
                    variant={myinfoData && myinfoData.mobile_number ? 'autofill' : ''}
                    readOnly
                    label="mobile number"
                    showLabel
                    register={register}
                    id="mobile_number"
                    type="text"
                    error={formErrors.mobile_number}
                    required={!myinfoData}
                    validationRules={{
                      pattern: {
                        value: SG_MOBILE_REGEX,
                        message: 'Please provide a valid mobile number',
                      },
                    }}
                  />
                  <Spacer height={15} />

                  <FormInput
                    // variant={myinfoData && myinfoData.email ? "autofill" : ""}
                    label="email address"
                    showLabel
                    register={register}
                    id="email"
                    type="text"
                    error={formErrors.email}
                    required
                    placeholder="example@email.com"
                    validationRules={{
                      pattern: {
                        value: EMAIL_REGEX,
                        message: 'Please enter a valid email address',
                      },
                    }}
                  />
                  <Spacer height={15} />
                </Flex>
              </Flex>
            </section>
          )}

          <Footer withTopBorder>
            <>
              <Button
                variant="secondary"
                onClick={onClickPrevious}
                type="button"
                disabled={sgPersonalIsLoading || creditsIsLoading}
              >
                PREVIOUS
              </Button>
              <Spacer width={50} />
            </>

            <Button
              onClick={onClickNext}
              type="button"
              disabled={myinfoData ? currentStep > 1 && !formIsValid : !formIsValid}
              isLoading={sgPersonalIsLoading || creditsIsLoading}
            >
              {currentStep === VERIFY_IDENTITY_MAX_PAGES ? 'FINISH' : 'NEXT'}
            </Button>
          </Footer>
        </AnimatePresence>
      </form>
    </PageWrapper>
  )
}

VerifyIdentityForm.propTypes = {
  myinfoData: PropTypes.object,
}

export default VerifyIdentityForm
