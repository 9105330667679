export const secureCardNumber = cardNo => {
  if (!cardNo || isNaN(parseInt(cardNo))) return cardNo
  if (cardNo && cardNo.type !== 'string') cardNo = cardNo.toString()

  const cleanedCardNo = cardNo.replace(/ /g, '').replace(/-/g, '')
  if (cleanedCardNo.length === 4) {
    return '**** **** **** ' + cleanedCardNo
  }

  if (cleanedCardNo.length !== 16) {
    return cardNo
  }

  return '**** **** **** ' + cleanedCardNo.slice(12, 16)
}
