import React from 'react'

import { PageWrapper, Button, Image, Paragraph as P, Flex } from 'components'
import { abnkLogo } from 'assets'

const ContinueOrderInAppOrBrowser = ({ handleContinueInApp, handleContinueInBrowser }) => {
  return (
    <PageWrapper withTopNav>
      <Flex flexDirection="column" justifyContent="center" alignItems="center" pt="20vh" stretch>
        <Image src={abnkLogo} alt="Abnk logo" height="30px" style={{ marginBottom: '30px' }} />
        <P variant="normal" textAlign="center">
          Continue with Abnk for flexible payments
        </P>

        <Button onClick={handleContinueInApp} mt="72px">
          Continue With Abnk App
        </Button>
        <Button variant="secondary" onClick={handleContinueInBrowser} mt="0">
          Continue In Browser
        </Button>
      </Flex>
    </PageWrapper>
  )
}

export default ContinueOrderInAppOrBrowser
