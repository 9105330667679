import React, { useEffect } from 'react'

import { Loading, TriggerPopup } from 'components'
import { useBuyerStore } from 'stores'
import {
  getTokenLocalDb,
  setAssumeAccountSetupStatus,
  setCountryCodeLocalDb,
  setMobileNumberLocalDb,
  setMobilePrefixLocalDb,
  removeTokenLocalDb,
} from 'utils'
import { GENERIC_ERR_MESSAGE } from 'const'

const AssumeAccount = () => {
  const {
    response: { getOrCreate: getOrCreateResponse },
    errors: { getOrCreate: getOrCreateError },
    resetStates: resetStatesBuyer,
    submitGetOrCreate,
  } = useBuyerStore()

  // *Methods
  const handleSetCountryCodes = prefix => {
    let countryCode = ''
    if (prefix === '+65') countryCode = 'sg'
    if (prefix === '+60') countryCode = 'my'

    setCountryCodeLocalDb(countryCode)
    setAssumeAccountSetupStatus('complete')
    window.location.href = `/${countryCode}/home`
  }

  // *Effects
  useEffect(() => {
    if (getTokenLocalDb()) {
      submitGetOrCreate()
    }
  }, [])

  useEffect(() => {
    if (getOrCreateResponse) {
      const { full_mobile_number } = getOrCreateResponse
      const [prefix, mobile] = full_mobile_number.split(' ')

      setMobilePrefixLocalDb(prefix.slice(1))
      setMobileNumberLocalDb(mobile)

      handleSetCountryCodes(prefix)
    }
  }, [getOrCreateResponse])

  // *JSX
  if (getOrCreateError) {
    return (
      <TriggerPopup
        message={(getOrCreateError && getOrCreateError.message) || GENERIC_ERR_MESSAGE}
        showPopup={!!getOrCreateError}
        buttonText="Return"
        callback={() => {
          resetStatesBuyer('getOrCreate')
          removeTokenLocalDb()
        }}
        nextDestination="/register"
        replaceHistory
      />
    )
  }

  return <Loading variant="fullscreen" />
}

export default AssumeAccount
