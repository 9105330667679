import React, { useEffect, useState } from 'react'

import {
  Paragraph as P,
  Spacer,
  PaymentMethodDropdown,
  PaymentMethodDropdownLoading,
} from 'components'
import { useBuyerStore } from 'stores'
import { getCountryCodeLocalDb } from 'utils'

const PayRemainingPaymentMethod = ({
  collectionPaymentMethod,
  tutorialStep,
  setSelectedPaymentMethod,
  setShouldDisablePayButton,
}) => {
  const countryCode = getCountryCodeLocalDb()

  const [selectedPaymentMethodError, setSelectedPaymentMethodError] = useState('')

  const {
    response: { paymentMethodFetch: paymentMethodFetchResponse },
    resetStates: resetStatesBuyer,
    fetchPaymentMethod,
  } = useBuyerStore()

  // *Effects
  useEffect(() => {
    fetchPaymentMethod(countryCode)

    return () => resetStatesBuyer('paymentMethodFetch')
  }, [])

  useEffect(() => {
    if (collectionPaymentMethod) {
      setSelectedPaymentMethod(collectionPaymentMethod)
    }
  }, [collectionPaymentMethod])

  useEffect(() => {
    if (selectedPaymentMethodError) {
      setShouldDisablePayButton(true)
    } else {
      setShouldDisablePayButton(false)
    }
  }, [selectedPaymentMethodError])

  // *JSX
  return (
    <>
      <P textAlign="left" style={{ fontWeight: 700 }}>
        Payment method
      </P>
      <Spacer height={10} />
      {tutorialStep === 0 && collectionPaymentMethod && paymentMethodFetchResponse ? (
        <PaymentMethodDropdown
          paymentMethods={paymentMethodFetchResponse}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          selectedPaymentMethodError={selectedPaymentMethodError}
          setSelectedPaymentMethodError={setSelectedPaymentMethodError}
          presetActivePaymentMethod={collectionPaymentMethod ? collectionPaymentMethod : null}
          saveCurrentUrl
          allowInvalidPaymentMethod
        />
      ) : (
        <PaymentMethodDropdownLoading />
      )}
    </>
  )
}

export default PayRemainingPaymentMethod
