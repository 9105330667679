import React, { useEffect, useRef, useState } from 'react'
import QRCode from 'react-qr-code'

import { useBuyerStore } from 'stores'
import { Paragraph as P, Flex, Image } from 'components'
import { appQrCode, abnkLogo } from 'assets'
import { captureIncomingQrCode, getDesktopQrCodeLink } from 'utils'

const POLL_CHECKOUT_REDIRECT_INTERVAL = 1000

const DesktopRedirect = () => {
  const pollCheckoutRedirectRef = useRef()
  const [qrCodeLink, setQrCodeLink] = useState('')

  const { acquireRedirectUrl } = useBuyerStore()

  const handlePollCheckoutRedirect = async checkoutIdParam => {
    pollCheckoutRedirectRef.current = setInterval(async () => {
      const res = await acquireRedirectUrl(checkoutIdParam)

      const redirectUrl = res?.data?.redirect_url
      const isRedirected = res?.data?.is_redirected

      if (redirectUrl) {
        clearInterval(pollCheckoutRedirectRef.current)
        window.location.href = redirectUrl
        return
      }

      if (typeof res === 'string' || isRedirected) {
        clearInterval(pollCheckoutRedirectRef.current)
      }
    }, POLL_CHECKOUT_REDIRECT_INTERVAL)
  }

  // *Effects
  useEffect(() => {
    // capture the order QR code params as they are not captured in the Order component if this component is reached
    const { checkoutId: checkoutIdParam } = captureIncomingQrCode()

    if (checkoutIdParam) handlePollCheckoutRedirect(checkoutIdParam)

    const desktopQrCodeLink = getDesktopQrCodeLink()
    if (
      desktopQrCodeLink &&
      !desktopQrCodeLink.includes('/my/register/') &&
      !desktopQrCodeLink.includes('/sg/register/')
    ) {
      setQrCodeLink(desktopQrCodeLink)
    }

    return () => {
      clearInterval(pollCheckoutRedirectRef.current)
    }
  }, [])

  // *JSX
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100vw"
      height="100vh"
      p={20}
    >
      <Image src={abnkLogo} alt="logo" height="30px" />

      <P
        variant="normal"
        textAlign="center"
        style={{ fontSize: '20px', maxWidth: '660px', margin: '30px 0' }}
      >
        Continue with Abnk by scanning the QR code below, and enjoy flexible payments
      </P>

      {qrCodeLink && qrCodeLink.length > 0 ? (
        <QRCode value={qrCodeLink} />
      ) : (
        <Image src={appQrCode} alt="qr_code" height="180px" width="180px" />
      )}
    </Flex>
  )
}

export default DesktopRedirect
