import React from 'react'
import PropTypes from 'prop-types'

import { Flex, Paragraph as P, Spacer } from 'components'
import { theme } from 'styles'
import { signOut, getMobilePrefixLocalDb, getMobileNumberLocalDb } from 'utils'

const AssumeAccountHeader = ({ show }) => {
  if (!show) return null

  // *Methods
  const handleStopAssuming = () => {
    alert('You will be logged out.')
    signOut()
  }

  // *JSX
  return (
    <Flex
      style={{
        width: '100%',
        position: 'fixed',
        height: 22,
        top: 0,
        backgroundColor: theme.colors.actions.failureRed,
        zIndex: 100,
      }}
      centered
    >
      <P color="white" variant="subcopy">
        {`Assuming account +${getMobilePrefixLocalDb() || ''} ${getMobileNumberLocalDb() || ''}`}
      </P>

      <Spacer width={8} />

      <Flex onClick={handleStopAssuming}>
        <P
          variant="subcopy"
          style={{
            color: theme.colors.actions.failureRed,
            border: '1px solid white',
            borderRadius: 8,
            paddingLeft: 5,
            paddingRight: 5,
            margin: 5,
            backgroundColor: 'white',
          }}
        >
          Stop Assuming
        </P>
      </Flex>
    </Flex>
  )
}

AssumeAccountHeader.propTypes = {
  show: PropTypes.bool.isRequired,
}

AssumeAccountHeader.defaultProps = {
  show: false,
}

export default AssumeAccountHeader
