import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useBuyerStore } from 'stores'
import { AnimatePresence } from 'framer-motion'
import {
  Button,
  Flex,
  Footer,
  Image,
  NavigateBack,
  PageWrapper,
  Paragraph as P,
  ProgressBar,
  Spacer,
  TriggerPopup,
} from 'components'
import { theme } from 'styles/theme'
import { addBankTransfer } from 'assets'
import { GENERIC_ERR_MESSAGE } from 'const'
import { capitalizeFirstLetter, logUserInteractionGA } from 'utils'

import EDDASkeleton from './EDDASkeleton'
import EDDARequest from './EDDARequest'

const EDDAForm = ({ backButtonText, buttonText, path, editableFields, handleSubmit }) => {
  const {
    formState: { isValid },
  } = useFormContext()
  const current = 1

  const [isDelay, setIsDelay] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [setupError, setSetupError] = useState('')

  const { fetchDBSeGIROMeta, resetStates } = useBuyerStore()

  const handleClearError = () => {
    resetStates('paymentMethodSubmit')
    setSetupError('')
    setIsLoading(false)
  }
  const handlePress = async () => {
    logUserInteractionGA(`${path.toUpperCase()}: eGIRO`)
    setIsLoading(true)

    const res = await handleSubmit()
    if (!res) return

    if (res.success) {
      window.location.replace(res.data.dbs_egiro.authorize_creation_url)
    } else {
      const errorArr = Object.entries(res.errors?.dbs_egiro?.authorize_creation_url || {})[0]
      if (!errorArr) {
        setSetupError(GENERIC_ERR_MESSAGE)
        return
      }
      const [field, value] = errorArr
      const key = capitalizeFirstLetter(field.split('_').join(' '))
      setSetupError(`${key}: ${value}`)
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsDelay(false)
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [setIsDelay])

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchDBSeGIROMeta()
    }, 100)
    return () => {
      clearTimeout(timeout)
    }
  }, [fetchDBSeGIROMeta])

  const formDisabled = isDelay || !isValid

  return (
    <AnimatePresence>
      {/* Error handling */}
      <TriggerPopup
        message={setupError}
        showPopup={setupError.length > 0}
        callback={handleClearError}
        stayAtCurrentPage
        buttonText="OKAY"
        key="error_popup"
      />

      <PageWrapper key="page_wrapper" withTopNav>
        <NavigateBack
          progressBar={
            <ProgressBar
              variant="narrow"
              barColor={theme.colors.buttons.marineBlue}
              current={current}
              total={1}
            />
          }
        >
          {backButtonText}
        </NavigateBack>

        <Flex mt={2}>
          <Image src={addBankTransfer} alt="bank_transfer_icon" width={24} />
          <P ml={2} style={{ fontWeight: 600 }}>
            eGIRO
          </P>
        </Flex>
        <Spacer height={25} />

        <form style={{ width: '100%' }}>
          <fieldset className="FormGroup" style={{ border: 'none' }}>
            {isDelay ? <EDDASkeleton /> : <EDDARequest editableFields={editableFields} />}
          </fieldset>
        </form>

        <Footer withTopBorder>
          <Button
            type="button"
            variant="primary"
            onClick={handlePress}
            disabled={formDisabled}
            isLoading={isLoading}
          >
            {buttonText}
          </Button>
        </Footer>
      </PageWrapper>
    </AnimatePresence>
  )
}

export default EDDAForm
