import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router'

import {
  PaymentMethodDropdown,
  PaymentMethodDropdownLoading,
  Heading as H,
  Spacer,
  PaymentSchedule,
  Flex,
  Footer,
  Button,
} from 'components'
import { useOrderStore } from 'stores'
import { logUserInteractionGA } from 'utils'

const TransactionPayment = ({ data }) => {
  const history = useHistory()
  const location = useLocation().search
  const orderCode = new URLSearchParams(location).get('code')
  const useNewPaymentMethod = new URLSearchParams(location).get('use_new_payment_method')

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null)
  const [selectedPaymentMethodWarning, setSelectedPaymentMethodWarning] = useState('')
  const [selectedPaymentMethodError, setSelectedPaymentMethodError] = useState('')

  // *Stores
  const {
    isLoading: { updatePaymentMethod: updatePaymentMethodIsLoading },
    response: { updatePaymentMethod: updatePaymentMethodResponse },
    resetStates: resetStatesOrder,
    updatePaymentMethod,
    fetchOrder,
  } = useOrderStore()

  // *Methods
  const handlePayRemaining = () => {
    logUserInteractionGA('Click: Pay Remaining Balance')
    history.push(`/transaction/payremaining?code=${orderCode}`)
  }

  // *Effects
  useEffect(() => {
    return () => {
      resetStatesOrder('updatePaymentMethod')
    }
  }, [])

  useEffect(() => {
    if (data && data.collection_payment_method) {
      setSelectedPaymentMethod(data.collection_payment_method)
    }
  }, [data])

  useEffect(() => {
    if (selectedPaymentMethod && selectedPaymentMethod.collection_payment_method_error_message) {
      return
    }

    if (
      selectedPaymentMethod &&
      data &&
      data.code &&
      data.collection_payment_method &&
      data.collection_payment_method.id
    ) {
      const orderCode = data.code
      const presetPaymentMethodId = data.collection_payment_method.id
      const paymentMethodId = selectedPaymentMethod.id

      if (presetPaymentMethodId !== paymentMethodId) updatePaymentMethod(orderCode, paymentMethodId)
    }
  }, [selectedPaymentMethod])

  useEffect(() => {
    if (updatePaymentMethodResponse && updatePaymentMethodResponse.success) {
      fetchOrder(orderCode)
      resetStatesOrder('updatePaymentMethod')
    }
  }, [updatePaymentMethodResponse])

  // *JSX
  return (
    <>
      <H variant="h4" textAlign="left">
        Payment method
      </H>
      <Spacer height={10} />
      {data && data.collection_payment_methods ? (
        <PaymentMethodDropdown
          paymentMethods={data.collection_payment_methods}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          selectedPaymentMethodWarning={selectedPaymentMethodWarning}
          setSelectedPaymentMethodWarning={setSelectedPaymentMethodWarning}
          selectedPaymentMethodError={selectedPaymentMethodError}
          setSelectedPaymentMethodError={setSelectedPaymentMethodError}
          presetActivePaymentMethod={(data && data.collection_payment_method) || null}
          saveCurrentUrl
          useNewPaymentMethod={useNewPaymentMethod}
          stayAtCurrentPageAfterClearingError
        />
      ) : (
        <PaymentMethodDropdownLoading />
      )}
      <Spacer height={20} />
      <H variant="h4" textAlign="left">
        Payment plan
      </H>
      <Spacer height={10} />
      <Flex stretch centered px={2}>
        <PaymentSchedule
          repayments={data && data.repayments}
          orderCode={orderCode}
          isLoading={updatePaymentMethodIsLoading}
          selectedPaymentMethod={selectedPaymentMethod}
        />
      </Flex>
      {data &&
        data.state === 'approved' &&
        data.early_repayment_plans &&
        data.early_repayment_plans.length > 0 && (
          <>
            <Spacer height={50} />
            <Footer style={{ background: 'white', zIndex: 2 }}>
              <Button onClick={handlePayRemaining}>PAY REMAINING BALANCE</Button>
            </Footer>
          </>
        )}
    </>
  )
}

export default TransactionPayment
