import { getCountryCodeLocalDb, findCountry } from 'utils'

export const fetchCurrencySymbol = () => {
  const countryCode = getCountryCodeLocalDb()
  const country = findCountry(countryCode)

  return country.currency
}

function convertToCurrency(num) {
  if (typeof num === 'string') {
    num = parseFloat(num)
  }

  return num.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

export function formatAsCurrency(num) {
  if (isNaN(parseInt(num))) return num

  const countryCode = getCountryCodeLocalDb()
  const currencySymbol = fetchCurrencySymbol()
  const amount = convertToCurrency(num)

  if (countryCode === 'sg') {
    return `${currencySymbol}${amount}`
  }

  if (countryCode === 'my') {
    return `${currencySymbol} ${amount}`
  }

  return num
}
