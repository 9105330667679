import styled from 'styled-components'

export const IndicatorContainer = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const InactiveIndicator = styled.li`
  opacity: 0.5;
  background: ${props => (props.variant === 'white' ? '#FFF' : '#888888')};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  margin: 0 3px;
`

export const ActiveIndicator = styled.li`
  background: ${props => (props.variant === 'white' ? '#FFF' : '#3aa1ef')};
  width: 30px;
  height: 8px;
  border-radius: 50%;
  border-radius: 4.5px;
  display: inline-block;
  margin: 0 3px;
`
