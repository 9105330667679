import React from 'react'
import PropTypes from 'prop-types'
import { AnimatePresence } from 'framer-motion'

import { RoundedContainer, Flex, Spacer, Image, Heading as H } from 'components'
import { caretDownBlack } from 'assets'

const Drawer = ({ isOpen, title, handleClick, children, ...rest }) => {
  return (
    <AnimatePresence>
      <RoundedContainer width="100%" mt={0} p={0} boxShadow="none" key={title} {...rest}>
        <Flex justifyContent="space-between" onClick={handleClick}>
          <H variant="h3" style={{ fontWeight: 700 }}>
            {title}
          </H>
          <Spacer width={15} />
          <Image
            src={caretDownBlack}
            alt="expand_accordion"
            initial={{
              rotate: 0,
              marginBottom: 5,
            }}
            animate={{
              rotate: isOpen ? 180 : 0,
              marginBottom: isOpen ? 0 : 5,
            }}
          />
        </Flex>
        <Spacer height={20} />

        <Flex
          flexDirection="column"
          width="100%"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ type: 'tween' }}
          style={{ display: isOpen ? '' : 'none' }}
        >
          {children}
        </Flex>
      </RoundedContainer>
    </AnimatePresence>
  )
}

Drawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
}

export default Drawer
