import React from 'react'
import PropTypes from 'prop-types'
import { AnimatePresence } from 'framer-motion'
import { Palette } from 'color-thief-react'
import Skeleton from 'react-loading-skeleton'
import tinycolor from 'tinycolor2'

import { Flex, Image } from 'components'

import {
  StyledCircleBackground,
  StyledCircleProgressLong,
  StyledCircleProgressShort,
} from './circularProgressStyle'
import { transparent } from 'assets'

const CircularProgress = ({
  sqSize,
  strokeWidth,
  percentageLong,
  percentageShort,
  percentageTotal,
  invisiblePercentageLong,
  logo,
  logoSize,
  invert,
}) => {
  if (typeof percentageLong === 'string') parseInt(percentageLong)
  if (typeof percentageShort === 'string') parseInt(percentageShort)
  if (typeof percentageTotal === 'string') parseInt(percentageTotal)

  // convert both percentages to relative of 100
  if (invert) {
    percentageShort = ((percentageLong - percentageShort) / percentageTotal) * 100
  } else {
    percentageShort = (percentageShort / percentageTotal) * 100
  }

  percentageLong = (percentageLong / percentageTotal) * 100
  percentageTotal = 100

  // SVG centers the stroke width on the radius, subtract out so circle fits in square
  const radius = (sqSize - strokeWidth) / 2
  // Enclose cicle in a circumscribing square
  const viewBox = `0 0 ${sqSize} ${sqSize}`
  // Arc length at 100% coverage is the circle circumference
  const dashArray = radius * Math.PI * 2
  // Scale 100% coverage overlay with the actual percent
  const dashOffsetLong = dashArray - (dashArray * percentageLong) / 100
  const dashOffsetShort = dashArray - (dashArray * percentageShort) / 100

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      my={2}
      style={{ height: parseInt(sqSize), width: parseInt(sqSize) }}
    >
      <Palette src={logo} crossOrigin="anonymous" format="hex" colorCount={4}>
        {({ data, loading }) => {
          if (loading) return <Skeleton height={sqSize} width={sqSize} circle />

          // fallback color - abnkBlue
          if (!data || data.length === 0) {
            data = ['#1980CE']
          }

          return (
            <AnimatePresence>
              <svg width={sqSize} height={sqSize} viewBox={viewBox}>
                <StyledCircleBackground
                  cx={sqSize / 2}
                  cy={sqSize / 2}
                  r={radius}
                  strokeWidth={`${strokeWidth}px`}
                />
                <StyledCircleProgressLong
                  cx={sqSize / 2}
                  cy={sqSize / 2}
                  r={radius}
                  stroke={
                    !invisiblePercentageLong ? tinycolor(data[0]).saturate(50).toString() : ''
                  }
                  strokeWidth={`${strokeWidth}px`}
                  // Start progress marker at 12 O'Clock
                  transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
                  transition={{ duration: 1, delay: 0.5 }}
                  initial={{
                    strokeDasharray: dashArray,
                    strokeDashoffset: dashArray,
                  }}
                  animate={{
                    strokeDasharray: dashArray,
                    strokeDashoffset: dashOffsetLong,
                  }}
                />
                <StyledCircleProgressShort
                  cx={sqSize / 2}
                  cy={sqSize / 2}
                  r={radius}
                  // stroke={data[0]}
                  stroke={tinycolor(data[0]).saturate(100).toString()}
                  strokeWidth={`${strokeWidth}px`}
                  transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
                  transition={{ duration: 1.5, delay: 0.5 }}
                  initial={{
                    strokeDasharray: dashArray,
                    strokeDashoffset: dashArray,
                  }}
                  animate={{
                    strokeDasharray: dashArray,
                    strokeDashoffset: dashOffsetShort,
                  }}
                />
              </svg>
            </AnimatePresence>
          )
        }}
      </Palette>

      <Image
        src={logo || transparent}
        alt="logo"
        height={logoSize ? logoSize : 30}
        width={logoSize ? logoSize : 30}
        style={{ position: 'absolute', width: logoSize ? logoSize : 30 }}
        initial={{ opacity: 0 }}
        transition={{ delay: 0.2, duration: 0.5 }}
        animate={{ opacity: 1 }}
      />
    </Flex>
  )
}

CircularProgress.propTypes = {
  sqSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  strokeWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  percentageLong: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  percentageShort: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  percentageTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  invisiblePercentageLong: PropTypes.bool,
  logo: PropTypes.node.isRequired,
  logoSize: PropTypes.number,
  invert: PropTypes.bool,
}

CircularProgress.defaultProps = {
  invisiblePercentageLong: false,
  logoSize: 30,
  invert: false,
}

export default CircularProgress
