import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { blackArrow } from 'assets'
import { theme } from 'styles'
import { Flex, Paragraph as P, Image, Modal, NavigateBack } from 'components'
import {
  getAssumeAccount,
  getBackToMerchantLink,
  removeBackToMerchantLink,
  removeDraftOrderLocalDb,
} from 'utils'
import { useScreenSize } from 'hooks'

const BackToMerchantHeader = ({ show }) => {
  const currentScreenSize = useScreenSize().screenSize()
  const backToMerchantLink = getBackToMerchantLink()

  const [showPopup, setShowPopup] = useState(false)

  // *Methods
  const handleClickBackToMerchant = () => {
    setShowPopup(true)
  }

  const handleConfirmBackToMerchant = () => {
    window.location.assign(backToMerchantLink)
    removeBackToMerchantLink()
    removeDraftOrderLocalDb()
  }

  const handleStayAtCurrentPage = () => {
    setShowPopup(false)
  }

  // *JSX
  if (!show) return null

  if (currentScreenSize.isDesktop) {
    return (
      <>
        <NavigateBack withBottomBorder onClick={handleClickBackToMerchant}>
          Back to merchant
        </NavigateBack>

        {showPopup && (
          <Modal
            messageArray={["Are you sure you want to return to merchant's page?"]}
            primaryButtonText="YES"
            primaryButtonAction={handleConfirmBackToMerchant}
            secondaryButtonText="NO"
            secondaryButtonAction={handleStayAtCurrentPage}
            style={{ maxWidth: 500 }}
          />
        )}
      </>
    )
  }

  return (
    <Flex
      style={{
        width: '100%',
        position: 'fixed',
        height: 25,
        top: getAssumeAccount() ? 25 : 0,
        backgroundColor: theme.colors.actions.lightBlue,
        zIndex: 100,
      }}
      centered
    >
      {showPopup && (
        <Modal
          messageArray={["Are you sure you want to return to merchant's page?"]}
          primaryButtonText="YES"
          primaryButtonAction={handleConfirmBackToMerchant}
          secondaryButtonText="NO"
          secondaryButtonAction={handleStayAtCurrentPage}
        />
      )}

      <Flex position="absolute" left="27px" onClick={handleClickBackToMerchant}>
        <Image src={blackArrow} alt="back-arrow" width={12} height={12} />
      </Flex>

      <P
        variant="subcopy"
        style={{
          fontWeight: 400,
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          maxWidth: '60vw',
        }}
      >
        Back to merchant
      </P>
    </Flex>
  )
}

BackToMerchantHeader.propTypes = {
  show: PropTypes.bool.isRequired,
}

export default BackToMerchantHeader
