import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { AnimatePresence } from 'framer-motion'

import { StyledOverlay, StyledTutorialContainer } from './tutorialWrapperStyle'
import TutorialBubble from './TutorialBubble/TutorialBubble'

// highlighted element will be above overlay
// tutorial bubble will be positioned below anchored element

const TutorialWrapper = ({
  children,
  tutorial,
  currentStep,
  showTutorial,
  shouldCallInteractionApi,
  setShouldCallInteractionApi,
  handleClick,
  ...rest
}) => {
  const {
    content,
    anchor_id: anchorId,
    highlight_id: highlightId,
    button_text: buttonText,
    action,
  } = tutorial[currentStep]

  // *Effects
  useEffect(() => {
    if (showTutorial) {
      // updating style after render
      window.requestAnimationFrame(() => {
        const element = document.getElementById(highlightId)
        if (element) {
          element.style.background = 'white'
          element.style.position = 'relative'
          element.style.zIndex = 20
          element.style.transform = 'translate3d(0,0,0)'
          element.style['-webkit-transform'] = 'translate3d(0,0,0)'
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        }
      })
    }
  }, [showTutorial, highlightId, anchorId])

  // *JSX
  if (!showTutorial) return <>{children}</>

  return (
    <AnimatePresence>
      <div key={anchorId} {...rest}>
        <StyledOverlay id="styled-overlay" />
        <StyledTutorialContainer>{children}</StyledTutorialContainer>
        <TutorialBubble
          totalSteps={tutorial.length - 2}
          currentStep={currentStep - 1}
          handleClick={handleClick}
          anchorId={anchorId}
          content={content}
          buttonText={buttonText}
          action={action}
          shouldCallInteractionApi={shouldCallInteractionApi}
          setShouldCallInteractionApi={setShouldCallInteractionApi}
          {...rest}
        />
      </div>
    </AnimatePresence>
  )
}

TutorialWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  tutorial: PropTypes.array.isRequired,
  showTutorial: PropTypes.bool,
  shouldCallInteractionApi: PropTypes.bool,
  setShouldCallInteractionApi: PropTypes.func,
  handleClick: PropTypes.func,
}

TutorialWrapper.defaultProps = {
  showTutorial: false,
  handleClick: () => {},
  shouldCallInteractionApi: false,
  setShouldCallInteractionApi: () => {},
}

export default TutorialWrapper
