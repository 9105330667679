import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'

import { Flex, Paragraph as P, Heading as H, Button, Spacer, Span, Footer } from 'components'
import { theme } from 'styles'
import {
  removeShouldCheckDraftAtCreditScreen,
  removeShouldReturnToOrder,
  formatAsCurrency,
  getDraftOrderLocalDb,
  logUserInteractionGA,
} from 'utils'
import { useBuyerStore } from 'stores'

const ReturnToPurchase = ({ store, amount, setShowOverlay }) => {
  const history = useHistory()
  const order = getDraftOrderLocalDb()

  const { resetStates: resetStatesBuyer } = useBuyerStore()

  // *Methods
  const handleReturnToPurchase = () => {
    logUserInteractionGA('Click: Return to Purchase')
    resetStatesBuyer('credits')
    removeShouldCheckDraftAtCreditScreen()
    removeShouldReturnToOrder()
    if (order && order.code) {
      history.push(`/order/pay?code=${order.code}`)
    } else {
      history.push('/order/pay')
    }
  }

  const handleStayHere = () => {
    logUserInteractionGA('Click: Stay Here')
    removeShouldCheckDraftAtCreditScreen()
    removeShouldReturnToOrder()
    setShowOverlay(false)
  }

  // *JSX
  return (
    <Flex flexDirection="column" centered pb={160}>
      <Spacer height={5} />

      <H variant="h4">Your purchase is ready!</H>
      <Spacer height={15} />

      <P textAlign="center" color={theme.colors.typography.gray1}>
        You have sufficient credit to complete your purchase with{' '}
        <Span style={{ fontWeight: 700 }}>{store.name}</Span> for{' '}
        <Span style={{ fontWeight: 700 }}>{formatAsCurrency(amount)}</Span>
      </P>

      <Footer>
        <Flex flexDirection="column" fitWidth centered>
          <Button mb={0} onClick={handleReturnToPurchase}>
            RETURN TO PURCHASE
          </Button>
          <Button variant="secondary" onClick={handleStayHere}>
            STAY HERE
          </Button>
        </Flex>
      </Footer>
    </Flex>
  )
}

ReturnToPurchase.propTypes = {
  amount: PropTypes.string.isRequired,
  setShowOverlay: PropTypes.func.isRequired,
}

export default ReturnToPurchase
