import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { Flex, RoundedContainer, Spacer } from 'components'

const NotificationLoading = () => {
  return (
    <>
      <Spacer height={10} />
      <RoundedContainer flexDirection="row" fitWidth>
        <Skeleton height={42} width={42} circle />
        <Spacer width={10} />

        <Flex flexDirection="column">
          <Skeleton height={20} width={150} />
          <Skeleton height={18} width={80} />
        </Flex>
      </RoundedContainer>
    </>
  )
}

export default NotificationLoading
