import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { AnimatePresence } from 'framer-motion'

import { RoundedContainer, Flex, Paragraph as P, Image, Spacer } from 'components'
import { newPendingPurchase } from 'assets'
import { formatAsCurrency, logUserInteractionGA } from 'utils'
import { theme } from 'styles'

const PendingPurchaseCTA = ({ order }) => {
  const history = useHistory()
  const [details, setDetails] = useState(null)

  const handleClick = () => {
    logUserInteractionGA('Click: You Have a Pending Purchase')
    if (order && order.code) {
      history.push(`/order/pay?code=${order.code}`)
    } else {
      history.push('/order/pay')
    }
  }

  useEffect(() => {
    if (order && order.grand_total_amount && order.store && order.store.name) {
      setDetails({
        totalAmount: order.grand_total_amount,
        merchant: order.store.name,
      })
    }
  }, [order])

  return (
    <AnimatePresence>
      <RoundedContainer
        boxShadow="0px 4px 10px rgba(0, 0, 0, 0.098066)"
        backgroundColor={theme.colors.actions.yellowWarning3}
        padding="8px 10px 8px 25px"
        onClick={handleClick}
        initial={{ y: '100vh' }}
        animate={{ y: 0 }}
        transition={{ duration: 1 }}
      >
        <Flex
          backgroundColor={theme.colors.actions.yellowWarning3}
          justifyContent="space-between"
          alignItems="center"
        >
          <Flex flexDirection="column" backgroundColor={theme.colors.actions.yellowWarning3}>
            <P variant="small" style={{ fontWeight: 700 }}>
              You have a pending purchase.
            </P>

            <P variant="small" color={theme.colors.lockGray} style={{ fontWeight: 600 }}>
              {details
                ? `${formatAsCurrency(details.totalAmount)} at ${details.merchant} `
                : 'Click to return to your purchase.'}
            </P>
          </Flex>
          <Spacer width={15} />
          <Image src={newPendingPurchase} alt="pending_purchase" width="70px" height="70px" />
        </Flex>
      </RoundedContainer>
    </AnimatePresence>
  )
}

export default PendingPurchaseCTA
