import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'

import { Flex, Spacer, Paragraph as P, Heading as H, Span, Footer, Button, Image } from 'components'
import { theme } from 'styles'
import { newApplyMoreCredit } from 'assets'
import {
  setShouldCheckDraftAtCreditScreen,
  formatAsCurrency,
  findMinDownpayment,
  logUserInteractionGA,
} from 'utils'

const RequireMoreCredit = ({
  availablePaymentPlans,
  allowAdditionalDataKycCheck,
  kycInfo,
  allKycsCompleted,
  setShowOverlay,
  minCredit,
}) => {
  const history = useHistory()

  const [minDownpayment, setMinDownpayment] = useState('')

  // *Methods
  const handleApplyMoreCredit = () => {
    const action = allKycsCompleted
      ? 'Contact Us'
      : allowAdditionalDataKycCheck
      ? 'Request Credit Limit Review'
      : 'Apply for More Credit'
    logUserInteractionGA(`Click: ${action}`)

    if (!allKycsCompleted) {
      if (allowAdditionalDataKycCheck) {
        history.push('/verify?type=manual')
      } else {
        history.push('/verify?type=cbs')
      }
    } else {
      return history.push('/contactus')
    }
  }

  // *Effects
  useEffect(() => {
    if (!allKycsCompleted) {
      setShouldCheckDraftAtCreditScreen()
    }
  }, [allKycsCompleted])

  useEffect(() => {
    const min = findMinDownpayment(availablePaymentPlans)
    setMinDownpayment(min)
  }, [availablePaymentPlans])

  // *JSX
  if (
    kycInfo &&
    (kycInfo.sg_additional_data === 'reviewing_for_credit' ||
      kycInfo.my_additional_data === 'reviewing_for_credit')
  ) {
    return (
      <Flex alignItems="center" width="100%" flexDirection="column" px={3} pb={90}>
        <H variant="h4" textAlign="center">
          Credit limit review pending
        </H>
        <Spacer height={10} />

        <P variant="small" color={theme.colors.typography.gray1}>
          Your application for credit increase is still being reviewed. If you have not received a
          response after 1-2 working days, please contact us.
        </P>
        <Spacer height={10} />

        <Footer>
          <Flex flexDirection="column" fitWidth>
            <Button onClick={handleApplyMoreCredit}>CONTACT US</Button>
          </Flex>
        </Footer>
      </Flex>
    )
  }

  return (
    <Flex alignItems="center" width="100%" flexDirection="column" mt={-30} px={3} pb={160}>
      <Image src={newApplyMoreCredit} width="170px" height="170px" />
      <H variant="h4" textAlign="center">
        Apply for more credit?
      </H>
      <Spacer height={10} />

      <P variant="small" color={theme.colors.typography.gray1}>
        Full instalment payment plans require at least{' '}
        <Span style={{ fontWeight: 700 }}>{formatAsCurrency(minCredit)}</Span> of credit for this
        purchase.
      </P>
      <Spacer height={10} />

      <P textAlign="left" variant="small" color={theme.colors.typography.gray1}>
        You may either: <br />
        {allowAdditionalDataKycCheck ? (
          <Span>1) Request for a credit limit review with us, or</Span>
        ) : (
          <Span>1) Apply for an increase in credit with us, or</Span>
        )}{' '}
        <br />
        2) Proceed with a downpayment of{' '}
        <Span style={{ fontWeight: 700 }}>{formatAsCurrency(minDownpayment)}</Span>.
      </P>

      <Footer>
        <Flex flexDirection="column" fitWidth>
          <Button mb={0} onClick={handleApplyMoreCredit}>
            {`${
              allKycsCompleted
                ? 'CONTACT US'
                : allowAdditionalDataKycCheck
                ? 'REQUEST CREDIT LIMIT REVIEW'
                : 'APPLY FOR MORE CREDIT'
            }`}
          </Button>
          <Button
            mb={10}
            variant="secondary"
            onClick={() => {
              logUserInteractionGA('Click: Proceed with Purchase')
              setShowOverlay(false)
            }}
          >
            PROCEED WITH PURCHASE
          </Button>
        </Flex>
      </Footer>
    </Flex>
  )
}

RequireMoreCredit.propTypes = {
  availablePaymentPlans: PropTypes.array.isRequired,
  allowAdditionalDataKycCheck: PropTypes.bool.isRequired,
  kycInfo: PropTypes.object.isRequired,
  allKycsCompleted: PropTypes.bool.isRequired,
  setShowOverlay: PropTypes.func.isRequired,
  minCredit: PropTypes.string.isRequired,
}

export default RequireMoreCredit
