import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router'
import { useSwipeable } from 'react-swipeable'

import {
  Flex,
  PageWrapper,
  Heading as H,
  SlideToggle,
  Spacer,
  StyledBackdrop,
  Footer,
} from 'components'
import { theme } from 'styles/theme'

import OngoingTransactions from './OngoingTransactions/OngoingTransactions'
import CompletedTransactions from './CompletedTransactions/CompletedTransactions'
import { logUserInteractionGA } from 'utils'

const Transactions = () => {
  const history = useHistory()
  const location = useLocation().search
  const transactionType = new URLSearchParams(location).get('type') || 'ongoing'

  const [activeIndex, setActiveIndex] = useState(transactionType === 'completed' ? 1 : 0)

  // *Methods
  const handleGoToCompleted = () => {
    if (transactionType === 'ongoing') {
      logUserInteractionGA('Click: Completed Transactions')
      history.replace('/transactions?type=completed')
      setActiveIndex(1)
    }
  }

  const handleGoToOngoing = () => {
    if (transactionType === 'completed') {
      logUserInteractionGA('Click: Ongoing Transactions')
      history.replace('/transactions?type=ongoing')
      setActiveIndex(0)
    }
  }

  const TRANSACTION_TAB_OPTIONS = [
    {
      label: 'Ongoing',
      callback: handleGoToOngoing,
    },
    {
      label: 'Completed',
      callback: handleGoToCompleted,
    },
  ]

  const swipeHandlers = useSwipeable({
    onSwiped: eventData => {
      const { dir } = eventData

      switch (dir) {
        case 'Left':
          handleGoToCompleted()
          break
        case 'Right':
          handleGoToOngoing()
          break
        default:
          return
      }
    },
  })

  // *JSX
  return (
    <div {...swipeHandlers}>
      <PageWrapper withBottomBar backgroundColor={theme.colors.background}>
        <StyledBackdrop>
          <H variant="h1" color="white">
            Transactions
          </H>
          <Spacer height={20} />
          <SlideToggle
            options={TRANSACTION_TAB_OPTIONS}
            variant="transparent"
            activeIndex={activeIndex}
          />
        </StyledBackdrop>

        <Flex flexDirection="column" width="100%">
          {transactionType === 'ongoing' && <OngoingTransactions />}
          {transactionType === 'completed' && <CompletedTransactions />}
        </Flex>
        <Footer
          style={{
            height: '80vh',
            backgroundColor: theme.colors.background,
            zIndex: -1,
          }}
        />
      </PageWrapper>
    </div>
  )
}

export default Transactions
