import React from 'react'

import { theme } from 'styles/theme'
import { Span } from 'components'

const Link = ({ children, fontWeight, ...rest }) => {
  return (
    <Span color={theme.colors.buttons.marineBlue} style={{ fontWeight: fontWeight }}>
      <a style={{ textDecoration: 'none' }} {...rest}>
        {children}
      </a>
    </Span>
  )
}

export default Link
