import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import Skeleton from 'react-loading-skeleton'

import { theme } from 'styles/theme'
import {
  Flex,
  RoundedContainer,
  Paragraph as P,
  Heading as H,
  Spacer,
  ProgressBar,
} from 'components'
import { formatAsCurrency, logUserInteractionGA } from 'utils'

const AvailableCredit = ({ userCredits }) => {
  const history = useHistory()

  const [percentage, setPercentage] = useState(null)

  // *Effects
  useEffect(() => {
    if (userCredits) {
      const { balance, total } = userCredits
      const calculation = Math.floor((parseInt(balance) / parseInt(total)) * 100)
      setPercentage(isNaN(calculation) ? 0 : calculation) // 0 divided by 0 returns NaN in javascript
    }
  }, [userCredits])

  // *JSX
  return (
    <RoundedContainer marginTop="-50px">
      <Flex flexDirection="column">
        <Flex justifyContent="space-between" width="100%">
          <P color={theme.colors.typography.gray1} style={{ fontWeight: 700 }}>
            Available Credit
          </P>
          <P
            color={theme.colors.abnkBlue}
            style={{ fontWeight: 700 }}
            onClick={() => {
              logUserInteractionGA('Click: Manage Credit')
              history.push('/credit')
            }}
          >
            Manage
          </P>
        </Flex>
        <Spacer height={10} />
        <Flex flexDirection="column">
          {userCredits ? (
            <H variant="h4">{formatAsCurrency(userCredits.balance)}</H>
          ) : (
            <Skeleton height={25} width={80} />
          )}
          <Flex alignItems="center">
            <ProgressBar
              initial={0}
              current={userCredits ? parseInt(userCredits.balance) : 0}
              total={userCredits ? parseInt(userCredits.total) : 1}
            />
            <Spacer width={10} />
            <P
              style={{ width: 50, fontWeight: 700, height: 23 }}
              color={theme.colors.typography.gray6}
            >
              {percentage !== null ? `${percentage}%` : ''}
            </P>
          </Flex>
        </Flex>
      </Flex>
    </RoundedContainer>
  )
}

export default AvailableCredit
