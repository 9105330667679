import React from 'react'
import { useHistory } from 'react-router'

import { Flex, Heading as H, Paragraph as P, Spacer, Footer, Button, Image } from 'components'
import { newFeedbackSent } from 'assets'
import { theme } from 'styles/theme'
import { logUserInteractionGA } from 'utils'

const ContactUsSuccess = () => {
  const history = useHistory()

  return (
    <>
      <Flex
        stretch
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        style={{ flex: 1, marginTop: -70 }}
      >
        <Image src={newFeedbackSent} width="240px" height="240px" />
        <H variant="h4">Feedback sent!</H>
        <Spacer height={10} />
        <P textAlign="center" color={theme.colors.typography.gray1} style={{ lineHeight: '28px' }}>
          Our customer service representative will contact you within a working day
        </P>
      </Flex>
      <Footer>
        <Button
          variant="primary"
          onClick={() => {
            logUserInteractionGA('Click: Back to Home')
            history.push('/home')
          }}
        >
          BACK TO HOME
        </Button>
      </Footer>
    </>
  )
}

export default ContactUsSuccess
