import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { AnimatePresence } from 'framer-motion'
import AdyenCheckout from '@adyen/adyen-web'
import '@adyen/adyen-web/dist/adyen.css'

import { useBuyerStore } from 'stores'
import {
  getCountryCodeLocalDb,
  pushPaymentMethodsHistory,
  getPaymentMethodRedirectLocalDb,
  removePaymentMethodRedirectLocalDb,
  getShouldRedirectToCredit,
} from 'utils'
import { Footer, Button, TriggerPopup, UndraggableOverlay } from 'components'
import { ADYEN_CLIENT_KEY } from 'const'

import './adyenPaymentForm.css'

const ADYEN_STATIC_CONFIGS = {
  locale: 'en_US',
  environment: 'test',
  clientKey: ADYEN_CLIENT_KEY,
}

const AdyenPaymentForm = () => {
  const history = useHistory()
  const countryCode = getCountryCodeLocalDb()

  const [adyenState, setAdyenState] = useState({})
  const [readyForSubmission, setReadyForSubmission] = useState(false)
  const [setupError, setSetupError] = useState('')
  const [success, setSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [show3ds, setShow3ds] = useState(false)

  const {
    response: {
      adyenPaymentMethods: adyenPaymentMethodsResponse,
      paymentMethodSubmit: paymentMethodSubmitResponse,
      paymentMethodUpdate: paymentMethodUpdateResponse,
    },
    fetchAdyenPaymentMethods,
    submitPaymentMethod,
    updatePaymentMethod,
    resetStates,
  } = useBuyerStore()

  // *Methods
  const renderCardComponent = adyenPaymentMethods => {
    const checkout = new AdyenCheckout({
      ...ADYEN_STATIC_CONFIGS,
      paymentMethodsResponse: adyenPaymentMethods,
      onChange: state => {
        setAdyenState(state)
      },
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          billingAddressRequired: false,
          showPayButton: false,
          styles: {
            base: {
              fontFamily: 'Helvetica',
              fontSize: '14px',
              fontWeight: '600',
            },
            placeholder: {
              fontFamily: 'Helvetica',
              fontSize: '14px',
              fontWeight: '600',
            },
          },
        },
      },
    })
    checkout.create('card').mount('#adyen-card-container')
  }

  const render3DSComponent = (paymentMethodId, adyenAction, type) => {
    const checkout = new AdyenCheckout({
      ...ADYEN_STATIC_CONFIGS,
      onAdditionalDetails: state => {
        // Update payment data with backend
        updatePaymentMethod({
          third_party_name: 'adyen',
          adyen: {
            payment_method_id: paymentMethodId,
            details: state.data.details,
          },
        })
      },
    })

    checkout
      .createFromAction(adyenAction, {
        challengeWindowSize: '05',
      })
      .mount(`#adyen-action-container-${type}`)
  }

  const handleSubmit = () => {
    if (adyenState.isValid) {
      setIsLoading(true)
      submitPaymentMethod({
        third_party_name: 'adyen',
        adyen: {
          country: countryCode,
          origin: window.location.origin,
          adyen_data: adyenState.data,
        },
      })
    }
  }

  const handleClearError = () => {
    resetStates('paymentMethodSubmit')
    resetStates('paymentMethodUpdate')
    setSetupError('')
    setIsLoading(false)
  }

  const handleAdditionalActions = paymentMethodSubmitResponse => {
    switch (paymentMethodSubmitResponse.status) {
      case 'require_action':
        // only show 3ds if it's Secure 2 type (native authentication, not redirection)
        if (paymentMethodSubmitResponse.adyen.action.url) {
          render3DSComponent(
            paymentMethodSubmitResponse.id,
            paymentMethodSubmitResponse.adyen.action,
            1,
          )
        } else {
          setShow3ds(true)
        }
        break
      case 'success':
        handleClearError()
        handleAddPaymentMethodSuccess()
        break
      default:
        setIsLoading(false)
        setSetupError(
          'An error occurred when adding the payment method. Please try again or use a different card.',
        )
    }
  }

  const handleUpdatePaymentMethodActions = paymentMethodUpdateResponse => {
    if (paymentMethodUpdateResponse.status === 'success') {
      handleClearError()
      setSuccess(true)
    } else {
      setIsLoading(false)
      setShow3ds(false)
      setSetupError(
        'An error occurred when authenticating with 3DS. Please check the card details and try again.',
      )
    }
  }

  const handleAddPaymentMethodSuccess = () => {
    const redirectUrl = getPaymentMethodRedirectLocalDb()
    if (redirectUrl) {
      // if user is coming from an order
      removePaymentMethodRedirectLocalDb()
      if (redirectUrl.split('?').length === 1) {
        window.location.replace(`${redirectUrl}?use_new_payment_method=true`)
      } else {
        window.location.replace(`${redirectUrl}&use_new_payment_method=true`)
      }
    } else {
      // if user should return to credit screen
      if (getShouldRedirectToCredit()) {
        return history.push('/verify/success?type=paymentmethod')
      } else {
        pushPaymentMethodsHistory(history, '/verify/success?type=paymentmethod')
      }
    }
  }

  // *Effects
  useEffect(() => {
    fetchAdyenPaymentMethods(countryCode)

    return () => {
      resetStates('adyenPaymentMethods')
      resetStates('paymentMethodSubmit')
      resetStates('paymentMethodUpdateResponse')
    }
  }, [])

  useEffect(() => {
    if (adyenState && adyenState.isValid) {
      setReadyForSubmission(true)
    }
  }, [adyenState.isValid])

  useEffect(() => {
    if (adyenPaymentMethodsResponse) {
      renderCardComponent(adyenPaymentMethodsResponse)
    }
  }, [adyenPaymentMethodsResponse])

  useEffect(() => {
    if (show3ds) {
      render3DSComponent(
        paymentMethodSubmitResponse.id,
        paymentMethodSubmitResponse.adyen.action,
        2,
      )
    }
  }, [show3ds])

  useEffect(() => {
    if (paymentMethodSubmitResponse) {
      handleAdditionalActions(paymentMethodSubmitResponse)
    }
  }, [paymentMethodSubmitResponse])

  useEffect(() => {
    if (paymentMethodUpdateResponse) {
      handleUpdatePaymentMethodActions(paymentMethodUpdateResponse)
    }
  }, [paymentMethodUpdateResponse])

  // *JSX
  return (
    <AnimatePresence>
      {/* Error handling */}
      <TriggerPopup
        message={setupError}
        showPopup={setupError.length > 0}
        callback={handleClearError}
        stayAtCurrentPage
        buttonText="OKAY"
        key="error_popup"
      />

      {/* Success handling */}
      <TriggerPopup
        message="Payment method setup successful."
        showPopup={success}
        stayAtCurrentPage
        callback={handleAddPaymentMethodSuccess}
        buttonText="OKAY"
        key="success_popup"
      />

      <div id="adyen-card-container" key="adyen-card-container" style={{ paddingBottom: 50 }} />
      <div
        id="adyen-action-container-1"
        key="adyen-action-container-1"
        style={{
          height: '100%',
          width: '100%',
          padding: '15px',
          paddingBottom: '50px',
        }}
      />

      {show3ds && (
        <UndraggableOverlay
          containerMinHeight="80vh"
          initial={{ y: 800 }}
          animate={{ y: 0 }}
          transition={{ type: 'tween' }}
        >
          <div
            id="adyen-action-container-2"
            key="adyen-action-container-2"
            style={{
              height: '100%',
              width: '100%',
              padding: '15px',
            }}
          />
        </UndraggableOverlay>
      )}
      <Footer withTopBorder>
        <Button onClick={handleSubmit} disabled={!readyForSubmission} isLoading={isLoading}>
          CREATE
        </Button>
      </Footer>
    </AnimatePresence>
  )
}

export default AdyenPaymentForm
