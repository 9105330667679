import React from 'react'
import QRCode from 'react-qr-code'
import { useEffect } from 'react'
import { isDesktop } from 'react-device-detect'
import { Paragraph as P, Flex, Image } from 'components'
import { redirectToAppOrDownload } from 'utils'
import { newAppStoreDesktopBg } from 'assets'

const QR_CODE_LINK = `https://${window.location.host}/appstore/`

const AppStore = () => {
  useEffect(() => {
    if (!isDesktop) redirectToAppOrDownload()
  }, [])

  if (!isDesktop) return null

  return (
    <Flex alignItems="center" width="100vw" height="100vh" style={{ overflow: 'hidden' }}>
      <Flex justifyContent="space-between" width="100vw">
        <Flex flexDirection="column" justifyContent="center" width="50%" padding="5%">
          <P
            variant="normal"
            style={{
              fontSize: '20px',
              fontWeight: '600',
              lineHeight: '32px',
              marginTop: '-25%',
              marginBottom: '60px',
            }}
          >
            Continue with Abnk by scanning the QR code below, and enjoy flexible payments
          </P>
          <QRCode value={QR_CODE_LINK} />
        </Flex>

        <Flex alignItems="center" width="60%">
          <Image
            src={newAppStoreDesktopBg}
            alt="appstore-desktop-bg"
            width="100%"
            height="100%"
            style={{ objectFit: 'cover' }}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default AppStore
