export const findTosUrl = countryCode => {
  let url = ''

  switch (countryCode) {
    case 'my':
      url = 'https://www.abnk.ai/terms-conditions-sg'
      break
    default:
      url = 'https://www.abnk.ai/terms-conditions-sg'
  }

  return url
}
