import React, { useEffect, useState } from 'react'

import { Flex, NavigateBack, PageWrapper, UpcomingPaymentsContainer } from 'components'
import { useBuyerStore } from 'stores'
import { getCountryCodeLocalDb, groupRepaymentsIntoMonths } from 'utils'
import { MONTHS } from 'const'

import LoadingUpcomingPayments from 'components/UpcomingPaymentsContainer/LoadingUpcomingPayments/LoadingUpcomingPayments'

const UpcomingPayments = () => {
  const countryCode = getCountryCodeLocalDb()

  const [groupedRepayments, setGroupedRepayments] = useState(null)

  const {
    response: { upcomingPayments: upcomingPaymentsResponse },
    resetStates: resetStatesBuyer,
    fetchUpcomingPayments,
  } = useBuyerStore()

  // *Effects
  useEffect(() => {
    fetchUpcomingPayments(countryCode)

    return () => resetStatesBuyer('upcomingPayments')
  }, [])

  useEffect(() => {
    if (upcomingPaymentsResponse) {
      const grouped = groupRepaymentsIntoMonths(upcomingPaymentsResponse.repayments)
      setGroupedRepayments(grouped)
    }
  }, [upcomingPaymentsResponse])

  // *JSX
  return (
    <PageWrapper withTopNav>
      <NavigateBack>Upcoming Payments</NavigateBack>
      <Flex flexDirection="column" width="100%">
        {groupedRepayments ? (
          Object.keys(groupedRepayments).map(year => {
            return Object.keys(groupedRepayments[year]).map(month => {
              const groupedRepaymentMonth = groupedRepayments[year][month]
              const monthName = MONTHS[month]

              return (
                <UpcomingPaymentsContainer
                  title={`${monthName} ${year}`}
                  boxShadow="0px 2px 20px 0px #00000019"
                  data={{
                    repayments: groupedRepaymentMonth,
                  }}
                  variant="full"
                  key={`${month}_${year}`}
                />
              )
            })
          })
        ) : (
          <LoadingUpcomingPayments />
        )}
      </Flex>
    </PageWrapper>
  )
}

export default UpcomingPayments
