import React from 'react'
import PropTypes from 'prop-types'

import { Paragraph as P, Link } from 'components'
import { theme } from 'styles/theme'
import { getCountryCodeLocalDb } from 'utils'

const PrivacyPolicy = ({ type }) => {
  const countryCode = getCountryCodeLocalDb()
  let url

  switch (countryCode) {
    case 'my':
      url = 'https://www.abnk.ai/privacy-policy-sg'
      break
    default:
      url = 'https://www.abnk.ai/privacy-policy-sg'
  }

  return (
    <P textAlign="center" style={{ fontSize: 10 }} color={theme.colors.lockGray} mt={-10}>
      {type === 'verify' && countryCode === 'sg'
        ? `You must be a Singaporean citizen above 18 years old to be granted
        credit. Your data will be kept safe according to our`
        : `Your data will be kept safe according to our`}{' '}
      <Link href={url} target="_blank">
        Privacy Policy
      </Link>
    </P>
  )
}

PrivacyPolicy.propTypes = {
  type: PropTypes.string,
}

export default PrivacyPolicy
