import { isAndroid, isIOS } from 'react-device-detect'
import { currentUrlToDeepLink } from './currentUrlToDeepLink'
import { redirectToAppStore } from './redirectToAppStore'

const HUAWEI_TERMS = [/Huawei/i, /HMSCore/i, /HuaweiBrowser/i]
const HUAWEI_REGEX =
  /ALP-|AMN-|ANA-|ANE-|ANG-|AQM-|ARS-|ART-|ATU-|BAC-|BLA-|BRQ-|CAG-|CAM-|CAN-|CAZ-|CDL-|CDY-|CLT-|CRO-|CUN-|DIG-|DRA-|DUA-|DUB-|DVC-|ELE-|ELS-|EML-|EVA-|EVR-|FIG-|FLA-|FRL-|GLK-|HMA-|HW-|HWI-|INE-|JAT-|JEF-|JER-|JKM-|JNY-|JSC-|LDN-|LIO-|LON-|LUA-|LYA-|LYO-|MAR-|MED-|MHA-|MLA-|MRD-|MYA-|NCE-|NEO-|NOH-|NOP-|OCE-|PAR-|PIC-|POT-|PPA-|PRA-|RNE-|SEA-|SLA-|SNE-|SPN-|STK-|TAH-|TAS-|TET-|TRT-|VCE-|VIE-|VKY-|VNS-|VOG-|VTR-|WAS-|WKG-|WLZ-|YAL/i

// TODO: fix app links
const HUAWEI_LINK = 'https://appgallery.huawei.com/app/TODO'
const GOOGLE_LINK = 'https://play.google.com/store/apps/details?id=ai.abnk.app'

export const redirectToAppOrDownload = () => {
  const DEEP_LINK_URL = currentUrlToDeepLink()
  window.location.replace(DEEP_LINK_URL)

  if (isAndroid) {
    const userAgent = navigator.userAgent
    const includeHuaweiTerms = HUAWEI_TERMS.some(term => term.test(userAgent))
    const isHuawei = includeHuaweiTerms || HUAWEI_REGEX.test(userAgent)
    const redirectUrl = isHuawei ? HUAWEI_LINK : GOOGLE_LINK

    setTimeout(() => {
      window.location.replace(redirectUrl)
    }, 500)
  }

  if (isIOS) {
    // allow more time for ios users to click Open
    setTimeout(redirectToAppStore, 2500)
  }
}
