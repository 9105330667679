import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'

import { Heading as H, Image, Spacer, Flex } from 'components'
import { whiteArrow, blackArrow } from 'assets'

import { StyledBackNavigation, StyledRoundNavigation } from './navigateBackStyle'

const NavigateBack = ({
  hide,
  onClick,
  children,
  noBackground,
  progressBar,
  withBottomBorder,
  rightComponent,
  containerStyle,
  ...rest
}) => {
  const history = useHistory()

  if (children.length === 0) {
    return hide ? (
      <Spacer height={40} />
    ) : (
      <Flex
        height={80}
        alignItems="center"
        position="fixed"
        top="0"
        left="0"
        width="100vw"
        paddingLeft="16px"
        pt={4}
        backgroundColor="white"
        zIndex="10"
        borderBottom={withBottomBorder ? '1px solid #E7E7E7' : 'none'}
        {...containerStyle}
      >
        <StyledRoundNavigation
          noBackground={noBackground}
          onClick={onClick ? onClick : () => history.goBack()}
          {...rest}
        >
          <Image
            src={noBackground ? blackArrow : whiteArrow}
            alt="back-button"
            style={{ opacity: 1 }}
            width={18}
            height={18}
          />
        </StyledRoundNavigation>
        {rightComponent && rightComponent}
      </Flex>
    )
  }

  return hide ? (
    <Spacer height={40} />
  ) : (
    <Flex
      flexDirection="column"
      position="fixed"
      top="0"
      left="0"
      width="100vw"
      paddingLeft="16px"
      pt={4}
      backgroundColor="white"
      zIndex="10"
      borderBottom={withBottomBorder ? '1px solid #E7E7E7' : 'none'}
      {...containerStyle}
    >
      <StyledBackNavigation {...rest}>
        <Flex alignItems="center" justifyContent="space-between">
          <Flex alignItems="center" onClick={onClick ? onClick : () => history.goBack()}>
            <Image
              src={blackArrow}
              alt="back-button"
              style={{ marginRight: 5, marginLeft: 5 }}
              width={18}
              height={18}
            />
            <H variant="h3">{children}</H>
          </Flex>
          <Spacer width={10} />
          <Flex alignItems="center" pr="16px">
            {rightComponent && rightComponent}
          </Flex>
        </Flex>
      </StyledBackNavigation>
      {progressBar && progressBar}
    </Flex>
  )
}

NavigateBack.propTypes = {
  children: PropTypes.node,
  hide: PropTypes.bool,
  onClick: PropTypes.func,
  current: PropTypes.number,
  total: PropTypes.number,
  noBackground: PropTypes.bool,
  withBottomBorder: PropTypes.bool,
  rightComponent: PropTypes.node,
  progressBar: PropTypes.node,
}

NavigateBack.defaultProps = {
  children: '',
  hide: false,
  onClick: null,
  withProgress: false,
  current: undefined,
  total: undefined,
  withBottomBorder: false,
  rightComponent: null,
  progressBar: null,
}

export default NavigateBack
