import React, { useState, useLayoutEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { NavigateBack, PageWrapper } from 'components'
import { registration } from 'contents/buyers'
import { useAuthStore } from 'stores'

import MobileNoForm from './MobileNoForm/MobileNoForm'
import OtpForm from './OtpForm/OtpForm'
import { isLoggedIn } from 'utils'

const Register = () => {
  const history = useHistory()

  const { resetStates: authResetStates } = useAuthStore()
  const [currentStep, setCurrentStep] = useState('mobile')
  const [prefix, setPrefix] = useState('')
  const [mobile, setMobile] = useState('')

  // *Methods
  const showOtp = ([countryPrefix, mobileNo]) => {
    setPrefix(countryPrefix)
    setMobile(mobileNo)
    setCurrentStep('otp')
  }

  const onClickNavigateBack = () => {
    setCurrentStep('mobile')
    setPrefix('')
    setMobile('')
    authResetStates('otpSend')
    authResetStates('otpVerify')
  }

  // *Effects
  useLayoutEffect(() => {
    if (isLoggedIn()) {
      history.push('/home')
    }
  }, [])

  // *JSX
  if (currentStep === 'mobile') {
    return (
      <PageWrapper withTopNav>
        <NavigateBack hide>{registration.navigation.otp_verification}</NavigateBack>
        <MobileNoForm showOtp={showOtp} />
      </PageWrapper>
    )
  }

  if (currentStep === 'otp') {
    return (
      <PageWrapper withTopNav>
        <NavigateBack onClick={onClickNavigateBack}>
          {registration.navigation.otp_verification}
        </NavigateBack>
        <OtpForm prefix={prefix} mobile={mobile} />
      </PageWrapper>
    )
  }
}

export default Register
