import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { AnimatePresence } from 'framer-motion'

import { Paragraph as P, Flex, Button, Image } from 'components'
import { theme } from 'styles'
import { voucherPointer, cross } from 'assets'

const VoucherCode = ({ explanation, code, handleRemoveVoucher }) => {
  const [showExplanation, setShowExplanation] = useState(true)

  // *Methods
  const handleToggleExplanation = () => {
    setShowExplanation(!showExplanation)
  }

  // *JSX
  return (
    <AnimatePresence>
      <Flex
        flexDirection="column"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.5 }}
        onClick={handleToggleExplanation}
      >
        {explanation && showExplanation && (
          <Flex
            flexDirection="column"
            centered
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <Flex
              style={{
                background: theme.colors.progressbar.barGreen1,
                padding: 8,
                borderRadius: 8,
              }}
            >
              <P variant="subcopy" color="white" centered style={{ fontWeight: 500 }}>
                {explanation}
              </P>
            </Flex>
            <Image src={voucherPointer} alt="voucher_pointer" width="15px" centered />
          </Flex>
        )}

        <Button
          variant="small"
          icon="voucher"
          rightIcon={handleRemoveVoucher ? cross : null}
          onClickRightIcon={handleRemoveVoucher}
          mt={1}
          style={{
            borderColor: theme.colors.progressbar.barGreen1,
            margin: 'auto',
            marginTop: 6,
          }}
        >
          {code}
        </Button>
      </Flex>
    </AnimatePresence>
  )
}

VoucherCode.propTypes = {
  explanation: PropTypes.string,
  code: PropTypes.string.isRequired,
}

export default VoucherCode
