import { getApprovedCreditLocalDb, advancedDayjs } from 'utils'

export const checkIfCreditIncreased = (amount, createdAt) => {
  const previousApprovedCredit = getApprovedCreditLocalDb()
  if (!previousApprovedCredit) return false

  const { amount: previousAmount, createdAt: previousCreatedAt } = previousApprovedCredit

  if (
    advancedDayjs(createdAt).isAfter(previousCreatedAt) &&
    parseInt(amount) > parseInt(previousAmount)
  ) {
    return true
  }
  return false
}
