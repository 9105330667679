import React from 'react'
import { useHistory } from 'react-router'

import { Flex, RoundedContainer, Paragraph as P, Image, RowDivider } from 'components'
import { theme } from 'styles/theme'
import { logUserInteractionGA } from 'utils'

const ResultRow = ({ merchant }) => {
  const history = useHistory()
  const { categories, name, logo, slug } = merchant

  const handleClick = () => {
    logUserInteractionGA(`Click: Merchant "${slug}"`)
    history.push(`/merchant/?slug=${slug}`)
  }

  return (
    <>
      <Flex justifyContent="space-between" width="100%" onClick={handleClick}>
        <Flex>
          <RoundedContainer
            width="55px"
            height="55px"
            padding="5px"
            alignItems="center"
            justifyContent="center"
          >
            <Image src={logo} alt="merchant-logo" style={{ objectFit: 'contain', width: '40px' }} />
          </RoundedContainer>
          <Flex flexDirection="column" justifyContent="center" ml={3}>
            <P style={{ fontWeight: 700 }}>{name}</P>
            <P variant="small" color={theme.colors.typography.gray7}>
              {categories[0].name}
            </P>
          </Flex>
        </Flex>
      </Flex>
      <RowDivider />
    </>
  )
}

export default ResultRow
