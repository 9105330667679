import React, { useState, useEffect } from 'react'

import { getTransactionReceiptLocalDb } from 'utils'
import { PageWrapper, Flex, Spacer, BrandContainer, Image, Footer } from 'components'
import { theme } from 'styles/theme'
import { dashedLine } from 'assets'

import { StyledReceiptContainer, StyledPunchHole } from './transactionReceiptFullStyle'
import TransactionReceiptDetails from './TransactionReceiptDetails/TransactionReceiptDetails'
import ReceiptAmount from 'components/PaymentReceipt/ReceiptAmount/ReceiptAmount'

const TransactionReceiptFull = () => {
  const [receipt, setReceipt] = useState(null)

  useEffect(() => {
    const receipt = getTransactionReceiptLocalDb()
    setReceipt(receipt)
  }, [])

  if (!receipt) return null

  return (
    <PageWrapper
      style={{
        background: theme.colors.abnkBlue,
      }}
    >
      <Flex flexDirection="column" alignItems="center" fitWidth>
        <StyledReceiptContainer initial={{ y: '100vh' }} animate={{ y: 0 }}>
          <BrandContainer
            brandLogo={receipt.merchant.logo}
            size={80}
            style={{
              margin: 'auto',
              marginTop: '-50px',
              marginBottom: '-20px',
            }}
          />
          <ReceiptAmount
            amount={
              receipt.repayment.payment_transaction
                ? receipt.repayment.payment_transaction.amount
                : receipt.repayment.downpayment_amount
            }
            merchantName={receipt.store.name}
            delay={0.5}
          />
          <>
            <StyledPunchHole variant="left" />
            <StyledPunchHole variant="right" />
            <Image src={dashedLine} alt="dashed_line" style={{ marginTop: 20 }} />
          </>
          <Spacer height={40} />
          <TransactionReceiptDetails data={receipt} />
        </StyledReceiptContainer>
      </Flex>

      <Footer
        style={{
          height: '200px',
          background: theme.colors.abnkBlue,
          zIndex: -1,
        }}
      />
    </PageWrapper>
  )
}

export default TransactionReceiptFull
