import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import QrReader from 'react-qr-reader'
import DOMPurify from 'dompurify'

import {
  NavigateBack,
  UndraggableOverlay,
  Flex,
  Spacer,
  Heading as H,
  Paragraph as P,
  TriggerPopup,
  Loading,
} from 'components'
import { theme } from 'styles/theme'
import { useWindowSize } from 'hooks'
import { useMagicStore } from 'stores'
import { getCountryCodeLocalDb, logUserInteractionGA } from 'utils'
import { Button } from 'components'

const QrScanner = () => {
  const history = useHistory()
  const windowSize = useWindowSize()
  const countryCode = getCountryCodeLocalDb()

  const [cameraError, setCameraError] = useState(false)
  const [urlError, setUrlError] = useState('')
  const [scannedLink, setScannedLink] = useState('')

  // *Stores
  const {
    isLoading: { validatePublicUrl: validatePublicUrlIsLoading },
    response: { validatePublicUrl: validatePublicUrlResponse },
    errors: { validatePublicUrl: validatePublicUrlErrors },
    submitValidatePublicUrl,
    resetStates: resetStatesMagic,
  } = useMagicStore()

  // *Methods
  const handleError = () => {
    logUserInteractionGA('Error: Camera Error', {
      message:
        'Unable to initiate QR Scanner. Please make sure you have allowed this site to use the camera.',
    })
    setCameraError(true)
  }

  const handleScan = result => {
    if (result) {
      const cleanResult = DOMPurify.sanitize(result)
      setScannedLink(cleanResult)
    }
  }

  const handleDisplayMyCode = () => {
    history.push('/myqrcode')
  }

  // *Effects
  useEffect(() => {
    return () => resetStatesMagic('validatePublicUrl')
  }, [])

  useEffect(() => {
    if (scannedLink) {
      resetStatesMagic('validatePublicUrl')
      submitValidatePublicUrl(scannedLink)
    }
  }, [scannedLink])

  useEffect(() => {
    if (validatePublicUrlResponse) {
      window.location.href = scannedLink
      return
    }
  }, [validatePublicUrlResponse])

  useEffect(() => {
    if (validatePublicUrlErrors) {
      setUrlError(`Please scan a valid ${countryCode.toUpperCase()} Abnk merchant link.`)
    } else {
      setUrlError('')
    }
  }, [validatePublicUrlErrors])

  // *JSX
  return (
    <>
      {/* Error handling */}
      {cameraError && (
        <TriggerPopup
          showPopup
          stayAtCurrentPage
          message="Unable to initiate QR Scanner. Please make sure you have allowed this site to use the camera."
          buttonText="RETURN"
          callback={() => setCameraError(false)}
        />
      )}

      <NavigateBack
        transparentOverlay
        containerStyle={{ backgroundColor: 'transparent', zIndex: 20 }}
      />
      <QrReader
        delay={100}
        facingMode="environment"
        style={{ width: '100%' }}
        onError={handleError}
        onScan={handleScan}
      />
      <UndraggableOverlay
        transparentOverlay
        containerMinHeight={`${windowSize.height - windowSize.width + 20}px`}
      >
        <Flex minHeight="50vh" justifyContent="space-between" flexDirection="column" px={4}>
          <Flex centered flexDirection="column">
            <Spacer height={10} />
            <H variant="h3" textAlign="center">
              Scan QR Code
            </H>
            <Spacer height={15} />
            <P textAlign="center" color={theme.colors.typography.gray11}>
              Scan the merchant’s code to make payment. If the merchant requires to scan your code
              instead, use the button below.
            </P>
            <Spacer height={15} />

            {validatePublicUrlIsLoading && <Loading />}

            {urlError && urlError.length > 0 && (
              <>
                <P textAlign="center" color={theme.colors.warningRed}>
                  {urlError}
                </P>
                <Spacer height={8} />
                <P
                  textAlign="center"
                  style={{ wordBreak: 'break-all' }}
                >{`You scanned ${scannedLink}`}</P>
              </>
            )}
          </Flex>
          <Button variant="secondary" onClick={handleDisplayMyCode}>
            DISPLAY MY CODE
          </Button>
        </Flex>
      </UndraggableOverlay>
    </>
  )
}

export default QrScanner
