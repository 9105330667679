import React from 'react'

import { StyledSpan } from './animatedEllipsesStyle'

const AnimatedEllipses = ({ ...rest }) => {
  return (
    <StyledSpan {...rest}>
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </StyledSpan>
  )
}

export default AnimatedEllipses
