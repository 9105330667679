import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useForm, Controller } from 'react-hook-form'

import {
  PageWrapper,
  Footer,
  Button,
  Flex,
  NavigateBack,
  FormSelect,
  FormInput,
  Spacer,
} from 'components'
import { useBuyerStore } from 'stores'
import { homeOwnershipOptions } from 'const'

const VerifyHomeOwnershipForm = () => {
  const history = useHistory()
  const {
    isLoading: { sgPersonal: sgPersonalIsLoading },
    response: { sgPersonal: sgPersonalResponse },
    errors: { sgPersonal: sgPersonalErrors },
    resetStates: resetStatesBuyer,
    submitSgIdentity,
  } = useBuyerStore()

  // *Form settings
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors: formErrors, isValid },
    control,
    watch,
  } = useForm({
    mode: 'all',
  })

  // *Methods
  const submitForm = () => {
    handleSubmit(data => {
      submitSgIdentity(data, null, null)
    })()
  }

  // *Effects
  useEffect(() => {
    return () => resetStatesBuyer('sgPersonal')
  }, [])

  useEffect(() => {
    if (sgPersonalResponse) {
      resetStatesBuyer('sgPersonal')
      history.push('/credit')
      return history.push('/verify/success?type=verification')
    }
  }, [sgPersonalResponse])

  useEffect(() => {
    if (sgPersonalErrors && sgPersonalErrors.home_ownership) {
      for (const [key, value] of Object.entries(sgPersonalErrors.home_ownership)) {
        setError(key, { message: value[0] }, true)
      }
    }
  }, [sgPersonalErrors])

  // *JSX
  return (
    <PageWrapper withTopNav>
      <form onSubmit={submitForm}>
        <Flex px={2} flexDirection="column">
          <Flex pt={2} pb={110} width="100%" flexDirection="column" overflowY="scroll">
            <Controller
              name="type"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FormSelect
                  {...field}
                  label="home ownership"
                  showLabel
                  required
                  isSearchable={false}
                  options={homeOwnershipOptions}
                  onChange={e => field.onChange(e.value)}
                  value={homeOwnershipOptions.find(c => c.value === field.value)}
                  style={{ width: 'calc(100vw - 48px)' }}
                  error={formErrors.type}
                />
              )}
            />

            {watch('type') === 'OTHERS' && (
              <>
                <Spacer height={15} />
                <FormInput
                  label="others"
                  showLabel
                  register={register}
                  id="others"
                  type="text"
                  error={formErrors.others}
                  required={watch('type') === 'OTHERS'}
                />
              </>
            )}
          </Flex>
        </Flex>
      </form>
      <Flex
        flexDirection="column"
        position="fixed"
        top="0"
        pt={4}
        backgroundColor="white"
        zIndex="1"
      >
        <NavigateBack>Verify Home Ownership</NavigateBack>
      </Flex>

      <Footer
        style={{
          borderTop: '1px solid #e8e8e8',
          backgroundColor: 'white',
        }}
      >
        <Button
          onClick={() => submitForm()}
          type="button"
          disabled={!isValid}
          isLoading={sgPersonalIsLoading}
        >
          FINISH
        </Button>
      </Footer>
    </PageWrapper>
  )
}

export default VerifyHomeOwnershipForm
