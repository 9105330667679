export const checkOnboardingStatus = kycs => {
  const identityKyc = kycs.find(
    kyc => kyc.kyc_type === 'sg_identity' || kyc.kyc_type === 'my_identity',
  )
  const paymentMethodKyc = kycs.find(
    kyc => kyc.kyc_type === 'sg_payment_method' || kyc.kyc_type === 'my_payment_method',
  )

  return {
    identityKyc: identityKyc ? identityKyc.status : 'incomplete',
    paymentMethodKyc: paymentMethodKyc ? paymentMethodKyc.status : 'incomplete',
  }
}
