import React, { useEffect } from 'react'

import { signOut } from 'utils'

const Error401 = () => {
  // *Effects
  useEffect(() => {
    signOut()
  }, [])

  return <></>
}

export default Error401
