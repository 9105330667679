import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { Flex } from 'components'

const ProfileHeaderLoading = () => {
  return (
    <Flex alignItems="center" mt={10} ml={10}>
      <Flex
        centered
        style={{
          borderRadius: '50%',
          backgroundColor: '#FFB352',
          width: 60,
          height: 60,
          border: '2px solid white',
        }}
      />

      <Flex flexDirection="column" marginLeft={15}>
        <Skeleton width={150} height={35} />
        <Skeleton width={120} height={26} />
      </Flex>
    </Flex>
  )
}

export default ProfileHeaderLoading
