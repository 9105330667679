import styled from 'styled-components'
import { motion } from 'framer-motion'

export const StyledOverlay = styled(motion.div)`
  display: ${props => (props.noOverlay ? 'none' : '')};
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${props => (props.transparentOverlay ? 'none' : 'rgba(79, 79, 79, 0.58)')};
  z-index: 11;
  padding-bottom: 30px;
`

export const StyledContainer = styled(motion.div)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-y: scroll;
  background: white;
  border-radius: 16px 16px 0px 0px;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.188893);
  z-index: 12;
  padding-top: 25px;
  min-height: ${props => (props.containerMinHeight ? props.containerMinHeight : '50%')};
  max-height: ${props => (props.containerMaxHeight ? props.containerMaxHeight : '90vh')};
`
