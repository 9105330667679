import React from 'react'

import { Flex, Paragraph as P, Spacer } from 'components'

import { ordinalSuffix, checkIsToday } from 'utils'
import { theme } from 'styles/theme'

import ScheduleRowCheckIcon from '../ScheduleRowCheckIcon/ScheduleRowCheckIcon'
import ScheduleRowPaymentDate from '../ScheduleRowPaymentDate/ScheduleRowPaymentDate'
import ScheduleRowPaymentAmount from '../ScheduleRowPaymentAmount/ScheduleRowPaymentAmount'
import { StyledTimeline } from '../scheduleRowStyle'

const PayEarlyScheduleRow = ({ payment, isLoading }) => {
  // *JSX
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Flex>
        <Flex flexDirection="column" justifyContent="center" alignItems="center">
          {payment.sequence > 0 && <StyledTimeline />}
          {payment && (
            <ScheduleRowCheckIcon
              paymentStatus={payment.state}
              isCanPay={payment.can_pay}
              isToday={checkIsToday(payment.due_at)}
            />
          )}
        </Flex>
        <Flex flexDirection="column" ml={3} my={2}>
          {payment && (
            <Flex>
              {payment && payment.collected_at && (
                <>
                  <P
                    color={theme.colors.typography.gray7}
                    variant="small"
                    style={{ fontWeight: 600 }}
                  >
                    {`${checkIsToday(payment.collected_at) ? 'Paid ' : 'Paid on'}`}
                  </P>
                  <Spacer width={3} />
                </>
              )}
              <ScheduleRowPaymentDate
                paymentStatus={payment.state}
                isCanPay={payment.can_pay}
                isDeactivated={payment.state === 'deactivated'}
                isOverdue={payment.state === 'overdue'}
                isEarlyRepayment={payment.is_early_repayment}
                due_at={payment.due_at}
                collected_at={payment.collected_at}
              />
            </Flex>
          )}

          {payment.is_early_repayment ? (
            <P variant="subcopy" color={theme.colors.lockGray} style={{ fontWeight: 400 }}>
              Early repayment
            </P>
          ) : (
            <P variant="subcopy" color={theme.colors.lockGray} style={{ fontWeight: 400 }}>
              {payment.sequence + 1}
              {ordinalSuffix(payment.sequence + 1)} payment
            </P>
          )}
        </Flex>
      </Flex>

      <Spacer width={10} />
      <ScheduleRowPaymentAmount
        paymentStatus={payment.state}
        isLoading={isLoading}
        isCanPay={payment.can_pay}
        isOverdue={payment.state === 'overdue'}
        totalAmount={payment.total_amount}
        paymentId={payment.id}
      />
    </Flex>
  )
}

export default PayEarlyScheduleRow
