import React, { useState } from 'react'
import { useHistory } from 'react-router'

import {
  PageWrapper,
  NavigateBack,
  Flex,
  Heading as H,
  Paragraph as P,
  Spacer,
  Footer,
  Button,
  DraggableOverlay,
  PaymentSchedule,
} from 'components'
import { getPaymentPlanLocalDb, logUserInteractionGA } from 'utils'
import { useOrderEligibilityCheck } from 'hooks'

import PaymentHeader from '../PaymentHeader/PaymentHeader'
import PaymentDetails from './PaymentDetails/PaymentDetails'
import { PaymentPlanCard } from '../..'
import CreditUsedFooter from '../CreditUsedFooter/CreditUsedFooter'

const PaymentPlanReview = () => {
  const history = useHistory()
  const selectedPlan = getPaymentPlanLocalDb()
  const { creditsIsLoading, eligibleForOrder } = useOrderEligibilityCheck()

  const [showPaymentSchedule, setShowPaymentSchedule] = useState(false)

  // *Methods
  const handleConfirmPaymentPlan = () => {
    logUserInteractionGA(`Confirm - Payment Plan`, {
      id: selectedPlan.payment_plan_def_id,
    })
    history.push('/order/confirm')
  }

  // *JSX
  return (
    <>
      {/* Draggable Overlay */}
      {showPaymentSchedule && (
        <DraggableOverlay cancel={() => setShowPaymentSchedule(false)}>
          <Flex mx={3} flexDirection="column">
            <H variant="h3">Payment schedule</H>
            <Spacer height={15} />
            <PaymentSchedule repayments={selectedPlan && selectedPlan.repayments} isActive={0} />
          </Flex>
        </DraggableOverlay>
      )}

      <PageWrapper withTopNav>
        <NavigateBack noBackground />
        <Spacer height={10} />

        <PaymentHeader initial={1} current={2} total={3} />

        <Flex mt={100} mb={130} flexDirection="column" width="100%">
          <P style={{ fontWeight: 700 }}>Step 2 of 3: Payment plan details</P>
          <Spacer height={10} />
          <PaymentPlanCard data={selectedPlan} onClick={() => {}} simplified active />

          <PaymentDetails
            handleViewPaymentSchedule={() => {
              if (!showPaymentSchedule) logUserInteractionGA('Click: View Payment Schedule')
              setShowPaymentSchedule(true)
            }}
          />
        </Flex>

        <Footer withTopBorder flexDirection="column">
          <Spacer height={15} />
          <CreditUsedFooter paymentPlan={selectedPlan} showProgressBar />
          {selectedPlan && selectedPlan.repayments && selectedPlan.repayments.length > 0 && (
            <Button
              onClick={handleConfirmPaymentPlan}
              isLoading={creditsIsLoading}
              disabled={!eligibleForOrder}
            >
              CONTINUE
            </Button>
          )}
        </Footer>
      </PageWrapper>
    </>
  )
}

export default PaymentPlanReview
