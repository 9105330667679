import React from 'react'

import { Flex } from 'components'

import ResultRowLoading from './ResultRow/ResultRowLoading'

const SearchResultsLoading = () => {
  return (
    <Flex flexDirection="column" mt={3} ml={2} fitWidth>
      <ResultRowLoading />
      <ResultRowLoading />
      <ResultRowLoading />
      <ResultRowLoading />
      <ResultRowLoading />
    </Flex>
  )
}

export default SearchResultsLoading
