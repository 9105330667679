import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Flex, BottomBar } from 'components'
import { useWindowSize } from 'hooks'

const PageWrapper = ({ children, withTopNav, withBottomBar, ...rest }) => {
  const { height } = useWindowSize()
  const [innerHeight, setInnerHeight] = useState(height)

  useEffect(() => {
    // always get the max height of the device browser as min height
    setInnerHeight(Math.max(height, innerHeight, 520))
  }, [height])

  if (withBottomBar) {
    return (
      <>
        <Flex
          id="page-wrapper"
          width="100vw"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          overflowY="scroll"
          minHeight={innerHeight}
          px={3}
          py={4}
          pb={100}
          pt={withTopNav ? 80 : 4}
          {...rest}
        >
          {children}
        </Flex>
        <BottomBar />
      </>
    )
  }

  return (
    <Flex
      width="100vw"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      overflowY="scroll"
      px={3}
      py={4}
      pt={withTopNav ? 80 : 4}
      minHeight={innerHeight}
      id="testing 123"
      {...rest}
    >
      {children}
    </Flex>
  )
}

PageWrapper.propTypes = {
  variant: PropTypes.bool,
}

PageWrapper.defaultProps = {
  withBottomBar: false,
}

export default PageWrapper
