import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router'
import Countdown from 'react-countdown'

import { useBuyerStore } from 'stores'
import {
  getCountryCodeLocalDb,
  findCbsKyc,
  advancedDayjs,
  checkIfCreditIncreased,
  getApprovedCreditLocalDb,
  getKycCreatedAt,
} from 'utils'
import { Flex, Modal, Paragraph as P, Spacer, Button, TriggerPopup } from 'components'
import { GENERIC_ERR_MESSAGE } from 'const'

const CbsReportManualAnalysis = () => {
  const history = useHistory()
  const countryCode = getCountryCodeLocalDb()

  const [error, setError] = useState('')
  const intervalRef = useRef()

  const cancelCountDown = useRef(Date.now() + 60000)

  const {
    response: { credits: creditsResponse },
    resetStates: resetStatesBuyer,
    fetchCredits,
  } = useBuyerStore()

  // *Methods
  const cancelButtonRenderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return (
        <Flex flexDirection="column">
          <P>It seems like the process is taking longer than expected.</P>
          <Spacer height={10} />
          <P>You may return to the previous page and resubmit your credit report.</P>
          <Spacer height={10} />
          <Button
            style={{ letterSpacing: 'normal' }}
            onClick={() => {
              history.push('/verify/cbs/manual')
            }}
          >
            RETURN
          </Button>
        </Flex>
      )
    } else {
      return (
        <Flex flexDirection="column">
          <P>We are handling the analysis of your credit report.</P>
          <Spacer height={10} />
          <P>Please keep this tab open and do not refresh.</P>
          <Spacer height={10} />
          <Button style={{ letterSpacing: 'normal' }} disabled>
            RETURN ({`${minutes}:${seconds >= 10 ? seconds : `${0}${seconds}`}`})
          </Button>
        </Flex>
      )
    }
  }

  // *Effects
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      fetchCredits(countryCode)
    }, 3000)

    return () => {
      resetStatesBuyer('credits')
      clearInterval(intervalRef.current)
    }
  }, [])

  // !credit assessment checkpoints start
  useEffect(() => {
    if (creditsResponse && creditsResponse.kycs && creditsResponse.assessment) {
      const cbsKyc = findCbsKyc(creditsResponse.kycs)
      // handle data_error
      if (cbsKyc && cbsKyc.status === 'data_error') {
        const previousCbsKyc = getKycCreatedAt('sg_credit_bureau_report')
        if (
          previousCbsKyc &&
          previousCbsKyc.created_at &&
          previousCbsKyc.created_at === cbsKyc.created_at
        ) {
          return
        }

        let message = GENERIC_ERR_MESSAGE
        if (cbsKyc.error_message) {
          message = cbsKyc.error_message
        }

        clearInterval(intervalRef.current)
        return setError(message)
      }

      const previousCredit = getApprovedCreditLocalDb()
      if (
        previousCredit &&
        previousCredit.createdAt &&
        advancedDayjs(creditsResponse.assessment.completed_at).isAfter(previousCredit.createdAt)
      ) {
        const creditIncreased = checkIfCreditIncreased(
          creditsResponse.amount,
          creditsResponse.assessment.completed_at,
        )
        if (creditIncreased) {
          history.push('/verify/success?type=cbs')
        } else {
          history.push('/home')
          history.push('/credit')
          history.push('/verify/cbs/outcome')
        }
      }
    }
  }, [creditsResponse])
  // !credit assessment checkpoints end

  // *JSX
  if (error && error.length > 0) {
    return (
      <TriggerPopup
        message={error}
        showPopup={!!error}
        buttonText="GO BACK"
        stayAtCurrentPage
        callback={() => {
          history.push('/home')
          history.push('/credit')
          return history.push('/verify?type=cbs')
        }}
      />
    )
  }

  return (
    <Modal>
      <Flex>
        <Countdown date={cancelCountDown.current} renderer={cancelButtonRenderer} />
      </Flex>
    </Modal>
  )
}

export default CbsReportManualAnalysis
