import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useForm } from 'react-hook-form'

import {
  PageWrapper,
  FormInput,
  NavigateBack,
  Footer,
  Button,
  Flex,
  Paragraph as P,
  Link,
  Spacer,
  FileUploadButton,
  FileUploadItem,
} from 'components'
import { useBuyerStore } from 'stores'
import { theme } from 'styles'

const CbsReportManual = () => {
  const history = useHistory()

  const {
    isLoading: { cbsUpload: cbsUploadIsLoading },
    response: { cbsUpload: cbsUploadResponse },
    errors: { cbsUpload: cbsUploadErrors },
    resetStates: resetStatesBuyer,
    submitCbsUpload,
  } = useBuyerStore()

  // *Form settings
  const {
    register,
    setError,
    setValue,
    watch,
    formState: { errors: formErrors },
  } = useForm({
    mode: 'all',
  })

  const [selectedFileNames, setSelectedFileNames] = useState([])

  const selectedFiles = watch('upload-documents')

  // *Methods
  const handleRemoveFile = index => {
    const updatedFileList = [...selectedFiles].filter((_, i) => i !== index)
    setValue('upload-documents', updatedFileList)
  }

  const submitForm = () => {
    const data = new FormData()
    ;[...selectedFiles].forEach(file => {
      data.append('file', file, file.name)
    })
    submitCbsUpload(data)
  }

  // *Effects
  useEffect(() => {
    return () => resetStatesBuyer('cbsUpload')
  }, [])

  useEffect(() => {
    if (selectedFiles?.length > 0) {
      const files = []
      ;[...selectedFiles].forEach(file => files.push(file.name))
      setSelectedFileNames(files)
    } else {
      setSelectedFileNames([])
    }
  }, [selectedFiles])

  useEffect(() => {
    if (cbsUploadResponse && cbsUploadResponse.success) {
      resetStatesBuyer('cbsUpload')
      history.push('/credit')
      return history.push('/verify/cbs/manual/analysis')
    }
  }, [cbsUploadResponse])

  useEffect(() => {
    if (cbsUploadErrors) {
      for (const [key, value] of Object.entries(cbsUploadErrors)) {
        setError(key, { message: value[0] }, true)
      }
    }
  }, [cbsUploadErrors])

  // *JSX
  return (
    <PageWrapper withTopNav>
      {/* Navigation */}
      <NavigateBack>Credit Bureau Report</NavigateBack>

      <form onSubmit={submitForm}>
        <Flex px={2} flexDirection="column">
          <P color={theme.colors.lockGray}>
            If you have an existing valid Credit Bureau report, you may upload it here. Otherwise,
            you may{' '}
            <Link onClick={() => history.push('/verify/cbs/auto')}>
              retrieve it through our app.
            </Link>
          </P>
          <Spacer height={15} />

          <FileUploadButton
            topText="Click this box to select files to upload"
            bottomText="Accepted formats: pdf"
            handleClick={() => document.querySelector('input').click()}
          />

          {!!formErrors?.file?.message && (
            <P variant="subcopy" color="red" mb={2}>
              {formErrors.file.message}
            </P>
          )}

          <Flex flexDirection="column">
            <P variant="subcopy" marginBottom={2}>{`${selectedFileNames.length} file${
              selectedFileNames.length > 0 ? 's' : ''
            } selected${selectedFileNames.length > 0 ? ':' : ''}`}</P>

            {selectedFileNames?.map((name, i) => (
              <FileUploadItem key={name} name={name} handleRemoveFile={() => handleRemoveFile(i)} />
            ))}
          </Flex>

          {/* hidden input, actual file picker */}
          <Flex display="none">
            <FormInput
              name="upload-documents"
              label="Upload PDF documents"
              register={register}
              id="upload-documents"
              type="file"
              accept=".pdf"
              errorMessage={formErrors?.file?.message}
              multiple
              required
              onChange={e => {
                const files = e.currentTarget.files
                if (files?.length === 0) return
                if (files && files.length > 0) {
                  const selected = Array.from(files)
                  if (selectedFileNames?.length > 0) {
                    // prevent adding the same file twice to upload list
                    const filtered = selected.filter(file => !selectedFileNames.includes(file.name))
                    setValue('upload-documents', selectedFiles.concat(filtered))
                  } else {
                    setValue('upload-documents', selected)
                  }
                }
              }}
            />
          </Flex>
        </Flex>

        <Footer
          style={{
            borderTop: '1px solid #e8e8e8',
            backgroundColor: 'white',
          }}
        >
          <Button
            onClick={submitForm}
            type="button"
            disabled={selectedFileNames.length <= 0}
            isLoading={cbsUploadIsLoading}
          >
            SUBMIT
          </Button>
        </Footer>
      </form>
    </PageWrapper>
  )
}

export default CbsReportManual
