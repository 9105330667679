import React from 'react'
import { useHistory, useLocation } from 'react-router'
import { useOrderStore } from 'stores'
import { theme } from 'styles'
import { PageWrapper, Flex, Heading as H, Paragraph as P, Spacer, Footer, Button } from 'components'

const PAYMENT_METHOD_MODE_MAP = {
  card: 'Payment method',
  my_emandate: 'Bank mandate',
}

const PAYMENT_METHOD_STATUS_MAP = {
  success: {
    add: 'added!',
    update: 'updated!',
    terminate: 'removed!',
  },
  processing: {
    add: 'processing!',
    update: 'processing!',
    terminate: 'processing!',
  },
  error: {
    add: 'error!',
    update: 'error!',
    terminate: 'error!',
  },
}

const PaymentMethodResult = () => {
  const history = useHistory()
  const location = useLocation().search

  const status = new URLSearchParams(location).get('status')
  const message = new URLSearchParams(location).get('message')
  const paymentMethodMode = new URLSearchParams(location).get('payment_method_mode')
  const action = new URLSearchParams(location).get('action')

  // *Stores
  const {
    response: { draft: draftResponse },
  } = useOrderStore()

  const handleClickReturn = () => {
    // return to pending order if there is one
    if (draftResponse?.orders?.length) {
      history.push('/home')
      history.push('/credit')
      if (draftResponse.code) {
        return history.push(`/order/pay?code=${draftResponse.code}`)
      } else {
        return history.push('/order/pay')
      }
    }

    history.push('/home') // push home first so user can click back and navigate to home from credit page
    return history.push('/credit')
  }

  // *JSX
  return (
    <PageWrapper
      style={{
        background: theme.colors.buttons.marineBlue,
        minHeight: '100vh',
      }}
    >
      <Flex
        stretch
        flex="1"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        color="white"
        pb={150}
        px={10}
      >
        <H variant="h3" textAlign="center">
          {`${PAYMENT_METHOD_MODE_MAP[paymentMethodMode]} ${PAYMENT_METHOD_STATUS_MAP[status][action]}`}
        </H>
        <Spacer height={10} />

        {!!message && (
          <>
            <P textAlign="center">{message}</P>
            <Spacer height={10} />
          </>
        )}
      </Flex>

      <Footer
        initial={{ y: 100 }}
        animate={{ y: 0 }}
        transition={{
          delay: 0.8,
          duration: 0.2,
          type: 'spring',
          stiffness: 150,
        }}
      >
        <Flex flexDirection="column" fitWidth>
          <Button variant="secondary" onClick={handleClickReturn}>
            RETURN
          </Button>
        </Flex>
      </Footer>
    </PageWrapper>
  )
}

export default PaymentMethodResult
