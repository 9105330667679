import React from 'react'

import { Paragraph as P, Flex, RoundedContainer, Image } from 'components'
import { dashedLine } from 'assets'
import { DetailRow } from 'web/buyers/pages'
import { ordinalSuffix } from 'utils'

const PayNextDetails = ({ data }) => {
  const { sequence, instalment_amount, instalment_processing_fee_amount, total_amount } = data

  return (
    <Flex mt={110} flexDirection="column" width="100%" zIndex={1}>
      <P style={{ fontWeight: 700 }}>Today&apos;s payment</P>
      <RoundedContainer boxShadow="0px 2px 6px 0px #0000002A" borderRadius={12}>
        <DetailRow
          title={`${sequence + 1}${ordinalSuffix(sequence + 1)} instalment amount`}
          amount={instalment_amount}
        />
        {instalment_processing_fee_amount !== '0.00' && (
          <DetailRow title={'Monthly processing fee'} amount={instalment_processing_fee_amount} />
        )}

        <Image src={dashedLine} alt="dashed_line" style={{ margin: '10px 0' }} />

        <DetailRow title={"Today's payment"} amount={total_amount} style={{ marginTop: '8px' }} />
      </RoundedContainer>
    </Flex>
  )
}

export default PayNextDetails
