import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import Skeleton from 'react-loading-skeleton'

import {
  getDraftOrderLocalDb,
  getCountryCodeLocalDb,
  formatAsCurrency,
  checkOnboardingStatus,
  extractKycInfo,
  logUserInteractionGA,
  removeBackToMerchantLink,
} from 'utils'
import { theme } from 'styles/theme'
import {
  PageWrapper,
  Modal,
  NavigateBack,
  Flex,
  RoundedContainer,
  Spacer,
  Heading as H,
  Paragraph as P,
  Span,
  Button,
  Footer,
  ProgressBar,
  TriggerPopup,
  Image,
  DraggableOverlay,
  VoucherCode,
  Link,
} from 'components'
import { useOrderStore, useBuyerStore } from 'stores'
import {
  removeDraftOrderLocalDb,
  setDraftOrderLocalDb,
  checkForInsufficientCredit,
  checkAllKycsCompleted,
  findIdentityKyc,
} from 'utils'
import { useOrderEligibilityCheck } from 'hooks'

import InsufficientCredit from './InsufficientCredit/InsufficientCredit'
import RequireMoreCredit from './RequireMoreCredit/RequireMoreCredit'
import VerificationUpdateRequired from './VerificationUpdateRequired/VerificationUpdateRequired'
import OnboardingRequired from './OnboardingRequired/OnboardingRequired'
import CreditExplanationNormal from '../PaymentPlans(Step1)/CreditExplanation/CreditExplanationNormal'
import VoucherEntryPopUp from './VoucherEntry/VoucherEntry'

const Order = () => {
  const history = useHistory()
  const countryCode = getCountryCodeLocalDb()
  const draftOrder = getDraftOrderLocalDb()
  const { creditsIsLoading, eligibleForOrder } = useOrderEligibilityCheck()

  let draftOrderCode
  if (draftOrder) draftOrderCode = draftOrder.code

  const [onboardingStatus, setOnboardingStatus] = useState(null)
  const [kycInfo, setKycInfo] = useState(null)
  const [allKycsCompleted, setAllKycsCompleted] = useState(true)
  const [showOverlay, setShowOverlay] = useState(false)
  const [creditIsInsufficient, setCreditIsInsufficient] = useState(false)
  const [creditMinSufficientAmount, setCreditMinSufficientAmount] = useState('0.00')
  const [identityKycIsExpired, setIdentityKycIsExpired] = useState(false)
  const [voucher, setVoucher] = useState(null)
  const [finalizedVoucherCode, setFinalizedVoucherCode] = useState('')
  const [showCreditExplanation, setShowCreditExplanation] = useState(false)
  const [showVoucherEntry, setShowVoucherEntry] = useState(false)
  const [latestOrderResponse, setLatestOrderResponse] = useState(null)

  // *Stores
  const {
    isLoading: {
      cancelOrder: cancelOrderIsLoading,
      order: orderIsLoading,
      addVoucher: addVoucherIsLoading,
      removeVoucher: removeVoucherIsLoading,
    },
    response: {
      cancelOrder: cancelOrderResponse,
      order: orderResponse,
      addVoucher: addVoucherResponse,
      removeVoucher: removeVoucherResponse,
    },
    errors: { cancelOrder: cancelOrderError },
    resetStates: resetStatesOrder,
    submitCancelOrder,
    fetchOrder,
    submitVoucher,
    removeVoucher,
  } = useOrderStore()

  const {
    response: { credits: creditsResponse },
    resetStates: resetStatesBuyer,
    fetchCredits,
    fetchPaymentMethod,
  } = useBuyerStore()

  // *Methods
  const handleDiscardPurchase = () => {
    const currentDraftOrder = getDraftOrderLocalDb()
    logUserInteractionGA(`Click: Discard Purchase ${currentDraftOrder.code}`)
    submitCancelOrder(currentDraftOrder.code)
  }

  const toggleShowVoucherEntryOverlay = () => {
    if (!showVoucherEntry) logUserInteractionGA('Click: Add Voucher')
    setShowVoucherEntry(!showVoucherEntry)
  }

  const handleRemoveVoucher = () => {
    logUserInteractionGA('Click: Remove Voucher')
    removeVoucher(latestOrderResponse.code, voucher.code)
  }

  const renderOverlay = () => {
    // !User journey checkpoints start
    // completed onboarding, but identity verification expired
    if (showOverlay && identityKycIsExpired) {
      return (
        <DraggableOverlay cancel={() => setShowOverlay(false)}>
          <Spacer height={25} />
          <VerificationUpdateRequired
            handleDiscardPurchase={handleDiscardPurchase}
            cancelOrderIsLoading={cancelOrderIsLoading}
          />
        </DraggableOverlay>
      )
    }

    // onboarding (verify identity + add payment method) checks
    if (
      showOverlay &&
      onboardingStatus &&
      (onboardingStatus.identityKyc !== 'data_available' ||
        onboardingStatus.paymentMethodKyc !== 'data_available')
    ) {
      return (
        <DraggableOverlay cancel={() => setShowOverlay(false)}>
          <Spacer height={5} />
          <OnboardingRequired onboardingStatus={onboardingStatus} />
        </DraggableOverlay>
      )
    }

    // credit is insufficient for all payment plans. Varying message depending on whether user completed all kycs
    if (
      showOverlay &&
      draftOrder &&
      creditsResponse &&
      kycInfo &&
      orderResponse &&
      orderResponse.state !== 'cancelled' &&
      'min_credit_amount_for_profit' in orderResponse &&
      'has_min_credit_amount_for_profit' in orderResponse &&
      !orderResponse.has_min_credit_amount_for_profit &&
      !orderIsLoading
    )
      return (
        <DraggableOverlay cancel={() => setShowOverlay(false)}>
          <Spacer height={5} />
          <InsufficientCredit
            minCredit={orderResponse.min_credit_amount_for_profit}
            kycInfo={kycInfo}
            allKycsCompleted={allKycsCompleted}
            allowAdditionalDataKycCheck={creditsResponse.can_allow_additional_data_kyc_check}
          />
        </DraggableOverlay>
      )

    // completed onboarding, but some payment plans have insufficient credit. Still have ways to increase credit
    if (
      showOverlay &&
      creditsResponse &&
      creditsResponse.kycs &&
      kycInfo &&
      orderResponse &&
      orderResponse.available_payment_plans &&
      orderResponse.available_payment_plans.length > 0 &&
      // orderResponse.min_credit_amount_for_profit &&
      creditIsInsufficient
    )
      return (
        <DraggableOverlay cancel={() => setShowOverlay(false)}>
          <Spacer height={5} />
          <RequireMoreCredit
            availablePaymentPlans={orderResponse.available_payment_plans}
            allowAdditionalDataKycCheck={creditsResponse.can_allow_additional_data_kyc_check}
            kycInfo={kycInfo}
            allKycsCompleted={allKycsCompleted}
            setShowOverlay={setShowOverlay}
            minCredit={creditMinSufficientAmount}
          />
        </DraggableOverlay>
      )
    // !User journey checkpoints end
  }

  // *Effects
  useEffect(() => {
    fetchPaymentMethod(countryCode)
    fetchCredits(countryCode)
    if (draftOrderCode) fetchOrder(draftOrderCode)

    return () => {
      resetStatesBuyer('credits')
      resetStatesOrder('order')
      resetStatesOrder('cancelOrder')
      resetStatesOrder('voucher')
    }
  }, [])

  useEffect(() => {
    if (voucher) {
      setShowVoucherEntry(false)
    }
  }, [voucher])

  useEffect(() => {
    if (creditsResponse) {
      const identityKyc = findIdentityKyc(creditsResponse.kycs)
      if (identityKyc && identityKyc.status === 'expired') {
        setIdentityKycIsExpired(true)
        setShowOverlay(true)
      }

      if (creditsResponse.kycs) {
        const kycs = extractKycInfo(creditsResponse.kycs)
        setKycInfo(kycs)

        const status = checkOnboardingStatus(creditsResponse.kycs)
        setOnboardingStatus(status)
      }

      const allKycsCompleted = checkAllKycsCompleted(creditsResponse.kycs)
      setAllKycsCompleted(allKycsCompleted)
    }
  }, [creditsResponse])

  useEffect(() => {
    if (onboardingStatus) {
      if (
        onboardingStatus.identityKyc !== 'data_available' ||
        onboardingStatus.paymentMethodKyc !== 'data_available'
      ) {
        setShowOverlay(true)
      }
    }
  }, [onboardingStatus])

  useEffect(() => {
    if (orderResponse) {
      setLatestOrderResponse(orderResponse)
    }
  }, [orderResponse])

  useEffect(() => {
    if (cancelOrderResponse && cancelOrderResponse.success) {
      removeDraftOrderLocalDb()
      removeBackToMerchantLink()
    }
  }, [cancelOrderResponse])

  useEffect(() => {
    if (latestOrderResponse && latestOrderResponse.code && finalizedVoucherCode) {
      submitVoucher(latestOrderResponse.code, finalizedVoucherCode)
    }
  }, [finalizedVoucherCode])

  useEffect(() => {
    if (addVoucherResponse) {
      setLatestOrderResponse(addVoucherResponse)
    }
  }, [addVoucherResponse])

  useEffect(() => {
    if (removeVoucherResponse) {
      setFinalizedVoucherCode('')
      setLatestOrderResponse(removeVoucherResponse)
    }
  }, [removeVoucherResponse])

  useEffect(() => {
    if (latestOrderResponse) {
      setDraftOrderLocalDb(latestOrderResponse)

      if (latestOrderResponse.available_payment_plans) {
        if (latestOrderResponse.available_payment_plans.length === 0) {
          setShowOverlay(true)
          setCreditIsInsufficient(true)
          setCreditMinSufficientAmount('0.00')
        } else {
          const { insufficientCredit, minSufficientCreditAmount } = checkForInsufficientCredit(
            latestOrderResponse.available_payment_plans,
          )
          if (insufficientCredit) setShowOverlay(true)
          setCreditIsInsufficient(insufficientCredit)
          setCreditMinSufficientAmount(minSufficientCreditAmount)
        }
      }

      if (latestOrderResponse.vouchers && latestOrderResponse.vouchers.length > 0) {
        setVoucher(latestOrderResponse.vouchers[0])
      } else if (latestOrderResponse.vouchers && latestOrderResponse.vouchers.length === 0) {
        setVoucher(null)
      }
    }
  }, [latestOrderResponse])

  // *JSX
  if (!draftOrder && orderResponse && !orderIsLoading) {
    return (
      <Modal
        messageArray={['No order found.']}
        primaryButtonText="RETURN HOME"
        primaryButtonAction={() => history.push('/home')}
      />
    )
  }

  return (
    <>
      {/* Error handling */}
      <TriggerPopup
        showPopup={cancelOrderError}
        message={cancelOrderError}
        callback={() => resetStatesOrder('cancelOrder')}
      />

      {/* Discard purchase handling */}
      <TriggerPopup
        showPopup={
          (cancelOrderResponse && cancelOrderResponse.success) ||
          (orderResponse && orderResponse.state === 'cancelled')
        }
        message="Order cancelled successfully."
        buttonText={
          cancelOrderResponse &&
          cancelOrderResponse.data &&
          cancelOrderResponse.data.redirect_url &&
          'BACK TO MERCHANT PAGE'
        }
        callback={() => {
          resetStatesOrder('cancelOrder')
          removeDraftOrderLocalDb()
          removeBackToMerchantLink()
          if (
            cancelOrderResponse &&
            cancelOrderResponse.data &&
            cancelOrderResponse.data.redirect_url
          ) {
            window.location.assign(cancelOrderResponse.data.redirect_url)
          }
        }}
      />

      {/* Draggable overlays */}
      {showCreditExplanation &&
        orderResponse &&
        'merchant_credit_balance' in orderResponse &&
        'buyer_credit_balance' in orderResponse &&
        'credit_balance' in orderResponse &&
        'credit_additional_amount' in orderResponse && (
          <DraggableOverlay cancel={() => setShowCreditExplanation(false)}>
            <CreditExplanationNormal
              merchantBalance={orderResponse.merchant_credit_balance}
              userBalance={orderResponse.buyer_credit_balance}
              creditBalance={orderResponse.credit_balance}
              creditAdditionalAmount={orderResponse.credit_additional_amount}
            />
          </DraggableOverlay>
        )}

      {/* Voucher overlay */}
      {showVoucherEntry && orderResponse && orderResponse.valid_voucher_codes && (
        <DraggableOverlay cancel={toggleShowVoucherEntryOverlay}>
          <VoucherEntryPopUp
            validVoucherCodes={orderResponse.valid_voucher_codes}
            setFinalizedVoucherCode={setFinalizedVoucherCode}
            addVoucherIsLoading={addVoucherIsLoading}
          />
        </DraggableOverlay>
      )}

      <PageWrapper withTopNav>
        <NavigateBack onClick={() => history.push('/home')}>Pay Merchant</NavigateBack>

        <Flex stretch centered flexDirection="column">
          <RoundedContainer
            width={150}
            height={150}
            justifyContent="center"
            boxShadow="0px 2px 7px 0px #00000019"
            borderRadius={12}
          >
            {draftOrder &&
            orderResponse &&
            orderResponse.merchant &&
            orderResponse.merchant.logo &&
            !orderIsLoading ? (
              <Image
                src={orderResponse.merchant.logo}
                alt="merchant_logo"
                style={{ maxWidth: '100%', maxHeight: '100%' }}
              />
            ) : (
              <Skeleton height={100} width={110} />
            )}
          </RoundedContainer>
          <Spacer height={25} />

          {draftOrder && orderResponse && orderResponse.grand_total_amount && !orderIsLoading ? (
            <H variant="h1" fontSize={48} textAlign="center" style={{ lineHeight: '40px' }}>
              {formatAsCurrency(orderResponse.grand_total_amount)}
            </H>
          ) : (
            <Skeleton height={50} width={200} />
          )}

          {draftOrder &&
          orderResponse &&
          orderResponse.store &&
          orderResponse.store.name &&
          !orderIsLoading ? (
            <>
              <Spacer height={20} />
              <H
                variant="h4"
                color={theme.colors.typography.gray8}
                fontWeight={600}
                centered
                textAlign="center"
              >
                {orderResponse.store.name}
              </H>
            </>
          ) : (
            <Skeleton height={20} width={100} style={{ marginTop: 10 }} />
          )}

          {orderIsLoading ? (
            <Skeleton height={20} width={100} style={{ marginTop: 10 }} />
          ) : draftOrder &&
            orderResponse &&
            orderResponse.order_campaign &&
            orderResponse.order_campaign.display_name ? (
            <>
              <Spacer height={5} />
              <H
                variant="h4"
                color={theme.colors.typography.gray8}
                fontWeight={600}
                centered
                textAlign="center"
              >
                {orderResponse.order_campaign.display_name}
              </H>
            </>
          ) : null}

          <Spacer height={200} />
        </Flex>

        {/* Vouchers */}
        <>
          <Spacer height={110} />
          <Flex
            centered
            style={{
              position: 'fixed',
              bottom: 215,
              width: 250,
              left: '50%',
              transform: 'translate(-50%, 0)',
            }}
          >
            {voucher &&
            voucher.code &&
            voucher.min_credit &&
            voucher.min_credit.min_credit_amount ? (
              <VoucherCode
                code={voucher.code}
                explanation={`This voucher grants you credit limit up to ${formatAsCurrency(
                  voucher.min_credit.min_credit_amount,
                )}, for this purchase only.`}
              />
            ) : voucher && voucher.code && voucher.description ? (
              <VoucherCode
                code={voucher.code}
                explanation={voucher.description}
                handleRemoveVoucher={handleRemoveVoucher}
              />
            ) : orderResponse ? (
              <Button variant="small" icon="voucher" onClick={toggleShowVoucherEntryOverlay}>
                Add voucher
              </Button>
            ) : (
              <></>
            )}
          </Flex>
        </>

        <Footer withTopBorder centered flexDirection="column">
          <Spacer height={20} />
          {orderResponse && orderResponse.credit_balance ? (
            <>
              <ProgressBar
                initial={0}
                current={orderResponse.credit_balance}
                total={orderResponse.credit_amount}
              />
              <Spacer height={8} />

              <P variant="subcopy" color={theme.colors.lockGray} centered>
                You have {formatAsCurrency(orderResponse.credit_balance)}{' '}
                {orderResponse.credit_additional_amount && (
                  <Span color={theme.colors.progressbar.barGreen1}>
                    (+
                    {formatAsCurrency(orderResponse.credit_additional_amount)}){' '}
                  </Span>
                )}
                of available credit{' '}
                <Link fontWeight={700} onClick={() => setShowCreditExplanation(true)}>
                  Details
                </Link>
              </P>
            </>
          ) : (
            <>
              <ProgressBar initial={0} current={0} total={0} />
              <Spacer height={8} />
              <Skeleton height={12} width={200} />
            </>
          )}

          <Button
            mb={0}
            onClick={() => {
              logUserInteractionGA('Click: Continue')
              history.push('/order/plans')
            }}
            isLoading={
              cancelOrderIsLoading ||
              orderIsLoading ||
              addVoucherIsLoading ||
              removeVoucherIsLoading ||
              creditsIsLoading
            }
            disabled={
              !orderResponse ||
              cancelOrderIsLoading ||
              !eligibleForOrder ||
              (orderResponse && !orderResponse.has_min_credit_amount_for_profit) ||
              (creditsResponse && creditsResponse.is_expired) ||
              addVoucherIsLoading ||
              removeVoucherIsLoading
            }
          >
            CONTINUE
          </Button>
          <Button
            mt={2}
            mb={0}
            backgroundColor="white"
            border="none"
            color={theme.colors.warningRed}
            onClick={handleDiscardPurchase}
            isLoading={
              cancelOrderIsLoading ||
              orderIsLoading ||
              addVoucherIsLoading ||
              removeVoucherIsLoading
            }
            disabled={!orderResponse}
          >
            DISCARD PURCHASE
          </Button>
        </Footer>

        {/* Draggable overlays */}
        {renderOverlay()}
      </PageWrapper>
    </>
  )
}

export default Order
