import React from 'react'
import PropTypes from 'prop-types'

import { Flex, Paragraph as P, Spacer } from 'components'
import { theme } from 'styles'

import ScheduleRow from './ScheduleRow/ScheduleRow'
import ScheduleRowLoading from './ScheduleRow/ScheduleRowLoading/ScheduleRowLoading'

const PaymentSchedule = ({
  repayments,
  isLoading,
  description = '',
  orderCode = '',
  isPayEarly = false,
  selectedPaymentMethod = null,
}) => {
  const sortedRepayments = repayments ? repayments.sort((a, b) => a.sequence - b.sequence) : null

  // *JSX
  if (isPayEarly) {
    return (
      <Flex stretch flexDirection="column" pb={4}>
        {description && (
          <>
            <P color={theme.colors.typography.gray10}>{description}</P>
            <Spacer height={12} />
          </>
        )}
        {sortedRepayments ? (
          sortedRepayments.map((payment, i) => {
            return <ScheduleRow key={i} payment={payment} isLoading={isLoading} isPayEarly />
          })
        ) : (
          <>
            <ScheduleRowLoading i={0} />
            <ScheduleRowLoading i={1} />
            <ScheduleRowLoading i={2} />
          </>
        )}
      </Flex>
    )
  }

  return (
    <Flex stretch flexDirection="column" pb={4}>
      {sortedRepayments ? (
        sortedRepayments.map((payment, i) => {
          return (
            <ScheduleRow
              key={i}
              payment={payment}
              orderCode={orderCode}
              isLoading={isLoading}
              selectedPaymentMethod={selectedPaymentMethod}
            />
          )
        })
      ) : (
        <>
          <ScheduleRowLoading i={0} />
          <ScheduleRowLoading i={1} />
          <ScheduleRowLoading i={2} />
        </>
      )}
    </Flex>
  )
}

PaymentSchedule.propTypes = {
  description: PropTypes.string,
  isPayEarly: PropTypes.bool,
  orderCode: PropTypes.string,
  isLoading: PropTypes.bool,
  repayments: PropTypes.array,
  selectedPaymentMethod: PropTypes.object,
}

export default PaymentSchedule
