import styled from 'styled-components'
import {
  space,
  color,
  typography,
  layout,
  flexbox,
  border,
  position,
  compose,
  variant,
} from 'styled-system'

import { theme } from 'styles/theme'

export const StyledButton = styled.button`
  font-family: ${theme.typography.fontFamily};
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  margin-top: 16px;
  margin-bottom: 16px;
  border-radius: 16px;
  width: 100%;
  letter-spacing: 2px;
  box-sizing: border-box;
  padding: ${props => (props.isLoading ? '5px' : '15px')};

  ${variant({
    variants: {
      primary: {
        backgroundColor: theme.colors.buttons.marineBlue,
        color: 'white',
        border: `1px solid ${theme.colors.buttons.marineBlue}`,
        textTransform: 'uppercase',
      },
      secondary: {
        backgroundColor: 'white',
        color: theme.colors.buttons.marineBlue,
        border: `1px solid ${theme.colors.buttons.marineBlue}`,
        textTransform: 'uppercase',
      },
      small: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 600,
        backgroundColor: 'white',
        color: `${theme.colors.charcoal}`,
        border: `1px solid ${theme.colors.buttons.lightGray}`,
        fontSize: '14px',
        lineHeight: '21px',
        borderRadius: '22px',
        padding: '5px 15px 5px 10px',
        width: 'auto',
        letterSpacing: 'normal',
      },
      extrasmall: {
        backgroundColor: 'white',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 600,
        border: 'none',
        fontSize: '11px',
        lineHeight: '19px',
        height: '24px',
        minWidth: '60px',
        borderRadius: '12px',
        padding: '5px 10px',
        width: 'auto',
        letterSpacing: 'normal',
      },
      tutorial: {
        backgroundColor: 'white',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 600,
        border: 'none',
        fontSize: '14px',
        lineHeight: '19px',
        width: 'auto',
        minWidth: '60px',
        borderRadius: '22px',
        padding: '8px 25px',
        letterSpacing: 'normal',
      },
    },
  })}

  ${props =>
    props.disabled &&
    variant({
      variants: {
        primary: {
          backgroundColor: `${theme.colors.typography.gray1} !important`,
          border: `1px solid ${theme.colors.typography.gray1} !important`,
          color: 'white',
        },
        secondary: {
          backgroundColor: `${theme.colors.typography.gray1} !important`,
          border: `1px solid ${theme.colors.typography.gray1} !important`,
          color: 'white',
        },
      },
    })}

  &:hover {
    cursor: pointer;
  }

  ${compose(space, color, typography, layout, flexbox, border, position)}
`

export const RoundButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${props =>
    props.disabled ? theme.colors.typography.gray1 : theme.colors.buttons.marineBlue};
  box-shadow: ${props =>
    props.disabled
      ? `0px 5px 8px ${theme.colors.typography.gray1}`
      : '0px 5px 8px rgba(58, 161, 239, 0.784774)'};
  border-radius: 50%;
  width: 60px;
  height: 60px;
  border: none;
`
