import React, { useState } from 'react'

import { PageWrapper, Heading as H, Spacer, Flex, StyledBackdrop } from 'components'
import { FeaturedMerchants, MerchantSearchBar, SearchResults } from 'web/buyers/pages'

import CategoryFilter from './CategoryFilter/CategoryFilter'

const Buy = () => {
  const [categories, setCategories] = useState([])
  const [searchTerm, setSearchTerm] = useState('')

  const variants = {
    show: {
      opacity: 1,
      height: 'auto',
      y: 0,
    },
    hide: { opacity: 0, height: 0, y: -500 },
  }

  // *JSX
  return (
    <PageWrapper withBottomBar>
      <Flex
        stretch
        animate={searchTerm.length > 0 || categories.length > 0 ? 'hide' : 'show'}
        variants={variants}
        transition={{ type: 'tween' }}
      >
        <StyledBackdrop>
          <H variant="h1" color="white">
            Merchants
          </H>
        </StyledBackdrop>
      </Flex>

      <Flex
        mt={searchTerm.length > 0 || categories.length > 0 ? 0 : -35}
        flexDirection="column"
        stretch
      >
        <MerchantSearchBar setSearchTerm={setSearchTerm} />
        {categories.length > 0 && (
          <CategoryFilter categories={categories} setCategories={setCategories} />
        )}
      </Flex>
      {searchTerm.length > 0 || categories.length > 0 ? (
        <SearchResults searchTerm={searchTerm} categories={categories} />
      ) : (
        <>
          {/* <Flex>
            <Spacer height={25} />
          </Flex>
          <MerchantCategories setCategories={setCategories} /> */}
          <Flex>
            <Spacer height={25} />
          </Flex>
          <FeaturedMerchants />
        </>
      )}
    </PageWrapper>
  )
}

export default Buy
