import create from 'zustand'

import {
  getOrder,
  getOrderDraft,
  getOrderOngoing,
  getOrderFullyRepaid,
  postFromCheckout,
  postFromOrderCampaign,
  postCancelOrder,
  postConfirmOrder,
  putPaymentMethod,
  postPayNext,
  postPayEarly,
  postOrderForStore,
  patchPaymentTransaction,
  postVoucher,
  deleteVoucher,
} from 'services'
import { error } from 'assets'

const useOrderStore = create((set, get) => ({
  isLoading: {
    order: false,
    draft: false,
    ongoing: false,
    fullyRepaid: false,
    fromCheckout: false,
    fromOrderCampaign: false,
    cancelOrder: false,
    confirmOrder: false,
    updatePaymentMethod: false,
    payNext: false,
    payEarly: false,
    orderForStore: false,
    updatePaymentTransaction: false,
    addVoucher: false,
    removeVoucher: false,
  },
  response: {
    order: null,
    draft: null,
    ongoing: null,
    fullyRepaid: null,
    fromCheckout: null,
    fromOrderCampaign: null,
    cancelOrder: null,
    confirmOrder: null,
    updatePaymentMethod: null,
    payNext: null,
    payEarly: null,
    orderForStore: null,
    updatePaymentTransaction: null,
    addVoucher: null,
    removeVoucher: null,
  },
  errors: {
    order: null,
    draft: null,
    ongoing: null,
    fullyRepaid: null,
    fromCheckout: null,
    fromOrderCampaign: null,
    cancelOrder: null,
    confirmOrder: null,
    updatePaymentMethod: null,
    payNext: null,
    payEarly: null,
    orderForStore: null,
    updatePaymentTransaction: null,
    addVoucher: null,
    removeVoucher: null,
  },
  resetStates: dataType => {
    set({
      isLoading: { ...get().isLoading, [dataType]: false },
      response: { ...get().response, [dataType]: null },
      errors: { ...get().errors, [dataType]: null },
    })
  },
  fetchOrder: async orderCode => {
    try {
      set({ isLoading: { ...get().isLoading, order: true } })
      const res = await getOrder(orderCode)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, order: false },
          response: { ...get().response, order: res.data },
          errors: { ...get().errors, order: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, order: false },
          response: { ...get().response, order: null },
          errors: { ...get().errors, order: res.data.errors },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, order: false },
        response: { ...get().response, order: null },
        errors: {
          ...get().errors,
          order: err && err.response && err.response.data,
        },
      })
    }
  },
  fetchOrderDraft: async countryCode => {
    try {
      set({ isLoading: { ...get().isLoading, draft: true } })
      const res = await getOrderDraft(countryCode)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, draft: false },
          response: { ...get().response, draft: res.data },
          errors: { ...get().errors, draft: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, draft: false },
          response: { ...get().response, draft: null },
          errors: { ...get().errors, draft: res.data.errors },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, draft: false },
        response: { ...get().response, draft: null },
        errors: { ...get().errors, draft: err },
      })
    }
  },
  fetchOrderOngoing: async countryCode => {
    try {
      set({ isLoading: { ...get().isLoading, ongoing: true } })
      const res = await getOrderOngoing(countryCode)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, ongoing: false },
          response: { ...get().response, ongoing: res.data },
          errors: { ...get().errors, ongoing: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, ongoing: false },
          response: { ...get().response, ongoing: null },
          errors: { ...get().errors, ongoing: res.data.errors },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, ongoing: false },
        response: { ...get().response, ongoing: null },
        errors: { ...get().errors, ongoing: err },
      })
    }
  },
  fetchOrderFullyRepaid: async countryCode => {
    try {
      set({ isLoading: { ...get().isLoading, fullyRepaid: true } })
      const res = await getOrderFullyRepaid(countryCode)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, fullyRepaid: false },
          response: { ...get().response, fullyRepaid: res.data },
          errors: { ...get().errors, fullyRepaid: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, fullyRepaid: false },
          response: { ...get().response, fullyRepaid: null },
          errors: { ...get().errors, fullyRepaid: res.data.errors },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, fullyRepaid: false },
        response: { ...get().response, fullyRepaid: null },
        errors: { ...get().errors, fullyRepaid: err },
      })
    }
  },
  submitFromCheckout: async checkoutId => {
    try {
      set({ isLoading: { ...get().isLoading, fromCheckout: true } })
      const res = await postFromCheckout(checkoutId)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, fromCheckout: false },
          response: { ...get().response, fromCheckout: res.data },
          errors: { ...get().errors, fromCheckout: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, fromCheckout: false },
          response: { ...get().response, fromCheckout: null },
          errors: {
            ...get().errors,
            fromCheckout: res.data.errors,
          },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, fromCheckout: false },
        response: { ...get().response, fromCheckout: null },
        errors: {
          ...get().errors,
          fromCheckout:
            (err && err.response && err.response.data.errors) || err.response.data.message,
        },
      })
    }
  },
  submitFromOrderCampaign: async orderCode => {
    try {
      set({ isLoading: { ...get().isLoading, fromOrderCampaign: true } })
      const res = await postFromOrderCampaign(orderCode)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, fromOrderCampaign: false },
          response: { ...get().response, fromOrderCampaign: res.data },
          errors: { ...get().errors, fromOrderCampaign: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, fromOrderCampaign: false },
          response: { ...get().response, fromOrderCampaign: null },
          errors: {
            ...get().errors,
            fromOrderCampaign: res.data.errors,
          },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, fromOrderCampaign: false },
        response: { ...get().response, fromOrderCampaign: null },
        errors: {
          ...get().errors,
          fromOrderCampaign: err.response.data.errors || err.response.data.message,
        },
      })
    }
  },
  submitCancelOrder: async orderCode => {
    try {
      set({ isLoading: { ...get().isLoading, cancelOrder: true } })
      const res = await postCancelOrder(orderCode)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, cancelOrder: false },
          response: { ...get().response, cancelOrder: res },
          errors: { ...get().errors, cancelOrder: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, cancelOrder: false },
          response: { ...get().response, cancelOrder: null },
          errors: { ...get().errors, cancelOrder: res.data.errors },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, cancelOrder: false },
        response: { ...get().response, cancelOrder: null },
        errors: {
          ...get().errors,
          cancelOrder: err && err.response && err.response.data.message,
        },
      })
    }
  },
  submitConfirmOrder: async (orderCode, paymentPlanId, paymentMethodId) => {
    try {
      set({ isLoading: { ...get().isLoading, confirmOrder: true } })
      const res = await postConfirmOrder(orderCode, paymentPlanId, paymentMethodId)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, confirmOrder: false },
          response: { ...get().response, confirmOrder: res },
          errors: { ...get().errors, confirmOrder: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, confirmOrder: false },
          response: { ...get().response, confirmOrder: null },
          errors: { ...get().errors, confirmOrder: res.data.errors },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, confirmOrder: false },
        response: { ...get().response, confirmOrder: null },
        errors: {
          ...get().errors,
          confirmOrder: err && err.response && err.response.data.message,
        },
      })
    }
  },
  updatePaymentMethod: async (orderCode, paymentMethodId) => {
    try {
      set({ isLoading: { ...get().isLoading, updatePaymentMethod: true } })
      const res = await putPaymentMethod(orderCode, paymentMethodId)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, updatePaymentMethod: false },
          response: { ...get().response, updatePaymentMethod: res },
          errors: { ...get().errors, updatePaymentMethod: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, updatePaymentMethod: false },
          response: { ...get().response, updatePaymentMethod: null },
          errors: { ...get().errors, updatePaymentMethod: res.errors },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, updatePaymentMethod: false },
        response: { ...get().response, updatePaymentMethod: null },
        errors: {
          ...get().errors,
          updatePaymentMethod: err && err.response && err.response.data.errors,
        },
      })
    }
  },
  submitPayNext: async (orderCode, paymentMethodId, repaymentId) => {
    try {
      set({ isLoading: { ...get().isLoading, payNext: true } })
      const res = await postPayNext(orderCode, paymentMethodId, repaymentId)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, payNext: false },
          response: { ...get().response, payNext: res },
          errors: { ...get().errors, payNext: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, payNext: false },
          response: { ...get().response, payNext: null },
          errors: { ...get().errors, payNext: res.data.errors },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, payNext: false },
        response: { ...get().response, payNext: null },
        errors: {
          ...get().errors,
          payNext: err && err.response && err.response.data.errors,
        },
      })
    }
  },
  submitPayEarly: async (orderCode, paymentMethodId, earlyRepaymentPercent) => {
    try {
      set({ isLoading: { ...get().isLoading, payEarly: true } })
      const res = await postPayEarly(orderCode, paymentMethodId, earlyRepaymentPercent)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, payEarly: false },
          response: { ...get().response, payEarly: res },
          errors: { ...get().errors, payEarly: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, payEarly: false },
          response: { ...get().response, payEarly: null },
          errors: { ...get().errors, payEarly: res.data.errors },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, payEarly: false },
        response: { ...get().response, payEarly: null },
        errors: {
          ...get().errors,
          payEarly: err && err.response && err.response.data.errors,
        },
      })
    }
  },
  submitOrderForStore: async (storeId, amount) => {
    try {
      set({ isLoading: { ...get().isLoading, orderForStore: true } })
      const res = await postOrderForStore(storeId, amount)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, orderForStore: false },
          response: { ...get().response, orderForStore: res.data },
          errors: { ...get().errors, orderForStore: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, orderForStore: false },
          response: { ...get().response, orderForStore: null },
          errors: {
            ...get().errors,
            orderForStore: res.data.errors,
          },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, orderForStore: false },
        response: { ...get().response, orderForStore: null },
        errors: {
          ...get().errors,
          orderForStore:
            (err && err.response && err.response.data.errors) || err.response.data.message,
        },
      })
    }
  },
  updatePaymentTransaction: async data => {
    try {
      set({
        isLoading: { ...get().isLoading, updatePaymentTransaction: true },
      })
      const res = await patchPaymentTransaction(data)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, updatePaymentTransaction: false },
          response: { ...get().response, updatePaymentTransaction: res.data },
          errors: { ...get().errors, updatePaymentTransaction: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, updatePaymentTransaction: false },
          response: { ...get().response, updatePaymentTransaction: null },
          errors: {
            ...get().errors,
            updatePaymentTransaction: res.data.errors,
          },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, updatePaymentTransaction: false },
        response: { ...get().response, updatePaymentTransaction: null },
        errors: {
          ...get().errors,
          updatePaymentTransaction: err && err.response && err.response.data.errors,
        },
      })
    }
  },
  submitVoucher: async (orderCode, voucherCode) => {
    try {
      set({ isLoading: { ...get().isLoading, addVoucher: true } })
      const res = await postVoucher(orderCode, voucherCode)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, addVoucher: false },
          response: { ...get().response, addVoucher: res.data },
          errors: { ...get().errors, addVoucher: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, addVoucher: false },
          response: { ...get().response, addVoucher: null },
          errors: {
            ...get().errors,
            addVoucher: res.data.errors,
          },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, addVoucher: false },
        response: { ...get().response, addVoucher: null },
        errors: {
          ...get().errors,
          addVoucher: (err && err.response && err.response.data.errors) || error.response.errors,
        },
      })
    }
  },
  removeVoucher: async (orderCode, voucherCode) => {
    try {
      set({ isLoading: { ...get().isLoading, removeVoucher: true } })
      const res = await deleteVoucher(orderCode, voucherCode)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, removeVoucher: false },
          response: { ...get().response, removeVoucher: res.data },
          errors: { ...get().errors, removeVoucher: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, removeVoucher: false },
          response: { ...get().response, removeVoucher: null },
          errors: {
            ...get().errors,
            removeVoucher: res.data.errors,
          },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, removeVoucher: false },
        response: { ...get().response, removeVoucher: null },
        errors: {
          ...get().errors,
          removeVoucher: (err && err.response && err.response.data.errors) || error.response.errors,
        },
      })
    }
  },
}))

export default useOrderStore
