import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'

import { RoundedContainer, Flex, Paragraph as P, Spacer } from 'components'
import { theme } from 'styles/theme'

import UpcomingPaymentRow from './UpcomingPaymentRow/UpcomingPaymentRow'
import LoadingUpcomingPayments from './LoadingUpcomingPayments/LoadingUpcomingPayments'
import { logUserInteractionGA } from 'utils'

const UpcomingPaymentsContainer = ({
  title,
  showTopNavigation,
  boxShadow,
  data,
  isLoading,
  variant,
  ...rest
}) => {
  const history = useHistory()
  const { repayments } = data

  // *JSX
  if (isLoading) return <LoadingUpcomingPayments />

  return (
    <RoundedContainer
      // mt={25}
      ml={0}
      mr={0}
      width="100%"
      boxShadow={boxShadow}
      {...rest}
    >
      <Flex flexDirection="column">
        <Flex justifyContent="space-between" width="100%">
          <P color={theme.colors.typography.gray1} style={{ fontWeight: 700 }}>
            {title}
          </P>
          {showTopNavigation && (
            <P
              color={theme.colors.abnkBlue}
              style={{ fontWeight: 700 }}
              onClick={() => {
                logUserInteractionGA('Click: View All Upcoming Payments')
                history.push('/order/due')
              }}
            >
              View all
            </P>
          )}
        </Flex>

        <Spacer height={10} />

        {repayments.map((repayment, i) => {
          const orderCode = repayment.order.code
          const repaymentAmount = repayment.total_amount
          const totalAmount = repayment.order.repayments_total_mount
          const remainingAmount = repayment.order.repayments_remaining_amount
          const paidAmount = parseFloat(totalAmount) - parseFloat(remainingAmount)
          const logo = repayment.order.merchant.logo
          const merchantName = repayment.order.store.name
          const dueAt = repayment.due_at
          const canPay = repayment.can_pay
          const repaymentState = repayment.state

          return (
            <UpcomingPaymentRow
              key={i}
              orderCode={orderCode}
              percentageShort={paidAmount}
              percentageLong={parseFloat(paidAmount) + parseFloat(repaymentAmount)}
              percentageTotal={totalAmount}
              logo={logo}
              merchantName={merchantName}
              dueAt={dueAt}
              amount={repaymentAmount}
              showAmountAsButton={variant === 'full' ? canPay : false}
              repaymentState={repaymentState}
            />
          )
        })}
      </Flex>
    </RoundedContainer>
  )
}

UpcomingPaymentsContainer.propTypes = {
  title: PropTypes.string.isRequired,
  showTopNavigation: PropTypes.bool,
  showAmountAsButton: PropTypes.bool,

  boxShadow: PropTypes.string,
}

UpcomingPaymentsContainer.defaultProps = {
  showTopNavigation: false,
  showAmountAsButton: false,

  boxShadow: '',
}

export default UpcomingPaymentsContainer
