import React from 'react'
import PropTypes from 'prop-types'

import { RoundedContainer, Image } from 'components'
import { transparent } from 'assets'

const BrandContainer = ({ brandLogo, size, ...rest }) => {
  return (
    <RoundedContainer
      height={size}
      width={size}
      justifyContent="center"
      boxShadow="0px 2px 7px 0px #00000019"
      borderRadius={12}
      p={2}
      {...rest}
    >
      <Image src={brandLogo || transparent} alt="merchant_logo" width="100%" />
    </RoundedContainer>
  )
}

BrandContainer.propTypes = {
  brandLogo: PropTypes.node.isRequired,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

BrandContainer.defaultProps = {
  size: 60,
}

export default BrandContainer
