import React, { useEffect, useState } from 'react'

import { Image } from 'components'
import {
  successCircleSolid,
  successCircleRed,
  successCircleActive,
  successCircleInactive,
  successCircleInactiveSolid,
} from 'assets'

const ScheduleRowCheckIcon = ({ paymentStatus, isCanPay, isToday, isNormalSchedule }) => {
  const [icon, setIcon] = useState(successCircleInactive)

  // *Methods
  const renderCheckIcon = () => {
    if (isToday || isCanPay) {
      if (paymentStatus === 'collect_success') return setIcon(successCircleSolid)
      if (paymentStatus === 'overdue' && isNormalSchedule) return setIcon(successCircleRed)
      return setIcon(successCircleActive)
    }

    switch (paymentStatus) {
      case 'collect_success':
        return setIcon(successCircleSolid)
      case 'overdue':
        return setIcon(successCircleRed)
      case 'deactivated':
        return setIcon(successCircleInactiveSolid)
      case 'created':
        return setIcon(successCircleInactive)
      default:
        return setIcon(successCircleInactive)
    }
  }

  //   *Effects
  useEffect(() => {
    renderCheckIcon()
  }, [paymentStatus, isToday, isCanPay])

  //   *JSX
  return <Image src={icon} alt="payment_status" style={{ zIndex: 1 }} />
}

export default ScheduleRowCheckIcon
