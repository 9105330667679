import React, { useState } from 'react'

import { Flex, Heading as H, Paragraph as P, Spacer, Image, Button, Footer } from 'components'
import { theme } from 'styles/theme'
import { caretDownBlack } from 'assets'

import StoreLocationRow from './StoreLocationRow.js/StoreLocationRow'
import { logUserInteractionGA } from 'utils'

const MerchantDetails = ({ merchant }) => {
  const { categories, name, highlighted_products, stores, website, description } = merchant

  const [showStoreLocations, setShowStoreLocations] = useState(false)

  return (
    <Flex my={3} mx={4} pb={100} flexDirection="column" style={{ height: '100%' }}>
      {categories.length > 0 &&
        categories.map(category => (
          <H variant="h4" color={theme.colors.tahitiGold} key={category.name}>
            {category.name}
          </H>
        ))}
      <H variant="h1">{name}</H>
      <Spacer height={10} />

      <P color={theme.colors.typography.gray1}>{description}</P>
      <Spacer height={20} />

      {highlighted_products.length > 0 && (
        <>
          <H variant="h4">Highlighted Product</H>
          {highlighted_products.map(product => {
            return (
              <P key={product.name} color={theme.colors.typography.gray1}>
                {product.name}
              </P>
            )
          })}
          <Spacer height={20} />
        </>
      )}

      {/* Hide locations for now as some stores are used to track for transactions and not actual locations */}
      {false && stores.length > 0 && (
        <Flex flexDirection="column">
          <Flex alignItems="center" onClick={() => setShowStoreLocations(!showStoreLocations)}>
            <H variant="h4">Store Locations</H>
            <Spacer width={10} />
            <Image
              src={caretDownBlack}
              alt="expand_stores"
              initial={{
                rotate: showStoreLocations ? 0 : 180,
                marginBottom: showStoreLocations ? 0 : 5,
              }}
              animate={{
                rotate: showStoreLocations ? 180 : 0,
                marginBottom: showStoreLocations ? 0 : 5,
              }}
            />
          </Flex>

          {showStoreLocations && (
            <Flex flexDirection="column">
              <Spacer height={10} />
              {stores.map(store => (
                <StoreLocationRow store={store} key={store.external_id} />
              ))}
            </Flex>
          )}
        </Flex>
      )}

      {website && (
        <Footer style={{ zIndex: 21, backgroundColor: 'white' }}>
          <Button
            variant="secondary"
            onClick={() => {
              logUserInteractionGA(`Click: Visit Website ${website}`)
              window.open(website, '_blank')
            }}
          >
            VISIT WEBSITE
          </Button>
        </Footer>
      )}
    </Flex>
  )
}

export default MerchantDetails
