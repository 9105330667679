import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { useStripe } from '@stripe/react-stripe-js'

import {
  PageWrapper,
  NavigateBack,
  Flex,
  Paragraph as P,
  Link,
  Spacer,
  Footer,
  Button,
  PaymentMethodDropdown,
  PaymentMethodDropdownLoading,
  TriggerPopup,
  Image,
} from 'components'
import {
  getCountryCodeLocalDb,
  formatAsCurrency,
  getDraftOrderLocalDb,
  pushTransctionHistory,
  getPaymentPlanLocalDb,
  removeDraftOrderLocalDb,
  setPaymentTransactionLocalDb,
  logUserInteractionGA,
  findTosUrl,
  removeBackToMerchantLink,
} from 'utils'
import { useOrderStore } from 'stores'
import { successCircleGray } from 'assets'
import { theme } from 'styles'
import { useOrderEligibilityCheck } from 'hooks'

import PaymentHeader from '../PaymentHeader/PaymentHeader'
import PaymentDetails from './PaymentDetails/PaymentDetails'

const ConfirmOrder = () => {
  const history = useHistory()
  const stripe = useStripe()
  const countryCode = getCountryCodeLocalDb()
  const draftOrder = getDraftOrderLocalDb()
  const selectedPlan = getPaymentPlanLocalDb()
  const { creditsIsLoading, eligibleForOrder } = useOrderEligibilityCheck()

  const tosUrl = findTosUrl(countryCode)

  // capture if user came from adding payment method
  const location = window.location.search
  const useNewPaymentMethod = new URLSearchParams(location).get('use_new_payment_method')

  const [updatedPaymentMethods, setUpdatedPaymentMethods] = useState(null)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null)
  const [selectedPaymentMethodError, setSelectedPaymentMethodError] = useState('')
  const [selectedPaymentMethodWarning, setSelectedPaymentMethodWarning] = useState('')
  const [paymentFailed, setPaymentFailed] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [agreeToTerms, setAgreeToTerms] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  // *Stores
  const {
    response: {
      order: orderResponse,
      confirmOrder: confirmOrderResponse,
      updatePaymentTransaction: updatePaymentTransactionResponse,
    },
    errors: { confirmOrder: confirmOrderError },
    resetStates: resetStatesOrder,
    fetchOrder,
    submitConfirmOrder,
    updatePaymentTransaction,
  } = useOrderStore()

  // *Methods
  const handleClearErrors = () => {
    setIsLoading(false)
    setPaymentFailed(false)
    setErrorMessage('')
    resetStatesOrder('confirmOrder')
    resetStatesOrder('updatePaymentTransaction')
  }

  const handleConfirmOrder = () => {
    const orderCode = draftOrder.code
    const paymentPlanId = selectedPlan.payment_plan_def_id
    const paymentMethodId = selectedPaymentMethod.id

    logUserInteractionGA(`Payment - Confirm Order`, { order: orderCode })
    setIsLoading(true)
    submitConfirmOrder(orderCode, paymentPlanId, paymentMethodId)
  }

  const handleStripeActionRequired = async () => {
    const stripeData = confirmOrderResponse.data.repayment.payment_transaction.stripe
    const { client_secret, payment_method_id } = stripeData

    const confirmCardPaymentResponse = await stripe.confirmCardPayment(client_secret, {
      payment_method: payment_method_id,
    })

    // handle authentication error
    if (
      confirmCardPaymentResponse &&
      confirmCardPaymentResponse.error &&
      confirmCardPaymentResponse.error.message &&
      confirmCardPaymentResponse.error.payment_intent &&
      confirmCardPaymentResponse.error.payment_intent.id
    ) {
      setIsLoading(false)
      const paymentIntentId = confirmCardPaymentResponse.error.payment_intent.id

      updatePaymentTransaction({
        third_party_name: 'stripe',
        stripe: {
          country: countryCode,
          payment_intent_id: paymentIntentId,
        },
      })
      return setErrorMessage(confirmCardPaymentResponse.error.message)
    }

    // handle authentication success
    if (
      confirmCardPaymentResponse &&
      confirmCardPaymentResponse.paymentIntent &&
      confirmCardPaymentResponse.paymentIntent.id
    ) {
      const paymentIntentId = confirmCardPaymentResponse.paymentIntent.id
      updatePaymentTransaction({
        third_party_name: 'stripe',
        stripe: {
          country: countryCode,
          payment_intent_id: paymentIntentId,
        },
      })
    }
  }

  // *Effects
  useEffect(() => {
    const orderCode = draftOrder.code
    fetchOrder(orderCode)

    return () => {
      resetStatesOrder('confirmOrder')
    }
  }, [])

  useEffect(() => {
    if (errorMessage) {
      logUserInteractionGA(`Error - ${errorMessage}`)
    }
  }, [errorMessage])

  useEffect(() => {
    if (orderResponse && orderResponse.available_payment_plans) {
      const plan = orderResponse.available_payment_plans.find(
        plan => plan.payment_plan_def_id === selectedPlan.payment_plan_def_id,
      )
      if (plan) setUpdatedPaymentMethods(plan.collection_payment_methods)
    }
  }, [orderResponse])

  useEffect(() => {
    if (confirmOrderResponse && confirmOrderResponse.data && confirmOrderResponse.success) {
      const orderState = confirmOrderResponse.data.order.state
      if (orderState === 'approved' || orderState === 'fully_repaid') {
        const orderCode = draftOrder.code

        setPaymentTransactionLocalDb(confirmOrderResponse.data)
        removeDraftOrderLocalDb()

        resetStatesOrder('confirmOrder')
        removeBackToMerchantLink()

        if (confirmOrderResponse.data.redirect_url) {
          pushTransctionHistory(history, orderCode)
          window.location.href = confirmOrderResponse.data.redirect_url
          return
        }

        pushTransctionHistory(history, orderCode)
        history.push('/order/success')
        return
      } else if (
        confirmOrderResponse.data.repayment.payment_transaction.status === 'require_action'
      ) {
        handleStripeActionRequired()
      } else {
        setIsLoading(false)
        setPaymentFailed(true)
        if (confirmOrderResponse.data.repayment.payment_transaction.failed_reason) {
          setErrorMessage(
            `An error occurred when making the payment. Reason: ${confirmOrderResponse.data.repayment.payment_transaction.failed_reason}`,
          )
        } else {
          setErrorMessage('An error occurred when making the payment. Please try again.')
        }
        return
      }
    }
  }, [confirmOrderResponse])

  useEffect(() => {
    if (
      updatePaymentTransactionResponse &&
      updatePaymentTransactionResponse.repayment &&
      updatePaymentTransactionResponse.repayment.payment_transaction &&
      updatePaymentTransactionResponse.repayment.payment_transaction.status
    ) {
      if (updatePaymentTransactionResponse.repayment.payment_transaction.status === 'success') {
        const orderCode = draftOrder.code

        setPaymentTransactionLocalDb(updatePaymentTransactionResponse)
        removeDraftOrderLocalDb()
        resetStatesOrder('confirmOrder')
        resetStatesOrder('updatePaymentTransaction')
        removeBackToMerchantLink()

        if (updatePaymentTransactionResponse.redirect_url) {
          pushTransctionHistory(history, orderCode)
          window.location.href = updatePaymentTransactionResponse.redirect_url
          return
        }

        pushTransctionHistory(history, orderCode)
        history.push('/order/success')
        return
      } else if (updatePaymentTransactionResponse.repayment.payment_transaction.failed_reason) {
        setErrorMessage(
          `An error occurred when making the payment. Reason: ${updatePaymentTransactionResponse.repayment.payment_transaction.failed_reason}`,
        )
      }
    }
  }, [updatePaymentTransactionResponse])

  // *JSX
  return (
    <>
      {/* Error handling */}
      <TriggerPopup
        showPopup={!!confirmOrderError}
        message={confirmOrderError}
        callback={() => resetStatesOrder('confirmOrder')}
      />

      <TriggerPopup
        showPopup={paymentFailed || !!errorMessage}
        message={errorMessage || 'An error occurred when making the payment. Please try again.'}
        buttonText="OKAY"
        stayAtCurrentPage
        callback={handleClearErrors}
      />

      <PageWrapper withTopNav>
        <NavigateBack noBackground />
        <Spacer height={10} />

        <PaymentHeader initial={2} current={3} total={3} />

        <Flex mt={100} mb={130} flexDirection="column" width="100%">
          <P style={{ fontWeight: 700 }}>{`Step 3 of 3: ${
            selectedPlan && selectedPlan.instalment_frequency === 'end_of_month'
              ? 'Confirm Payment Plan'
              : "Make today's payment"
          }`}</P>
          <Spacer height={10} />

          <PaymentDetails />

          <Spacer height={20} />
          <P style={{ fontWeight: 700 }}>Payment method</P>

          <Spacer height={10} />
          {selectedPlan && selectedPlan.collection_payment_methods && updatedPaymentMethods ? (
            <PaymentMethodDropdown
              paymentMethods={updatedPaymentMethods}
              setSelectedPaymentMethod={setSelectedPaymentMethod}
              selectedPaymentMethodWarning={selectedPaymentMethodWarning}
              setSelectedPaymentMethodWarning={setSelectedPaymentMethodWarning}
              selectedPaymentMethodError={selectedPaymentMethodError}
              setSelectedPaymentMethodError={setSelectedPaymentMethodError}
              saveCurrentUrl
              useNewPaymentMethod={useNewPaymentMethod}
              stayAtCurrentPageAfterClearingError
            />
          ) : (
            <PaymentMethodDropdownLoading />
          )}
          <Spacer height={5} />

          {updatedPaymentMethods && updatedPaymentMethods.length > 0 && (
            <Flex justifyContent="flex-start">
              {agreeToTerms ? (
                <Image
                  onClick={() => setAgreeToTerms(!agreeToTerms)}
                  src={successCircleGray}
                  alt="tick"
                  width="14px"
                />
              ) : (
                <div
                  onClick={() => setAgreeToTerms(!agreeToTerms)}
                  style={{
                    width: 14,
                    height: 14,
                    margin: 'auto',
                    marginLeft: 0,
                    marginRight: 0,
                    border: `1px solid ${theme.colors.lockGray}`,
                    borderRadius: '50%',
                  }}
                />
              )}
              <Spacer width={5} />
              <P color={theme.colors.lockGray} style={{ fontSize: 10 }}>
                I agree to the terms in Abnk&apos;s{' '}
                <Link href={tosUrl} target="_blank">
                  Terms of Service
                </Link>
              </P>
            </Flex>
          )}
        </Flex>

        <Footer withTopBorder flexDirection="column">
          <Spacer height={8} />
          {selectedPlan && selectedPlan.repayments && selectedPlan.repayments.length > 0 && (
            <Button
              onClick={handleConfirmOrder}
              isLoading={isLoading || creditsIsLoading}
              disabled={
                !agreeToTerms ||
                !updatedPaymentMethods ||
                updatedPaymentMethods.length === 0 ||
                !!selectedPaymentMethodError ||
                !eligibleForOrder
              }
            >
              {selectedPlan.instalment_frequency === 'end_of_month'
                ? 'CONFIRM'
                : `PAY ${formatAsCurrency(selectedPlan.repayments[0].total_amount)}`}
            </Button>
          )}
        </Footer>
      </PageWrapper>
    </>
  )
}

export default ConfirmOrder
