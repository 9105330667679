import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'

import { faq } from 'contents'
import { Flex, BasicAccordion, Paragraph as P, Span, Spacer, NoResults } from 'components'
import { theme } from 'styles/theme'

const FAQSearchResults = ({ searchTerm }) => {
  const history = useHistory()
  const [searchResults, setSearchResults] = useState([])
  const [noResults, setNoResults] = useState(false)

  // *Effects
  useEffect(() => {
    if (searchTerm && searchTerm.length > 0) {
      setNoResults(false)

      const filteredResults = []
      faq.forEach(category => {
        category.items.forEach(item => {
          const { question, answer } = item
          if (
            question.toLowerCase().search(searchTerm.toLowerCase()) > -1 ||
            answer.toLowerCase().search(searchTerm.toLowerCase()) > -1
          ) {
            filteredResults.push(item)
          }
        })
      })

      if (filteredResults.length === 0) setNoResults(true)

      setSearchResults(filteredResults)
    }
  }, [searchTerm])

  // *Methods
  const handleClickContactUs = () => {
    history.push('/contactus')
  }

  const renderContactUs = () => {
    return (
      <P
        variant="small"
        color={theme.colors.typography.gray1}
        textAlign="center"
        style={{ fontWeight: 500 }}
      >
        Don&apos;t see your question here?{' '}
        <Span color={theme.colors.buttons.marineBlue} onClick={handleClickContactUs}>
          Contact us
        </Span>
      </P>
    )
  }

  // *JSX
  if (noResults) {
    return <NoResults title="No matching results" actionItem={renderContactUs()} />
  }

  return (
    <Flex flexDirection="column" fitWidth>
      {searchResults.map(result => (
        <BasicAccordion
          title={result.question}
          content={result.answer}
          highlight={searchTerm}
          initial={{ opacity: 0, y: -100 }}
          animate={{ opacity: 1, y: 0 }}
          key={result.question}
        />
      ))}
      <Spacer height={10} />

      {renderContactUs()}
    </Flex>
  )
}

export default FAQSearchResults
