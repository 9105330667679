import { useEffect } from 'react'
import { useLocation } from 'react-router'

const useTrackingFBPixel = () => {
  const location = useLocation()

  useEffect(() => {
    if (!window.fbq) {
      console.warn('Facebook Pixel tracking is not available')
      return
    }
    window.fbq('track', 'PageView')
  }, [location])
}

export default useTrackingFBPixel
