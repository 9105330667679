import React, { useEffect } from 'react'

import { useOrderStore } from 'stores'
import { NoTransactions, Spacer } from 'components'
import { getCountryCodeLocalDb } from 'utils'

import TransactionLoading from '../TransactionLoading/TransactionLoading'
import TransactionRow from '../TransactionRow/TransactionRow'

const OngoingTransactions = () => {
  const countryCode = getCountryCodeLocalDb()

  const {
    response: { ongoing: ongoingResponse },
    fetchOrderOngoing,
  } = useOrderStore()

  // *Effects
  useEffect(() => {
    fetchOrderOngoing(countryCode)
  }, [])

  // *JSX
  if (!ongoingResponse) return <TransactionLoading />

  if (ongoingResponse && ongoingResponse.orders && ongoingResponse.orders.length === 0) {
    return (
      <NoTransactions
        title="No ongoing transaction"
        content="Make your first purchase by exploring our partnership merchants!"
      />
    )
  }

  return (
    <>
      <Spacer height={20} />
      {ongoingResponse.orders.map(order => {
        return <TransactionRow order={order} type="ongoing" key={order.code} />
      })}
    </>
  )
}

export default OngoingTransactions
