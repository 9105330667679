import React from 'react'
import { useHistory } from 'react-router'

import { RoundedContainer, Flex, CircularProgress, Paragraph as P, Span } from 'components'
import { formatAsCurrency, advancedDayjs, logUserInteractionGA } from 'utils'
import { theme } from 'styles/theme'

const TransactionRow = ({ order, type }) => {
  const history = useHistory()

  const {
    merchant,
    store,
    code,
    repayments_remaining_amount,
    repayments_total_mount,
    repayments_fully_paid_at,
  } = order

  return (
    <RoundedContainer
      p={15}
      boxShadow="0px 2px 20px 0px #00000019"
      onClick={() => {
        logUserInteractionGA(`Click: Transaction`, { id: code })
        history.push(`/transaction/order?code=${code}&type=payment`)
      }}
    >
      <Flex alignItems="center" overflow="hidden">
        <CircularProgress
          strokeWidth="5"
          sqSize="58"
          invisiblePercentageLong
          percentageTotal={repayments_total_mount}
          percentageLong={repayments_total_mount}
          percentageShort={repayments_remaining_amount}
          logo={merchant && merchant.logo}
          invert
        />
        <Flex overflow="hidden" alignItems="center" flex="1" justifyContent="space-between">
          <Flex flexDirection="column" overflow="hidden" ml={3}>
            <P
              style={{
                fontWeight: 700,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {store.name}
            </P>
            {type === 'ongoing' ? (
              <P variant="small" color={theme.colors.typography.gray7} style={{ fontWeight: 500 }}>
                <Span color="black" style={{ fontSize: 16, fontWeight: 600 }}>
                  {formatAsCurrency(repayments_remaining_amount)}
                </Span>{' '}
                of {formatAsCurrency(repayments_total_mount)} remaining
              </P>
            ) : (
              <P
                variant="small"
                color={theme.colors.typography.gray7}
                style={{ fontWeight: 500, whiteSpace: 'initial' }}
              >
                Completed {advancedDayjs(repayments_fully_paid_at).format('Do MMM YYYY')}
              </P>
            )}
          </Flex>
          {type === 'completed' && (
            <Flex flex="1" ml="5px">
              <P style={{ fontWeight: 700 }}>{formatAsCurrency(repayments_total_mount)}</P>
            </Flex>
          )}
        </Flex>
      </Flex>
    </RoundedContainer>
  )
}

export default TransactionRow
