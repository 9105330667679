import React, { useState, useEffect } from 'react'
import isEmpty from 'lodash/isEmpty'

import { NavigateBack, PageWrapper, Flex, NoResults } from 'components'
import { theme } from 'styles/theme'
import { useBuyerStore } from 'stores'
import { getCountryCodeLocalDb, groupNotificationsIntoDays, logUserInteractionGA } from 'utils'

import NotificationDayContainer from './NotificationDayContainer/NotificationDayContainer'
import NotificationLoading from './NotificationLoading'
import MarkAllAsRead from './MarkAllAsRead/MarkAllAsRead'

const Notification = () => {
  const countryCode = getCountryCodeLocalDb()

  const [groupedNotifications, setGroupedNotifications] = useState(null)
  const [markReadAll, setMarkReadAll] = useState(false)

  // *Stores
  const {
    response: { notifications: notificationsResponse },
    resetStates: resetStatesBuyer,
    fetchNotifications,
    submitNotificationReadAll,
  } = useBuyerStore()

  // *Methods
  const handleReadAll = () => {
    logUserInteractionGA('Click: Mark All as Read')
    submitNotificationReadAll()
    setMarkReadAll(true)
  }

  // *Effects
  useEffect(() => {
    fetchNotifications(countryCode)

    return () => resetStatesBuyer('notifications')
  }, [])

  useEffect(() => {
    if (notificationsResponse && notificationsResponse.notifications) {
      const grouped = groupNotificationsIntoDays(notificationsResponse.notifications)
      setGroupedNotifications(grouped)
    }
  }, [notificationsResponse])

  // *JSX
  return (
    <PageWrapper
      withTopNav
      style={{
        backgroundColor: theme.colors.background,
        height: '100vh',
      }}
    >
      <NavigateBack rightComponent={<MarkAllAsRead handleClick={handleReadAll} />}>
        Notifications
      </NavigateBack>
      {groupedNotifications && isEmpty(groupedNotifications) && (
        <NoResults title="No notifications found" />
      )}
      <Flex flexDirection="column" mb={4}>
        {groupedNotifications ? (
          Object.keys(groupedNotifications).map(date => (
            <NotificationDayContainer
              date={date}
              notifications={groupedNotifications[date]}
              key={date}
              markReadAll={markReadAll}
            />
          ))
        ) : (
          <>
            <NotificationLoading />
            <NotificationLoading />
            <NotificationLoading />
          </>
        )}
      </Flex>
    </PageWrapper>
  )
}

export default Notification
