import {
  clearLocalDb,
  removeBackToMerchantLink,
  setBackToMerchantLink,
  setCheckoutIdLocalDb,
  setReferredCodeLocalDb,
  setStoreIdLocalDb,
} from 'utils'
import { BASE_BACKEND_URL } from 'const'

export const captureIncomingQrCode = () => {
  // capture code or id if buyer is coming from qr code
  const location = window.location.search
  const referredCode = new URLSearchParams(location).get('order_campaign_referred_code')
  const checkoutId = new URLSearchParams(location).get('checkout_id')
  const storeId = new URLSearchParams(location).get('store_id')
  const cancelRedirect = new URLSearchParams(location).get('cancel_redirect')

  if (checkoutId) {
    clearLocalDb()
    setCheckoutIdLocalDb(checkoutId)
    if (cancelRedirect) {
      setBackToMerchantLink(`${BASE_BACKEND_URL}/v2/magic/ckt/${checkoutId}/cancelredirect/`)
    } else {
      removeBackToMerchantLink()
    }
  }

  if (referredCode) {
    clearLocalDb()
    setReferredCodeLocalDb(referredCode)
    removeBackToMerchantLink()
  }

  if (storeId) {
    clearLocalDb()
    setStoreIdLocalDb(storeId)
    removeBackToMerchantLink()
  }

  return { checkoutId }
}
