import styled from 'styled-components'

export const StyledWrapper = styled.div`
  display: block;
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  max-width: 450px;
  margin: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`
