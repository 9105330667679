import React from 'react'
import PropTypes from 'prop-types'

import { FormLabel, FormError, Flex } from 'components'

import { StyledInput, StyledIcon, StyledRightIcon } from './formInputStyle'

const FormInput = ({
  variant,
  showLabel,
  label,
  doNotCapitalize = false,
  id,
  register,
  required,
  validationRules,
  error,
  placeholder,
  inputIcon,
  onClearInput,
  showRightIcon,
  rightIcon,
  ...rest
}) => {
  return (
    <Flex flexDirection="column" stretch>
      {showLabel && (
        <FormLabel doNotCapitalize={doNotCapitalize}>
          {label}
          {required && <span style={{ color: 'red' }}>*</span>}
        </FormLabel>
      )}
      <StyledIcon inputIcon={inputIcon}>
        <StyledInput
          {...register(id, { required, ...validationRules })}
          variant={variant}
          placeholder={placeholder}
          inputIcon={inputIcon}
          error={error}
          {...rest}
        />
        {showRightIcon && <StyledRightIcon onClick={onClearInput} icon={rightIcon} />}
      </StyledIcon>
      {error && (
        <FormError>
          {error.type === 'required' ? 'This field is required' : error.message}
        </FormError>
      )}
    </Flex>
  )
}

FormInput.propTypes = {
  variant: PropTypes.string,
  showLabel: PropTypes.bool,
  label: PropTypes.string,
  register: PropTypes.func.isRequired,
  required: PropTypes.bool,
  error: PropTypes.object,
  placeholder: PropTypes.string,
  inputIcon: PropTypes.string,
}

export default FormInput
