import React from 'react'

import { clearInput } from 'assets'
import { Flex, Paragraph as P, Image, Spacer } from 'components'
import { theme } from 'styles/theme'

import { StyledCategoryItem } from './categoryFilterStyle'

const CategoryFilter = ({ categories, setCategories }) => {
  const handleDeleteCategory = deletedCategory => {
    const newCategories = categories.filter(
      category => category.external_id !== deletedCategory.external_id,
    )
    setCategories(newCategories)
  }

  return (
    <Flex mt={3}>
      {categories &&
        categories.map(category => {
          return (
            <StyledCategoryItem key={category.name}>
              <P variant="small" color={theme.colors.charcoal} style={{ fontWeight: 600 }}>
                {category.name}
              </P>
              <Spacer width={5} />
              <Image
                src={clearInput}
                alt="clear_filter"
                width={12}
                onClick={() => handleDeleteCategory(category)}
              />
            </StyledCategoryItem>
          )
        })}
    </Flex>
  )
}

export default CategoryFilter
