import React from 'react'
import PropTypes from 'prop-types'

import { Flex } from 'components'

const RoundedContainerItem = ({ left, middleTop, middleBottom, right, ...rest }) => {
  return (
    <Flex py={10} justifyContent="center" {...rest}>
      <Flex minWidth={50} centered>
        {left}
      </Flex>
      <Flex flexDirection="column" justifyContent="center" pl={10}>
        {middleTop}
        {middleBottom}
      </Flex>
      <Flex marginLeft="auto">{right}</Flex>
    </Flex>
  )
}

RoundedContainerItem.propTypes = {
  left: PropTypes.node.isRequired,
  middleTop: PropTypes.node.isRequired,
  middleBottom: PropTypes.node.isRequired,
  right: PropTypes.node,
}

export default RoundedContainerItem
