import React from 'react'
import PropTypes from 'prop-types'

import { StyledRoundedContainer } from './roundedContainerStyle'

const RoundedContainer = ({ variant, children, boxShadow, fitWidth, ...rest }) => {
  return (
    <StyledRoundedContainer boxShadow={boxShadow} fitWidth={fitWidth} variant={variant} {...rest}>
      {children}
    </StyledRoundedContainer>
  )
}

RoundedContainer.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.node,
  boxShadow: PropTypes.string,
  fitWith: PropTypes.bool,
  clickable: PropTypes.bool,
}

RoundedContainer.defaultProps = {
  variant: 'roundall',
  clickable: false,
  fitWidth: false,
}

export default RoundedContainer
