import React from 'react'

import { RoundedContainer, Image } from 'components'
import { dashedLine } from 'assets'

import DetailRow from './DetailRow/DetailRow'
import { getPaymentPlanLocalDb } from 'utils'

const PaymentDetails = () => {
  const selectedPlan = getPaymentPlanLocalDb()

  return (
    <RoundedContainer boxShadow="0px 2px 6px 0px #0000002A" borderRadius={12}>
      {selectedPlan &&
      selectedPlan.instalment_frequency === 'end_of_month' &&
      selectedPlan.repayments &&
      selectedPlan.repayments.length === 2 ? (
        <>
          <DetailRow title="Today's Payment" amount={selectedPlan.repayments[0].total_amount} />
          <DetailRow title="Pay in 1 month" amount={selectedPlan.repayments[1].total_amount} />
        </>
      ) : (
        selectedPlan &&
        selectedPlan.repayments &&
        selectedPlan.repayments.length > 0 &&
        selectedPlan.downpayment_amount &&
        selectedPlan.instalment_amount &&
        selectedPlan.instalment_processing_fee_amount && (
          <>
            <DetailRow
              title={'Downpayment'}
              amount={selectedPlan.repayments[0].downpayment_amount}
            />
            <DetailRow
              title={'1st instalment amount'}
              amount={selectedPlan.repayments[0].instalment_amount}
            />

            {selectedPlan.repayments[0].instalment_processing_fee_amount !== '0.00' && (
              <DetailRow
                title={'Monthly processing fee'}
                amount={selectedPlan.repayments[0].instalment_processing_fee_amount}
              />
            )}

            <Image src={dashedLine} alt="dashed_line" style={{ margin: '10px 0' }} />
            <DetailRow
              title={"Today's payment"}
              amount={selectedPlan.repayments[0].total_amount}
              style={{ marginBottom: 0 }}
            />
          </>
        )
      )}
    </RoundedContainer>
  )
}

export default PaymentDetails
