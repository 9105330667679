import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { RoundedContainer, Flex, Paragraph as P, Spacer } from 'components'

const PaymentPlanCardLoading = () => {
  return (
    <>
      <RoundedContainer
        borderRadius={12}
        boxShadow="0px 2px 6px 0px #0000002A"
        py={20}
        my={12}
        border="none"
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Flex alignItems="flex-end">
            <Skeleton height={35} width={100} />/
            <Skeleton height={23} width={30} />
          </Flex>
          <Skeleton height={23} width={77} />
        </Flex>
        <Spacer height={10} />
        <Flex justifyContent="space-between">
          <Flex flexDirection="column" mr={15}>
            <P variant="subcopy" style={{ fontWeight: 500 }}>
              Downpayment
            </P>
            <Skeleton height={17} width={90} />
          </Flex>
          <Flex flexDirection="column" mr={15}>
            <P variant="subcopy" style={{ fontWeight: 500 }}>
              Processing fee
            </P>
            <Skeleton height={17} width={90} />
          </Flex>
          <Flex flexDirection="column">
            <P variant="subcopy" style={{ fontWeight: 500 }}>
              Total
            </P>
            <Skeleton height={17} width={60} />
          </Flex>
        </Flex>
      </RoundedContainer>
    </>
  )
}

export default PaymentPlanCardLoading
