import React from 'react'
import PropTypes from 'prop-types'

import { Paragraph as P, Heading as H, Image } from 'components'
import { theme } from 'styles/theme'
import { lock } from 'assets'

import { AnimatedContainer, StyledContainer, StyledNumbering } from './taskItemStyle'

const TaskItem = ({ animationDelay, step, header, content, status }) => {
  if (status === 'completed') {
    return (
      <AnimatedContainer
        initial={{ x: '100vw' }}
        animate={{ x: 0 }}
        transition={{ delay: animationDelay, duration: 0.5 }}
      >
        <StyledNumbering
          backgroundColor={theme.colors.progressbar.barGreen1}
          borderColor={theme.colors.progressbar.barGreen1}
        >
          <H variant="h3" color="white">
            {step}
          </H>
        </StyledNumbering>
        <StyledContainer backgroundColor={theme.colors.faintGreen}>
          <P variant="small" style={{ fontWeight: 600, lineHeight: '28px' }}>
            {header}
          </P>
          <P
            variant="small"
            style={{
              fontWeight: 500,
              color: theme.colors.progressbar.barGreen1,
            }}
          >
            {content}
          </P>
        </StyledContainer>
      </AnimatedContainer>
    )
  }

  if (status === 'locked') {
    return (
      <AnimatedContainer
        initial={{ x: '100vw' }}
        animate={{ x: 0 }}
        transition={{ delay: animationDelay, duration: 0.5 }}
      >
        <StyledNumbering
          backgroundColor={theme.colors.lockGray}
          borderColor={theme.colors.lockGray}
        >
          <Image src={lock} alt="lock-icon" />
        </StyledNumbering>
        <StyledContainer backgroundColor={theme.colors.faintGray}>
          <P variant="small" style={{ fontWeight: 600, lineHeight: '28px' }}>
            {header}
          </P>
          <P
            variant="small"
            style={{
              fontWeight: 500,
              color: theme.colors.lockGray,
            }}
          >
            {content}
          </P>
        </StyledContainer>
      </AnimatedContainer>
    )
  }

  return (
    <AnimatedContainer
      initial={{ x: '100vw' }}
      animate={{ x: 0 }}
      transition={{ delay: animationDelay, duration: 0.5 }}
    >
      <StyledNumbering backgroundColor="white" borderColor={theme.colors.progressbar.barGreen1}>
        <H variant="h3" color={theme.colors.progressbar.barGreen1}>
          {step}
        </H>
      </StyledNumbering>
      <StyledContainer backgroundColor="white">
        <P variant="small" style={{ fontWeight: 600, lineHeight: '28px' }}>
          {header}
        </P>
        <P
          variant="small"
          style={{
            fontWeight: 500,
            color: theme.colors.lockGray,
          }}
        >
          {content}
        </P>
      </StyledContainer>
    </AnimatedContainer>
  )
}

TaskItem.propTypes = {
  animationDelay: PropTypes.number,
  step: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  status: PropTypes.string,
}

TaskItem.defaultProps = {
  animationDelay: 0,
  status: '',
}

export default TaskItem
