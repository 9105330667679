import styled from 'styled-components'

import { theme } from 'styles/theme'

export const StyledContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 50%;
  max-width: 150px;

  &::after {
    content: '';
    display: block;
    margin-top: 100%;
  }
`

export const StyledCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${props => (props.circleColor ? props.circleColor : theme.colors.faintGray2)};
  border-radius: 50%;
  z-index: 11;
`
