export const checkForInsufficientCredit = paymentPlans => {
  // insufficient credit only if all payment plans do not have sufficient credit
  const insufficientCredit = paymentPlans.every(plan => plan.insufficient_credit === true)
  const minSufficientCreditAmounts = paymentPlans.map(plan => plan.sufficient_credit_amount)
  const minSufficientCreditAmount =
    minSufficientCreditAmounts.length > 0
      ? Math.min(...minSufficientCreditAmounts).toFixed(2)
      : '0.00'
  return { insufficientCredit, minSufficientCreditAmount }
}
