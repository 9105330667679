import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { FormInput } from 'components'
import { search, clearInput } from 'assets'

const FAQSearchBar = ({ setSearchTerm }) => {
  const [showClearIcon, setShowClearIcon] = useState(false)

  const { register, setValue, getValues, watch } = useForm()

  // *Methods
  const submitForm = e => {
    e.preventDefault()
  }

  useEffect(() => {
    setSearchTerm(getValues('search_faq') || '')
  }, [watch()])

  // *JSX
  return (
    <form onSubmit={submitForm} autoComplete="off" style={{ width: '100%' }}>
      <FormInput
        id="search_faq"
        register={register}
        placeholder="Search FAQs"
        inputIcon={search}
        onChange={e => {
          setValue('search_faq', e.target.value)
          if (e.target.value.length > 0) {
            setShowClearIcon(true)
          } else {
            setShowClearIcon(false)
          }
        }}
        style={{
          height: 56,
          borderRadius: 12,
          border: 'none',
          boxShadow: '0px 2px 7px rgba(0, 0, 0, 0.098066)',
        }}
        onClearInput={() => {
          setValue('search_faq', '')
          setSearchTerm('')
          setShowClearIcon(false)
        }}
        showRightIcon={showClearIcon}
        rightIcon={clearInput}
      />
    </form>
  )
}

export default FAQSearchBar
