import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router'
import { useStripe } from '@stripe/react-stripe-js'

import {
  PageWrapper,
  NavigateBack,
  Footer,
  Button,
  Spacer,
  DraggableOverlay,
  Flex,
  Heading as H,
  PaymentSchedule,
  TutorialWrapper,
  TriggerPopup,
} from 'components'
import { PAY_EARLY_TUTORIAL } from 'const'
import {
  formatAsCurrency,
  getCountryCodeLocalDb,
  pushTransctionHistory,
  setPaymentTransactionLocalDb,
  findFullRepaymentPlan,
  logUserInteractionGA,
} from 'utils'
import { useOrderStore } from 'stores'

import PayRemainingBalanceHeader from './PayRemainingBalanceHeader/PayRemainingBalanceHeader'
import PayRemainingBalanceHeaderLoading from './PayRemainingBalanceHeader/PayRemainingBalanceHeaderLoading'
import PayRemainingAmount from './PayRemainingAmount/PayRemainingAmount'
import PayRemainingPaymentMethod from './PayRemainingPaymentMethod/PayRemainingPaymentMethod'

const PayRemainingBalance = () => {
  const stripe = useStripe()
  const countryCode = getCountryCodeLocalDb()
  const history = useHistory()
  const location = useLocation().search
  const orderCode = new URLSearchParams(location).get('code')

  const [showPaymentSchedule, setShowPaymentSchedule] = useState(false)
  const [repaymentsRemainingAmount, setRepaymentsRemainingAmount] = useState('')
  const [selectedRepaymentPlan, setSelectedRepaymentPlan] = useState(null)
  const [tutorialStep, setTutorialStep] = useState(0)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null)
  const [shouldDisablePayButton, setShouldDisablePayButton] = useState(false)
  const [balanceToPay, setBalanceToPay] = useState('')

  const [paymentFailed, setPaymentFailed] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  // *Stores
  const {
    response: {
      order: orderResponse,
      payEarly: payEarlyResponse,
      updatePaymentTransaction: updatePaymentTransactionResponse,
    },
    resetStates: resetStatesOrder,
    fetchOrder,
    submitPayEarly,
    updatePaymentTransaction,
  } = useOrderStore()

  // *Methods
  const togglePaymentSchedule = () => {
    setTutorialStep(0)
    setShowPaymentSchedule(!showPaymentSchedule)
  }

  const handleTutorial = () => {
    if (tutorialStep === PAY_EARLY_TUTORIAL.length - 1) {
      setTutorialStep(0)
    } else {
      setTutorialStep(tutorialStep => tutorialStep + 1)
    }
  }

  const handlePayEarly = () => {
    const paymentMethodId = selectedPaymentMethod.id

    logUserInteractionGA(`Payment - Pay Remaining Balance`, {
      order: orderCode,
    })
    setIsLoading(true)
    submitPayEarly(orderCode, paymentMethodId, selectedRepaymentPlan.early_repayment_percent)
  }

  const handleClearErrors = () => {
    setIsLoading(false)
    setPaymentFailed(false)
    setErrorMessage('')

    resetStatesOrder('payEarly')
    resetStatesOrder('updatePaymentTransaction')
  }

  const handleStripeActionRequired = async () => {
    const stripeData = payEarlyResponse.data.repayment.payment_transaction.stripe
    const { client_secret, payment_method_id } = stripeData

    const confirmCardPaymentResponse = await stripe.confirmCardPayment(client_secret, {
      payment_method: payment_method_id,
    })

    // handle authentication error
    if (
      confirmCardPaymentResponse &&
      confirmCardPaymentResponse.error &&
      confirmCardPaymentResponse.error.message
    ) {
      setIsLoading(false)
      const paymentIntentId = confirmCardPaymentResponse.error.payment_intent.id

      updatePaymentTransaction({
        third_party_name: 'stripe',
        stripe: {
          country: countryCode,
          payment_intent_id: paymentIntentId,
        },
      })
      return setErrorMessage(confirmCardPaymentResponse.error.message)
    }

    // handle authentication success
    if (
      confirmCardPaymentResponse &&
      confirmCardPaymentResponse.paymentIntent &&
      confirmCardPaymentResponse.paymentIntent.id
    ) {
      const paymentIntentId = confirmCardPaymentResponse.paymentIntent.id
      updatePaymentTransaction({
        third_party_name: 'stripe',
        stripe: {
          country: countryCode,
          payment_intent_id: paymentIntentId,
        },
      })
    }
  }

  // *Effects
  useEffect(() => {
    if (orderCode) {
      fetchOrder(orderCode)
    }

    return () => {
      resetStatesOrder('order')
      resetStatesOrder('payEarly')
      resetStatesOrder('updatePaymentTransaction')
    }
  }, [])

  useEffect(() => {
    if (errorMessage) {
      logUserInteractionGA(`Error - ${errorMessage}`)
    }
  }, [errorMessage])

  useEffect(() => {
    if (
      orderResponse &&
      orderResponse.repayments_remaining_amount &&
      orderResponse.early_repayment_plans &&
      orderResponse.early_repayment_plans.length > 0
    ) {
      setRepaymentsRemainingAmount(orderResponse.repayments_remaining_amount)

      const fullRepaymentPlan = findFullRepaymentPlan(orderResponse.early_repayment_plans)
      setSelectedRepaymentPlan(fullRepaymentPlan)
    }
  }, [orderResponse])

  useEffect(() => {
    if (
      orderResponse &&
      orderResponse.repayments_remaining_amount &&
      selectedRepaymentPlan &&
      selectedRepaymentPlan.early_repayment_percent
    ) {
      const balance =
        (parseFloat(orderResponse.repayments_remaining_amount) *
          parseInt(selectedRepaymentPlan.early_repayment_percent)) /
        100

      setBalanceToPay(balance)
    }
  }, [orderResponse, selectedRepaymentPlan])

  useEffect(() => {
    if (
      payEarlyResponse &&
      payEarlyResponse.success &&
      payEarlyResponse.data &&
      payEarlyResponse.data.repayment
    ) {
      if (
        payEarlyResponse.data.repayment.payment_transaction &&
        payEarlyResponse.data.repayment.payment_transaction.status === 'success'
      ) {
        setPaymentTransactionLocalDb(payEarlyResponse.data)

        resetStatesOrder('payEarly')
        pushTransctionHistory(history, orderCode)

        history.push('/transaction/success')
      } else if (payEarlyResponse.data.repayment.payment_transaction.status === 'require_action') {
        handleStripeActionRequired()
      } else {
        setPaymentFailed(true)
        if (payEarlyResponse.data.repayment.payment_transaction.failed_reason) {
          setErrorMessage(
            `An error occurred when making the payment. Reason: ${payEarlyResponse.data.repayment.payment_transaction.failed_reason}`,
          )
        } else {
          setErrorMessage('An error occurred when making the payment. Please try again.')
        }
        return
      }
    }
  }, [payEarlyResponse])

  useEffect(() => {
    if (
      updatePaymentTransactionResponse &&
      updatePaymentTransactionResponse.repayment &&
      updatePaymentTransactionResponse.repayment.payment_transaction &&
      updatePaymentTransactionResponse.repayment.payment_transaction.status
    ) {
      if (updatePaymentTransactionResponse.repayment.payment_transaction.status === 'success') {
        setPaymentTransactionLocalDb(updatePaymentTransactionResponse)

        resetStatesOrder('updatePaymentTransaction')
        resetStatesOrder('payEarly')

        pushTransctionHistory(history, orderCode)
        history.push('/transaction/success')
      } else if (updatePaymentTransactionResponse.repayment.payment_transaction.failed_reason) {
        setErrorMessage(
          `An error occurred when making the payment. Reason: ${updatePaymentTransactionResponse.repayment.payment_transaction.failed_reason}`,
        )
      }
    }
  }, [updatePaymentTransactionResponse])

  // *JSX
  return (
    <TutorialWrapper
      tutorial={PAY_EARLY_TUTORIAL}
      currentStep={tutorialStep}
      showTutorial={tutorialStep > 0}
      handleClick={handleTutorial}
    >
      <PageWrapper withTopNav>
        <NavigateBack noBackground />

        {/* Error handling */}
        <TriggerPopup
          showPopup={paymentFailed || !!errorMessage}
          message={errorMessage || 'An error occurred when making the payment. Please try again.'}
          buttonText="OKAY"
          stayAtCurrentPage
          callback={handleClearErrors}
        />

        <TriggerPopup
          showPopup={
            orderResponse &&
            orderResponse.early_repayment_plans &&
            orderResponse.early_repayment_plans.length === 0
          }
          message={'There are no available early repayment plans.'}
          buttonText="OKAY"
          nextDestination="/transactions"
          callback={handleClearErrors}
        />

        {/* Draggable Overlay */}
        {showPaymentSchedule &&
          orderResponse &&
          orderResponse.repayments &&
          orderResponse.repayments.length > 0 && (
            <DraggableOverlay cancel={togglePaymentSchedule}>
              <Flex mx={3} flexDirection="column">
                <H variant="h3">Payment schedule</H>
                <Spacer height={15} />
                <PaymentSchedule
                  isPayEarly
                  repayments={selectedRepaymentPlan.adjusted_repayments}
                  isActive={0}
                  description="Monthly payments will be collected from your specified payment method on each instalment date."
                />
              </Flex>
            </DraggableOverlay>
          )}

        {orderResponse ? (
          <PayRemainingBalanceHeader
            merchant={orderResponse.merchant}
            repaymentAmount={selectedRepaymentPlan?.early_repayment_amount}
          />
        ) : (
          <PayRemainingBalanceHeaderLoading />
        )}

        <Spacer height={120} />
        <PayRemainingAmount
          isLoading={!orderResponse}
          togglePaymentSchedule={togglePaymentSchedule}
          repaymentsRemainingAmount={repaymentsRemainingAmount}
          selectedRepaymentPlan={selectedRepaymentPlan}
          balanceToPay={balanceToPay}
          handleTutorial={handleTutorial}
        />

        <Spacer height={30} />
        <PayRemainingPaymentMethod
          collectionPaymentMethod={
            orderResponse && orderResponse.collection_payment_method
              ? orderResponse.collection_payment_method
              : null
          }
          tutorialStep={tutorialStep}
          selectedPaymentMethod={selectedPaymentMethod}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          setShouldDisablePayButton={setShouldDisablePayButton}
        />

        <Spacer height={100} />
        <Footer withTopBorder>
          <Button
            onClick={handlePayEarly}
            isLoading={isLoading}
            disabled={!orderResponse || !selectedPaymentMethod || shouldDisablePayButton}
          >{`PAY ${formatAsCurrency(selectedRepaymentPlan?.early_repayment_amount ?? '')}`}</Button>
        </Footer>
      </PageWrapper>
    </TutorialWrapper>
  )
}

export default PayRemainingBalance
