import flagSG from 'flag-icon-css/flags/4x3/sg.svg'
import flagMY from 'flag-icon-css/flags/4x3/my.svg'
import { isValidMYMobile, isValidSGMobile } from 'utils/validators'
import {
  contactUs,
  faq,
  notificationSettings,
  paymentMethod,
  termsOfService,
  settings,
} from 'assets'

const DEEPLINK_URL_SCHEME_MAP = {
  development: 'abnk',
  uat: 'abnk-uat',
  production: 'abnk',
}

export const BASE_AREA51_URL = process.env.REACT_APP_AREA51_URL
export const BASE_BACKEND_URL = process.env.REACT_APP_BACKEND_URL
export const BASE_API_URL = process.env.REACT_APP_BACKEND_URL + '/api'
export const WHITELABEL = process.env.REACT_APP_WHITELABEL
export const DEEPLINK_URL_SCHEME = DEEPLINK_URL_SCHEME_MAP[process.env.REACT_APP_ENV]

export const ADYEN_CLIENT_KEY = process.env.REACT_APP_ADYEN_CLIENT_KEY
export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY
export const STRIPE_PUBLIC_KEY_MY = process.env.REACT_APP_STRIPE_PUBLIC_KEY_MY

export const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID

export const DEBOUNCE_DELAY = 1000

export const COUNTRY_CODE_SG = 'sg'
export const COUNTRY_CODE_MY = 'my'

export const LANGUAGE_OPTIONS = [
  { name: 'ENG', value: 'eng' },
  { name: 'MAY', value: 'may' },
]

export const DESKTOP_MAX_WIDTH = 500

export const SG_NRIC_REGEX = /^[STFGstfg]\d{7}[A-Za-z]$/
export const MY_NRIC_REGEX = /^\d{6}\d{2}\d{4}$/

export const SG_POSTAL_REGEX = /^\d{6}/

export const SG_MOBILE_REGEX = /^(8|9)\d{7}$/
export const MY_MOBILE_REGEX = /^(\+?6?01)[0-46-9]*[0-9]{7,8}$/
export const EMAIL_REGEX =
  /^[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$/

export const PAY_REMAINING_CATEGORIES = ['Full repayment', 'Partial repayment']

export const countryCodeOptions = [
  {
    code: 'sg',
    currency: 'S$',
    currencyCode: 'SGD',
    value: '65',
    label: '+65',
    title: 'Singapore',
    icon: flagSG,
    placeholder: '87654321',
    mobileNumberValidator: isValidSGMobile,
  },
  {
    code: 'my',
    currency: 'RM',
    currencyCode: 'MYR',
    value: '60',
    label: '+60',
    title: 'Malaysia',
    icon: flagMY,
    placeholder: '167654321',
    mobileNumberValidator: isValidMYMobile,
  },
]

export const VERIFY_IDENTITY_MAX_PAGES = 3
export const VERIFY_IDENTITY_PAGES = [
  '',
  'Personal Information',
  'Home Ownership',
  'Contact Information',
]

export const PROFILE_OPTIONS = [
  {
    icon: paymentMethod,
    text: 'Payment methods',
    destination: '/paymentmethods',
  },
  {
    icon: notificationSettings,
    text: 'Notifications',
    destination: '/notifications',
  },
  {
    icon: contactUs,
    text: 'Contact us',
    destination: '/contactus',
  },
  { icon: faq, text: 'FAQ', destination: '/faq' },
  {
    icon: termsOfService,
    text: 'Terms of Service',
    destination: '/termsofservice',
  },
  {
    icon: settings,
    text: 'Settings',
    destination: '/settings',
  },
]
