import create from 'zustand'

import { postValidatePublicUrl } from 'services'

const useMagicStore = create((set, get) => ({
  isLoading: { validatePublicUrl: false },
  response: { validatePublicUrl: null },
  errors: { validatePublicUrl: null },
  resetStates: dataType => {
    set({
      isLoading: { ...get().isLoading, [dataType]: false },
      response: { ...get().response, [dataType]: null },
      errors: { ...get().errors, [dataType]: null },
    })
  },
  submitValidatePublicUrl: async publicUrl => {
    try {
      set({ isLoading: { ...get().isLoading, validatePublicUrl: true } })
      const res = await postValidatePublicUrl(publicUrl)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, validatePublicUrl: false },
          response: { ...get().response, validatePublicUrl: res.data },
          errors: { ...get().errors, validatePublicUrl: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, validatePublicUrl: false },
          response: {
            ...get().response,
            validatePublicUrl: res,
          },
          errors: { ...get().errors, validatePublicUrl: res.errors },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, validatePublicUrl: false },
        response: { ...get().response, validatePublicUrl: null },
        errors: {
          ...get().errors,
          validatePublicUrl: err && err.response && err.response.data.message,
        },
      })
    }
  },
}))

export default useMagicStore
