import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { Flex, Image, Spacer } from 'components'
import { successCircleInactive } from 'assets'

import { StyledTimeline } from '../scheduleRowStyle'

const ScheduleRowLoading = i => {
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Flex>
        <Flex flexDirection="column" justifyContent="center" alignItems="center">
          {i > 0 && <StyledTimeline />}
          <Image
            src={successCircleInactive}
            alt="payment_status"
            style={{ zIndex: 1 }}
            height="22"
            width="22"
          />
        </Flex>
        <Flex flexDirection="column" ml={3} my={2}>
          <Skeleton height={18} width={97} />
          <Skeleton height={15} width={50} />
        </Flex>
      </Flex>

      <Spacer width={10} />

      <Skeleton height={23} width={59} />
    </Flex>
  )
}

export default ScheduleRowLoading
