import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import Skeleton from 'react-loading-skeleton'
import isEmpty from 'lodash/isEmpty'

import { ghlLogo, bell, abnkLogo } from 'assets'
import {
  PageWrapper,
  Flex,
  Heading as H,
  PendingPurchaseCTA,
  UpcomingPaymentsContainer,
  StyledBackdrop,
  NoTransactions,
  Paragraph as P,
  TriggerPopup,
} from 'components'
import { useAuthStore, useOrderStore, useBuyerStore } from 'stores'
import {
  getCountryCodeLocalDb,
  formatAsCurrency,
  setDraftOrderLocalDb,
  groupRepaymentsIntoMonths,
  setBuyerNameLocalDb,
  logUserInteractionGA,
  getAssumeAccount,
  getAssumeAccountSetupStatus,
  removeTokenLocalDb,
} from 'utils'
import { WHITELABEL, GENERIC_ERR_MESSAGE } from 'const'

import { StyledIcon, StyledLogo, StyledActiveNotification } from './homeStyle'
import AvailableCredit from './AvailableCredit/AvailableCredit'
import LoadingUpcomingPayments from 'components/UpcomingPaymentsContainer/LoadingUpcomingPayments/LoadingUpcomingPayments'

const Home = () => {
  const history = useHistory()
  const countryCode = getCountryCodeLocalDb()

  const [userCredits, setUserCredits] = useState(null)
  const [groupedRepayments, setGroupedRepayments] = useState(null)
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0)
  const [currentDraftOrder, setCurrentDraftOrder] = useState(null)

  // *Stores
  const { submitExtendToken } = useAuthStore()

  const {
    response: {
      credits: creditsResponse,
      upcomingPayments: upcomingPaymentsResponse,
      getOrCreate: getOrCreateResponse,
    },
    errors: { getOrCreate: getOrCreateError },
    resetStates: resetStatesBuyer,
    fetchCredits,
    fetchUpcomingPayments,
    submitGetOrCreate,
  } = useBuyerStore()

  const {
    response: { draft: draftResponse },
    resetStates: resetStatesOrder,
    fetchOrderDraft,
  } = useOrderStore()

  // *Effects
  useEffect(() => {
    if (getAssumeAccount() && getAssumeAccountSetupStatus() !== 'complete') {
      history.push('/assumeaccount')
    }

    fetchCredits(countryCode)
    fetchOrderDraft(countryCode)
    fetchUpcomingPayments(countryCode)
    submitExtendToken()
    submitGetOrCreate()

    return () => {
      resetStatesBuyer('credits')
      resetStatesBuyer('getOrCreate')
      resetStatesBuyer('upcomingPayments')
      resetStatesOrder('draft')
    }
  }, [])

  useEffect(() => {
    if (creditsResponse) {
      setUserCredits({
        total: creditsResponse.amount,
        balance: creditsResponse.balance,
      })
    }
  }, [creditsResponse])

  useEffect(() => {
    if (draftResponse && draftResponse.orders && draftResponse.orders.length > 0) {
      setDraftOrderLocalDb(draftResponse.orders[draftResponse.orders.length - 1])
      setCurrentDraftOrder(draftResponse.orders[draftResponse.orders.length - 1])
    }
  }, [draftResponse])

  useEffect(() => {
    if (upcomingPaymentsResponse) {
      const grouped = groupRepaymentsIntoMonths(upcomingPaymentsResponse.repayments)
      setGroupedRepayments(grouped)
    }
  }, [upcomingPaymentsResponse])

  useEffect(() => {
    if (getOrCreateResponse) {
      setBuyerNameLocalDb(getOrCreateResponse.name)
    }

    if (getOrCreateResponse && getOrCreateResponse.unread_notification_count) {
      setUnreadNotificationCount(getOrCreateResponse.unread_notification_count)
    }
  }, [getOrCreateResponse])

  // *JSX
  return (
    <PageWrapper withBottomBar style={{ paddingBottom: 220 }}>
      <TriggerPopup
        message={(getOrCreateError && getOrCreateError.message) || GENERIC_ERR_MESSAGE}
        showPopup={!!getOrCreateError}
        buttonText="Return"
        callback={() => {
          resetStatesBuyer('getOrCreate')
          removeTokenLocalDb()
        }}
        nextDestination="/register"
        replaceHistory
      />

      <StyledBackdrop variant="home">
        <StyledLogo
          src={WHITELABEL === 'ghl' ? ghlLogo : abnkLogo}
          alt="Abnk logo"
          width={WHITELABEL === 'ghl' ? 60 : 90}
          height="auto"
          style={{
            top: WHITELABEL === 'ghl' ? 30 : 15,
            left: WHITELABEL === 'ghl' ? 30 : 15,
          }}
        />
        <div
          onClick={() => {
            logUserInteractionGA('Click: Notification Bell Icon')
            history.push('/notifications')
          }}
        >
          <StyledIcon src={bell} alt="bell-icon" />
          {unreadNotificationCount > 0 && (
            <StyledActiveNotification
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{ delay: 0.3, duration: 0.3 }}
            >
              <P variant="subcopy" color="white" style={{ fontWeight: 500 }}>
                {unreadNotificationCount}
              </P>
            </StyledActiveNotification>
          )}
        </div>
        <>
          <H variant="h4" color="white">
            Remaining due
          </H>
          {upcomingPaymentsResponse && upcomingPaymentsResponse.total_due_amount ? (
            <H color="white" style={{ fontSize: 48, marginTop: 15 }} variant="h1">
              {formatAsCurrency(upcomingPaymentsResponse.total_due_amount)}
            </H>
          ) : (
            <Skeleton height={50} width={180} style={{ opacity: 0.6 }} />
          )}
        </>
      </StyledBackdrop>
      {userCredits && <AvailableCredit userCredits={userCredits} />}

      {groupedRepayments ? (
        isEmpty(groupedRepayments) ? (
          <NoTransactions
            title="You have no transactions"
            content="Start by exploring our merchants"
          />
        ) : (
          Object.keys(groupedRepayments).map((year, yearIndex) => {
            return Object.keys(groupedRepayments[year]).map((month, monthIndex) => {
              // only display the most recent upcoming month
              if (yearIndex > 0 || monthIndex > 0) return null

              const groupedRepaymentMonth = groupedRepayments[year][month]
              return (
                <UpcomingPaymentsContainer
                  boxShadow="0px 2px 20px 0px #00000019"
                  data={{
                    repayments: groupedRepaymentMonth,
                  }}
                  key={`${monthIndex}-${yearIndex}`}
                  showTopNavigation
                  title="Upcoming payments"
                />
              )
            })
          })
        )
      ) : (
        <LoadingUpcomingPayments />
      )}

      {draftResponse &&
        draftResponse.orders &&
        draftResponse.orders.length > 0 &&
        currentDraftOrder && (
          <Flex
            style={{
              bottom: 100,
              width: 'calc(100% - 32px)',
              position: 'fixed',
              zIndex: 1,
            }}
          >
            <PendingPurchaseCTA order={currentDraftOrder} />
          </Flex>
        )}
    </PageWrapper>
  )
}

export default Home
