import React from 'react'

import { RoundedContainer, Paragraph as P } from 'components'
import { theme } from 'styles/theme'

const CategoryCard = ({ category, activeCategory, setActiveCategory, ...rest }) => {
  return (
    <RoundedContainer
      boxShadow={category !== activeCategory ? '0px 2px 15px rgba(0, 0, 0, 0.098066)' : 'none'}
      backgroundColor={category !== activeCategory ? 'white' : theme.colors.buttons.marineBlue}
      color={category !== activeCategory ? 'black' : 'white'}
      mr="12px"
      py={3}
      onClick={() => setActiveCategory(category)}
      {...rest}
    >
      <P
        variant="small"
        style={{ fontWeight: 700, whiteSpace: 'nowrap', overflow: 'hidden' }}
        textAlign="center"
      >
        {category}
      </P>
    </RoundedContainer>
  )
}

export default CategoryCard
