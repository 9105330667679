import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'

import { Modal } from 'components'

const TriggerPopup = ({
  message,
  showPopup,
  callback,
  nextDestination,
  buttonText,
  stayAtCurrentPage,
  replaceHistory,
}) => {
  const history = useHistory()

  if (showPopup) {
    return (
      <Modal
        messageArray={[message]}
        primaryButtonText={buttonText || 'RETURN HOME'}
        primaryButtonAction={() => {
          if (callback && typeof callback === 'function') {
            callback()
          }
          if (!stayAtCurrentPage) {
            if (nextDestination) {
              replaceHistory ? history.replace(nextDestination) : history.push(nextDestination)
            } else {
              replaceHistory ? history.replace('/home') : history.push('/home')
            }
          }
        }}
      />
    )
  }

  return null
}

TriggerPopup.propTypes = {
  message: PropTypes.string,
  showPopup: PropTypes.bool,
  callback: PropTypes.func,
  nextDestination: PropTypes.string,
  buttonText: PropTypes.string,
  stayAtCurrentPage: PropTypes.bool,
}

TriggerPopup.defaultProps = {
  stayAtCurrentPage: false,
}

export default TriggerPopup
