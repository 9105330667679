import React, { useRef, useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import QRCode from 'react-qr-code'

import { useBuyerStore } from 'stores'
import { PageWrapper, NavigateBack, Flex, Heading as H, Paragraph as P, Spacer } from 'components'

const MyQrCode = () => {
  const history = useHistory()
  const { fetchWebsocketUrl, fetchBuyerQr } = useBuyerStore()

  const wsRef = useRef()
  const refetchRef = useRef()

  const [qrCode, setQrCode] = useState('')

  const establishWebsocket = async () => {
    const res = await fetchWebsocketUrl()
    if (res.success) {
      const websocket = new WebSocket(res.data.ws_url)
      wsRef.current = websocket
      wsRef.current.onmessage = event => {
        const data = JSON.parse(event.data)
        if (data.event_type === 'checkout_created') {
          history.push(`/order?checkout_id=${data.checkout_created.id}`)
        }
      }
    }
  }

  const getBuyerQr = async () => {
    clearTimeout(refetchRef.current)
    const res = await fetchBuyerQr()
    if (res.success) {
      setQrCode(res.data.qr_code_value)
      const expires = new Date(res.data.expires_at).getTime()
      const now = new Date().getTime()
      const timeToExecute = expires - now
      refetchRef.current = setTimeout(() => {
        getBuyerQr()
      }, timeToExecute)
    }
  }

  useEffect(() => {
    establishWebsocket()
    getBuyerQr()

    return () => {
      wsRef.current?.close()
      clearTimeout(refetchRef.current)
    }
  }, [])

  return (
    <>
      <PageWrapper
        withTopNav
        paddingBottom={150}
        style={{
          height: '90vh',
        }}
      >
        <NavigateBack withBottomBorder>My Code</NavigateBack>

        <Flex flexDirection="column" stretch flex="1" padding={24} paddingTop={60}>
          <H centered variant="h2" style={{ fontWeight: 700 }}>
            Show this code to the merchant
          </H>
          <Spacer height={32} />
          {!!qrCode && (
            <Flex centered>
              <QRCode value={qrCode} />
            </Flex>
          )}
          <Spacer height={32} />
          <P centered color="#7F7F7F">
            You will be redirected to the payment page after the merchant scans this.
          </P>
        </Flex>
      </PageWrapper>
    </>
  )
}

export default MyQrCode
