import styled from 'styled-components'
import { motion } from 'framer-motion'

export const StyledContainer = styled(motion.div)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: white;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.188893);
  margin: 0;
  width: 100%;
  border-radius: 16px 16px 0px 0px;
  box-sizing: border-box;
`

export const StyledNavItem = styled.div`
  text-align: center;
  /* background-color: white; */

  &:hover {
    cursor: pointer;
  }
`
