import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { Flex } from 'components'

import { StyledCardContainer } from './paymentMethodCardStyle'

const PaymentMethodCardLoading = ({ ...rest }) => {
  return (
    <StyledCardContainer style={{ justifyContent: 'flex-start' }} {...rest}>
      <Skeleton height={47} width={47} style={{ borderRadius: 8 }} />
      <Flex>
        <Flex flexDirection="column" justifyContent="center" ml={3}>
          <Skeleton height={15} width={100} />
          <Skeleton height={15} width={130} />
        </Flex>
      </Flex>
    </StyledCardContainer>
  )
}

export default PaymentMethodCardLoading
