import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { RoundedContainer } from 'components'
import { theme } from 'styles/theme'

const DropDownLoading = ({ skeletonWidth, ...rest }) => {
  return (
    <RoundedContainer
      boxShadow="none"
      border={`1px solid ${theme.colors.borderGray}`}
      p="12px 20px"
      mt={0}
      style={{ borderRadius: 12 }}
      {...rest}
    >
      <Skeleton height={20} width={skeletonWidth} />
    </RoundedContainer>
  )
}

export default DropDownLoading
