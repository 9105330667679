import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import { FormInput } from 'components'
import { search, clearInput } from 'assets'
import { logUserInteractionGA } from 'utils'

const MerchantSearchBar = ({ setSearchTerm }) => {
  const [showClearIcon, setShowClearIcon] = useState(false)

  const { register, setValue, handleSubmit } = useForm()

  // *Methods
  const submitForm = e => {
    e.preventDefault()
    handleSubmit(data => {
      const searchTerm = data.search_merchant
      logUserInteractionGA(`Search Merchant`, { keyword: searchTerm })
      setSearchTerm(searchTerm)
    })()
  }

  // *JSX
  return (
    <form onSubmit={submitForm} autoComplete="off">
      <FormInput
        id="search_merchant"
        register={register}
        placeholder="Search by name or category"
        inputIcon={search}
        onChange={e => {
          setValue('search_merchant', e.target.value)
          if (e.target.value.length > 0) {
            setShowClearIcon(true)
          } else {
            setShowClearIcon(false)
          }
        }}
        style={{
          height: 56,
          borderRadius: 12,
          border: 'none',
          boxShadow: '0px 2px 7px rgba(0, 0, 0, 0.098066)',
        }}
        onClearInput={() => {
          setValue('search_merchant', '')
          setSearchTerm('')
          setShowClearIcon(false)
        }}
        showRightIcon={showClearIcon}
        rightIcon={clearInput}
      />
    </form>
  )
}

export default MerchantSearchBar
