import React, { useEffect } from 'react'

import { Flex, Heading as H, Spacer } from 'components'
import { useMerchantStore } from 'stores'
import { getCountryCodeLocalDb } from 'utils'

import FeaturedCard from './FeaturedCard/FeaturedCard'
import FeaturedCardSkeleton from './FeaturedCard/FeaturedCardSkeleton'

const FeaturedMerchants = () => {
  const countryCode = getCountryCodeLocalDb()
  const {
    response: { featured: featuredResponse },
    fetchFeatured,
  } = useMerchantStore()

  // *Effects
  useEffect(() => {
    if (countryCode) {
      fetchFeatured(countryCode)
    }
  }, [])

  // *JSX
  if (!featuredResponse) {
    return (
      <Flex flexDirection="column" width="100%">
        <H variant="h3">Featured merchants</H>
        <Flex flexWrap="wrap">
          <FeaturedCardSkeleton />
          <FeaturedCardSkeleton />
        </Flex>
      </Flex>
    )
  }

  return (
    <Flex flexDirection="column" width="100%">
      <H variant="h3">Featured merchants</H>
      <Flex flexWrap="wrap">
        {featuredResponse &&
          featuredResponse.merchants &&
          featuredResponse.merchants.map(merchant => {
            return <FeaturedCard key={merchant.name} merchant={merchant} />
          })}

        {/* spacer to prevent last element from taking whole row if it's alone */}
        {featuredResponse &&
          featuredResponse.merchants &&
          featuredResponse.merchants.length % 2 !== 0 && <Spacer width="50%" />}
      </Flex>
    </Flex>
  )
}

export default FeaturedMerchants
