import styled from 'styled-components'
import { motion } from 'framer-motion'

import { theme } from 'styles/theme'

export const StyledContainer = styled(motion.div)`
  height: 10px;
  border-radius: 8px;
  background-color: ${theme.colors.progressbar.background};
  width: 100%;
  padding: 0;
  overflow: hidden;
`

export const StyledContainerNarrow = styled(motion.div)`
  top: 20px;
  left: 0;
  width: 100vw;
  height: 4px;
  background-color: ${theme.colors.progressbar.background};
  margin-left: -16px;
  border-radius: 0;
`

export const StyledProgress = styled(motion.div)`
  position: relative;
  z-index: 2;
  height: 100%;
  border-radius: 8px;
  background-color: ${props => props.barColor || theme.colors.progressbar.barGreen1};
`

export const StyledSecondaryProgress = styled(motion.div)`
  position: absolute;
  top: 0;
  z-index: 1;
  height: 100%;
  border-radius: 8px;
  background-color: ${props => props.barColor || theme.colors.progressbar.barGreenLight1};
`
