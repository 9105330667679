const COUNTRY_CODE = {
  'Asia/Singapore': 'sg',
  'Asia/Kuala_Lumpur': 'my',
}

export const redirectToAppStore = async () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  // TODO: fix app store link
  const url = `https://apps.apple.com/${COUNTRY_CODE[timezone]}/app/`

  window.location.replace(url)
}
