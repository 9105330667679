import React from 'react'
import Select, { components } from 'react-select'

import { FormLabel, FormError, Flex, Image } from 'components'

import { selectStyles, selectStylesAutofill } from './formSelectStyle'

const { Option, SingleValue } = components
const IconOption = props => {
  const { data } = props
  return (
    <Option {...props}>
      {data.icon && (
        <Image src={data.icon} style={{ width: 25, marginRight: 5 }} alt={data.label} />
      )}
      {data.label}
    </Option>
  )
}

const ValueOption = props => {
  const { data } = props
  return (
    <SingleValue {...props}>
      {data.icon && (
        <Image src={data.icon} style={{ width: 25, marginRight: 5 }} alt={data.label} />
      )}
      {data.label}
    </SingleValue>
  )
}

const FormSelect = React.forwardRef(
  (
    {
      label,
      showLabel,
      onChange,
      onBlur,
      name,
      options,
      defaultValue,
      error,
      style,
      variant,
      required,
      isDisabled,
      hideChevron = false,
      ...rest
    },
    ref,
  ) => {
    return (
      <Flex flexDirection="column">
        {showLabel && (
          <FormLabel width="100%">
            {label}
            {required && <span style={{ color: 'red' }}>*</span>}
          </FormLabel>
        )}
        <div style={style}>
          <Select
            isDisabled={isDisabled}
            menuPlacement="auto"
            menuPortalTarget={document.body}
            name={name}
            ref={ref}
            onChange={onChange}
            onBlur={onBlur}
            options={options}
            defaultValue={defaultValue}
            placeholder="Select option"
            {...rest}
            components={{
              Option: IconOption,
              SingleValue: ValueOption,
              ...(hideChevron && {
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }),
            }}
            styles={variant === 'autofill' ? selectStylesAutofill : selectStyles}
          />
        </div>
        {error && <FormError>{error.message}</FormError>}
      </Flex>
    )
  },
)

FormSelect.displayName = 'FormSelect'

export default FormSelect
