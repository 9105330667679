import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { motion } from 'framer-motion'

import { newSuccessfulVerification, newCreditReportSuccess, newDocumentSubmission } from 'assets'
import {
  PageWrapper,
  Heading as H,
  Paragraph as P,
  Span,
  Flex,
  Spacer,
  Button,
  Footer,
  Loading,
} from 'components'
import { theme } from 'styles/theme'
import { getCountryCodeLocalDb, logUserInteractionGA } from 'utils'
import { useOrderStore } from 'stores'

const Success = () => {
  const history = useHistory()
  const countryCode = getCountryCodeLocalDb()

  // capture text to display from url params
  const location = useLocation().search
  const type = new URLSearchParams(location).get('type')
  const manual = new URLSearchParams(location).get('manual')

  // *Stores
  const {
    response: { draft: draftResponse },
    resetStates: resetStatesOrder,
    fetchOrderDraft,
  } = useOrderStore()

  // *Methods
  const handleClickReturn = () => {
    // return to pending order if there is one
    if (
      draftResponse &&
      draftResponse.orders &&
      draftResponse.orders.length > 0 &&
      type !== 'documents' // don't send user to order after credit limit review submission
    ) {
      history.push('/home')
      history.push('/credit')
      if (draftResponse.code) {
        return history.push(`/order/pay?code=${draftResponse.code}`)
      } else {
        return history.push('/order/pay')
      }
    }

    // return to credit
    // if (getShouldRedirectToCredit()) {
    history.push('/home') // push home first so user can click back and navigate to home from credit page
    return history.push('/credit')
    // }

    // go back by default
    // return history.goBack()
  }

  const onBackButtonEvent = e => {
    e.preventDefault()
    handleClickReturn()
  }

  // *Effects
  useEffect(() => {
    fetchOrderDraft(countryCode)

    return () => {
      resetStatesOrder('draft')
    }
  }, [])

  useEffect(() => {
    logUserInteractionGA(
      `Success - ${
        type === 'documents'
          ? 'Documents Submitted'
          : type === 'paymentmethod'
          ? 'Payment Method Added'
          : type === 'cbs'
          ? 'Cbs Verification'
          : 'Verification'
      }`,
    )
  }, [type])

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname)
    window.addEventListener('popstate', onBackButtonEvent)

    return () => window.removeEventListener('popstate', onBackButtonEvent)
  }, [draftResponse])

  // *JSX
  if (!draftResponse) {
    return <Loading variant="fullscreen" />
  }

  return (
    <PageWrapper
      style={{
        background: theme.colors.buttons.marineBlue,
        minHeight: '100vh',
      }}
    >
      <Flex flexDirection="column" stretch centered pt="20vh">
        <Flex height="200px">
          <motion.img
            src={
              type === 'cbs'
                ? newCreditReportSuccess
                : type === 'documents'
                ? newDocumentSubmission
                : newSuccessfulVerification
            }
            alt="success"
            transition={{
              duration: 0.2,
              type: 'spring',
              stiffness: 100,
            }}
            initial={{ scale: 0.1 }}
            animate={{ scale: 1.0 }}
            width={type === 'cbs' || type === 'documents' ? '240px' : '200px'}
            height={type === 'cbs' || type === 'documents' ? '240px' : '200px'}
          />
        </Flex>
        <Spacer height={type === 'cbs' ? 60 : 30} />
        <H variant="h3" color="white">
          {type === 'documents'
            ? 'Documents submitted!'
            : type === 'paymentmethod'
            ? 'Payment method added!'
            : type === 'cbs'
            ? 'Documents submitted!'
            : 'Verification successful!'}
        </H>

        {manual === 'true' && (
          <>
            <Spacer height={10} />
            <P variant="small" textAlign="center" color="white" px={3}>
              You will be notified of your outcome within{' '}
              <Span style={{ whiteSpace: 'nowrap' }}>1-2</Span> working days
            </P>
          </>
        )}
      </Flex>
      <Footer
        initial={{ y: 100 }}
        animate={{ y: 0 }}
        transition={{
          delay: 0.8,
          duration: 0.2,
          type: 'spring',
          stiffness: 150,
        }}
      >
        <Button variant="secondary" type="button" onClick={handleClickReturn}>
          RETURN
        </Button>
      </Footer>
    </PageWrapper>
  )
}

export default Success
