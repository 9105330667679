import React from 'react'
import PropTypes from 'prop-types'
import { SpinnerCircular } from 'spinners-react'

import { theme } from 'styles/theme'
import { StyledContainer } from './loadingStyle'

const Loading = ({ variant, size = 50, color, ...rest }) => {
  if (variant === 'fullscreen') {
    return (
      <StyledContainer variant={variant} {...rest}>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            fontSize: '50px',
            color: 'white',
            transform: 'translate(-50%,-50%)',
          }}
        >
          <SpinnerCircular size={50} color={theme.colors.abnkBlue} />
        </div>
      </StyledContainer>
    )
  }

  return <SpinnerCircular color={color || theme.colors.abnkBlue} size={size} {...rest} />
}

Loading.propTypes = {
  variant: PropTypes.string,
}

Loading.defaultProps = {
  variant: '',
}

export default Loading
