import React from 'react'
import PropTypes from 'prop-types'

import { StyledHeading } from './headingStyle'

const Heading = ({ centered, variant, children, ...rest }) => {
  return (
    <StyledHeading centered={centered} variant={variant} {...rest}>
      {children}
    </StyledHeading>
  )
}

Heading.propTypes = {
  centered: PropTypes.bool,
  variant: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
}

Heading.defaultProps = {
  centered: false,
}

export default Heading
