import React from 'react'
import PropTypes from 'prop-types'

import { Flex, Heading as H, RoundedContainer } from 'components'
import { advancedDayjs } from 'utils'

import NotificationRow from './NotificationRow/NotificationRow'

const NotificationDayContainer = ({ date, notifications, markReadAll }) => {
  if (advancedDayjs().format('Do MMM YYYY') === date) date = 'Today'
  if (advancedDayjs().add(-1, 'day').format('Do MMM YYYY') === date) date = 'Yesterday'

  return (
    <Flex flexDirection="column" mt={3}>
      <H variant="h4">{date}</H>

      <RoundedContainer py={0} fitWidth style={{ overflow: 'hidden' }}>
        {notifications.map(notification => {
          return (
            <NotificationRow
              notification={notification}
              markReadAll={markReadAll}
              key={notification.id}
            />
          )
        })}
      </RoundedContainer>
    </Flex>
  )
}

NotificationDayContainer.propTypes = {
  date: PropTypes.string.isRequired,
  notifications: PropTypes.array.isRequired,
  markReadAll: PropTypes.bool.isRequired,
}

export default NotificationDayContainer
