import React, { useState } from 'react'

import { PageWrapper, NavigateBack, Footer, Spacer } from 'components'
import { theme } from 'styles/theme'
import { faq } from 'contents'

import FAQSearchBar from './FAQSearchBar/FAQSearchBar'
import FAQSearchResults from './FAQSearchResults/FAQSearchResults'
import FAQCategories from './FAQCategories/FAQCategories'

const FAQ = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [activeCategory, setActiveCategory] = useState(faq[0].categoryName)

  return (
    <PageWrapper withTopNav backgroundColor={theme.colors.background}>
      <NavigateBack withBottomBorder>FAQ</NavigateBack>
      <Spacer height={15} />

      <FAQSearchBar setSearchTerm={setSearchTerm} />
      <Spacer height={15} />

      {searchTerm.length === 0 ? (
        <FAQCategories activeCategory={activeCategory} setActiveCategory={setActiveCategory} />
      ) : (
        <FAQSearchResults searchTerm={searchTerm} />
      )}

      <Footer
        style={{
          height: 200,
          backgroundColor: theme.colors.background,
          zIndex: -1,
        }}
      />
    </PageWrapper>
  )
}

export default FAQ
