import React from 'react'

import { Flex, Paragraph as P } from 'components'
import { theme } from 'styles/theme'
import { formatAsCurrency } from 'utils'

const DetailRow = ({ title, amount, isNegativeValue, isBreakdown, ...rest }) => {
  return (
    <Flex justifyContent="space-between" mb={isBreakdown ? 1 : 2} alignItems="center" {...rest}>
      <P
        variant={isBreakdown ? 'small' : ''}
        style={{ fontWeight: isBreakdown ? 400 : 500 }}
        color={theme.colors.typography.gray10}
      >
        {isBreakdown ? '- ' : ''}
        {title}
      </P>
      <P
        variant={isBreakdown ? 'small' : ''}
        style={{ fontWeight: isBreakdown ? 400 : 700 }}
        textAlign="right"
        color={isNegativeValue ? theme.colors.progressbar.barGreen1 : ''}
      >
        {isNegativeValue ? '-' : ''}
        {formatAsCurrency(amount)}
      </P>
    </Flex>
  )
}

export default DetailRow
