import styled from 'styled-components'
import { motion } from 'framer-motion'

export const StyledBubbleContainer = styled(motion.div)`
  position: fixed;
  top: ${props => `${props.top}px`};
  margin-left: 25px;
  margin-top: 10px;
  z-index: 21;
  padding: 15px 20px 5px 20px;
  background-color: #323f4b;
  border-radius: 8px;
  max-width: 85%;
`

export const StyledBubblePointer = styled.div`
  width: 20px;
  background-color: #323f4b;
`
