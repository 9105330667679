import { theme } from 'styles/theme'

export const otpContainerStyle = {
  width: '100%',
  marginBottom: '10px',
}

export const otpInputStyle = {
  fontFamily: theme.typography.fontFamily,
  fontWeight: '700',
  fontSize: '20px',
  width: '45px',
  height: '55px',
  marginRight: '5px',
  borderRadius: '14px',
  border: '0.5px solid #7B8794',
}

export const otpFocusStyle = {
  border: `1px solid ${theme.colors.actions.actionBlue}`,
}

export const otpErrorStyle = {
  border: `1px solid ${theme.colors.actions.failureRed}`,
}
