import React from 'react'
import {
  Flex,
  Image,
  AdyenPaymentForm,
  StripeContainer,
  PageWrapper,
  NavigateBack,
  Paragraph as P,
  Spacer,
  Loading,
} from 'components'
import { addDebitCard } from 'assets'
import { usePaymentThirdParty } from 'hooks'

// currently not used, may deprecate this.
const AddDebitCard = () => {
  const { thirdParty } = usePaymentThirdParty()

  return (
    <PageWrapper withTopNav>
      <NavigateBack withBottomBorder>New payment method</NavigateBack>

      <Flex mt={2}>
        <Image src={addDebitCard} alt="credit_card_icon" width={24} />
        <P ml={2} style={{ fontWeight: 600 }}>
          Debit card
        </P>
      </Flex>

      <Spacer height={25} />
      {!thirdParty && (
        <Flex m="auto">
          <Loading />
        </Flex>
      )}

      {thirdParty && thirdParty.card === 'adyen' ? <AdyenPaymentForm /> : <StripeContainer />}
    </PageWrapper>
  )
}

export default AddDebitCard
