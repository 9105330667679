import React from 'react'
import { useHistory } from 'react-router-dom'

import { Paragraph as P, Button } from 'components'
import { formatAsCurrency, logUserInteractionGA } from 'utils'
import { theme } from 'styles'

const ScheduleRowPaymentAmount = ({
  paymentStatus,
  isLoading,
  isCanPay,
  isOverdue,
  totalAmount,
  selectedPaymentMethod = null,
  orderCode = '',
  paymentId = '',
}) => {
  const history = useHistory()

  // *JSX
  if (!isCanPay) {
    return (
      <P
        color={
          paymentStatus === 'collect_success' || paymentStatus === 'deactivated'
            ? theme.colors.lockGray
            : 'black'
        }
        style={{
          fontWeight: 600,
          textDecoration:
            paymentStatus === 'collect_success' || paymentStatus === 'deactivated'
              ? 'line-through'
              : 'none',
        }}
      >{`${formatAsCurrency(totalAmount)}`}</P>
    )
  }

  if (isCanPay) {
    return (
      <Button
        isLoading={isLoading}
        spinnerSize={24}
        disabled={!selectedPaymentMethod}
        style={{
          padding: isLoading ? '0px 3px' : '7px 3px',
          fontSize: 14,
          borderRadius: 12,
          maxWidth: 120,
          backgroundColor: isOverdue ? theme.colors.typography.red1 : '',
          borderColor: isOverdue ? theme.colors.typography.red1 : '',
          marginRight: -15,
          letterSpacing: 'normal',
        }}
        onClick={() => {
          logUserInteractionGA(`Click: To Pay Repayment`, {
            order: orderCode,
            repayment: paymentId,
          })
          history.push(`/transaction/paynext?order=${orderCode}&repayment=${paymentId}`)
        }}
      >
        {`Pay ${formatAsCurrency(totalAmount)}`}
      </Button>
    )
  }
}

export default ScheduleRowPaymentAmount
