import { theme } from 'styles/theme'
import styled from 'styled-components'

export const InputContainer = styled.div`
  background-color: white;
  font-family: ${theme.typography.fontFamily};
  font-size: 14px;
  padding: 11px;
  border: 1px solid #9c9d9d;
  border-radius: 8px;
  box-sizing: border-box;
`

export const CARD_STYLES = {
  base: {
    fontWeight: 600,
    fontSize: '14px',
    fontSmoothing: 'antialiased',
    padding: '15px',

    '::placeholder': {
      color: theme.colors.typography.gray6,
    },
    ':-webkit-autofill': {
      color: '#e39f48',
    },
  },
  invalid: {
    color: '#E25950',

    '::placeholder': {
      color: '#FFCCA5',
    },
  },
}
