import React from 'react'

import { FormLabel, FormError, Flex } from 'components'

import { StyledFormDatePicker } from './formDatePickerStyle'

const FormDatePicker = ({
  variant,
  label,
  showLabel,
  required,
  error,
  readOnly,
  selected,
  ...rest
}) => {
  return (
    <Flex flexDirection="column">
      {showLabel && (
        <FormLabel>
          {label}
          {required && <span style={{ color: 'red' }}>*</span>}
        </FormLabel>
      )}
      <StyledFormDatePicker
        variant={variant}
        readOnly={readOnly}
        selected={selected}
        dateFormat="dd/MM/yyyy"
        className="form_datepicker"
        withPortal
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        {...rest}
      />
      {error && (
        <FormError>
          {error.type === 'required' ? 'This field is required' : error.message}
        </FormError>
      )}
    </Flex>
  )
}

export default FormDatePicker
