import styled from 'styled-components'
import { color, layout, compose } from 'styled-system'

export const CarouselImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${compose(color, layout)}
`

export const CarouselImg = styled.img`
  object-fit: ${props => props.objectFit};
  height: ${props => (props.imageHeight ? props.imageHeight : '100%')};
  width: ${props => (props.imageWidth ? props.imageWidth : '100%')};
`
