import styled from 'styled-components'

import { theme } from 'styles/theme'

export const StyledLabel = styled.label`
  font-family: ${theme.typography.fontFamily};
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-transform: ${props => (props.doNotCapitalize ? 'none' : 'capitalize')};
`
