import React from 'react'
import { useHistory } from 'react-router'

import { Flex, Paragraph as P, Image, Spacer, RowDivider } from 'components'
import {
  formatAsCurrency,
  ordinalSuffix,
  advancedDayjs,
  fetchCardIcon,
  setTransactionReceiptLocalDb,
  logUserInteractionGA,
} from 'utils'
import { theme } from 'styles/theme'

const TransactionReceiptRow = ({ data, paymentMethod, merchant, store, vouchers, orderId }) => {
  const history = useHistory()
  const { total_amount, sequence, payment_transaction, is_early_repayment } = data

  const pMethod = payment_transaction ? payment_transaction.payment_method : paymentMethod

  // *Methods
  const handleRowClick = () => {
    logUserInteractionGA(`Click: View Transaction Receipt`, {
      payment_transaction: data.payment_transaction ? data.payment_transaction.id : 'null',
    })
    setTransactionReceiptLocalDb({
      merchant: merchant,
      store: store,
      repayment: data,
      paymentMethod: pMethod,
      vouchers: vouchers,
      orderId: orderId,
    })

    history.push('/transaction/receipt')
  }

  // *JSX
  return (
    <>
      <Flex flexDirection="column" onClick={handleRowClick}>
        <Flex justifyContent="space-between">
          <P style={{ fontWeight: 700 }}>{store.name}</P>
          <P style={{ fontWeight: 700 }}>{formatAsCurrency(total_amount)}</P>
        </Flex>
        <Spacer height={3} />

        <Flex>
          {is_early_repayment ? (
            <P variant="subcopy" color={theme.colors.typography.gray7}>
              Early repayment
            </P>
          ) : (
            <P variant="subcopy" color={theme.colors.typography.gray7}>{`${
              sequence + 1
            }${ordinalSuffix(sequence + 1)} payment`}</P>
          )}
        </Flex>
        <Spacer height={3} />

        <Flex justifyContent="space-between">
          <P variant="subcopy" color={theme.colors.typography.gray7} style={{ fontWeight: 500 }}>
            {`Paid ${advancedDayjs(
              payment_transaction ? payment_transaction.created_at : data.due_at,
            ).format('Do MMM YYYY hh:mma')}`}
          </P>
          <Flex>
            {pMethod.mode === 'card' && (
              <>
                <Image src={fetchCardIcon(pMethod.card.brand)} alt="card_icon" width="32" />
                <P
                  variant="subcopy"
                  color={theme.colors.typography.gray7}
                  style={{ fontWeight: 500, marginTop: 3 }}
                  ml={1}
                >
                  {`**** ${pMethod.card.ending_digits}`}
                </P>
              </>
            )}
            {pMethod.mode === 'edda' && (
              <>
                <P>{pMethod.edda_request.bank_name}</P>
                <P
                  variant="subcopy"
                  color={theme.colors.typography.gray7}
                  style={{ fontWeight: 500, marginTop: 3 }}
                  ml={1}
                >
                  {`*** ${pMethod.edda_request.bank_acc_no_last_4}`}
                </P>
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
      <RowDivider style={{ margin: '15px 0' }} />
    </>
  )
}

export default TransactionReceiptRow
