import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'

import {
  DraggableOverlay,
  Heading as H,
  Paragraph as P,
  Flex,
  Spacer,
  Footer,
  Button,
  RowDivider,
} from 'components'
import { theme } from 'styles/theme'

import TransactionRow from './TransactionRow/TransactionRow'
import { logUserInteractionGA } from 'utils'

const PaymentMethodRemoveError = ({ orders, handleClearError }) => {
  const history = useHistory()

  // *JSX
  return (
    <DraggableOverlay cancel={handleClearError}>
      <Flex flexDirection="column" mx={2} pb={120}>
        <H variant="h4">Payment method in use</H>
        <Spacer height={15} />

        <P color={theme.colors.typography.gray11}>
          This payment method is being used by the following transactions for instalments. Switch
          them to another method before trying again.
        </P>
        <Spacer height={15} />

        <RowDivider />
        {orders.map(order => {
          return <TransactionRow order={order} type="completed" key={order.code} />
        })}
      </Flex>

      <Footer withTopBorder>
        <Button
          onClick={() => {
            logUserInteractionGA('Click: Manage Transactions')
            history.push('/transactions')
          }}
        >
          MANAGE TRANSACTIONS
        </Button>
      </Footer>
    </DraggableOverlay>
  )
}

PaymentMethodRemoveError.propTypes = {
  orders: PropTypes.array.isRequired,
  handleClearError: PropTypes.func.isRequired,
}

export default PaymentMethodRemoveError
