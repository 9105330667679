import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useForm } from 'react-hook-form'

import {
  PageWrapper,
  Footer,
  Button,
  Flex,
  NavigateBack,
  ProgressBar,
  FormInput,
  Spacer,
  Modal,
} from 'components'
import { useBuyerStore } from 'stores'
import { Success } from 'web/buyers/pages'
import { EMAIL_REGEX } from 'const'

const FillWithCtos = () => {
  const location = useLocation().search
  const status = new URLSearchParams(location).get('status')

  const history = useHistory()

  const [ctosIsLoading, setCtosIsLoading] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const {
    response: { ctosUrl: ctosUrlResponse },
    errors: { ctosUrl: ctosUrlErrors },
    resetStates: resetStatesBuyer,
    fetchCtosUrl,
  } = useBuyerStore()

  // *Form settings
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isValid },
    watch,
  } = useForm({
    mode: 'all',
  })

  // *Methods
  const isValidNric = () => {
    const MY_NRIC_LENGTH = 12
    return watch('nric').length === MY_NRIC_LENGTH
  }

  const submitForm = () => {
    handleSubmit(data => {
      const { nric, full_name, email } = data
      setCtosIsLoading(true)
      fetchCtosUrl(nric, full_name, email)
    })()
  }

  // *Effects
  useEffect(() => {
    return () => resetStatesBuyer('ctosUrl')
  }, [])

  useEffect(() => {
    if (ctosUrlResponse && ctosUrlResponse.url) {
      window.location.href = ctosUrlResponse.url
      resetStatesBuyer('ctosUrl')
    }
  }, [ctosUrlResponse])

  useEffect(() => {
    if (ctosUrlErrors) {
      setShowErrorModal(true)
      setCtosIsLoading(false)
    }
  }, [ctosUrlErrors])

  // *JSX
  if (status === 'success') return <Success />

  return (
    <PageWrapper withTopNav>
      {/* Modals */}
      {showErrorModal && (
        <Modal
          messageArray={ctosUrlErrors && [ctosUrlErrors]}
          primaryButtonText="Retry"
          primaryButtonAction={() => {
            setShowErrorModal(false)
            window.location.reload()
          }}
          secondaryButtonText="Exit"
          secondaryButtonAction={() => history.push('/verify?type=identity')}
        />
      )}

      {/* Navigation */}
      <NavigateBack progressBar={<ProgressBar variant="narrow" current={1} total={1} />}>
        Verify Identity
      </NavigateBack>

      <form onSubmit={submitForm} style={{ width: '100%' }}>
        <Flex px={2} flexDirection="column">
          <Flex pt={2} pb={110} width="100%" flexDirection="column" overflowY="scroll">
            <FormInput
              label="NRIC"
              showLabel
              register={register}
              id="nric"
              type="number"
              error={formErrors.nric}
              required
            />
            <Spacer height={15} />

            <FormInput
              label="Full Name (as per IC)"
              doNotCapitalize
              showLabel
              register={register}
              id="full_name"
              type="text"
              error={formErrors.full_name}
              required
            />
            <Spacer height={15} />

            <FormInput
              label="email"
              showLabel
              register={register}
              id="email"
              type="text"
              error={formErrors.email}
              required
              validationRules={{
                pattern: {
                  value: EMAIL_REGEX,
                  message: 'Please enter a valid email address',
                },
              }}
            />
            <Spacer height={15} />
          </Flex>
        </Flex>

        <Footer
          style={{
            borderTop: '1px solid #e8e8e8',
            backgroundColor: 'white',
          }}
        >
          <Button
            onClick={submitForm}
            type="button"
            disabled={!isValid || !isValidNric()}
            isLoading={ctosIsLoading}
          >
            SUBMIT
          </Button>
        </Footer>
      </form>
    </PageWrapper>
  )
}

export default FillWithCtos
