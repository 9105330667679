import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const useTrackingSegment = () => {
  const location = useLocation()

  useEffect(() => {
    if (!window.analytics)
      return console.warn(
        'Tracking not enabled, as window.analytics is not available. Please make sure Segment script is provided in index.html.',
      )

    window.analytics.page()
  }, [location])
}

export default useTrackingSegment
