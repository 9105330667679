import { useEffect } from 'react'
import { useLocation } from 'react-router'

import { GA_MEASUREMENT_ID } from 'const'

const useTrackingGA = () => {
  const location = useLocation()

  useEffect(() => {
    if (!window.gtag || !GA_MEASUREMENT_ID) {
      console.warn(
        'Tracking not enabled, please make sure window.gtag is present by providing a GA script in index.html, and provide a GA_MEASUREMENT_ID',
      )
      return
    }

    window.gtag('config', GA_MEASUREMENT_ID, {
      page_title: location.pathname,
      page_path: location.pathname + location.search,
    })
  }, [location])
}

export default useTrackingGA
