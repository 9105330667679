import React from 'react'
import PropTypes from 'prop-types'
import { AnimatePresence } from 'framer-motion'

import { RoundedContainer, Flex, Paragraph as P, Spacer, Button } from 'components'

import { StyledOverlay, StyledModalContainer } from './modalStyle'

const Modal = ({
  children,
  messageArray,
  primaryButtonText,
  primaryButtonAction,
  secondaryButtonText,
  secondaryButtonAction,
  ...rest
}) => {
  // manual mode overrides the content, allow user to render children instead
  if (children) {
    return (
      <AnimatePresence>
        <StyledOverlay id="is-overlay">
          <StyledModalContainer initial={{ y: 1000 }} animate={{ y: 0 }} exit={{ y: 1000 }}>
            <RoundedContainer width="90%" {...rest}>
              <Flex flexDirection="column">{children}</Flex>
            </RoundedContainer>
          </StyledModalContainer>
        </StyledOverlay>
      </AnimatePresence>
    )
  }

  return (
    <AnimatePresence>
      <StyledOverlay>
        <StyledModalContainer initial={{ y: 1000 }} animate={{ y: 0 }} exit={{ y: 1000 }}>
          <RoundedContainer width="90%" {...rest}>
            <Flex flexDirection="column">
              {messageArray &&
                messageArray.map((chunk, i) => {
                  return (
                    <div key={i}>
                      <P>{chunk}</P>
                      <Spacer height={10} />
                    </div>
                  )
                })}
              <Flex flexDirection="column">
                <Button onClick={primaryButtonAction} mb={0}>
                  {primaryButtonText}
                </Button>
                {secondaryButtonText && (
                  <>
                    <Spacer width={20} />
                    <Button variant="secondary" onClick={secondaryButtonAction}>
                      {secondaryButtonText}
                    </Button>
                  </>
                )}
              </Flex>
            </Flex>
          </RoundedContainer>
        </StyledModalContainer>
      </StyledOverlay>
    </AnimatePresence>
  )
}

Modal.propTypes = {
  messageArray: PropTypes.array,
  primaryButtonText: PropTypes.string,
  primaryButtonAction: PropTypes.func,
  secondaryButtonText: PropTypes.string,
  secondaryButtonAction: PropTypes.func,
}

export default Modal
