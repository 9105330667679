import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router'

import { captureIncomingQrCode, getTokenLocalDb, getIsRepeatUser } from 'utils'

const PrivateRoute = props => {
  const { component: Component, title, ...rest } = props

  useEffect(() => {
    document.title = title
  }, [title])

  captureIncomingQrCode() // TODO: is this needed?
  const token = getTokenLocalDb()

  if (!token) {
    if (!getIsRepeatUser()) {
      return <Redirect to={{ pathname: '/onboarding', state: { from: props.location } }} />
    }

    return <Redirect to={{ pathname: '/register', state: { from: props.location } }} />
  }

  return <Route {...rest} render={() => <Component {...props} />} />
}

export default PrivateRoute
