import React, { useEffect } from 'react'
import { Route } from 'react-router'

const PublicRoute = props => {
  const { component: Component, title, ...rest } = props

  useEffect(() => {
    document.title = title
  }, [title])

  return <Route {...rest} render={() => <Component {...props} />} />
}

export default PublicRoute
