import { advancedDayjs } from 'utils'

export const groupNotificationsIntoDays = notifications => {
  let results = {}

  notifications.map(notification => {
    const date = advancedDayjs(notification.created_at).format('Do MMM YYYY')

    if (results[date]) {
      return (results[date] = [...results[date], { ...notification }])
    } else {
      return (results = { ...results, [date]: [{ ...notification }] })
    }
  })

  return results
}
