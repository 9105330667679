import React from 'react'
import { Switch } from 'react-router'
import { ErrorFallback, PublicRoute } from 'components'
import { AppStore } from 'web/buyers/pages'

const BuyerCommon = () => {
  return (
    <Switch>
      <PublicRoute exact path="/appstore" component={AppStore} title="App Store" />
      <PublicRoute exact path="/error" component={ErrorFallback} title="Error" />
    </Switch>
  )
}

export default BuyerCommon
