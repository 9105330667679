import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'

import { useBuyerStore } from 'stores'
import { blueDot, newPaymentMethodIntro } from 'assets'
import { Flex, Image, Paragraph as P, Spacer, RowDivider } from 'components'
import { theme } from 'styles/theme'
import { advancedDayjs, capitalizeFirstLetter, logUserInteractionGA } from 'utils'

const NotificationRow = ({ notification, markReadAll }) => {
  const history = useHistory()
  const { id, is_read: isRead, message, created_at: createdAt } = notification

  const [logo, setLogo] = useState(null)
  const [orderCode, setOrderCode] = useState('')
  const [notificationType, setNotificationType] = useState('')
  const [displayIsRead, setDisplayIsRead] = useState(isRead)

  const { submitNotificationRead } = useBuyerStore()

  // *Methods
  const handleClickNotification = id => {
    logUserInteractionGA(`Click: Notification`, { id: id })
    submitNotificationRead(id)
    setDisplayIsRead(true)

    if (notificationType === 'credit_assessment') return history.push('/credit')
    if (orderCode.length > 0)
      return history.push(`/transaction/order?code=${orderCode}&type=payment`)
  }

  // *Effects
  useEffect(() => {
    if (notification && notification.type && notification.type.length > 0) {
      if (notification.type.includes('credit_assessment')) {
        setNotificationType('credit_assessment')
      }
    }

    if (notification && notification.order) {
      if (notification.order.code) setOrderCode(notification.order.code)
      if (notification.order.merchant && notification.order.merchant.logo)
        setLogo(notification.order.merchant.logo)
    }
  }, [])

  // *JSX
  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        stretch
        py={20}
        style={{
          backgroundColor: `${markReadAll || displayIsRead ? '' : theme.colors.faintBlue}`,
          width: 'calc(100% + 40px)',
          marginLeft: -20,
          paddingLeft: 20,
          paddingRight: 20,
          position: 'relative',
          zIndex: -1,
        }}
        onClick={() => handleClickNotification(id)}
      >
        <Flex alignItems="center">
          <Flex style={{ maxWidth: 42, maxHeight: 42 }}>
            <Image
              withTransition
              src={logo || newPaymentMethodIntro}
              alt="merchant_logo"
              style={{ borderRadius: '50%' }}
              initial={{ opacity: 0 }}
              transition={{ delay: 0.2, duration: 0.5 }}
              animate={{ opacity: 1 }}
              width={42}
              height={42}
            />
          </Flex>
          <Spacer width={20} />
          <Flex flexDirection="column">
            <P variant="small" style={{ fontWeight: 600 }}>
              {capitalizeFirstLetter(message)}
            </P>
            <P variant="small" color={theme.colors.typography.gray1} style={{ lineHeight: '28px' }}>
              {capitalizeFirstLetter(advancedDayjs(createdAt).fromNow())}
            </P>
          </Flex>
        </Flex>
        <Spacer width={20} />

        {markReadAll || displayIsRead ? (
          <Spacer width={10} />
        ) : (
          <Image src={blueDot} width={10} alt="unread_notification" />
        )}
      </Flex>
      <RowDivider style={{ width: 'calc(100% + 40px)', margin: '0 0 0 -20px' }} />
    </>
  )
}

NotificationRow.propTypes = {
  notification: PropTypes.object.isRequired,
  markReadAll: PropTypes.bool.isRequired,
}

export default NotificationRow
