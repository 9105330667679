import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { Header, Flex, Spacer } from 'components'

const PayNextHeaderLoading = () => {
  return (
    <Header withBottomBorder>
      <Flex mt={45} ml={45}>
        <Skeleton height={68} width={68} style={{ borderRadius: 12 }} />

        <Flex flexDirection="column" ml={3} justifyContent="center">
          <Skeleton height={36} width={110} />
          <Skeleton height={23} width={110} />
        </Flex>
      </Flex>
      <Spacer height={20} />
    </Header>
  )
}

export default PayNextHeaderLoading
