import styled from 'styled-components'
import Collapse from 'rc-collapse'
import 'rc-collapse/assets/index.css'

export const StyledCollapse = styled(Collapse)`
  &.rc-collapse {
    width: 100%;
    border-radius: 0px;
    border-color: transparent;
    background-color: ${props => props.theme.colors.white};
    border: 0px;
    > .rc-collapse-item {
      border: 0px;
      > .rc-collapse-header {
        height: auto;
        line-height: normal;
        text-indent: 0px;
        text-transform: none;
        display: flex;
        align-items: center;
      }
    }
    .rc-collapse-content {
      border-bottom-width: 0px;
      border-right-width: 0px;
      border-style: solid;
      border-top-width: 0px;
    }
    .rc-collapse-content-box {
      margin-top: 0px;
      margin-bottom: 12px;
    }
  }
`
