import React from 'react'

import { Paragraph as P } from 'components'
import { theme } from 'styles/theme'

const FormError = ({ children, ...rest }) => {
  return (
    <P variant="subcopy" pt="5px" color={theme.colors.actions.failureRed} {...rest}>
      {children}
    </P>
  )
}

export default FormError
