import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'

import { Modal, Loading, TriggerPopup } from 'components'
import { useOrderStore, useMerchantStore } from 'stores'
import {
  getReferredCodeLocalDb,
  setDraftOrderLocalDb,
  removeReferredCodeLocalDb,
  getCheckoutIdLocalDb,
  removeCheckoutIdLocalDb,
  getStoreIdLocalDb,
  removeStoreIdLocalDb,
  removeFromStoreIdLocalDb,
  setFromStoreIdLocalDb,
  captureIncomingQrCode,
  redirectToAppOrDownload,
  isDevEnvironment,
  removeBackToMerchantLink,
} from 'utils'

import ContinueOrderInAppOrBrowser from './ContinueOrderInAppOrBrowser/ContinueOrderInAppOrBrowser'

const Order = () => {
  const history = useHistory()

  // capture token is it contains assume_account
  const location = window.location.search
  const redirect_to_mobile_app = new URLSearchParams(location).get('redirect_to_mobile_app')

  const [referredCode, setReferredCode] = useState(null)
  const [checkoutId, setCheckoutId] = useState(null)
  const [storeId, setStoreId] = useState(null)

  const [shouldShowRedirectPopup, setShouldShowRedirectPopup] = useState(false)
  const [shouldCreateOrder, setShouldCreateOrder] = useState(() =>
    isDevEnvironment() ? false : false,
  )

  // *Stores
  const {
    response: { fromCheckout: fromCheckoutResponse, fromOrderCampaign: fromOrderCampaignResponse },
    errors: { fromCheckout: fromCheckoutError, fromOrderCampaign: fromOrderCampaignError },
    resetStates: resetStatesOrder,
    submitFromCheckout,
    submitFromOrderCampaign,
  } = useOrderStore()

  const {
    response: { store: storeResponse },
    errors: { store: storeError },
    resetStates: resetStatesMerchant,
    fetchStore,
  } = useMerchantStore()

  // *Methods
  const handleError = () => {
    resetStatesOrder('fromOrderCampaign')
    resetStatesOrder('fromCheckout')
    resetStatesMerchant('store')
    removeBackToMerchantLink()

    return history.push('/home')
  }

  const handleContinueInApp = () => {
    redirectToAppOrDownload()
  }

  const handleContinueInBrowser = () => {
    setShouldShowRedirectPopup(false)
    setShouldCreateOrder(true)
  }

  // *Effects
  useEffect(() => {
    captureIncomingQrCode()

    const code = getReferredCodeLocalDb()
    const checkoutId = getCheckoutIdLocalDb()
    const storeId = getStoreIdLocalDb()
    setReferredCode(code)
    setCheckoutId(checkoutId)
    setStoreId(storeId)

    // only handle deeplink logic if redirect_to_mobile_app is true
    if (code || checkoutId || storeId) {
      if (redirect_to_mobile_app === 'true') {
        setShouldShowRedirectPopup(true)
      } else {
        handleContinueInBrowser()
      }
    }

    return () => {
      resetStatesOrder('fromOrderCampaign')
      resetStatesOrder('fromCheckout')
      resetStatesMerchant('store')
    }
  }, [])

  useEffect(() => {
    if (!shouldCreateOrder) return

    if (referredCode) {
      submitFromOrderCampaign(referredCode)
    }

    if (checkoutId) {
      submitFromCheckout(checkoutId)
    }

    if (storeId) {
      fetchStore(storeId)
    }
  }, [referredCode, checkoutId, storeId, shouldCreateOrder])

  useEffect(() => {
    if (fromOrderCampaignResponse) {
      setDraftOrderLocalDb(fromOrderCampaignResponse)
      removeReferredCodeLocalDb()
      removeStoreIdLocalDb()
      removeFromStoreIdLocalDb()
      resetStatesOrder('fromOrderCampaign')

      history.push('/home')
      if (fromOrderCampaignResponse.code) {
        return history.push(`/order/pay?code=${fromOrderCampaignResponse.code}`)
      } else {
        return history.push('/order/pay')
      }
    }
  }, [fromOrderCampaignResponse])

  useEffect(() => {
    if (fromCheckoutResponse) {
      setDraftOrderLocalDb(fromCheckoutResponse)
      removeCheckoutIdLocalDb()
      removeStoreIdLocalDb()
      removeFromStoreIdLocalDb()
      resetStatesOrder('fromCheckout')

      history.push('/home')
      if (fromCheckoutResponse.code) {
        return history.push(`/order/pay?order_id=${fromCheckoutResponse.code}`)
      } else {
        return history.push('/order/pay')
      }
    }
  }, [fromCheckoutResponse])

  useEffect(() => {
    if (storeResponse) {
      setFromStoreIdLocalDb(storeResponse)
      removeCheckoutIdLocalDb()
      removeReferredCodeLocalDb()
      resetStatesMerchant('store')

      history.push('/home')
      return history.push(`/order/pay/manual?store_id=${storeId}`)
    }
  }, [storeResponse])

  // *JSX
  // Handle order creation
  if (shouldShowRedirectPopup) {
    return (
      <ContinueOrderInAppOrBrowser
        handleContinueInApp={handleContinueInApp}
        handleContinueInBrowser={handleContinueInBrowser}
      />
    )
  }

  // Error handling
  if (fromCheckoutError) {
    return (
      <TriggerPopup
        showPopup={fromCheckoutError}
        message={fromCheckoutError}
        callback={handleError}
      />
    )
  }

  if (fromOrderCampaignError) {
    return (
      <TriggerPopup
        showPopup={fromOrderCampaignError}
        message={fromOrderCampaignError}
        callback={handleError}
      />
    )
  }

  if (fromOrderCampaignError && fromOrderCampaignError.referred_code) {
    return (
      <Modal
        messageArray={['Referred code is invalid.']}
        primaryButtonAction={() => {
          resetStatesOrder('fromOrderCampaign')
          history.push('/home')
        }}
        primaryButtonText="RETURN HOME"
      />
    )
  }

  if (fromCheckoutError && fromCheckoutError.checkout_id) {
    return (
      <Modal
        messageArray={['Checkout ID is invalid.']}
        primaryButtonAction={() => {
          resetStatesOrder('fromCheckout')
          history.push('/home')
        }}
        primaryButtonText="RETURN HOME"
      />
    )
  }

  if (storeError && storeError.message) {
    return (
      <Modal
        messageArray={[storeError.message]}
        primaryButtonAction={() => {
          resetStatesOrder('store')
          history.push('/home')
        }}
        primaryButtonText="RETURN HOME"
      />
    )
  }

  return <Loading variant="fullscreen" />
}

export default Order
