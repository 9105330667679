import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useFormContext } from 'react-hook-form'
import { useBuyerStore } from 'stores'
import { AnimatePresence } from 'framer-motion'
import {
  Flex,
  Image,
  PageWrapper,
  NavigateBack,
  Paragraph as P,
  ProgressBar,
  Spacer,
  Footer,
  Button,
  TriggerPopup,
} from 'components'
import { theme } from 'styles/theme'
import { addBankTransfer } from 'assets'
import { GENERIC_ERR_MESSAGE } from 'const'
import { capitalizeFirstLetter, logUserInteractionGA } from 'utils'

import BankMandateSkeleton from './BankMandateSkeleton'
import BankMandateStep1 from './BankMandateStep1'
import BankMandateStep2 from './BankMandateStep2'

const STEP1_FIELDS = ['buyer_name', 'buyer_nric', 'buyer_phone_number', 'buyer_email']

const BankMandateForm = ({
  backButtonText,
  buttonText,
  buttonVariant = 'primary',
  path,
  editableFields,
  handleSubmit,
  defaultData = null,
  paymentMethodId,
}) => {
  const history = useHistory()
  const location = useLocation().search
  const step = new URLSearchParams(location).get('step')

  const {
    trigger,
    reset,
    formState: { isValid },
  } = useFormContext()
  const current = { 1: 0.5, 2: 1 }[step] ?? 0

  const [isDelay, setIsDelay] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [setupError, setSetupError] = useState('')

  const {
    response: { paynetFPXEmandateMeta: paynetMetaResponse },
    fetchPaynetFPXEmandateMeta,
    resetStates,
  } = useBuyerStore()

  const handleClearError = () => {
    resetStates('paymentMethodSubmit')
    setSetupError('')
    setIsLoading(false)
  }
  const handlePress = async () => {
    if (step === '1') {
      const formValid = await trigger(STEP1_FIELDS)
      if (formValid) {
        history.push(`/paymentmethods/${path}/bank?step=2`, { data: defaultData, paymentMethodId })
      }
    } else if (step === '2') {
      logUserInteractionGA(`${path.toUpperCase()}: FPX EMandate`)
      setIsLoading(true)

      const res = await handleSubmit()
      if (!res) return

      if (res.success) {
        window.location.replace(res.data.fpx.auth_url)
      } else {
        const errorArr = Object.entries(res.errors?.fpx?.emandate || {})[0]
        if (!errorArr) {
          setSetupError(GENERIC_ERR_MESSAGE)
          return
        }
        const [field, value] = errorArr
        const key = capitalizeFirstLetter(field.split('_').join(' '))
        setSetupError(`${key}: ${value}`)
      }
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsDelay(false)
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [setIsDelay])

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchPaynetFPXEmandateMeta()
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [fetchPaynetFPXEmandateMeta])

  useEffect(() => {
    if (paynetMetaResponse?.initial) {
      reset(defaultData || paynetMetaResponse.initial)
    }
  }, [reset, defaultData, paynetMetaResponse?.initial])

  const stepMap = {
    1: <BankMandateStep1 editableFields={editableFields} />,
    2: <BankMandateStep2 editableFields={editableFields} />,
  }

  const formDisabled = isDelay || !isValid

  return (
    <AnimatePresence>
      {/* Error handling */}
      <TriggerPopup
        message={setupError}
        showPopup={setupError.length > 0}
        callback={handleClearError}
        stayAtCurrentPage
        buttonText="OKAY"
        key="error_popup"
      />

      <PageWrapper key="page_wrapper" withTopNav>
        <NavigateBack
          progressBar={
            <ProgressBar
              variant="narrow"
              barColor={theme.colors.buttons.marineBlue}
              current={current}
              total={1}
            />
          }
        >
          {backButtonText}
        </NavigateBack>

        <Flex mt={2}>
          <Image src={addBankTransfer} alt="bank_transfer_icon" width={24} />
          <P ml={2} style={{ fontWeight: 600 }}>
            Bank Mandate
          </P>
        </Flex>
        <Spacer height={25} />

        <form style={{ width: '100%' }}>
          <fieldset className="FormGroup" style={{ border: 'none' }}>
            {isDelay ? <BankMandateSkeleton /> : step ? stepMap[step] : null}
          </fieldset>
        </form>

        <Footer withTopBorder>
          <Button
            type="button"
            variant={step === '1' ? 'primary' : buttonVariant}
            onClick={handlePress}
            disabled={formDisabled}
            isLoading={isLoading}
          >
            {step === '1' ? 'CONTINUE' : buttonText}
          </Button>
        </Footer>
      </PageWrapper>
    </AnimatePresence>
  )
}

export default BankMandateForm
