import { useHistory } from 'react-router-dom'
import { getCountryCodeLocalDb, setAssumeAccount, setTokenLocalDb } from 'utils'
import { clearLocalDb } from './clearLocalDb'

export const captureAssumeAccountToken = () => {
  const history = useHistory()
  // capture token is it contains assume_account
  const location = window.location.search
  const assumeToken = new URLSearchParams(location).get('assume_token')

  if (assumeToken) {
    clearLocalDb()
    setAssumeAccount()
    setTokenLocalDb(assumeToken)

    const countryCode = getCountryCodeLocalDb()
    return history.replace(`/${countryCode}/assumeaccount/`)
  }
}
