import React, { useRef, useState, useEffect } from 'react'

import {
  Header,
  Flex,
  RoundedContainer,
  Heading as H,
  Paragraph as P,
  ProgressBar,
  Spacer,
  Image,
} from 'components'
import { getDraftOrderLocalDb, formatAsCurrency } from 'utils'
import { theme } from 'styles/theme'
import { transparent, voucher } from 'assets'

const PaymentHeader = ({ initial, current, total }) => {
  const draftOrder = useRef(getDraftOrderLocalDb())

  const [merchantLogo, setMerchantLogo] = useState(null)
  const [voucherApplied, setVoucherApplied] = useState(false)

  // *Effects
  useEffect(() => {
    if (draftOrder.current) {
      setMerchantLogo(
        <Image
          src={draftOrder.current.merchant.logo || transparent}
          alt="merchant_logo"
          width="100%"
        />,
      )

      if (
        draftOrder.current &&
        draftOrder.current.vouchers &&
        draftOrder.current.vouchers.length > 0
      ) {
        setVoucherApplied(true)
      }
    }
  }, [draftOrder.current])

  // *JSX
  return (
    <Header>
      <Flex mt={42} ml={45}>
        <RoundedContainer
          height={68}
          width={68}
          justifyContent="center"
          boxShadow="0px 2px 7px 0px #00000019"
          borderRadius={12}
          p={2}
        >
          {merchantLogo}
        </RoundedContainer>

        <Flex flexDirection="column" mx={3} justifyContent="center">
          <Flex alignItems="center">
            <H variant="h1" fontSize={32}>
              {draftOrder.current && formatAsCurrency(draftOrder.current.grand_total_amount)}
            </H>
            {voucherApplied && (
              <>
                <Spacer width={5} />
                <Image src={voucher} alt="voucher" width={21} height={21} />
              </>
            )}
          </Flex>
          <P color={theme.colors.typography.gray8} style={{ fontWeight: 600 }}>
            {draftOrder.current && draftOrder.current.store.name}
          </P>
        </Flex>
      </Flex>
      <Spacer height={10} />
      <ProgressBar
        barColor={theme.colors.buttons.marineBlue}
        initial={initial}
        current={current}
        total={total}
        style={{ marginLeft: 0 }}
        variant="narrow"
      />
    </Header>
  )
}

export default PaymentHeader
