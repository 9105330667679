import React, { useState } from 'react'
import { AnimatePresence } from 'framer-motion'

import { Flex, Spacer, Image } from 'components'
import { caretDown } from 'assets'
import { theme } from 'styles/theme'

import DropDownOptions from './DropDownOptions/DropDownOptions'

const DropDown = ({ options, activeValue, onClickOption }) => {
  const { name } = activeValue
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <AnimatePresence>
      <Flex flexDirection="column">
        <Flex
          justifyContent="space-between"
          style={{
            border: `1px solid ${theme.colors.borderGray2}`,
            borderRadius: 12,
            padding: '12px 20px',
            backgroundColor: 'white',
            width: '100%',
          }}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {name}
          <Spacer width={20} />
          <Image
            src={caretDown}
            alt="expand_dropdown"
            initial={{ rotate: isExpanded ? 0 : 180 }}
            animate={{ rotate: isExpanded ? 180 : 0 }}
          />
        </Flex>
        {isExpanded && (
          <DropDownOptions
            options={options}
            activeValue={activeValue}
            onClickOption={onClickOption}
            setIsExpanded={setIsExpanded}
          />
        )}
      </Flex>
    </AnimatePresence>
  )
}

export default DropDown
