import React from 'react'
import { useFormContext } from 'react-hook-form'
import { FormInput, Spacer } from 'components'
import { EMAIL_REGEX } from 'const'

const BankMandateStep1 = ({ editableFields }) => {
  const {
    register,
    formState: { errors: formErrors },
  } = useFormContext()

  return (
    <>
      <FormInput
        id="buyer_name"
        label="name"
        showLabel
        type="text"
        placeholder="J.Smith"
        error={formErrors.buyer_name}
        register={register}
        readOnly={!editableFields.includes('buyer_name')}
        required
      />

      <Spacer height={15} />
      <FormInput
        id="buyer_nric"
        label="IC number"
        showLabel
        type="number"
        placeholder="123456789"
        error={formErrors.buyer_nric}
        register={register}
        readOnly={!editableFields.includes('buyer_nric')}
        required
      />

      <Spacer height={15} />
      <FormInput
        id="buyer_phone_number"
        label="mobile number"
        showLabel
        type="number"
        placeholder="123456789"
        error={formErrors.buyer_phone_number}
        register={register}
        readOnly={!editableFields.includes('buyer_phone_number')}
        required
      />

      <Spacer height={15} />
      <FormInput
        id="buyer_email"
        label="email"
        showLabel
        type="text"
        placeholder="example@email.com"
        error={formErrors.buyer_email}
        register={register}
        readOnly={!editableFields.includes('buyer_email')}
        validationRules={{
          pattern: {
            value: EMAIL_REGEX,
            message: 'Please enter a valid email address',
          },
        }}
        required
      />
    </>
  )
}

export default BankMandateStep1
