import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'

import { Flex, Paragraph as P, Spacer, Image, TriggerPopup } from 'components'
import { visaLogo, mastercardLogo, cardIcon, caretDown } from 'assets'
import { theme } from 'styles/theme'
import { logUserInteractionGA, secureCardNumber, setPaymentMethodRedirectLocalDb } from 'utils'

import {
  StyledDropdownOptionContainer,
  StyledDropdownOption,
  StyledCardIcon,
} from './paymentMethodDropdownStyle'

const PaymentMethodDropdown = ({
  paymentMethods,
  setSelectedPaymentMethod,
  selectedPaymentMethodWarning,
  setSelectedPaymentMethodWarning,
  selectedPaymentMethodError,
  setSelectedPaymentMethodError,
  presetActivePaymentMethod,
  saveCurrentUrl,
  useNewPaymentMethod,
  allowInvalidPaymentMethod,
  stayAtCurrentPageAfterClearingError,
}) => {
  const [activePaymentMethod, setActivePaymentMethod] = useState(presetActivePaymentMethod || null)
  const [errorMessage, setErrorMessage] = useState('')
  const [showOptions, setShowOptions] = useState(false)
  const [showNewMethodPopup, setShowNewMethodPopup] = useState(false)

  const cardPaymentMethods = paymentMethods.filter(pm => pm.mode === 'card')
  const eDDAPaymentMethods = paymentMethods.filter(pm => pm.mode === 'edda')

  // *Methods
  const handleAddNewPaymentMethod = () => {
    logUserInteractionGA('Click: Add New Payment Method')
    if (saveCurrentUrl) {
      const currentUrl = window.location.href
      setPaymentMethodRedirectLocalDb(currentUrl)
    }
  }

  const handleError = () => {
    if (stayAtCurrentPageAfterClearingError) return setErrorMessage('')
    window.location.reload()
  }

  const renderIcon = brand => {
    switch (brand) {
      case 'visa':
        return (
          <Flex height={47}>
            <Image src={visaLogo} alt="visa_logo" style={{ width: 47 }} />
          </Flex>
        )
      case 'mastercard':
      case 'mc':
        return (
          <Flex height={47}>
            <Image src={mastercardLogo} alt="mastercard_logo" style={{ width: 47 }} />
          </Flex>
        )
      default:
        return (
          <StyledCardIcon>
            <Image src={cardIcon} alt="card_icon" />
          </StyledCardIcon>
        )
    }
  }

  const renderCard = card => {
    const { brand, ending_digits: endingDigits, funding } = card

    return (
      <Flex>
        {renderIcon(brand)}

        <Flex flexDirection="column" ml={3}>
          <P style={{ fontWeight: 700 }}>{funding === 'credit' ? 'Credit Card' : 'Debit Card'}</P>
          <Spacer height={5} />
          <P color={theme.colors.typography.gray1} style={{ fontWeight: 400 }}>
            {secureCardNumber(endingDigits)}
          </P>
        </Flex>
      </Flex>
    )
  }

  const renderCaret = () => {
    return (
      <motion.img
        src={caretDown}
        initial={{ rotate: showOptions ? 0 : 180 }}
        animate={{ rotate: showOptions ? 180 : 0 }}
        width={15}
      />
    )
  }

  const renderEDDA = edda_request => {
    const { bank_acc_no_last_4, bank_name } = edda_request

    return (
      <Flex>
        <Flex flexDirection="column" ml={3}>
          <P style={{ fontWeight: 700 }}>{bank_name}</P>
          <Spacer height={5} />
          <P color={theme.colors.typography.gray1} style={{ fontWeight: 400 }}>
            {`*** ${bank_acc_no_last_4}`}
          </P>
        </Flex>
      </Flex>
    )
  }

  const renderOptions = () => {
    return (
      <Flex flexDirection="column">
        {cardPaymentMethods &&
          cardPaymentMethods.map((cardItem, i) => {
            const card = cardItem.card
            return (
              <StyledDropdownOptionContainer key={`${card.ending_digits}_${i}`}>
                <StyledDropdownOption
                  style={{
                    border: 'none',
                  }}
                  onClick={() => {
                    logUserInteractionGA(`Select - Payment Method`, {
                      id: cardItem.id,
                    })
                    setActivePaymentMethod(cardItem)
                    setSelectedPaymentMethod(cardItem)
                  }}
                >
                  {renderCard(card)}
                </StyledDropdownOption>
              </StyledDropdownOptionContainer>
            )
          })}

        {eDDAPaymentMethods &&
          eDDAPaymentMethods.map(item => {
            return (
              <StyledDropdownOptionContainer key={item.id}>
                <StyledDropdownOption
                  style={{
                    border: 'none',
                  }}
                  onClick={() => {
                    logUserInteractionGA(`Select - Payment Method`, {
                      id: item.id,
                    })
                    setActivePaymentMethod(item)
                    setSelectedPaymentMethod(item)
                  }}
                >
                  {renderEDDA(item.edda_request)}
                </StyledDropdownOption>
              </StyledDropdownOptionContainer>
            )
          })}

        <StyledDropdownOptionContainer>
          <StyledDropdownOption
            onClick={() => {
              setShowNewMethodPopup(true)
            }}
            style={{
              border: 'none',
              padding: '15px 0',
              justifyContent: 'center',
            }}
          >
            <P color={theme.colors.buttons.marineBlue} style={{ fontWeight: 600 }}>
              Create new payment method
            </P>
          </StyledDropdownOption>
        </StyledDropdownOptionContainer>
      </Flex>
    )
  }

  // *Effects
  useEffect(() => {
    if (!presetActivePaymentMethod && paymentMethods && paymentMethods.length > 0) {
      const defaultPaymentMethod = paymentMethods.find(pm => pm.is_default === true)

      if (defaultPaymentMethod) {
        setActivePaymentMethod(defaultPaymentMethod)
        setSelectedPaymentMethod(defaultPaymentMethod)
      } else {
        setActivePaymentMethod(paymentMethods[0])
        setSelectedPaymentMethod(paymentMethods[0])
      }
    }
  }, [])

  useEffect(() => {
    if (useNewPaymentMethod) {
      setActivePaymentMethod(paymentMethods[paymentMethods.length - 1])
      setSelectedPaymentMethod(paymentMethods[paymentMethods.length - 1])
    }
  }, [useNewPaymentMethod])

  useEffect(() => {
    if (allowInvalidPaymentMethod) return

    if (activePaymentMethod && activePaymentMethod.collection_payment_method_warning_message) {
      setSelectedPaymentMethodWarning(activePaymentMethod.collection_payment_method_warning_message)
    } else {
      setSelectedPaymentMethodWarning('')
    }

    if (activePaymentMethod && activePaymentMethod.collection_payment_method_error_message) {
      setSelectedPaymentMethodError(activePaymentMethod.collection_payment_method_error_message)
      setErrorMessage(activePaymentMethod.collection_payment_method_error_message)
    } else {
      setSelectedPaymentMethodError('')
    }
  }, [activePaymentMethod])

  // *JSX
  // handle add new payment method
  if (showNewMethodPopup) {
    return (
      <TriggerPopup
        showPopup={showNewMethodPopup}
        message="You will be redirected back to this page right after adding your new payment method."
        callback={handleAddNewPaymentMethod}
        nextDestination="/paymentmethods/new"
        buttonText="OKAY"
        replaceHistory
      />
    )
  }

  // handle no paymentMethods
  if (!paymentMethods || paymentMethods.length === 0) {
    return (
      <Flex onClick={() => setShowOptions(!showOptions)} flexDirection="column">
        <StyledDropdownOption showOptions={showOptions} style={{ justifyContent: 'space-between' }}>
          <P color={theme.colors.typography.gray1}>Select payment method</P>
          {renderCaret()}
        </StyledDropdownOption>
        {showOptions && renderOptions()}
      </Flex>
    )
  }

  return (
    <>
      {/* Error handling */}
      <TriggerPopup
        showPopup={!!errorMessage}
        message={errorMessage}
        callback={handleError}
        stayAtCurrentPage
        buttonText="OKAY"
      />

      <Flex onClick={() => setShowOptions(!showOptions)} flexDirection="column" width="100%">
        <StyledDropdownOption showOptions={showOptions} style={{ justifyContent: 'space-between' }}>
          {activePaymentMethod && (
            <>
              {activePaymentMethod.mode === 'card' && renderCard(activePaymentMethod.card)}
              {activePaymentMethod.mode === 'edda' && renderEDDA(activePaymentMethod.edda_request)}
            </>
          )}
          {renderCaret()}
        </StyledDropdownOption>
        {showOptions && renderOptions()}
      </Flex>

      <Spacer height={3} />
      <P
        color={
          selectedPaymentMethodError
            ? theme.colors.actions.failureRed2
            : selectedPaymentMethodWarning
            ? theme.colors.actions.yellowWarning5
            : ''
        }
        variant="subcopy"
        style={{ fontWeight: 400, fontSize: 10 }}
      >
        {selectedPaymentMethodError || selectedPaymentMethodWarning}
      </P>
    </>
  )
}

PaymentMethodDropdown.propTypes = {
  paymentMethods: PropTypes.array,
  setSelectedPaymentMethod: PropTypes.func,
  selectedPaymentMethodError: PropTypes.string,
  setSelectedPaymentMethodError: PropTypes.func,
  presetActivePaymentMethod: PropTypes.object,
  saveCurrentUrl: PropTypes.bool,
  useNewPaymentMethod: PropTypes.bool,
  allowInvalidPaymentMethod: PropTypes.bool,
}

PaymentMethodDropdown.defaultProps = {
  saveCurrentUrl: true,
  useNewPaymentMethod: false,
  allowInvalidPaymentMethod: false,
}

export default PaymentMethodDropdown
