import { visaLogo, mastercardLogo, cardIcon } from 'assets'

export const fetchCardIcon = cardType => {
  switch (cardType) {
    case 'visa':
      return visaLogo
    case 'mastercard':
    case 'mc':
      return mastercardLogo
    default:
      return cardIcon
  }
}
