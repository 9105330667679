import { setCountryCodeLocalDb, getCountryCodeLocalDb, signOut, clearLocalDb } from 'utils'

// signs user out and redirect to corresponding register page if user changes country basename in url manually
export const autoCorrectUrlPath = () => {
  const countryCodeFromCookies = getCountryCodeLocalDb()
  const locationUrl = window.location.href

  let countryCodeFromUrl = ''
  if (locationUrl.includes('/my/')) {
    countryCodeFromUrl = 'my'
  }
  if (locationUrl.includes('/sg/')) {
    countryCodeFromUrl = 'sg'
  }

  // *handle redirection where there's no country code in url by adding country code into url
  if (countryCodeFromUrl === '') {
    const { protocol, hostname, port, pathname, search } = window.location
    const portNo = port ? `:${port}` : ''

    if (locationUrl.includes('/appstore')) {
      return
    }

    if (locationUrl.includes('/error')) {
      // handle error routing
      window.location.href = `${protocol}//${hostname}${portNo}/${countryCodeFromCookies}/error`
    } else {
      window.location.href = `${protocol}//${hostname}${portNo}/${countryCodeFromCookies}${pathname}${search}`
    }
    return
  }

  // *auto correct country code in url
  if (countryCodeFromCookies !== countryCodeFromUrl) {
    setCountryCodeLocalDb(countryCodeFromUrl)
    clearLocalDb()
    signOut()
  }
}
