import React from 'react'
import { useRanger } from 'react-ranger'

import { Flex } from 'components'

import { Handle } from './Handle/Handle'
import { Track } from './Track/Track'
import { Segment } from './Segment/Segment'

const StyledSlider = ({ values, setValues, min, max }) => {
  // const [values, setValues] = React.useState([15]);

  const { getTrackProps, segments, handles } = useRanger({
    min,
    max,
    stepSize: 1,
    values,
    onChange: setValues,
  })

  return (
    <Flex fitWidth flexDirection="column">
      <Track {...getTrackProps()}>
        {segments.map(({ getSegmentProps }, i) => (
          <Segment {...getSegmentProps()} index={i} key={i} />
        ))}
        {handles.map(({ value, active, getHandleProps }) => (
          <button
            key={value}
            {...getHandleProps({
              style: {
                appearance: 'none',
                border: 'none',
                background: 'transparent',
                outline: 'none',
              },
            })}
          >
            <Handle active={active} />
          </button>
        ))}
      </Track>
    </Flex>
  )
}

export default StyledSlider
