import React from 'react'

import { StyledContainer, StyledCircle } from './responsiveCircleStyle'

const ResponsiveCircle = ({ children, circleColor, ...rest }) => {
  return (
    <StyledContainer {...rest}>
      <StyledCircle circleColor={circleColor}>{children}</StyledCircle>
    </StyledContainer>
  )
}

export default ResponsiveCircle
