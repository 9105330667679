import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'

import { Flex, CircularProgress, Paragraph as P, Button } from 'components'
import { theme } from 'styles/theme'
import { advancedDayjs, formatAsCurrency, logUserInteractionGA } from 'utils'

const UpcomingPaymentRow = ({
  orderCode,
  percentageLong,
  percentageShort,
  percentageTotal,
  logo,
  merchantName,
  dueAt,
  amount,
  showAmountAsButton,
  repaymentState,
}) => {
  const history = useHistory()

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      overflow="hidden"
      onClick={() => {
        logUserInteractionGA(`Click: Order`, { id: orderCode })
        history.push(`/transaction/order?code=${orderCode}&type=payment`)
      }}
    >
      <Flex>
        <CircularProgress
          strokeWidth="5"
          sqSize="58"
          percentageTotal={percentageTotal}
          percentageLong={percentageLong}
          percentageShort={percentageShort}
          logo={logo}
        />
      </Flex>

      <Flex flexDirection="column" justifyContent="center" ml={3} flex="1" overflow="hidden">
        <P
          style={{
            fontWeight: 700,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {merchantName}
        </P>
        <P variant="small" color={theme.colors.typography.gray7}>
          {advancedDayjs(dueAt).format('Do MMM YYYY')}
        </P>
      </Flex>

      <Flex alignItems="center" ml={3}>
        {showAmountAsButton ? (
          <Button
            style={{
              letterSpacing: 'normal',
              padding: '7px 3px',
              fontSize: 14,
              borderRadius: 12,
              maxWidth: 120,
              minWidth: 100,
              backgroundColor: repaymentState === 'overdue' ? theme.colors.typography.red1 : '',
              borderColor: repaymentState === 'overdue' ? theme.colors.typography.red1 : '',
            }}
          >
            Pay {formatAsCurrency(amount)}
          </Button>
        ) : (
          <P style={{ fontWeight: 700 }}>{formatAsCurrency(amount)}</P>
        )}
      </Flex>
    </Flex>
  )
}

UpcomingPaymentRow.propTypes = {
  orderCode: PropTypes.string.isRequired,
  percentageShort: PropTypes.number.isRequired,
  percentageLong: PropTypes.number,
  logo: PropTypes.node.isRequired,
  merchantName: PropTypes.string.isRequired,
  dueAt: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  showAmountAsButton: PropTypes.bool,
  repaymentState: PropTypes.string.isRequired,
}

UpcomingPaymentRow.defaultProps = {
  percentageLong: 0,
  showAmountAsButton: false,
}

export default UpcomingPaymentRow
