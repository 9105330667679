import { theme } from 'styles/theme'

const commonStyles = {
  menuPortal: styles => ({ ...styles, zIndex: 9999 }),
  container: styles => ({
    ...styles,
    marginLeft: 1,
    marginRight: 1,
  }),
  input: styles => ({
    ...styles,
    padding: '9px 0',
    fontWeight: 600,
  }),
  singleValue: (styles, { data }) => ({
    ...styles,
    fontWeight: 600,
    fontSize: 14,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamily,
    color: data.value === '' ? theme.colors.typography.gray6 : 'black',
  }),
  option: styles => ({
    ...styles,
    display: 'flex',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamily,
  }),
}

export const selectStyles = {
  control: styles => ({
    ...styles,
    borderRadius: 8,
    height: 48,
  }),
  placeholder: styles => ({
    ...styles,
    fontFamily: theme.typography.fontFamily,
    color: theme.colors.typography.gray6,
    fontWeight: 600,
    fontSize: 14,
  }),
  ...commonStyles,
}

export const selectStylesAutofill = {
  control: styles => ({
    ...styles,
    borderRadius: 8,
    height: 48,
    border: '1px solid #9c9d9d',
    boxShadow: '0 0 0 30px #F0F3F5 inset !important',
    color: 'black',
  }),
  ...commonStyles,
}
