import React, { useState } from 'react'
import { AnimatePresence } from 'framer-motion'

import { RoundedContainer, Spacer, Paragraph as P, Image, Flex } from 'components'
import { theme } from 'styles/theme'
import { dashedLine, solidLine, caretDown } from 'assets'

import DetailRow from './DetailRow/DetailRow'
import { getPaymentPlanLocalDb } from 'utils'

const PaymentDetails = ({ handleViewPaymentSchedule }) => {
  const selectedPlan = getPaymentPlanLocalDb()

  const [showBreakdown, setShowBreakdown] = useState(false)

  // *Methods
  const toggleBreakdown = () => {
    setShowBreakdown(!showBreakdown)
  }

  return (
    <RoundedContainer boxShadow="0px 2px 6px 0px #0000002A" borderRadius={12}>
      <>
        <DetailRow title="Downpayment" amount={selectedPlan.initial_downpayment_amount} />

        <AnimatePresence>
          <DetailRow
            title={
              <Flex alignItems="center">
                <P style={{ fontWeight: 500 }}>{`${
                  selectedPlan.instalment_frequency === 'end_of_month'
                    ? 'Pay in 1 month'
                    : 'Total instalments'
                }`}</P>
                <Spacer width={5} />
                <Image
                  src={caretDown}
                  alt="expand breakdown"
                  width={10}
                  height={10}
                  initial={{
                    rotate: 0,
                  }}
                  animate={{
                    rotate: showBreakdown ? 180 : 0,
                  }}
                />
              </Flex>
            }
            amount={selectedPlan.initial_total_owed_amount}
            onClick={toggleBreakdown}
          />
        </AnimatePresence>

        {showBreakdown && (
          <Flex flexDirection="column" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <DetailRow
              title={
                selectedPlan.instalment_frequency === 'end_of_month'
                  ? 'Payment amount'
                  : 'Instalment amount'
              }
              amount={selectedPlan.initial_total_instalment_amount}
              isBreakdown
            />

            {selectedPlan.initial_total_processing_fee_amount &&
              selectedPlan.initial_total_processing_fee_amount !== '0.00' && (
                <>
                  <DetailRow
                    title={'Total processing fee'}
                    amount={selectedPlan.initial_total_processing_fee_amount}
                    isBreakdown
                  />
                  <Spacer height={5} />
                </>
              )}
          </Flex>
        )}

        {selectedPlan.discount_amount && selectedPlan.discount_amount !== '0.00' && (
          <DetailRow
            title={'Discount'}
            amount={selectedPlan.discount_amount}
            isNegativeValue={selectedPlan.discount_amount !== '0.00'}
          />
        )}

        <Image src={dashedLine} alt="dashed_line" style={{ margin: '10px 0' }} />
        <DetailRow title={'Total amount'} amount={selectedPlan.total_amount} />
      </>

      <Image src={solidLine} alt="solid_line" style={{ margin: '10px 0' }} />
      <Spacer height={10} />
      <P
        textAlign="center"
        color={theme.colors.buttons.marineBlue}
        style={{ fontWeight: 700 }}
        onClick={handleViewPaymentSchedule}
      >
        View payment schedule
      </P>
    </RoundedContainer>
  )
}

export default PaymentDetails
