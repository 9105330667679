import React, { useState, useEffect } from 'react'

import { Flex, Image, Spacer, Heading as H, Paragraph as P, Footer, Button } from 'components'
import { newDiscountPromoCode } from 'assets'
import { theme } from 'styles'

import VoucherEntryInput from './VoucherEntryInput/VoucherEntryInput'
import { logUserInteractionGA } from 'utils'

const VoucherEntryPopUp = ({ validVoucherCodes, setFinalizedVoucherCode, addVoucherIsLoading }) => {
  const [voucherCode, setVoucherCode] = useState('')
  const [voucherCodeIsValid, setVoucherCodeIsValid] = useState(false)

  // *Methods
  const handleChangeVoucherCode = e => {
    setVoucherCode(e.target.value)
  }

  const checkValidVoucherCode = () => {
    if (validVoucherCodes && validVoucherCodes.length > 0) {
      return validVoucherCodes.includes(voucherCode)
    }
    return false
  }

  const handleSubmitVoucher = () => {
    logUserInteractionGA(`Submit - Apply Voucher ${voucherCode}`)
    setFinalizedVoucherCode(voucherCode)
  }

  // *Effects
  useEffect(() => {
    if (voucherCode) {
      const isValid = checkValidVoucherCode()
      setVoucherCodeIsValid(isValid)
    }
  }, [voucherCode])

  // *JSX
  return (
    <Flex alignItems="center" width="100%" flexDirection="column" px={2} pb={110} mt={-20}>
      <Image src={newDiscountPromoCode} alt="coupon" width="170px" height="170px" />

      <Flex alignItems="flex-start" width="100%" flexDirection="column">
        <H variant="h3" textAlign="left">
          Got voucher?
        </H>

        <Spacer height={5} />
        <P variant="small" color={theme.colors.typography.gray11} style={{ lineHeight: '28px' }}>
          If you have a valid voucher code you may enter it below. Only one code may be used at
          once.
        </P>

        <Spacer height={15} />
        <VoucherEntryInput
          voucherCode={voucherCode}
          voucherCodeIsValid={voucherCodeIsValid}
          handleChangeVoucherCode={handleChangeVoucherCode}
        />

        <Footer px={4}>
          <Button
            isLoading={addVoucherIsLoading}
            disabled={!voucherCodeIsValid}
            onClick={handleSubmitVoucher}
          >
            APPLY VOUCHER
          </Button>
        </Footer>
      </Flex>
    </Flex>
  )
}

export default VoucherEntryPopUp
