import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { AnimatePresence } from 'framer-motion'

import { RoundedContainer, Paragraph as P, RowDivider, Loading, TriggerPopup } from 'components'
import { theme } from 'styles/theme'
import { useBuyerStore } from 'stores'
import { getCountryCodeLocalDb, logUserInteractionGA } from 'utils'

import PaymentMethodRemoveError from './PaymentMethodRemoveError/PaymentMethodRemoveError'

const PaymentMethodCardOptions = ({
  mode = 'card',
  data = null,
  paymentMethodId,
  setOptionsIsOpenFor,
  disableSetAsDefault,
  canUpdate,
  canTerminate,
}) => {
  const history = useHistory()

  const countryCode = getCountryCodeLocalDb()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [paymentMethodUsedByOrders, setPaymentMethodUsedByOrders] = useState([])

  const {
    response: {
      paymentMethodRemove: paymentMethodRemoveResponse,
      defaultPaymentMethodSubmit: defaultPaymentMethodSubmitResponse,
    },
    errors: {
      paymentMethodRemove: paymentMethodRemoveError,
      defaultPaymentMethodSubmit: defaultPaymentMethodSubmitError,
    },
    resetStates: resetStatesBuyer,
    removePaymentMethod,
    submitDefaultPaymentMethod,
  } = useBuyerStore()

  // *Methods
  const handleRemovePaymentMethod = () => {
    if (!canTerminate) return

    setIsLoading(true)
    logUserInteractionGA(`Click: Remove Payment Method`, {
      id: paymentMethodId,
    })
    removePaymentMethod(paymentMethodId)
  }

  const handleViewPaymentMethod = () => {
    logUserInteractionGA(`Click: View Payment Method`, { id: paymentMethodId })
    history.push('/paymentmethods/view/bank?step=1', { paymentMethodId, data })
  }

  const handleManagePaymentMethod = () => {
    logUserInteractionGA(`Click: Manage Payment Method`, { id: paymentMethodId })
    history.push('/paymentmethods/manage/bank?step=1', { paymentMethodId, data })
  }

  const handleTerminateMandate = () => {
    if (!canTerminate) return
    history.push('/paymentmethods/terminate/bank?step=1', { paymentMethodId, data })
  }

  const handleClearError = () => {
    resetStatesBuyer('paymentMethodRemove')
    resetStatesBuyer('defaultPaymentMethodSubmit')
    setError('')
    setIsLoading(false)
    setPaymentMethodUsedByOrders([])
    setOptionsIsOpenFor('')
  }

  const handleSetDefaultPaymentMethod = () => {
    if (disableSetAsDefault) return

    logUserInteractionGA(`Click: Set Payment Method as Default`, {
      id: paymentMethodId,
    })
    setIsLoading(true)
    submitDefaultPaymentMethod(countryCode, paymentMethodId)
  }

  // *Effects
  useEffect(() => {
    return () => {
      resetStatesBuyer('paymentMethodRemove')
      resetStatesBuyer('defaultPaymentMethodSubmit')
    }
  }, [])

  useEffect(() => {
    if (paymentMethodRemoveResponse && paymentMethodRemoveResponse.success) {
      window.location.reload()
    }
  }, [paymentMethodRemoveResponse])

  useEffect(() => {
    if (
      defaultPaymentMethodSubmitResponse &&
      defaultPaymentMethodSubmitResponse.status === 'success'
    ) {
      window.location.reload()
    }
  }, [defaultPaymentMethodSubmitResponse])

  useEffect(() => {
    if (
      paymentMethodRemoveError &&
      paymentMethodRemoveError.data &&
      paymentMethodRemoveError.data.orders_with_collection_payment_method
    ) {
      setPaymentMethodUsedByOrders(
        paymentMethodRemoveError.data.orders_with_collection_payment_method,
      )
    }
  }, [paymentMethodRemoveError])

  useEffect(() => {
    if (defaultPaymentMethodSubmitError) {
      setError(defaultPaymentMethodSubmitError)
    }
  }, [defaultPaymentMethodSubmitError])

  // *JSX
  return (
    <AnimatePresence>
      {/* error handling */}
      <TriggerPopup
        message={error}
        showPopup={!!error}
        stayAtCurrentPage
        callback={handleClearError}
        buttonText="OKAY"
        key={error}
      />

      {paymentMethodUsedByOrders.length > 0 && (
        <PaymentMethodRemoveError
          orders={paymentMethodUsedByOrders}
          handleClearError={handleClearError}
          key={paymentMethodUsedByOrders}
        />
      )}

      {/* loading handling */}
      {isLoading && <Loading variant="fullscreen" style={{ opacity: 0.8 }} />}

      <RoundedContainer
        boxShadow="0px 2px 6px 0px #00000032"
        textAlign="center"
        width="176px"
        padding="10px 5px"
        style={{
          alignSelf: 'flex-end',
          right: 10,
          top: 50,
          position: 'absolute',
        }}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        key={paymentMethodId}
      >
        <P
          variant="small"
          color={disableSetAsDefault ? theme.colors.lockGray : 'black'}
          style={{ fontWeight: 600 }}
          my={1}
          onClick={handleSetDefaultPaymentMethod}
        >
          Set as default
        </P>
        <RowDivider />
        {mode === 'card' ? (
          <>
            <P
              variant="small"
              style={{ fontWeight: 600 }}
              color={!canTerminate ? theme.colors.lockGray : theme.colors.actions.failureRed}
              my={1}
              onClick={handleRemovePaymentMethod}
            >
              Remove
            </P>
          </>
        ) : mode === 'my_emandate' ? (
          <>
            {canUpdate ? (
              <P
                variant="small"
                color="black"
                style={{ fontWeight: 600 }}
                my={1}
                onClick={handleManagePaymentMethod}
              >
                Manage
              </P>
            ) : (
              <P
                variant="small"
                color="black"
                style={{ fontWeight: 600 }}
                my={1}
                onClick={handleViewPaymentMethod}
              >
                View
              </P>
            )}
            <RowDivider />
            <P
              variant="small"
              style={{ fontWeight: 600 }}
              color={!canTerminate ? theme.colors.lockGray : theme.colors.actions.failureRed}
              my={1}
              onClick={handleTerminateMandate}
            >
              Terminate
            </P>
          </>
        ) : null}
      </RoundedContainer>
    </AnimatePresence>
  )
}

export default PaymentMethodCardOptions
