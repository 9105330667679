import styled from 'styled-components'
import { motion } from 'framer-motion'

export const StyledOverlay = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #4f4f4f;
  opacity: 0.58;
  z-index: 11;
  padding-bottom: 30px;
`

export const StyledTutorialContainer = styled(motion.div)`
  position: relative;
`
