import Cookies from 'js-cookie'

import { WHITELABEL } from 'const'

export const setCountryCodeLocalDb = countryCode => {
  Cookies.set('country_code', countryCode)
}

export const getCountryCodeLocalDb = () => {
  // Whitelabel override
  if (WHITELABEL === 'ghl') return 'my'

  let countryCodeFromUrl = ''
  const locationUrl = window.location.href

  if (locationUrl.includes('/my/')) {
    countryCodeFromUrl = 'my'
  }
  if (locationUrl.includes('/sg/')) {
    countryCodeFromUrl = 'sg'
  }

  return Cookies.get('country_code') || countryCodeFromUrl || 'sg'
}

export const removeCountryCodeLocalDb = () => {
  Cookies.remove('country_code')
}

export const setTokenLocalDb = token => {
  localStorage.setItem('token', token)
}

export const getTokenLocalDb = () => {
  const result = localStorage.getItem('token')
  return result
}

export const removeTokenLocalDb = () => {
  localStorage.removeItem('token')
}

export const setBuyerNameLocalDb = name => {
  localStorage.setItem('buyer_name', name)
}

export const getBuyerNameLocalDb = () => {
  const result = localStorage.getItem('buyer_name')
  return result || 'Unverified User'
}

export const removeBuyerNameLocalDb = () => {
  localStorage.removeItem('buyer_name')
}

export const setPaymentMethodRedirectLocalDb = url => {
  localStorage.setItem('payment_method_redirect', url)
}

export const getPaymentMethodRedirectLocalDb = () => {
  const result = localStorage.getItem('payment_method_redirect')
  return result
}

export const removePaymentMethodRedirectLocalDb = () => {
  localStorage.removeItem('payment_method_redirect')
}

export const setCheckoutIdLocalDb = checkoutId => {
  localStorage.setItem('checkout_id', checkoutId)
}

export const getCheckoutIdLocalDb = () => {
  const result = localStorage.getItem('checkout_id')
  return result
}

export const removeCheckoutIdLocalDb = () => {
  localStorage.removeItem('checkout_id')
}

export const setReferredCodeLocalDb = referredCode => {
  localStorage.setItem('referred_code', referredCode)
}

export const getReferredCodeLocalDb = () => {
  const result = localStorage.getItem('referred_code')
  return result
}

export const removeReferredCodeLocalDb = () => {
  localStorage.removeItem('referred_code')
}

export const setStoreIdLocalDb = StoreId => {
  localStorage.setItem('store_id', StoreId)
}

export const getStoreIdLocalDb = () => {
  const result = localStorage.getItem('store_id')
  return result
}

export const removeStoreIdLocalDb = () => {
  localStorage.removeItem('store_id')
}

export const setFromStoreIdLocalDb = store => {
  localStorage.setItem('from_store_id', JSON.stringify(store))
}

export const getFromStoreIdLocalDb = () => {
  const result = localStorage.getItem('from_store_id')
  if (result !== null && result !== undefined) {
    return JSON.parse(result)
  }
  return null
}

export const removeFromStoreIdLocalDb = () => {
  localStorage.removeItem('from_store_id')
}

export const setDraftOrderLocalDb = draftOrder => {
  localStorage.setItem('draft_order', JSON.stringify(draftOrder))
}

export const getDraftOrderLocalDb = () => {
  const result = localStorage.getItem('draft_order')
  if (result !== null && result !== undefined) {
    return JSON.parse(result)
  }
  return null
}

export const removeDraftOrderLocalDb = () => {
  localStorage.removeItem('draft_order')
}

export const setShouldRedirectToCredit = () => {
  localStorage.setItem('should_redirect_to_credit', 'true')
}

export const getShouldRedirectToCredit = () => {
  const result = localStorage.getItem('should_redirect_to_credit')
  return result
}

export const removeShouldRedirectToCredit = () => {
  localStorage.removeItem('should_redirect_to_credit')
}

export const setShouldRedirectToVerifyIdentity = () => {
  localStorage.setItem('should_redirect_to_verify_identity', 'true')
}

export const getShouldRedirectToVerifyIdentity = () => {
  const result = localStorage.getItem('should_redirect_to_verify_identity')
  return result
}

export const removeShouldRedirectToVerifyIdentity = () => {
  localStorage.removeItem('should_redirect_to_verify_identity')
}

export const setShouldRedirectToAddPayment = () => {
  localStorage.setItem('should_redirect_to_add_payment', 'true')
}

export const getShouldRedirectToAddPayment = () => {
  const result = localStorage.getItem('should_redirect_to_add_payment')
  return result
}

export const removeShouldRedirectToAddPayment = () => {
  localStorage.removeItem('should_redirect_to_add_payment')
}

export const setShouldReturnToOrder = () => {
  localStorage.setItem('should_return_to_order', 'true')
}

export const getShouldReturnToOrder = () => {
  const result = localStorage.getItem('should_return_to_order')
  return result
}

export const removeShouldReturnToOrder = () => {
  localStorage.removeItem('should_return_to_order')
}

export const setShouldCheckDraftAtCreditScreen = () => {
  localStorage.setItem('should_check_draft_at_credit', 'true')
}

export const getShouldCheckDraftAtCreditScreen = () => {
  const result = localStorage.getItem('should_check_draft_at_credit')
  return result
}

export const removeShouldCheckDraftAtCreditScreen = () => {
  localStorage.removeItem('should_check_draft_at_credit')
}

export const setShouldCheckIntentAtCreditScreen = () => {
  localStorage.setItem('should_check_intent_at_credit', 'true')
}

export const getShouldCheckIntentAtCreditScreen = () => {
  const result = localStorage.getItem('should_check_intent_at_credit')
  return result
}

export const removeShouldCheckIntentAtCreditScreen = () => {
  localStorage.removeItem('should_check_intent_at_credit')
}

export const setMobilePrefixLocalDb = prefix => {
  localStorage.setItem('mobile_prefix', prefix)
}

export const getMobilePrefixLocalDb = () => {
  const result = localStorage.getItem('mobile_prefix')
  return result
}

export const removeMobilePrefixLocalDb = () => {
  localStorage.removeItem('mobile_prefix')
}

export const setMobileNumberLocalDb = mobile => {
  localStorage.setItem('mobile_number', mobile)
}

export const getMobileNumberLocalDb = () => {
  const result = localStorage.getItem('mobile_number')
  return result
}

export const removeMobileNumberLocalDb = () => {
  localStorage.removeItem('mobile_number')
}

export const setPaymentTransactionLocalDb = paymentTransaction => {
  localStorage.setItem('payment_transaction', JSON.stringify(paymentTransaction))
}

export const getPaymentTransactionLocalDb = () => {
  const result = localStorage.getItem('payment_transaction')
  if (result !== null && result !== undefined) {
    return JSON.parse(result)
  }
  return null
}

export const removePaymentTransactionLocalDb = () => {
  localStorage.removeItem('payment_transaction')
}

export const setTransactionReceiptLocalDb = transactionReceipt => {
  localStorage.setItem('transaction_receipt', JSON.stringify(transactionReceipt))
}

export const getTransactionReceiptLocalDb = () => {
  const result = localStorage.getItem('transaction_receipt')
  if (result !== null && result !== undefined) {
    return JSON.parse(result)
  }
  return null
}

export const removeTransactionReceiptLocalDb = () => {
  localStorage.removeItem('transaction_receipt')
}

export const setPaymentPlanLocalDb = paymentPlan => {
  localStorage.setItem('payment_plan', JSON.stringify(paymentPlan))
}

export const getPaymentPlanLocalDb = () => {
  const result = localStorage.getItem('payment_plan')
  if (result !== null && result !== undefined) {
    return JSON.parse(result)
  }
  return null
}

export const removePaymentPlanLocalDb = () => {
  localStorage.removeItem('payment_plan')
}

export const setMyinfoDataLocalDb = data => {
  localStorage.setItem('myinfo_data', JSON.stringify(data))
}

export const getMyinfoDataLocalDb = () => {
  const result = localStorage.getItem('myinfo_data')
  if (result !== null && result !== undefined) {
    return JSON.parse(result)
  }
  return null
}

export const removeMyinfoDataLocalDb = () => {
  localStorage.removeItem('myinfo_data')
}

export const setSgVerifyIdentitySavedFormLocalDb = formData => {
  localStorage.setItem('sg_verify_identity_saved_form', JSON.stringify(formData))
}

export const getSgVerifyIdentitySavedFormLocalDb = () => {
  const result = localStorage.getItem('sg_verify_identity_saved_form')
  if (result !== null && result !== undefined) {
    return JSON.parse(result)
  }
  return null
}

export const removeSgVerifyIdentitySavedFormLocalDb = () => {
  localStorage.removeItem('sg_verify_identity_saved_form')
}

export const setIsRepeatUser = () => {
  localStorage.setItem('is_repeat_user', 'true')
}

export const getIsRepeatUser = () => {
  const result = localStorage.getItem('is_repeat_user')
  return result
}

export const removeIsRepeatUser = () => {
  localStorage.removeItem('is_repeat_user')
}

export const setCbsJobIdLocalDb = jobId => {
  localStorage.setItem('cbs_job_id', jobId)
}

export const getCbsJobIdLocalDb = () => {
  const result = localStorage.getItem('cbs_job_id')
  return result
}

export const removeCbsJobIdLocalDb = () => {
  localStorage.removeItem('cbs_job_id')
}

export const setApprovedCreditLocalDb = (amount, createdAt) => {
  localStorage.setItem('approved_credit', JSON.stringify({ amount, createdAt }))
}

export const getApprovedCreditLocalDb = () => {
  const result = localStorage.getItem('approved_credit')
  if (result !== null && result !== undefined) {
    return JSON.parse(result)
  }
  return null
}

export const removeApprovedCreditLocalDb = () => {
  localStorage.removeItem('approved_credit')
}

export const setAssumeAccount = () => {
  localStorage.setItem('assume_account', 'true')
}

export const getAssumeAccount = () => {
  const result = localStorage.getItem('assume_account')
  return result
}

export const removeAssumeAccount = () => {
  localStorage.removeItem('assume_account')
}

export const setAssumeAccountSetupStatus = () => {
  localStorage.setItem('assume_account_setup_status', 'complete')
}

export const getAssumeAccountSetupStatus = () => {
  const result = localStorage.getItem('assume_account_setup_status')
  return result
}

export const removeAssumeAccountSetupStatus = () => {
  localStorage.removeItem('assume_account_setup_status')
}

export const setKycCreatedAt = (kycType, kyc) => {
  localStorage.setItem(`${kycType}_created_at`, JSON.stringify(kyc))
}

export const getKycCreatedAt = kycType => {
  const result = localStorage.getItem(`${kycType}_created_at`)
  if (result !== null && result !== undefined) {
    return JSON.parse(result)
  }
  return null
}

export const removeKycCreatedAt = kycType => {
  localStorage.removeItem(`${kycType}_created_at`)
}

export const removeAllKycCreatedAt = () => {
  removeKycCreatedAt('sg_identity')
  removeKycCreatedAt('sg_home_ownership')
  removeKycCreatedAt('sg_payment_method')
  removeKycCreatedAt('sg_credit_bureau_report')
  removeKycCreatedAt('sg_additional_data')
  removeKycCreatedAt('my_identity')
  removeKycCreatedAt('my_enqws')
  removeKycCreatedAt('my_payment_method')
  removeKycCreatedAt('my_payment_method')
}

export const setDesktopQrCodeLink = link => {
  if (!link.includes('/my/register/') && !link.includes('/sg/register/')) {
    localStorage.setItem(`desktop_qr_code`, link)
  }
}

export const getDesktopQrCodeLink = () => {
  const result = localStorage.getItem(`desktop_qr_code`)
  return result
}

export const removeDesktopQrCodeLink = () => {
  localStorage.removeItem(`desktop_qr_code`)
}

export const setBackToMerchantLink = link => {
  localStorage.setItem(`back_to_merchant_link`, link)
}

export const getBackToMerchantLink = () => {
  const result = localStorage.getItem(`back_to_merchant_link`)
  return result
}

export const removeBackToMerchantLink = () => {
  localStorage.removeItem(`back_to_merchant_link`)
}
