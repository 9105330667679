import React from 'react'
import PropTypes from 'prop-types'

import { Paragraph as P } from 'components'
import { theme } from 'styles'

const MarkAllAsRead = ({ handleClick }) => {
  return (
    <P
      variant="small"
      color={theme.colors.buttons.marineBlue}
      style={{ fontWeight: 700 }}
      onClick={handleClick}
    >
      Mark all as read
    </P>
  )
}

MarkAllAsRead.propTypes = {
  handleClick: PropTypes.func.isRequired,
}

export default MarkAllAsRead
