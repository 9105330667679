import React, { useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'

import { ProgressBar, Spacer, Paragraph as P, Link, DraggableOverlay, Flex } from 'components'
import { theme } from 'styles'
import { getDraftOrderLocalDb, formatAsCurrency, checkForInsufficientCredit } from 'utils'

import CreditExplanationNormal from '../PaymentPlans(Step1)/CreditExplanation/CreditExplanationNormal'

const CreditUsedFooter = ({ paymentPlan, showProgressBar }) => {
  const draftOrder = getDraftOrderLocalDb()

  const [showCreditExplanation, setShowCreditExplanation] = useState(false)
  const [insufficientCredit, setInsufficientCredit] = useState(false)

  // *Effects
  useEffect(() => {
    if (draftOrder && draftOrder.available_payment_plans) {
      const { insufficientCredit } = checkForInsufficientCredit(draftOrder.available_payment_plans)
      setInsufficientCredit(insufficientCredit)
    }
  }, [draftOrder])

  // *JSX
  if (!paymentPlan || !draftOrder)
    return (
      <Flex stretch centered flexDirection="column">
        <ProgressBar initial={0} current={0} total={0} />
        <Spacer height={8} />
        <Skeleton height={12} width={240} />
      </Flex>
    )

  return (
    <>
      {/* Draggable overlay */}
      {showCreditExplanation &&
        draftOrder &&
        'merchant_credit_balance' in draftOrder &&
        'buyer_credit_balance' in draftOrder &&
        'credit_balance' in draftOrder &&
        'credit_additional_amount' in draftOrder && (
          <DraggableOverlay cancel={() => setShowCreditExplanation(false)}>
            <CreditExplanationNormal
              merchantBalance={draftOrder.merchant_credit_balance}
              userBalance={draftOrder.buyer_credit_balance}
              creditBalance={draftOrder.credit_balance}
              creditAdditionalAmount={draftOrder.credit_additional_amount}
            />
          </DraggableOverlay>
        )}

      {showProgressBar && (
        <ProgressBar
          barColor={
            paymentPlan && paymentPlan.insufficient_credit
              ? theme.colors.actions.yellowWarning2
              : ''
          }
          secondaryBarColor={
            paymentPlan && paymentPlan.insufficient_credit
              ? theme.colors.actions.yellowWarning4
              : ''
          }
          current={parseInt(draftOrder.credit_balance) - parseInt(paymentPlan.credit_used_amount)}
          secondaryCurrent={draftOrder.credit_balance}
          total={draftOrder.credit_amount}
        />
      )}
      <Spacer height={8} />
      {paymentPlan && paymentPlan.credit_used_amount && (
        <P variant="subcopy" textAlign="center" color={theme.colors.lockGray} centered>
          {`You will use up to ${formatAsCurrency(paymentPlan.credit_used_amount)} of credit`}{' '}
          <Link fontWeight={700} onClick={() => setShowCreditExplanation(true)}>
            Details
          </Link>
        </P>
      )}
      {draftOrder &&
        draftOrder &&
        draftOrder.is_merchant_credit_balance &&
        insufficientCredit &&
        draftOrder.store &&
        draftOrder.store.name && (
          <>
            <Spacer height={5} />
            <P variant="subcopy" textAlign="center" color={theme.colors.typography.red1}>
              {`Merchant credit limit reached for ${draftOrder.store.name}`}
            </P>
          </>
        )}
    </>
  )
}

export default CreditUsedFooter
