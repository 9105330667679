import React from 'react'

import {
  Flex,
  Image,
  AdyenPaymentForm,
  StripeContainer,
  PageWrapper,
  NavigateBack,
  Paragraph as P,
  Spacer,
  Loading,
  GHLContainer,
} from 'components'
import { addCreditCard } from 'assets'
import { usePaymentThirdParty } from 'hooks'
import { WHITELABEL } from 'const'

// currently using this component to add both credit and debit cards
const AddCreditCard = () => {
  const { thirdParty } = usePaymentThirdParty()

  return (
    <PageWrapper withTopNav>
      <NavigateBack withBottomBorder>New payment method</NavigateBack>

      {WHITELABEL === 'abnk' && (
        <Flex mt={2}>
          <Image src={addCreditCard} alt="credit_card_icon" width={24} />
          <P ml={2} style={{ fontWeight: 600 }}>
            Add card
          </P>
        </Flex>
      )}

      <Spacer height={25} />
      {WHITELABEL === 'ghl' ? (
        <GHLContainer />
      ) : !thirdParty ? (
        <Flex m="auto">
          <Loading />
        </Flex>
      ) : thirdParty && thirdParty.card === 'adyen' ? (
        <AdyenPaymentForm />
      ) : (
        <StripeContainer />
      )}
    </PageWrapper>
  )
}

export default AddCreditCard
