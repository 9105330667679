import React, { useEffect } from 'react'
import { BrowserRouter, useHistory } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import axios from 'axios'
import ReactGA from 'react-ga'
import * as Sentry from '@sentry/react'

import { StripeContainer, ErrorFallback } from 'components'
import { theme } from 'styles/theme'
import { GlobalStyle } from 'styles/global'
import {
  autoCorrectUrlPath,
  getAssumeAccount,
  getCountryCodeLocalDb,
  getTokenLocalDb,
  signOut,
} from 'utils'
import { GA_MEASUREMENT_ID } from 'const'
import { useBuyerStore } from 'stores'

import BuyerCommon from 'web/buyers/router/BuyerCommon'
import BuyerSG from './web/buyers/router/BuyerSG'
import BuyerMY from './web/buyers/router/BuyerMY'

import './App.css'

const App = () => {
  const history = useHistory()
  const countryCode = getCountryCodeLocalDb()
  const basename = `/${countryCode}`

  const {
    response: { getOrCreate: getOrCreateResponse },
    submitGetOrCreate,
  } = useBuyerStore()

  axios.interceptors.request.use(
    request => {
      if (!request.url.includes('/otp')) {
        const token = getTokenLocalDb()
        request.headers['Authorization'] = `Bearer ${token}`
      }
      return request
    },
    error => {
      return Promise.reject(error)
    },
  )

  // handle 401 errors, redirect users to login page to get new token
  axios.interceptors.response.use(
    response => {
      return response
    },
    error => {
      if (error.response && error.response.status === 401) {
        signOut()
        history.push('/register')
      }
      return Promise.reject(error)
    },
  )

  // *Effects
  useEffect(() => {
    if (getTokenLocalDb()) submitGetOrCreate()
    ReactGA.initialize(GA_MEASUREMENT_ID, {
      redactEmail: false,
    })

    if (!getAssumeAccount()) {
      autoCorrectUrlPath()
    }
  }, [])

  useEffect(() => {
    if (getOrCreateResponse && getOrCreateResponse.id) {
      // set user id for GA user explorer
      ReactGA.set({ userId: getOrCreateResponse.id })
      window.gtag('set', 'user_id', getOrCreateResponse.id)
    }
  }, [getOrCreateResponse])

  const isCommonPage = location.pathname !== '/' && !location.pathname.includes(`/${countryCode}/`)

  // *JSX
  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback />} showDialog>
      <StripeContainer>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          {isCommonPage ? (
            <BrowserRouter>
              <BuyerCommon />
            </BrowserRouter>
          ) : (
            <BrowserRouter basename={basename}>
              {countryCode === 'sg' ? <BuyerSG /> : <BuyerMY />}
            </BrowserRouter>
          )}
        </ThemeProvider>
      </StripeContainer>
    </Sentry.ErrorBoundary>
  )
}

export default Sentry.withProfiler(App)
