import React from 'react'
import OtpInput from 'react-otp-input'

import { FormError, Flex } from 'components'

import { otpContainerStyle, otpInputStyle, otpFocusStyle, otpErrorStyle } from './formOtpStyle'

const FormOtp = props => {
  return (
    <Flex flexDirection="column">
      <OtpInput
        {...props}
        containerStyle={otpContainerStyle}
        inputStyle={otpInputStyle}
        focusStyle={otpFocusStyle}
        errorStyle={otpErrorStyle}
      />
      {props.hasErrored && props.error && (
        <FormError style={{ fontSize: '16px' }}>{props.error}</FormError>
      )}
    </Flex>
  )
}

export default FormOtp
