import styled, { keyframes } from 'styled-components'

const ellipsesAnimation = keyframes`
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
`

export const StyledSpan = styled.span`
  span {
    opacity: 0;
    animation-name: ${ellipsesAnimation};
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }

  span:nth-child(1) {
    animation-delay: 0s;
  }

  span:nth-child(2) {
    animation-delay: 0.1s;
  }

  span:nth-child(3) {
    animation-delay: 0.2s;
  }
`
