import React from 'react'

import { RoundedContainer } from 'components'

import TransactionReceiptRow from './TransactionReceiptRow/TransactionReceiptRow'

const TransactionReceipts = ({ data }) => {
  return (
    <RoundedContainer boxShadow="0px 2px 20px 0px #00000019">
      {data &&
        data.repayments &&
        data.repayments.map((repayment, i) => {
          const collectSuccess = repayment.state === 'collect_success'
          if (collectSuccess) {
            return (
              <TransactionReceiptRow
                data={repayment}
                paymentMethod={data.collection_payment_method}
                merchant={data.merchant}
                vouchers={data.vouchers}
                store={data.store}
                orderId={data.code}
                key={i}
              />
            )
          }
          return null
        })}
    </RoundedContainer>
  )
}

export default TransactionReceipts
