import React from 'react'
import PropTypes from 'prop-types'

import { StyledBox } from './boxStyle'

const Box = ({ children, ...rest }) => {
  return <StyledBox {...rest}>{children}</StyledBox>
}

Box.propTypes = {
  children: PropTypes.node,
}

export default Box
