import { createGlobalStyle } from 'styled-components'

import { calendar } from 'assets'
import { theme } from 'styles/theme'

export const GlobalStyle = createGlobalStyle`
    * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }

    body {
        max-width: 450px;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        --webkit-overflow-scrolling: auto !important;
        /* overscroll-behavior-y: contain; Prevent page refresh when pulling down on mobile */
    }

    #root {
        scrollbar-width: none;  /* Firefox */
    }


    ::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
    }

    
    /* Customize form component styles according to figma */
    input:focus,
    select:focus,
    textarea:focus,
    button:focus, 
    span:focus {
        outline: none;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active
    input:disabled, input:read-only
    {  
        border: 1px solid #9C9D9D;
        -webkit-appearance: none;
        -webkit-box-shadow: 0 0 0 30px #F0F3F5 inset !important;
        box-shadow: 0 0 0 30px #F0F3F5 inset !important;
        color: black
    }
    input::placeholder {
        color: ${theme.colors.lockGray}
    }

    /* workaround for date input */
    input[type="date"]::-webkit-calendar-picker-indicator {
        color: rgba(0, 0, 0, 0);
        opacity: 1;
        display: block;
        background: url(${calendar}) no-repeat;
        width: 20px;
        height: 20px;
        border-width: thin;
    }

    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance:textfield;
    }

    ::-webkit-datetime-edit-year-field:not([aria-valuenow]),
    ::-webkit-datetime-edit-month-field:not([aria-valuenow]),
    ::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
        color: transparent;
    }
`
