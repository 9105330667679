import styled from 'styled-components'
import { motion } from 'framer-motion'

import { landingBackdrop } from 'assets'
import { theme } from 'styles/theme'

export const StyledLogo = styled(motion.img)`
  position: absolute;
`

export const StyledIcon = styled(motion.img)`
  position: absolute;
  top: 30px;
  right: 30px;
`

export const StyledActiveNotification = styled(motion.div)`
  position: absolute;
  background: ${theme.colors.actions.failureRed};
  z-index: 20;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  top: 23px;
  right: 23px;
`

export const StyledBackdrop = styled(motion.div)`
  max-width: calc(100% + 32px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(${landingBackdrop}) no-repeat;
  background-size: 100%;
  width: 100vw;
  min-height: 280px;
  margin-left: -16px;
  margin-right: -16px;
  margin-top: -45px;
  box-sizing: border-box;
`
