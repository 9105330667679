import React from 'react'
import PropTypes from 'prop-types'

import { Flex } from 'components'

import {
  StyledContainer,
  StyledContainerNarrow,
  StyledProgress,
  StyledSecondaryProgress,
} from './progressBarStyle'

const ProgressBar = ({
  topLeftLabel,
  topRightLabel,
  bottomLeftLabel,
  bottomRightLabel,
  initial,
  current,
  secondaryCurrent,
  total,
  variant,
  barColor,
  secondaryBarColor,
  ...rest
}) => {
  const initialWidth = initial && Math.floor((parseFloat(initial) / parseFloat(total)) * 100) + '%'
  const width = Math.floor((parseFloat(current) / parseFloat(total)) * 100) + '%'
  let secondaryWidth = 0
  if (secondaryCurrent) {
    secondaryWidth = Math.floor((parseFloat(secondaryCurrent) / parseFloat(total)) * 100) + '%'
  }

  if (variant === 'narrow') {
    return (
      <Flex position="relative" height={10}>
        <StyledContainerNarrow {...rest}>
          {secondaryCurrent && (
            <StyledSecondaryProgress
              position="absolute"
              transition={{ delay: 0.5, duration: 0.5 }}
              initial={{ width: initialWidth || 0 }}
              animate={{ width: secondaryWidth }}
              barColor={secondaryBarColor}
              style={{ height: 4 }}
            />
          )}
          <StyledProgress
            transition={{ delay: 0.5, duration: 0.5 }}
            initial={{ width: initialWidth || 0 }}
            animate={{ width: width }}
            barColor={barColor}
          />
        </StyledContainerNarrow>
      </Flex>
    )
  }

  return (
    <Flex flexDirection="column" stretch {...rest}>
      {(topLeftLabel || topRightLabel) && (
        <Flex justifyContent="space-between">
          {topLeftLabel ? topLeftLabel : <div />}
          {topRightLabel ? topRightLabel : <div />}
        </Flex>
      )}
      <StyledContainer variant={variant}>
        {secondaryCurrent && (
          <StyledSecondaryProgress
            position="absolute"
            transition={{ delay: 0.5, duration: 0.5 }}
            initial={{ width: initialWidth || 0 }}
            animate={{ width: secondaryWidth }}
            barColor={secondaryBarColor}
          />
        )}
        <StyledProgress
          barColor={barColor}
          transition={{ delay: 0.5, duration: 0.5 }}
          initial={{ width: initialWidth || 0 }}
          animate={{ width: width }}
        />
        {(bottomLeftLabel || bottomRightLabel) && (
          <Flex justifyContent="space-between">
            {bottomLeftLabel ? bottomLeftLabel : <div />}
            {bottomRightLabel ? bottomRightLabel : <div />}
          </Flex>
        )}
      </StyledContainer>
    </Flex>
  )
}

ProgressBar.propTypes = {
  leftLabel: PropTypes.node,
  rightLabel: PropTypes.node,
  initial: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  current: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  secondaryCurrent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  variant: PropTypes.string,
  barColor: PropTypes.string,
  secondaryBarColor: PropTypes.string,
}

export default ProgressBar
