import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { theme } from 'styles'
import { useBuyerStore } from 'stores'
import { StyledLabel } from 'components/FormLabel/formLabelStyle'
import { Paragraph as P, Flex, FormInput, FormSelect, Spacer } from 'components'

const BankMandateStep2 = ({ editableFields }) => {
  const {
    control,
    register,
    formState: { errors: formErrors },
  } = useFormContext()

  const {
    response: { paynetFPXEmandateMeta: paynetMetaResponse },
  } = useBuyerStore()

  const frequencyOptions = [{ value: '', label: 'Select option' }]
  if (paynetMetaResponse?.frequency_modes) {
    paynetMetaResponse.frequency_modes.forEach(({ key, name }) => {
      frequencyOptions.push({ value: key, label: name })
    })
  }

  const bankOptions = [{ value: '', label: 'Select option' }]
  if (paynetMetaResponse?.banks) {
    paynetMetaResponse.banks.forEach(({ id, name }) => {
      bankOptions.push({ value: id, label: name })
    })
  }

  return (
    <>
      <FormInput
        id="max_amount"
        label="maximum amount"
        showLabel
        type="number"
        placeholder="0.00"
        error={formErrors.max_amount}
        register={register}
        readOnly={!editableFields.includes('max_amount')}
        required
      />

      <Spacer height={15} />
      <StyledLabel>Frequency</StyledLabel>
      <Flex>
        <FormInput
          id="max_frequency"
          type="number"
          placeholder="5"
          error={formErrors.max_frequency}
          register={register}
          readOnly={!editableFields.includes('max_frequency')}
          validationRules={{
            min: {
              value: 5,
              message: 'Must be greater than 4',
            },
          }}
          required
          style={{ marginTop: 2 }}
        />
        <Spacer width={15} />
        <P variant="small" textAlign="center" pt={1} mt={2} style={{ fontWeight: 600 }}>
          per
        </P>
        <Spacer width={15} />
        <Controller
          name="frequency_mode"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <FormSelect
              {...field}
              isSearchable={false}
              options={frequencyOptions}
              onChange={e => field.onChange(e.value)}
              value={frequencyOptions.find(c => c.value === field.value)}
              error={formErrors.frequency_mode}
              style={{ minWidth: '9.5rem' }}
              isDisabled={!editableFields.includes('frequency_mode')}
              variant={editableFields.includes('frequency_mode') ? '' : 'autofill'}
              required
            />
          )}
        />
      </Flex>

      <Spacer height={15} />
      <FormInput
        id="purpose"
        label="purpose of payment"
        showLabel
        type="text"
        placeholder="Instalment payment"
        error={formErrors.purpose}
        register={register}
        readOnly={!editableFields.includes('purpose')}
        required
      />

      <Spacer height={15} />
      <FormInput
        id="external_id"
        label="payment reference no."
        showLabel
        type="text"
        readOnly={!editableFields.includes('external_id')}
        register={register}
      />

      <Spacer height={15} />
      <Controller
        name="buyer_bank_id"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <FormSelect
            {...field}
            label="payer bank"
            showLabel
            options={bankOptions}
            onChange={e => field.onChange(e.value)}
            value={bankOptions.find(c => c.value === field.value)}
            style={{ width: '100%' }}
            error={formErrors.buyer_bank_id}
            isDisabled={!editableFields.includes('buyer_bank_id')}
            variant={editableFields.includes('buyer_bank_id') ? '' : 'autofill'}
            required
          />
        )}
      />

      <P variant="tag" color={theme.colors.lockGray} pt={20} pb={100} style={{ fontWeight: 400 }}>
        I fully understand and agree to the Terms and Conditions of Direct Debit services and I
        acknowledge that upon successful completion of this online application, RM1.00 shall be
        debited from my selected account to ensure the said account is active for the purpose of
        application for this service. Please note that Abnk at its sole discretion may refund the
        RM1.00 subject to its own Terms and Conditions. Please contact Abnk for futher details.
      </P>
    </>
  )
}

export default BankMandateStep2
