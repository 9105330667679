import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import Skeleton from 'react-loading-skeleton'

import { NavigateBack, Flex, UndraggableOverlay, ImageCarousel, CustomIndicator } from 'components'
import { useMerchantStore } from 'stores'
import { useWindowSize } from 'hooks'

import MerchantDetails from './MerchantDetails/MerchantDetails'
import MerchantDetailsLoading from './MerchantDetails/MerchantDetailsLoading'

const Merchant = () => {
  const location = useLocation().search
  const slug = new URLSearchParams(location).get('slug')
  const windowSize = useWindowSize()

  const [merchantImages, setMerchantImages] = useState([])
  const [currentImage, setCurrentImage] = useState(0)
  const [noStoreImage, setNoStoreImage] = useState(false)

  const {
    response: { merchant: merchantResponse },
    resetStates: resetStatesMerchant,
    fetchMerchant,
  } = useMerchantStore()

  // *Methods
  const handleSwipe = index => {
    if (currentImage <= merchantImages.length - 1) {
      setCurrentImage(index)
    }
  }

  // *Effects
  useEffect(() => {
    if (slug) {
      fetchMerchant(slug)
    }

    return () => {
      resetStatesMerchant('merchant')
    }
  }, [])

  useEffect(() => {
    if (
      merchantResponse &&
      merchantResponse.logo &&
      merchantResponse.stores &&
      merchantResponse.stores.length > 0
    ) {
      const storeImages = merchantResponse.stores.reduce((result, store) => {
        return store.photo ? [...result, store.photo] : result
      }, [])

      if (storeImages.length > 0) {
        setMerchantImages(storeImages)
      } else {
        setNoStoreImage(true)
        setMerchantImages([merchantResponse.logo])
      }
    }
  }, [merchantResponse])

  // *JSX
  return (
    <Flex>
      <NavigateBack style={{ zIndex: 21 }} containerStyle={{ backgroundColor: 'none' }} />

      <Flex flexDirection="column">
        {merchantImages.length > 0 ? (
          <>
            <ImageCarousel
              objectFit={noStoreImage ? 'contain' : 'cover'}
              images={merchantImages}
              carouselHeight={`${windowSize.height * 0.4}px`}
              imageHeight={noStoreImage ? '140px' : '100%'}
              imageWidth={noStoreImage ? '100vw' : '100%'}
              swipeable={true}
              backgroundColor="white"
              showIndicators={false}
              containerStyle={{ width: '100vw' }}
              onChange={handleSwipe}
            />

            <Flex
              style={{
                position: 'absolute',
                bottom: '8vh',
                left: 20,
              }}
              id="custom_indicator"
            >
              <CustomIndicator
                steps={currentImage}
                totalSteps={merchantImages.length - 1}
                variant="white"
              />
            </Flex>
          </>
        ) : (
          <Skeleton width="100vw" height="35vh" />
        )}
        <UndraggableOverlay
          noOverlay
          containerMinHeight={`${windowSize.height * 0.66}px`}
          containerMaxHeight={`${windowSize.height * 0.66}px`}
        >
          {merchantResponse ? (
            <MerchantDetails merchant={merchantResponse} />
          ) : (
            <MerchantDetailsLoading />
          )}
        </UndraggableOverlay>
      </Flex>
    </Flex>
  )
}

export default Merchant
