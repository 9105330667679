import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { Flex } from 'components'

const DetailRowLoading = ({ titleWidth, amountWidth }) => {
  return (
    <Flex justifyContent="space-between" mb={2} alignItems="center">
      <Skeleton height={23} width={titleWidth} />
      <Skeleton height={23} width={amountWidth} />
    </Flex>
  )
}

export default DetailRowLoading
