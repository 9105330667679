import React from 'react'
import { cross } from 'assets'
import { Flex, Paragraph as P, Image } from 'components'

const FileUploadItem = ({ name, handleRemoveFile }) => {
  return (
    <Flex flexDirection="row" alignItems="center">
      <P
        mt={1}
        mb={1}
        mr={2}
        maxWidth={280}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {name}
      </P>
      <Flex
        justifyContent="center"
        alignItems="center"
        onClick={handleRemoveFile}
        style={{
          border: '0.13rem solid red',
          borderRadius: '50%',
          width: '1.4rem',
          height: '1.4rem',
        }}
      >
        <Image
          src={cross}
          alt="cross"
          width="58%"
          height="58%"
          style={{
            filter:
              'invert(15%) sepia(85%) saturate(6819%) hue-rotate(5deg) brightness(106%) contrast(123%)',
          }}
        />
      </Flex>
    </Flex>
  )
}

export default FileUploadItem
