import styled from 'styled-components'

import { theme } from 'styles/theme'

export const StyledCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  width: calc(100vw - 32px);
  padding: 18px;
  border-radius: 12px;
  border: 1px solid ${theme.colors.borderGray2};
  margin: 8px 0;
  position: relative;
`

export const StyledCardIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  height: 47px;
  width: 47px;
  padding: 5px;
  background-color: ${theme.colors.buttons.marineBlue};
`
