import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { motion } from 'framer-motion'

import {
  PageWrapper,
  Flex,
  Heading as H,
  Paragraph as P,
  Spacer,
  Footer,
  Button,
  Loading,
} from 'components'
import { useBuyerStore } from 'stores'
import { getCountryCodeLocalDb } from 'utils'
import { newCreditReportOutcome } from 'assets'
import { theme } from 'styles'

const VerificationOutcome = () => {
  const history = useHistory()
  const countryCode = getCountryCodeLocalDb()

  const [warningText, setWarningText] = useState('')

  // *Stores
  const {
    response: { credits: creditsResponse },
    fetchCredits,
  } = useBuyerStore()

  // *Methods
  const handleReturn = () => {
    return history.goBack()
  }

  // *Effects
  useEffect(() => {
    fetchCredits(countryCode)
  }, [])

  useEffect(() => {
    if (creditsResponse && creditsResponse.warning_message) {
      setWarningText(creditsResponse.warning_message)
    }
  }, [creditsResponse])

  // *JSX
  return (
    <PageWrapper
      style={{
        background: theme.colors.buttons.marineBlue,
        minHeight: '100vh',
      }}
    >
      <Flex
        stretch
        flex="1"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        color="white"
        pb={150}
        px={10}
      >
        <motion.img
          src={newCreditReportOutcome}
          alt="report_outcome"
          transition={{
            duration: 0.2,
            type: 'spring',
            stiffness: 100,
          }}
          initial={{ scale: 0.1 }}
          animate={{ scale: 1.0 }}
          width="240px"
          height="240px"
        />
        <H variant="h3" textAlign="center">
          Verification Outcome
        </H>
        <Spacer height={10} />

        {warningText.length === 0 && <Loading color="white" style={{ marginTop: 20 }} />}
        {warningText.length > 0 && <P textAlign="center">{warningText}</P>}
        <Spacer height={10} />
      </Flex>

      <Footer
        initial={{ y: 100 }}
        animate={{ y: 0 }}
        transition={{
          delay: 0.8,
          duration: 0.2,
          type: 'spring',
          stiffness: 150,
        }}
      >
        <Flex flexDirection="column" fitWidth>
          <Button variant="secondary" onClick={handleReturn}>
            RETURN
          </Button>
        </Flex>
      </Footer>
    </PageWrapper>
  )
}

export default VerificationOutcome
