// *used to improve UX, buyer won't get back to payment process on clicking 'back' after successful payment
export const pushPaymentMethodsHistory = (history, nextDestination) => {
  const stackCount = 10
  for (let i = 0; i < stackCount; i++) {
    if (i === stackCount - 1) {
      history.push(`/paymentmethods`)
    } else {
      history.push('/profile')
    }
  }
  history.push(nextDestination)
}
