import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { theme } from 'styles'
import { useBuyerStore } from 'stores'
import { Paragraph as P, FormSelect, Spacer } from 'components'

const EDDARequest = ({ editableFields }) => {
  const {
    control,
    formState: { errors: formErrors },
  } = useFormContext()

  const {
    response: { DBSeGIROMeta: DBSeGIROMetaResponse },
  } = useBuyerStore()

  const bankOptions = [{ value: '', label: 'Select option' }]
  if (DBSeGIROMetaResponse?.banks) {
    DBSeGIROMetaResponse.banks.forEach(({ id, name }) => {
      bankOptions.push({ value: id, label: name })
    })
  }

  return (
    <>
      <Spacer height={15} />
      <Controller
        name="applicant_bank_code"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <FormSelect
            {...field}
            label="payer bank"
            showLabel
            options={bankOptions}
            onChange={e => field.onChange(e.value)}
            value={bankOptions.find(c => c.value === field.value)}
            style={{ width: '100%' }}
            error={formErrors.applicant_bank_code}
            isDisabled={!editableFields.includes('applicant_bank_code')}
            variant={editableFields.includes('applicant_bank_code') ? '' : 'autofill'}
            required
          />
        )}
      />

      <P variant="tag" color={theme.colors.lockGray} pt={20} pb={100} style={{ fontWeight: 400 }}>
        You will be taken to your bank website to complete the GIRO creation.
      </P>
    </>
  )
}

export default EDDARequest
