import React from 'react'
import PropTypes from 'prop-types'

import { useWindowSize } from 'hooks'

import { StyledContainer, StyledHomeContainer } from './styledBackdropStyle'

const StyledBackdrop = ({ variant, children, ...rest }) => {
  let screenWidth = useWindowSize().width
  if (screenWidth < 415) screenWidth = 415

  if (variant === 'home') {
    return (
      <StyledHomeContainer screenWidth={screenWidth} {...rest}>
        {children}
      </StyledHomeContainer>
    )
  }

  return (
    <StyledContainer screenWidth={screenWidth} {...rest}>
      {children}
    </StyledContainer>
  )
}

StyledBackdrop.propTypes = {
  variant: PropTypes.string,
}

export default StyledBackdrop
