import styled from 'styled-components'
import { motion } from 'framer-motion'

export const StyledCircleBackground = styled(motion.circle)`
  fill: none;
  stroke: #f0f3f5;
`

export const StyledCircleProgressLong = styled(motion.circle)`
  fill: none;
  /* stroke: #fe6e61; */
  opacity: 0.3;
  stroke-linecap: round;
  stroke-linejoin: round;
  z-index: 1;
`

export const StyledCircleProgressShort = styled(motion.circle)`
  fill: none;
  /* stroke: #fe6e61; */
  stroke-linecap: round;
  stroke-linejoin: round;
  z-index: 2;
`
