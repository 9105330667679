import { advancedDayjs } from 'utils'

export const checkIsToday = date => {
  return advancedDayjs().isSame(advancedDayjs(date), 'day')
}

export const checkIsYesterday = date => {
  return advancedDayjs().add(-1, 'day').isSame(advancedDayjs(date), 'day')
}

export const checkIsOverdue = date => {
  return advancedDayjs().isAfter(advancedDayjs(date), 'day')
}
