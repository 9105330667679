import styled from 'styled-components'

export const StyledRowDivider = styled.div`
  width: ${props => (props.width ? props.width : '100%')};
  margin: auto;
  border-top: 1px solid #dcdcdc;
  margin-top: 5px;
  margin-bottom: 5px;

  &:last-child {
    display: ${props => (props.show ? 'block' : 'none')};
  }
`
