import styled from 'styled-components'
import {
  space,
  color,
  typography,
  layout,
  flexbox,
  border,
  position,
  compose,
  variant,
} from 'styled-system'
import { motion } from 'framer-motion'

export const StyledRoundedContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  z-index: 1;
  background-color: white;
  box-shadow: ${({ boxShadow }) => (boxShadow ? boxShadow : '0px 2px 9px rgba(0, 0, 0, 0.188893)')};
  margin: 0;
  width: calc(100% - 20px);
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: ${props => (props.fitWidth ? 'calc(100vw - 32px)' : '100%')};
  box-sizing: border-box;

  ${variant({
    variants: {
      roundtop: {
        borderRadius: '16px 16px 0px 0px',
        position: 'fixed',
        bottom: 0,
      },
      roundall: {
        borderRadius: '16px',
      },
    },
  })}
  ${compose(space, color, typography, flexbox, border, position, layout)}
`
