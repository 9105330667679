import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import * as dayjs from 'dayjs'

import { Flex, Paragraph as P, Spacer, Button, Image } from 'components'
import { theme } from 'styles/theme'
import { dashedLine } from 'assets'
import {
  formatAsCurrency,
  secureCardNumber,
  getPaymentTransactionLocalDb,
  pushTransctionHistory,
  fetchCardIcon,
} from 'utils'

const PaymentDetailsInstalment = ({ data }) => {
  const history = useHistory()
  const paymentTransaction = getPaymentTransactionLocalDb()
  const currentUrl = window.location.pathname
  const paymentMethod = data.repayment.payment_transaction.payment_method
  const transactionType = currentUrl.includes('/transaction') ? 'Payment' : 'Purchase'

  const [paymentDetails, setPaymentDetails] = useState(null)

  // *Methods
  const renderPaymentDetailRow = (key, value) => {
    return (
      <Flex key={key} justifyContent="space-between" mb={2} alignItems="center">
        <P style={{ fontWeight: 500 }} color={theme.colors.typography.gray10}>
          {key}
        </P>
        <Spacer width={20} />
        <P
          style={{
            fontWeight: 700,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
          textAlign="right"
        >
          {value}
        </P>
      </Flex>
    )
  }

  // *Effects
  useEffect(() => {
    if (data) {
      setPaymentDetails([
        { key: 'Order ID', value: data.order.code },
        { key: 'Merchant', value: data.order.store.name },
        {
          key: 'Transacted at',
          value: dayjs(data.repayment.payment_transaction.created_at).format('DD MMM YYYY, hh:mma'),
        },
        {
          key: 'Payment plan',
          value: `${formatAsCurrency(data.order.selected_payment_plan.instalment_amount)} / ${
            data.order.selected_payment_plan.instalment_frequency
          }`,
        },
        {
          key: 'Duration',
          value: `${data.order.selected_payment_plan.instalment_count} instalments`,
        },
      ])
    }
  }, [data])

  // *JSX
  return (
    <Flex flexDirection="column" px={3}>
      {paymentDetails &&
        paymentDetails.map(detail => {
          if (transactionType === 'Payment') {
            if (detail.key === 'Payment plan' || detail.key === 'Duration')
              // return <Spacer height={10} key={detail.key} />;
              return null
          }
          return renderPaymentDetailRow(detail.key, detail.value)
        })}
      {data &&
        data.order &&
        data.order.vouchers &&
        data.order.vouchers.length > 0 &&
        renderPaymentDetailRow('Voucher used', data.order.vouchers[0].code)}
      <>
        <Spacer height={10} />
        <Image src={dashedLine} alt="dashed_line" style={{ margin: '0 10px' }} />
        <Spacer height={10} />
      </>
      {renderPaymentDetailRow("Today's payment", formatAsCurrency(data.repayment.total_amount))}
      <P style={{ fontWeight: 500 }} color={theme.colors.typography.gray10}>
        Payment method
      </P>
      <Flex justifyContent="space-between" py={2}>
        {paymentMethod.mode === 'card' && (
          <>
            <Image
              src={fetchCardIcon(paymentMethod.card.brand)}
              alt="payment_logo"
              style={{ width: 50 }}
            />
            <P style={{ fontWeight: 700 }} textAlign="right">
              {secureCardNumber(paymentMethod.card.ending_digits)}
            </P>
          </>
        )}
        {paymentMethod.mode === 'edda' && (
          <>
            <P style={{ fontWeight: 700 }}>{paymentMethod.edda_request.bank_name}</P>
            <P style={{ fontWeight: 700 }} textAlign="right">
              {`*** ${paymentMethod.edda_request.bank_acc_no_last_4}`}
            </P>
          </>
        )}
      </Flex>

      <Spacer height={40} />
      <Button
        variant="secondary"
        onClick={() => {
          pushTransctionHistory(history, paymentTransaction.order.code)
        }}
      >
        RETURN
      </Button>
    </Flex>
  )
}

export default PaymentDetailsInstalment
