import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { Header, Flex, RoundedContainer, Spacer } from 'components'

const PayRemainingBalanceHeaderLoading = () => {
  return (
    <Header withBottomBorder>
      <Flex mt={42} ml={45} mr={15}>
        <RoundedContainer
          height={68}
          width={68}
          alignItems="center"
          justifyContent="center"
          boxShadow="0px 2px 7px 0px #00000019"
          borderRadius={12}
        >
          <Skeleton height={45} width={45} />
        </RoundedContainer>

        <Flex flexDirection="column" ml={3} justifyContent="center">
          <Skeleton height={25} width={200} />
          <Spacer height={3} />
          <Skeleton height={20} width={100} />
        </Flex>
      </Flex>
      <Spacer height={15} />
    </Header>
  )
}

export default PayRemainingBalanceHeaderLoading
