import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import AutosizeInput from 'react-input-autosize'

import { Flex, Heading as H, Paragraph as P, Spacer } from 'components'
import { theme } from 'styles/theme'
import { limitInputToTwoDecimals, fetchCurrencySymbol, formatAsCurrency } from 'utils'

const ManualAmount = ({ setAmount, onSubmit, isValidAmount, setIsValidAmount, minOrderAmount }) => {
  const [manualAmount, setManualAmount] = useState('')

  // *Methods
  const handleChangeAmount = e => {
    const value = e.target.value
    setManualAmount(limitInputToTwoDecimals(value))
  }

  // *Effects
  useEffect(() => {
    setAmount(manualAmount)
    if (manualAmount && manualAmount.length > 0) {
      if (!minOrderAmount) return setIsValidAmount(true)

      if (minOrderAmount && manualAmount >= parseFloat(minOrderAmount)) {
        setIsValidAmount(true)
      } else {
        setIsValidAmount(false)
      }
    }
  }, [manualAmount])

  // *JSX
  return (
    <>
      <Flex alignItems="center" justifyContent="center" mt={-3} fitWidth>
        <H variant="h1" style={{ fontSize: 48 }}>
          {fetchCurrencySymbol()}
        </H>
        <Spacer width={5} />

        <form
          onSubmit={e => {
            e.preventDefault()
            if (isValidAmount) onSubmit()
          }}
        >
          <AutosizeInput
            autoFocus
            type="number"
            name="manual_amount"
            step="0.01"
            placeholder="0.00"
            value={manualAmount}
            onChange={handleChangeAmount}
            inputStyle={{
              fontSize: 48,
              fontFamily: 'Poppins',
              fontWeight: 700,
              border: 'none',
              color: manualAmount === '0.00' ? theme.colors.typography.gray6 : 'black',
            }}
          />
        </form>
      </Flex>
      {minOrderAmount && !isValidAmount && manualAmount.length > 0 && (
        <P
          variant="small"
          color={theme.colors.actions.failureRed}
        >{`Please enter at least ${formatAsCurrency(minOrderAmount)}`}</P>
      )}
    </>
  )
}

ManualAmount.propTypes = {
  setAmount: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  isValidAmount: PropTypes.bool.isRequired,
  setIsValidAmount: PropTypes.func.isRequired,
  minOrderAmount: PropTypes.string,
}

export default ManualAmount
