import React from 'react'
import PropTypes from 'prop-types'
import { Panel } from 'rc-collapse'

import { StyledCollapse } from './accordionStyle'

const Accordion = ({ header, children, defaultActiveKey, accordion, rest }) => (
  <StyledCollapse defaultActiveKey={defaultActiveKey} accordion={accordion} {...rest}>
    <Panel header={header} headerClass="my-header-class">
      {children}
    </Panel>
  </StyledCollapse>
)

Accordion.propTypes = {
  header: PropTypes.string.isRequired,
  defaultActiveKey: PropTypes.string,
  children: PropTypes.node.isRequired,
  accordion: PropTypes.bool,
}

Accordion.propTypes = {
  defaultActiveKey: null,
  accordion: false,
}

Accordion.Panel = Panel

export default Accordion
