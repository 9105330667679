import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useStripe } from '@stripe/react-stripe-js'

import {
  PageWrapper,
  Flex,
  Spacer,
  Paragraph as P,
  NavigateBack,
  PaymentMethodDropdown,
  PaymentMethodDropdownLoading,
  Footer,
  Button,
  TriggerPopup,
} from 'components'
import { useBuyerStore, useOrderStore } from 'stores'
import {
  getCountryCodeLocalDb,
  formatAsCurrency,
  pushTransctionHistory,
  setPaymentTransactionLocalDb,
  logUserInteractionGA,
} from 'utils'

import PayNextHeader from './PayNextHeader/PayNextHeader'
import PayNextHeaderLoading from './PayNextHeader/PayNextHeaderLoading'
import PayNextDetails from './PayNextDetails/PayNextDetails'
import PayNextDetailsLoading from './PayNextDetails/PayNextDetailsLoading'

const TransactionPayNext = () => {
  const stripe = useStripe()
  const countryCode = getCountryCodeLocalDb()
  const history = useHistory()
  const location = useLocation().search
  const orderCode = new URLSearchParams(location).get('order')
  const repaymentId = new URLSearchParams(location).get('repayment')
  const useNewPaymentMethod = new URLSearchParams(location).get('use_new_payment_method')

  const [currentRepayment, setCurrentRepayment] = useState(null)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null)
  const [selectedPaymentMethodError, setSelectedPaymentMethodError] = useState('')
  const [paymentFailed, setPaymentFailed] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  // *Stores
  const {
    response: {
      order: orderResponse,
      payNext: payNextResponse,
      updatePaymentTransaction: updatePaymentTransactionResponse,
    },
    errors: { payNext: payNextError },
    resetStates: resetStatesOrder,
    fetchOrder,
    submitPayNext,
    updatePaymentTransaction,
  } = useOrderStore()

  const {
    response: { paymentMethodFetch: paymentMethodFetchResponse },
    resetStates: resetStatesBuyer,
    fetchPaymentMethod,
  } = useBuyerStore()

  // *Methods
  const handlePayRepayment = () => {
    const paymentMethodId = selectedPaymentMethod.id

    logUserInteractionGA(`Payment - Pay Next`, {
      order: orderCode,
      repayment: repaymentId,
    })
    setIsLoading(true)
    submitPayNext(orderCode, paymentMethodId, repaymentId)
  }

  const handleClearErrors = () => {
    setIsLoading(false)
    setPaymentFailed(false)
    setErrorMessage('')
    resetStatesOrder('payNext')
    resetStatesOrder('updatePaymentTransaction')
  }

  const handleStripeActionRequired = async () => {
    const stripeData = payNextResponse.data.repayment.payment_transaction.stripe
    const { client_secret, payment_method_id } = stripeData

    const confirmCardPaymentResponse = await stripe.confirmCardPayment(client_secret, {
      payment_method: payment_method_id,
    })

    // handle authentication error
    if (
      confirmCardPaymentResponse &&
      confirmCardPaymentResponse.error &&
      confirmCardPaymentResponse.error.message
    ) {
      setIsLoading(false)
      const paymentIntentId = confirmCardPaymentResponse.error.payment_intent.id

      updatePaymentTransaction({
        third_party_name: 'stripe',
        stripe: {
          country: countryCode,
          payment_intent_id: paymentIntentId,
        },
      })
      return setErrorMessage(confirmCardPaymentResponse.error.message)
    }

    // handle authentication success
    if (
      confirmCardPaymentResponse &&
      confirmCardPaymentResponse.paymentIntent &&
      confirmCardPaymentResponse.paymentIntent.id
    ) {
      const paymentIntentId = confirmCardPaymentResponse.paymentIntent.id
      updatePaymentTransaction({
        third_party_name: 'stripe',
        stripe: {
          country: countryCode,
          payment_intent_id: paymentIntentId,
        },
      })
    }
  }

  // *Effects
  useEffect(() => {
    if (orderCode) {
      fetchOrder(orderCode)
    }

    if (countryCode) {
      fetchPaymentMethod(countryCode)
    }

    return () => {
      resetStatesBuyer('paymentMethodFetch')
      resetStatesOrder('order')
      resetStatesOrder('payNext')
    }
  }, [])

  useEffect(() => {
    if (errorMessage) {
      logUserInteractionGA(`Error - ${errorMessage}`)
    }
  }, [errorMessage])

  useEffect(() => {
    if (orderResponse && orderResponse.repayments) {
      const selectedRepayment = orderResponse.repayments.find(
        repayment => repayment.id === repaymentId,
      )
      setCurrentRepayment(selectedRepayment)
    }

    if (orderResponse && orderResponse.collection_payment_method) {
      setSelectedPaymentMethod(orderResponse.collection_payment_method)
    }
  }, [orderResponse])

  useEffect(() => {
    if (payNextResponse && payNextResponse.success && payNextResponse.data) {
      if (
        payNextResponse.data.repayment.payment_transaction &&
        payNextResponse.data.repayment.payment_transaction.status === 'success'
      ) {
        setPaymentTransactionLocalDb(payNextResponse.data)

        resetStatesOrder('payNext')
        pushTransctionHistory(history, orderCode)
        history.push('/transaction/success')
      } else if (payNextResponse.data.repayment.payment_transaction.status === 'require_action') {
        handleStripeActionRequired()
      } else {
        setPaymentFailed(true)
        if (payNextResponse.data.repayment.payment_transaction.failed_reason) {
          setErrorMessage(
            `An error occurred when making the payment. Reason: ${payNextResponse.data.repayment.payment_transaction.failed_reason}`,
          )
        } else {
          setErrorMessage('An error occurred when making the payment. Please try again.')
        }
        return
      }
    }
  }, [payNextResponse])

  useEffect(() => {
    if (payNextError) {
      setPaymentFailed(true)
      if (payNextError.repayment_id) {
        setErrorMessage(payNextError.repayment_id[0])
      } else if (payNextError.payment_method_id) {
        setErrorMessage(
          'We are unable to find this payment method in your account. Please check if the payment method is still valid, or use another payment method.',
        )
      } else {
        setErrorMessage('An error occurred when making the payment. Please try again.')
      }
    }
  }, [payNextError])

  useEffect(() => {
    if (
      updatePaymentTransactionResponse &&
      updatePaymentTransactionResponse.repayment &&
      updatePaymentTransactionResponse.repayment.payment_transaction &&
      updatePaymentTransactionResponse.repayment.payment_transaction.status
    ) {
      if (updatePaymentTransactionResponse.repayment.payment_transaction.status === 'success') {
        setPaymentTransactionLocalDb(updatePaymentTransactionResponse)

        resetStatesOrder('updatePaymentTransaction')
        resetStatesOrder('payNext')
        pushTransctionHistory(history, orderCode)
        history.push('/transaction/success')
      } else if (updatePaymentTransactionResponse.repayment.payment_transaction.failed_reason) {
        setErrorMessage(
          `An error occurred when making the payment. Reason: ${updatePaymentTransactionResponse.repayment.payment_transaction.failed_reason}`,
        )
      }
    }
  }, [updatePaymentTransactionResponse])

  // *JSX
  return (
    <>
      {/* Error handling */}
      <TriggerPopup
        showPopup={paymentFailed || !!errorMessage}
        message={errorMessage || 'An error occurred when making the payment. Please try again.'}
        buttonText="OKAY"
        stayAtCurrentPage
        callback={handleClearErrors}
      />

      <PageWrapper withTopNav>
        <NavigateBack noBackground />

        {orderResponse && currentRepayment ? (
          <>
            <Flex width="100%" flexDirection="column">
              <PayNextHeader
                store={orderResponse.store}
                merchant={orderResponse.merchant}
                repaymentAmount={currentRepayment.total_amount}
              />

              <PayNextDetails data={currentRepayment} />

              <Spacer height={20} />
              <P style={{ fontWeight: 700 }}>Payment method</P>
              <Spacer height={10} />
            </Flex>
            <Footer withTopBorder>
              <Button
                disabled={!orderResponse || !selectedPaymentMethod || !!selectedPaymentMethodError}
                isLoading={isLoading}
                onClick={handlePayRepayment}
              >{`PAY ${formatAsCurrency(currentRepayment.total_amount)}`}</Button>
            </Footer>
          </>
        ) : (
          <>
            <Flex width="100%" flexDirection="column">
              <PayNextHeaderLoading />
              <PayNextDetailsLoading />

              <Spacer height={20} />
              <P style={{ fontWeight: 700 }}>Payment method</P>
              <Spacer height={10} />
            </Flex>
          </>
        )}

        {paymentMethodFetchResponse && orderResponse ? (
          <PaymentMethodDropdown
            paymentMethods={paymentMethodFetchResponse}
            setSelectedPaymentMethod={setSelectedPaymentMethod}
            selectedPaymentMethodError={selectedPaymentMethodError}
            setSelectedPaymentMethodError={setSelectedPaymentMethodError}
            presetActivePaymentMethod={
              (orderResponse && orderResponse.collection_payment_method) || null
            }
            saveCurrentUrl
            allowInvalidPaymentMethod
            useNewPaymentMethod={useNewPaymentMethod}
            stayAtCurrentPageAfterClearingError
          />
        ) : (
          <PaymentMethodDropdownLoading />
        )}
        <Spacer height={100} />
      </PageWrapper>
    </>
  )
}

export default TransactionPayNext
