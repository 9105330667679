export const extractKycInfo = kycs => {
  let kycInfo = {}

  kycs.map(kyc => {
    const { kyc_type, status, expired_at } = kyc
    return (kycInfo = {
      ...kycInfo,
      [kyc_type]: status,
      [`${kyc_type}_validity`]: expired_at,
    })
  })

  return kycInfo
}
