import React from 'react'
import PropTypes from 'prop-types'

import { Flex, Heading as H, Paragraph as P, Spacer, Span } from 'components'
import { theme } from 'styles/theme'
import { formatAsCurrency } from 'utils'

const CreditExplanationNormal = ({
  merchantBalance,
  userBalance,
  creditBalance,
  creditAdditionalAmount,
}) => {
  return (
    <Flex mx={3} flexDirection="column" pb={4}>
      <Spacer height={15} />
      <H variant="h4">Spending credits</H>
      <Spacer height={15} />
      <>
        <P variant="small" color={theme.colors.typography.gray11}>
          The maximum credit that can be used for this purchase is{' '}
          <Span color={theme.colors.abnkBlue} style={{ fontWeight: 700 }}>
            {formatAsCurrency(creditBalance || '0.00')}
          </Span>
          , based on the lower of the following limits:
        </P>
        <Spacer height={15} />

        <P variant="small" color={theme.colors.typography.gray11}>
          Remaining user credit:{' '}
          <Span style={{ fontWeight: 700 }}>{formatAsCurrency(userBalance || '0.00')}</Span>{' '}
          {creditAdditionalAmount && <Span style={{ fontWeight: 700 }}>(from voucher)</Span>}
        </P>

        <P variant="small" color={theme.colors.typography.gray11}>
          Remaining merchant credit:{' '}
          {merchantBalance ? (
            <Span style={{ fontWeight: 700 }}>{formatAsCurrency(merchantBalance)}</Span>
          ) : (
            <Span style={{ fontWeight: 700 }}>No limit</Span>
          )}
        </P>
      </>
    </Flex>
  )
}

CreditExplanationNormal.propTypes = {
  merchantBalance: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  userBalance: PropTypes.string.isRequired,
  voucherAdditionalCredit: PropTypes.number,
}

export default CreditExplanationNormal
