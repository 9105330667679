import React from 'react'

import NormalScheduleRow from './NormalScheduleRow/NormalScheduleRow'
import PayEarlyScheduleRow from './PayEarlyScheduleRow/PayEarlyScheduleRow'

const ScheduleRow = ({
  isPayEarly = false,
  payment,
  orderCode,
  isLoading,
  selectedPaymentMethod,
}) => {
  if (!payment) return <></>

  if (isPayEarly) {
    return <PayEarlyScheduleRow key={payment.id} payment={payment} isLoading={isLoading} />
  } else {
    return (
      <NormalScheduleRow
        key={payment.id}
        payment={payment}
        orderCode={orderCode}
        isLoading={isLoading}
        selectedPaymentMethod={selectedPaymentMethod}
      />
    )
  }
}

export default ScheduleRow
