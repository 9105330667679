import React from 'react'
import PropTypes from 'prop-types'
import escapeRegExp from 'lodash/escapeRegExp'

import { Span } from 'components'

const Highlighted = ({ text, highlight = '' }) => {
  if (!highlight.trim()) {
    return <Span>{text}</Span>
  }

  const regex = new RegExp(`(${escapeRegExp(highlight)})`, 'gi')
  const parts = text.split(regex)
  return (
    <Span>
      {parts
        .filter(part => part)
        .map((part, i) =>
          regex.test(part) ? <mark key={i}>{part}</mark> : <Span key={i}>{part}</Span>,
        )}
    </Span>
  )
}

Highlighted.propTypes = {
  text: PropTypes.string.isRequired,
}

export default Highlighted
