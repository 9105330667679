import React from 'react'
import { Flex, Paragraph as P, Image } from 'components'
import { exportIcon } from 'assets'

const FileUploadButton = ({ handleClick, topText, bottomText }) => {
  return (
    <Flex
      alignItems="center"
      mb={2}
      onClick={handleClick}
      style={{
        border: '0.12rem solid #D3D3D3',
        borderRadius: '0.8rem',
        padding: '0.7rem',
      }}
    >
      <Image
        src={exportIcon}
        width="34rem"
        height="34rem"
        style={{
          backgroundColor: '#EDEDED',
          borderRadius: '0.5rem',
          padding: '0.3rem',
          paddingTop: '0.5rem',
          marginRight: '0.6rem',
        }}
      />
      <Flex flexDirection="column">
        {[topText, bottomText].map(text => (
          <P
            key={text}
            variant="subcopy"
            textAlign="left"
            color="#979797"
            style={{ fontWeight: 400 }}
          >
            {text}
          </P>
        ))}
      </Flex>
    </Flex>
  )
}

export default FileUploadButton
