import React from 'react'
import PropTypes from 'prop-types'

import { IndicatorContainer, ActiveIndicator, InactiveIndicator } from './customIndicatorStyle'

const CustomIndicator = ({ steps, totalSteps, variant }) => {
  const arr = Array(totalSteps + 1)
    .fill()
    .map((_, index) => index)

  return (
    <IndicatorContainer>
      {arr.map((step, i) => {
        if (step === steps) {
          return <ActiveIndicator key={i} variant={variant} />
        }
        return <InactiveIndicator key={i} variant={variant} />
      })}
    </IndicatorContainer>
  )
}

CustomIndicator.propTyeps = {
  steps: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
}

export default CustomIndicator
