import React, { useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useSwipeable } from 'react-swipeable'

import { Flex } from 'components'

import { StyledOverlay, StyledContainer, StyledHandle } from './draggableOverlayStyle'

const containerVariants = {
  enter: {
    opacity: 1,
  },
  default: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 1,
    transition: {
      when: 'afterChildren',
    },
  },
}

const childVariants = {
  hidden: {
    opacity: 0,
    y: '100vh',
  },
  default: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'tween',
    },
  },
  full: {
    opacity: 1,
    y: '1vh',
    transition: {
      type: 'spring',
    },
  },
}

const DraggableOverlay = ({ cancel, children }) => {
  const [variant, setVariant] = useState('default')

  // *Methods
  const swipeHandlers = useSwipeable({
    onSwiped: eventData => {
      const { dir } = eventData

      if (dir === 'Up') {
        setVariant('full')
      } else {
        setVariant('default')
      }

      if (dir === 'Down') {
        if (variant === 'default' && typeof cancel === 'function') {
          cancel()
        }
      }
    },
    // preventDefaultTouchmoveEvent: true,
  })

  // *JSX
  return (
    <AnimatePresence style={{ overscrollBehaviorY: 'contain' }}>
      <StyledOverlay
        onClick={cancel}
        variants={containerVariants}
        initial="hidden"
        animate="default"
        exit={{ opacity: 0 }}
      >
        <StyledContainer
          onClick={e => e.stopPropagation()}
          variants={childVariants}
          initial="hidden"
          animate={variant}
          variant={variant}
          {...swipeHandlers}
        >
          <StyledHandle />
          <Flex
            flexDirection="column"
            style={{
              maxHeight: '80vh',
              overflowY: 'scroll',
              margin: '15px 20px',
            }}
          >
            {children}
          </Flex>
        </StyledContainer>
      </StyledOverlay>
    </AnimatePresence>
  )
}

export default DraggableOverlay
