import styled from 'styled-components'
import { variant } from 'styled-system'
import { motion } from 'framer-motion'

export const StyledOverlay = styled(motion.div)`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 11;
  padding-bottom: 30px;
`

export const StyledContainer = styled(motion.div)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-y: scroll;
  background: white;
  border-radius: 16px 16px 0px 0px;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.188893);
  max-height: 90vh;
  z-index: 12;

  ${variant({
    variants: {
      default: {
        minHeight: '20%',
      },
      full: {
        minHeight: '90%',
      },
    },
  })};
`

export const StyledHandle = styled.div`
  background-color: #eaeaea;
  height: 9px;
  width: 67px;
  border-radius: 4.5px;
  align-self: center;
  margin: 16px auto;
`
