import styled from 'styled-components'

export const Handle = styled('div')`
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
`
