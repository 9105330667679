import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'

import { Flex, RoundedContainer, Paragraph as P, Image } from 'components'
import { theme } from 'styles/theme'
import { logUserInteractionGA } from 'utils'

const FeaturedCard = ({ merchant }) => {
  const history = useHistory()
  const { categories, logo, name, slug } = merchant

  const handleClick = () => {
    logUserInteractionGA(`Click: Merchant "${slug}"`)
    history.push(`/merchant/?slug=${slug}`)
  }

  return (
    <RoundedContainer
      flex="1 0 40%"
      mx={10}
      overflow="hidden"
      backgroundColor="transparent"
      onClick={handleClick}
    >
      <Flex flexDirection="column" stretch centered>
        <Flex width="100px" height="100px" stretch centered>
          <Image
            src={logo}
            alt="merchant-logo"
            style={{ objectFit: 'contain', maxWidth: '80px' }}
          />
        </Flex>
        <Flex flexDirection="column" alignSelf="flex-start" mt={3} maxWidth="100%">
          <P
            variant="small"
            style={{
              fontWeight: 700,
            }}
          >
            {name}
          </P>
          <P
            variant="small"
            color={theme.colors.typography.gray7}
            style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {categories[0].name}
          </P>
        </Flex>
      </Flex>
    </RoundedContainer>
  )
}

FeaturedCard.propTypes = {
  merchant: PropTypes.any,
}

export default FeaturedCard
