import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { Flex, Spacer, Image, RowDivider } from 'components'

import { dashedLine } from 'assets'

const PayRemainingAmountLoading = () => {
  return (
    <Flex flexDirection="column">
      <Flex justifyContent="space-between">
        <Skeleton height={22} width={120} />
        <Skeleton height={22} width={80} />
      </Flex>

      <Spacer height={10} />
      <Flex justifyContent="space-between">
        <Skeleton height={22} width={180} />
        <Skeleton height={22} width={80} />
      </Flex>

      <Image src={dashedLine} alt="dashed_line" style={{ marginTop: 20, marginBottom: 20 }} />

      <Flex justifyContent="space-between">
        <Skeleton height={22} width={140} />
        <Skeleton height={22} width={80} />
      </Flex>

      <RowDivider style={{ margin: '10px 0px 15px 0px' }} />

      <Flex justifyContent="center">
        <Skeleton height={22} width={250} />
      </Flex>
    </Flex>
  )
}

export default PayRemainingAmountLoading
