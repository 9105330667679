import styled from 'styled-components'
import { variant } from 'styled-system'

import { theme } from 'styles/theme'

const StyledIcon = styled.label`
  &:before {
    content: '';
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    width: 20px;
    background: ${props => props.inputIcon && `url(${props.inputIcon}) center / contain no-repeat`};
  }
`

export const StyledRightIcon = styled.label`
  content: '';
  position: absolute;
  right: 15px;
  top: 0;
  bottom: 0;
  width: 15px;
  background: ${props => props.icon && `url(${props.icon}) center / contain no-repeat`};
`

const StyledInput = styled.input`
  background-color: white;
  font-family: ${theme.typography.fontFamily};
  border-radius: 8px;
  font-size: 14px;
  padding: 11px;
  padding-left: ${props => props.inputIcon && '35px'};
  font-weight: 600;
  width: 100%;
  border: ${props =>
    props.error
      ? `1px solid ${theme.colors.actions.failureRed}`
      : `1px solid ${theme.colors.borderGray}`};
  box-sizing: border-box;

  ${variant({
    variants: {
      autofill: {
        border: '1px solid #9c9d9d',
        boxShadow: '0 0 0 30px #fffad9 inset !important',
        backgroundClip: 'content-box !important',
      },
    },
  })}

  ::placeholder {
    color: ${theme.colors.typography.gray6};
  }

  &:focus {
    outline: none;
    border: 1px solid ${theme.colors.actions.actionBlue};
  }
`

export { StyledInput, StyledIcon }
