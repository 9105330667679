import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'
import Countdown from 'react-countdown'
import ReactGA from 'react-ga'

import { useBuyerStore, useAuthStore } from 'stores'
import {
  Flex,
  Image,
  Paragraph as P,
  Span,
  FormOtp,
  Loading,
  Spacer,
  Button,
  Footer,
  TriggerPopup,
} from 'components'
import {
  getCheckoutIdLocalDb,
  getReferredCodeLocalDb,
  getCountryCodeLocalDb,
  getStoreIdLocalDb,
  setTokenLocalDb,
  findIdentityKyc,
  // setShouldRedirectToAddPayment,
  // findPaymentMethodKyc,
  setBuyerNameLocalDb,
  removeTokenLocalDb,
} from 'utils'
import { GENERIC_ERR_MESSAGE } from 'const'
import { theme } from 'styles/theme'
import { newOnboardingVerify } from 'assets'
import { useWindowSize } from 'hooks'

const OtpForm = ({ prefix, mobile }) => {
  const countryCode = getCountryCodeLocalDb()
  const history = useHistory()
  const windowSize = useWindowSize()

  const [otp, setOtp] = useState('')
  const [countdown, setCountdown] = useState(Date.now() + 10000)
  const [isLoading, setIsLoading] = useState(false)

  const otpLength = useRef(6)

  // *Stores
  const {
    isLoading: { otpSend: otpSendIsLoading },
    response: { otpSend: otpSendResponse, otpVerify: otpVerifyResponse },
    errors: { otpVerify: otpVerifyErrors },
    resetStates: authResetStates,
    submitMobile,
    submitOtp,
  } = useAuthStore()
  const { session_id } = otpSendResponse

  const {
    response: { getOrCreate: getOrCreateResponse, credits: creditsResponse },
    errors: { getOrCreate: getOrCreateError },
    resetStates: resetStatesBuyer,
    fetchCredits,
    submitGetOrCreate,
  } = useBuyerStore()

  // *Methods
  const renderer = ({ seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <P color={theme.colors.typography.gray1}>
          Didn&apos;t receive the code?{' '}
          <Span color={theme.colors.linkBlue} style={{ fontWeight: 600 }} onClick={onResendOtp}>
            Resend
          </Span>
        </P>
      )
    } else {
      // Render a countdown
      return <P style={{ color: theme.colors.typography.gray1 }}>Resend OTP in {seconds} seconds</P>
    }
  }

  const onChange = otp => {
    setOtp(otp)
    if (otp.length === 1) {
      authResetStates('otpVerify')
    }
  }

  const onResendOtp = () => {
    authResetStates('otpVerify')
    setOtp('')
    setCountdown(Date.now() + 10000)
    submitMobile(prefix, mobile)
  }

  const handleSubmitOtp = () => {
    authResetStates('otpVerify')
    submitOtp(otp, session_id)
    setIsLoading(true)
  }

  // *Effects
  useEffect(() => {
    return () => {
      resetStatesBuyer('otpVerify')
      resetStatesBuyer('getOrCreate')
      resetStatesBuyer('credits')
    }
  }, [])

  useEffect(() => {
    if (otpVerifyResponse && otpVerifyResponse.token) {
      setTokenLocalDb(otpVerifyResponse.token)
      submitGetOrCreate()
    }
  }, [otpVerifyResponse])

  useEffect(() => {
    if (otpVerifyErrors) {
      setIsLoading(false)
      setOtp('')
    }
  }, [otpVerifyErrors])

  useEffect(() => {
    if (getOrCreateResponse) {
      // set user id for GA user explorer
      if (getOrCreateResponse.id) {
        console.log('set reactga userId as' + getOrCreateResponse.id)
        ReactGA.set({ userId: getOrCreateResponse.id })
      }

      // Analytics Tracking - New sign up
      if (getOrCreateResponse.is_new_user) {
        if (window.fbq) {
          // https://developers.facebook.com/docs/meta-pixel/reference#standard-events
          window.fbq('track', 'CompleteRegistration')
        }
      }

      fetchCredits(countryCode)
      setBuyerNameLocalDb(getOrCreateResponse.name)
    }
  }, [getOrCreateResponse])

  useEffect(() => {
    if (creditsResponse && creditsResponse.kycs) {
      const identityKyc = findIdentityKyc(creditsResponse.kycs)
      // const paymentMethodKyc = findPaymentMethodKyc(creditsResponse.kycs)

      // !User journey checkpoints start
      // direct user to order page if there is a purchase intent
      const checkoutId = getCheckoutIdLocalDb()
      const referredCode = getReferredCodeLocalDb()
      const storeId = getStoreIdLocalDb()

      if (checkoutId !== null || referredCode !== null || storeId !== null) {
        history.push('/home')
        return history.push('/order')
      }

      // direct user to verify identity if not done
      if (identityKyc && identityKyc.status === 'incomplete') {
        // if (paymentMethodKyc && paymentMethodKyc.status === 'incomplete') {
        //   setShouldRedirectToAddPayment() // add a flag to redirect user to add payment if not done
        // }

        history.push('/home')
        history.push('/credit')
        return history.push('/verify?type=identity&initial=true')

        //   // direct user to add payment method if not done
        // } else if (paymentMethodKyc && paymentMethodKyc.status === 'incomplete') {
        //   history.push('/home')
        //   history.push('/credit')
        //   return history.push('/verify?type=paymentmethod&initial=true')
        //
        //   // direct user to home page if verification is done
      } else {
        history.push('/home')
      }
    }
    // !User journey checkpoints end
  }, [creditsResponse])

  // *JSX
  return (
    <>
      <TriggerPopup
        message={(getOrCreateError && getOrCreateError.message) || GENERIC_ERR_MESSAGE}
        showPopup={!!getOrCreateError}
        buttonText="Return"
        callback={() => {
          resetStatesBuyer('getOrCreate')
          removeTokenLocalDb()
          window.location.reload()
        }}
        nextDestination="/register"
        replaceHistory
      />

      <Flex flexDirection="column">
        {windowSize.height > 600 ? (
          <Flex my={3} fitWidth centered>
            <Image width="200px" my={2} src={newOnboardingVerify} alt="otp-icon" />
          </Flex>
        ) : (
          <Flex my={3} stretch justifyContent="center">
            <Image width="150px" my={2} src={newOnboardingVerify} alt="otp-icon" />
          </Flex>
        )}

        <Flex flexDirection="column" fitWidth centered minHeight={150}>
          <P color={theme.colors.typography.gray1} pl={1}>
            Enter the OTP sent to{' '}
            <Span fontWeight={600} color="black" fontSize={16}>{`+${prefix} ${mobile}`}</Span>
          </P>
          <Spacer height="15px" />
          <Flex fitWidth centered>
            <form onSubmit={e => e.preventDefault()}>
              <FormOtp
                onChange={onChange}
                value={otp}
                numInputs={otpLength.current}
                isDisabled={isLoading}
                isInputNum
                shouldAutoFocus
                hasErrored={otpVerifyErrors}
                error={otpVerifyErrors}
              />
              {/* hidden input for submission in mobile */}
              <input
                type="submit"
                style={{ display: 'none' }}
                disabled={otp.length < otpLength.current}
                onClick={handleSubmitOtp}
              />
            </form>
          </Flex>
        </Flex>
      </Flex>

      <Footer flexDirection="column" centered>
        {otpSendIsLoading ? <Loading /> : <Countdown date={countdown} renderer={renderer} />}

        <Button
          isLoading={isLoading}
          type="button"
          disabled={otp.length < otpLength.current}
          onClick={handleSubmitOtp}
        >
          VERIFY
        </Button>
      </Footer>
    </>
  )
}

OtpForm.propTypes = {
  prefix: PropTypes.string.isRequired,
  mobile: PropTypes.string.isRequired,
}

export default OtpForm
