import React from 'react'
import PropTypes from 'prop-types'

import { StyledContainer, ActiveStyledOption, InactiveStyledOption } from './slideToggleStyle'

const SlideToggle = ({ options, variant, activeIndex }) => {
  return (
    <StyledContainer variant={variant}>
      {options.map((option, index) => {
        return index === activeIndex ? (
          <ActiveStyledOption variant={variant} key={index} onClick={option.callback}>
            {option.label}
          </ActiveStyledOption>
        ) : (
          <InactiveStyledOption variant={variant} key={index} onClick={option.callback}>
            {option.label}
          </InactiveStyledOption>
        )
      })}
    </StyledContainer>
  )
}

SlideToggle.propTypes = {
  options: PropTypes.array.isRequired,
  activeIndex: PropTypes.number,
  variant: PropTypes.string,
}

SlideToggle.defaultProps = {
  activeIndex: 0,
  variant: 'default',
}

export default SlideToggle
