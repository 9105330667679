import React from 'react'

import {
  Header,
  Flex,
  RoundedContainer,
  Heading as H,
  Paragraph as P,
  Spacer,
  Image,
} from 'components'
import { formatAsCurrency } from 'utils'
import { theme } from 'styles/theme'
import { transparent } from 'assets'

const PayRemainingBalanceHeader = ({ merchant, repaymentAmount }) => {
  return (
    <Header withBottomBorder>
      <Flex mt={42} ml={45} mr={15}>
        <RoundedContainer
          height={68}
          width={68}
          justifyContent="center"
          boxShadow="0px 2px 7px 0px #00000019"
          borderRadius={12}
          p={2}
        >
          <Image src={merchant.logo || transparent} alt="merchant_logo" width="100%" />
        </RoundedContainer>

        <Flex flexDirection="column" ml={3} justifyContent="center">
          <H variant="h3">Pay remaining balance</H>
          <P variant="small" color={theme.colors.typography.gray2} style={{ fontWeight: 600 }}>
            {`${repaymentAmount && formatAsCurrency(repaymentAmount)} remaining`}
          </P>
        </Flex>
      </Flex>
      <Spacer height={15} />
    </Header>
  )
}

export default PayRemainingBalanceHeader
