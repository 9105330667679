import React, { useEffect } from 'react'
import { useHistory } from 'react-router'

import { Flex, Spacer, Heading as H, Paragraph as P, Footer, Button, Image } from 'components'
import { theme } from 'styles'
import { newVerifyIdentity } from 'assets'
import { setShouldCheckDraftAtCreditScreen } from 'utils'

const VerificationUpdateRequired = () => {
  const history = useHistory()

  // *Methods
  const handleClickVerify = () => {
    history.push('/verify?type=identity')
  }

  // *Effects
  useEffect(() => {
    setShouldCheckDraftAtCreditScreen()
  }, [])

  return (
    <Flex alignItems="center" width="100%" flexDirection="column" px={3} pb={150} mt={-40}>
      <Image src={newVerifyIdentity} alt="identity_card_icon" width="180px" height="180px" />

      <H variant="h4" textAlign="center">
        Verification update required
      </H>
      <Spacer height={10} />

      <P textAlign="center" color={theme.colors.typography.gray1}>
        You have to update your verification before you can continue the purchase
      </P>

      <Footer>
        <Flex flexDirection="column" fitWidth>
          <Button onClick={handleClickVerify}>VERIFY</Button>
        </Flex>
      </Footer>
    </Flex>
  )
}

export default VerificationUpdateRequired
