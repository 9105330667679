import styled from 'styled-components'
import { motion } from 'framer-motion'
import { variant } from 'styled-system'

import { theme } from 'styles/theme'

export const StyledReceiptContainer = styled(motion.div)`
  width: 100%;
  margin: 15px;
  margin-top: 70px;
  background-color: white;
  box-shadow: 0px 2px 9px 0px #00000030;
  border-radius: 12px;
`

export const StyledPunchHole = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${theme.colors.abnkBlue};

  ${variant({
    variants: {
      left: {
        left: '-20px',
      },
      right: {
        right: '-20px',
      },
    },
  })}
`
