import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import { CarouselImageContainer, CarouselImg } from './imageCarouselStyle'

const ImageCarousel = ({
  images,
  carouselHeight,
  imageHeight,
  imageWidth,
  swipeable,
  backgroundColor,
  selectedItem,
  showIndicators,
  objectFit,
  onChange,
  ...rest
}) => {
  const [imageLoaded, setImageLoaded] = useState(false)

  const indicatorStyles = {
    background: '#888888',
    width: 8,
    height: 8,
    borderRadius: '50%',
    display: 'inline-block',
    margin: '0 3px',
  }

  return (
    <Carousel
      showThumbs={false}
      showStatus={false}
      autoPlay={false}
      showArrows={false}
      swipeable={swipeable}
      selectedItem={selectedItem}
      showIndicators={showIndicators}
      onChange={onChange}
      renderIndicator={(onClickHandler, isSelected, index, label) => {
        if (isSelected) {
          return (
            <li
              style={{
                ...indicatorStyles,
                background: '#3AA1EF',
                width: 30,
                borderRadius: '4.5px',
              }}
              aria-label={`Selected: ${label} ${index + 1}`}
              title={`Selected: ${label} ${index + 1}`}
            />
          )
        }
        return (
          <li
            style={indicatorStyles}
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            value={index}
            key={index}
            role="button"
            tabIndex={0}
            title={`${label} ${index + 1}`}
            aria-label={`${label} ${index + 1}`}
          />
        )
      }}
    >
      {images.map((image, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <CarouselImageContainer height={carouselHeight} bg={backgroundColor} key={i} {...rest}>
          {!imageLoaded && <Skeleton height={imageHeight || '100%'} width={imageWidth || '100%'} />}

          <CarouselImg
            objectFit={objectFit}
            src={image}
            alt={`carousel_${i}`}
            onLoad={() => setImageLoaded(true)}
            style={{
              height: imageHeight ? imageHeight : '100%',
              width: imageWidth ? imageWidth : '100%',
              display: imageLoaded ? '' : 'none',
            }}
          />
        </CarouselImageContainer>
      ))}
    </Carousel>
  )
}

ImageCarousel.propTypes = {
  images: PropTypes.array.isRequired,
  carouselHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  imageHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  imageWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  swipeable: PropTypes.bool,
  backgroundColor: PropTypes.string,
  selectedItem: PropTypes.number,
  showIndicators: PropTypes.bool,
  objectFit: PropTypes.string,
}

ImageCarousel.defaultProps = {
  carouselHeight: '100%',
  imageHeight: '100%',
  imageWidth: '100%',
  swipeable: true,
  backgroundColor: 'black',
  selectedItem: 0,
  showIndicators: true,
  objectFit: 'contain',
}

export default ImageCarousel
