import React from 'react'
import PropTypes from 'prop-types'

import { Flex, Image, Spacer, Heading as H } from 'components'
import { newMerchantSearchNoMatch } from 'assets'

const NoResults = ({ title, actionItem }) => {
  return (
    <Flex flexDirection="column" flex="1" m="auto" centered mt={-80}>
      <Image src={newMerchantSearchNoMatch} alt="no-results" style={{ width: 270 }} />
      <H variant="h4" mt={-20}>
        {title}
      </H>
      <Spacer height={5} />

      {actionItem && actionItem}
    </Flex>
  )
}

NoResults.propTypes = {
  title: PropTypes.string,
  actionItem: PropTypes.node,
}

export default NoResults
