import create from 'zustand'

import {
  postGetOrCreate,
  getCtosUrl,
  getMyinfoUrl,
  getMyinfoDetails,
  getCredit,
  postSgIdentity,
  postCbsPurchase,
  getCbsPurchaseById,
  postCbsUpload,
  getPaymentThirdParty,
  getAdyenPaymentMethods,
  getPaynetFPXEmandateMeta,
  getPaymentMethod,
  postPaymentMethod,
  patchPaymentMethod,
  deletePaymentMethod,
  postDefaultPaymentMethod,
  getUpcomingPayments,
  getNotifications,
  postNotificationRead,
  postNotificationReadAll,
  getContactUsTopics,
  postContactUs,
  getSettings,
  putSettings,
  postBuyerInteraction,
  postAdditionalData,
  postAcquireRedirectUrl,
  getWebsocketUrl,
  getBuyerQr,
  getDBSeGIROMeta,
  postCPFHomeOwnershipCheck,
  getCPFHomeOwnershipCheckById,
} from 'services'

const useBuyerStore = create((set, get) => ({
  isLoading: {
    getOrCreate: false,
    ctosUrl: false,
    myInfoUrl: false,
    myInfoDetails: false,
    credits: false,
    sgPersonal: false,
    cbsPurchase: false,
    cbsPurchaseById: false,
    cbsUpload: false,
    paymentThirdParty: false,
    adyenPaymentMethods: false,
    paynetFPXEmandateMeta: false,
    paymentMethodFetch: false,
    paymentMethodSubmit: false,
    paymentMethodUpdate: false,
    paymentMethodRemove: false,
    defaultPaymentMethodSubmit: false,
    upcomingPayments: false,
    notifications: false,
    notificationRead: false,
    notificationReadAll: false,
    contactUsTopics: false,
    contactUsSubmit: false,
    settings: false,
    settingsUpdate: false,
    buyerInteraction: false,
    additionalData: false,
  },
  response: {
    getOrCreate: null,
    ctosUrl: null,
    myInfoUrl: null,
    myInfoDetails: null,
    credits: null,
    sgPersonal: null,
    cbsPurchase: null,
    cbsPurchaseById: null,
    cbsUpload: null,
    paymentThirdParty: null,
    adyenPaymentMethods: null,
    paynetFPXEmandateMeta: null,
    paymentMethodFetch: null,
    paymentMethodSubmit: null,
    paymentMethodUpdate: null,
    paymentMethodRemove: null,
    defaultPaymentMethodSubmit: null,
    upcomingPayments: null,
    notifications: null,
    notificationRead: null,
    notificationReadAll: null,
    contactUsTopics: null,
    contactUsSubmit: null,
    settings: null,
    settingsUpdate: null,
    buyerInteraction: null,
    additionalData: null,
  },
  errors: {
    getOrCreate: null,
    ctosUrl: null,
    myInfoUrl: null,
    myInfoDetails: null,
    credits: null,
    sgPersonal: null,
    cbsPurchase: null,
    cbsPurchaseById: null,
    cbsUpload: null,
    paymentThirdParty: null,
    adyenPaymentMethods: null,
    paynetFPXEmandateMeta: null,
    paymentMethodFetch: null,
    paymentMethodSubmit: null,
    paymentMethodUpdate: null,
    paymentMethodRemove: null,
    defaultPaymentMethodSubmit: null,
    upcomingPayments: null,
    notifications: null,
    notificationRead: null,
    notificationReadAll: null,
    contactUsTopics: null,
    contactUsSubmit: null,
    settings: null,
    settingsUpdate: null,
    buyerInteraction: null,
    additionalData: null,
  },
  resetStates: dataType => {
    set({
      isLoading: { ...get().isLoading, [dataType]: false },
      response: { ...get().response, [dataType]: null },
      errors: { ...get().errors, [dataType]: null },
    })
  },
  submitGetOrCreate: async () => {
    try {
      set({ isLoading: { ...get().isLoading, getOrCreate: true } })
      const res = await postGetOrCreate()

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, getOrCreate: false },
          response: { ...get().response, getOrCreate: res.data },
          errors: { ...get().errors, getOrCreate: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, getOrCreate: false },
          response: { ...get().response, getOrCreate: null },
          errors: { ...get().errors, getOrCreate: res.error },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, getOrCreate: false },
        response: { ...get().response, getOrCreate: null },
        errors: { ...get().errors, getOrCreate: err.response.data },
      })
    }
  },
  fetchMyinfoUrl: async () => {
    try {
      set({ isLoading: { ...get().isLoading, myInfoUrl: true } })
      const res = await getMyinfoUrl()

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, myInfoUrl: false },
          response: { ...get().response, myInfoUrl: res.data },
          errors: { ...get().errors, myInfoUrl: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, myInfoUrl: false },
          response: { ...get().response, myInfoUrl: null },
          errors: { ...get().errors, myInfoUrl: res.error },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, myInfoUrl: false },
        response: { ...get().response, myInfoUrl: null },
        errors: { ...get().errors, myInfoUrl: err },
      })
    }
  },
  fetchCtosUrl: async (userNric, userName, email) => {
    try {
      set({ isLoading: { ...get().isLoading, ctosUrl: true } })
      const res = await getCtosUrl(userNric, userName, email)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, ctosUrl: false },
          response: { ...get().response, ctosUrl: res.data },
          errors: { ...get().errors, ctosUrl: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, ctosUrl: false },
          response: { ...get().response, ctosUrl: null },
          errors: { ...get().errors, ctosUrl: res.data.errors },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, ctosUrl: false },
        response: { ...get().response, ctosUrl: null },
        errors: {
          ...get().errors,
          ctosUrl: err && err.response && err.response.data.message,
        },
      })
    }
  },
  fetchMyinfoDetails: async (code, state) => {
    try {
      set({ isLoading: { ...get().isLoading, myInfoDetails: true } })
      const res = await getMyinfoDetails(code, state)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, myInfoDetails: false },
          response: { ...get().response, myInfoDetails: res.data },
          errors: { ...get().errors, myInfoDetails: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, myInfoDetails: false },
          response: { ...get().response, myInfoDetails: null },
          errors: { ...get().errors, myInfoDetails: res.error },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, myInfoDetails: false },
        response: { ...get().response, myInfoDetails: null },
        errors: {
          ...get().errors,
          myInfoDetails: err && err.response && err.response.data,
        },
      })
    }
  },
  fetchCredits: async countryCode => {
    try {
      set({ isLoading: { ...get().isLoading, credits: true } })
      const res = await getCredit(countryCode)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, credits: false },
          response: { ...get().response, credits: res.data },
          errors: { ...get().errors, credits: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, credits: false },
          response: { ...get().response, credits: null },
          errors: { ...get().errors, credits: res.data.errors },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, credits: false },
        response: { ...get().response, credits: null },
        errors: {
          ...get().errors,
          credits: err && err.response && err.response.data,
        },
      })
    }
  },
  submitSgIdentity: async (homeOwnership, identity) => {
    try {
      set({ isLoading: { ...get().isLoading, sgPersonal: true } })
      const res = await postSgIdentity(homeOwnership, identity)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, sgPersonal: false },
          response: { ...get().response, sgPersonal: res.data },
          errors: { ...get().errors, sgPersonal: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, sgPersonal: false },
          response: { ...get().response, sgPersonal: null },
          errors: { ...get().errors, sgPersonal: res.data.errors },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, sgPersonal: false },
        response: { ...get().response, sgPersonal: null },
        errors: {
          ...get().errors,
          sgPersonal: err && err.response && err.response.data,
        },
      })
    }
  },
  submitCbsPurchase: async () => {
    try {
      set({ isLoading: { ...get().isLoading, cbsPurchase: true } })
      const res = await postCbsPurchase()

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, cbsPurchase: false },
          response: { ...get().response, cbsPurchase: res.data },
          errors: { ...get().errors, cbsPurchase: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, cbsPurchase: false },
          response: { ...get().response, cbsPurchase: null },
          errors: { ...get().errors, cbsPurchase: res.data.errors },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, cbsPurchase: false },
        response: { ...get().response, cbsPurchase: null },
        errors: {
          ...get().errors,
          cbsPurchase: err && err.response && err.response.data.message,
        },
      })
    }
  },
  fetchCbsPurchaseById: async jobId => {
    try {
      set({ isLoading: { ...get().isLoading, cbsPurchaseById: true } })
      const res = await getCbsPurchaseById(jobId)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, cbsPurchaseById: false },
          response: { ...get().response, cbsPurchaseById: res.data },
          errors: { ...get().errors, cbsPurchaseById: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, cbsPurchaseById: false },
          response: { ...get().response, cbsPurchaseById: null },
          errors: { ...get().errors, cbsPurchaseById: res.data.errors },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, cbsPurchaseById: false },
        response: { ...get().response, cbsPurchaseById: null },
        errors: {
          ...get().errors,
          cbsPurchaseById: err && err.response && err.response.data.message,
        },
      })
    }
  },
  submitCbsUpload: async data => {
    try {
      set({ isLoading: { ...get().isLoading, cbsUpload: true } })
      const res = await postCbsUpload(data)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, cbsUpload: false },
          response: { ...get().response, cbsUpload: res },
          errors: { ...get().errors, cbsUpload: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, cbsUpload: false },
          response: { ...get().response, cbsUpload: null },
          errors: { ...get().errors, cbsUpload: res.data.errors },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, cbsUpload: false },
        response: { ...get().response, cbsUpload: null },
        errors: {
          ...get().errors,
          cbsUpload: err && err.response && err.response.data.errors,
        },
      })
    }
  },
  submitCPFHomeOwnershipCheck: async () => {
    try {
      set({ isLoading: { ...get().isLoading, cpfHomeOwnership: true } })
      const res = await postCPFHomeOwnershipCheck()

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, cpfHomeOwnership: false },
          response: { ...get().response, cpfHomeOwnership: res.data },
          errors: { ...get().errors, cpfHomeOwnership: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, cpfHomeOwnership: false },
          response: { ...get().response, cpfHomeOwnership: null },
          errors: { ...get().errors, cpfHomeOwnership: res.data.errors },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, cpfHomeOwnership: false },
        response: { ...get().response, cpfHomeOwnership: null },
        errors: {
          ...get().errors,
          cbsPurchase: err && err.response && err.response.data.message,
        },
      })
    }
  },
  fetchCPFHomeOwnershipCheckById: async jobId => {
    try {
      set({ isLoading: { ...get().isLoading, cpfHomeOwnershipById: true } })
      const res = await getCPFHomeOwnershipCheckById(jobId)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, cpfHomeOwnershipById: false },
          response: { ...get().response, cpfHomeOwnershipById: res.data },
          errors: { ...get().errors, cpfHomeOwnershipById: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, cpfHomeOwnershipById: false },
          response: { ...get().response, cpfHomeOwnershipById: null },
          errors: { ...get().errors, cpfHomeOwnershipById: res.data.errors },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, cpfHomeOwnershipById: false },
        response: { ...get().response, cpfHomeOwnershipById: null },
        errors: {
          ...get().errors,
          cpfHomeOwnershipById: err && err.response && err.response.data.message,
        },
      })
    }
  },
  fetchPaymentThirdParty: async countryCode => {
    try {
      set({ isLoading: { ...get().isLoading, paymentThirdParty: true } })
      const res = await getPaymentThirdParty(countryCode)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, paymentThirdParty: false },
          response: { ...get().response, paymentThirdParty: res.data },
          errors: { ...get().errors, paymentThirdParty: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, paymentThirdParty: false },
          response: { ...get().response, paymentThirdParty: null },
          errors: { ...get().errors, paymentThirdParty: res.error },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, paymentThirdParty: false },
        response: { ...get().response, paymentThirdParty: null },
        errors: {
          ...get().errors,
          paymentThirdParty: err && err.response && err.response.data.message,
        },
      })
    }
  },
  fetchAdyenPaymentMethods: async countryCode => {
    try {
      set({ isLoading: { ...get().isLoading, adyenPaymentMethods: true } })
      const res = await getAdyenPaymentMethods(countryCode)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, adyenPaymentMethods: false },
          response: { ...get().response, adyenPaymentMethods: res.data },
          errors: { ...get().errors, adyenPaymentMethods: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, adyenPaymentMethods: false },
          response: { ...get().response, adyenPaymentMethods: null },
          errors: { ...get().errors, adyenPaymentMethods: res.data.errors },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, adyenPaymentMethods: false },
        response: { ...get().response, adyenPaymentMethods: null },
        errors: {
          ...get().errors,
          adyenPaymentMethods: err && err.response && err.response.data.errors,
        },
      })
    }
  },
  fetchPaynetFPXEmandateMeta: async () => {
    try {
      set({ isLoading: { ...get().isLoading, paynetFPXEmandateMeta: true } })
      const res = await getPaynetFPXEmandateMeta()

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, paynetFPXEmandateMeta: false },
          response: { ...get().response, paynetFPXEmandateMeta: res.data },
          errors: { ...get().errors, paynetFPXEmandateMeta: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, paynetFPXEmandateMeta: false },
          response: { ...get().response, paynetFPXEmandateMeta: null },
          errors: { ...get().errors, paynetFPXEmandateMeta: res.data.errors },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, paynetFPXEmandateMeta: false },
        response: { ...get().response, paynetFPXEmandateMeta: null },
        errors: {
          ...get().errors,
          paynetFPXEmandateMeta: err && err.response && err.response.data.errors,
        },
      })
    }
  },
  fetchDBSeGIROMeta: async () => {
    try {
      set({ isLoading: { ...get().isLoading, DBSeGIROMeta: true } })
      const res = await getDBSeGIROMeta()

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, DBSeGIROMeta: false },
          response: { ...get().response, DBSeGIROMeta: res.data },
          errors: { ...get().errors, DBSeGIROMeta: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, DBSeGIROMeta: false },
          response: { ...get().response, DBSeGIROMeta: null },
          errors: { ...get().errors, DBSeGIROMeta: res.data.errors },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, DBSeGIROMeta: false },
        response: { ...get().response, DBSeGIROMeta: null },
        errors: {
          ...get().errors,
          DBSeGIROMeta: err && err.response && err.response.data.errors,
        },
      })
    }
  },
  fetchPaymentMethod: async countryCode => {
    try {
      set({ isLoading: { ...get().isLoading, paymentMethodFetch: true } })
      const res = await getPaymentMethod(countryCode)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, paymentMethodFetch: false },
          response: { ...get().response, paymentMethodFetch: res.data },
          errors: { ...get().errors, paymentMethodFetch: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, paymentMethodFetch: false },
          response: { ...get().response, paymentMethodFetch: null },
          errors: { ...get().errors, paymentMethodFetch: res.data.errors },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, paymentMethodFetch: false },
        response: { ...get().response, paymentMethodFetch: null },
        errors: {
          ...get().errors,
          paymentMethodFetch: err && err.response && err.response.data.errors,
        },
      })
    }
  },
  submitPaymentMethod: async paymentMethod => {
    try {
      set({ isLoading: { ...get().isLoading, paymentMethodSubmit: true } })
      const res = await postPaymentMethod(paymentMethod)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, paymentMethodSubmit: false },
          response: { ...get().response, paymentMethodSubmit: res.data },
          errors: { ...get().errors, paymentMethodSubmit: null },
        })
        return res
      } else {
        set({
          isLoading: { ...get().isLoading, paymentMethodSubmit: false },
          response: { ...get().response, paymentMethodSubmit: null },
          errors: { ...get().errors, paymentMethodSubmit: res.data.errors },
        })
        return res
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, paymentMethodSubmit: false },
        response: { ...get().response, paymentMethodSubmit: null },
        errors: {
          ...get().errors,
          paymentMethodSubmit: err && err.response && err.response.data.errors,
        },
      })
      return err?.response?.data
    }
  },
  updatePaymentMethod: async (paymentMethod, paymentMethodId = null) => {
    try {
      set({ isLoading: { ...get().isLoading, paymentMethodUpdate: true } })
      const res = await patchPaymentMethod(paymentMethod, paymentMethodId)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, paymentMethodUpdate: false },
          response: { ...get().response, paymentMethodUpdate: res.data },
          errors: { ...get().errors, paymentMethodUpdate: null },
        })
        return res
      } else {
        set({
          isLoading: { ...get().isLoading, paymentMethodUpdate: false },
          response: { ...get().response, paymentMethodUpdate: null },
          errors: { ...get().errors, paymentMethodUpdate: res.data.errors },
        })
        return res
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, paymentMethodUpdate: false },
        response: { ...get().response, paymentMethodUpdate: null },
        errors: {
          ...get().errors,
          paymentMethodUpdate: err && err.response && err.response.data.errors,
        },
      })
      return err?.response?.data
    }
  },
  removePaymentMethod: async paymentMethodId => {
    try {
      set({ isLoading: { ...get().isLoading, paymentMethodRemove: true } })
      const res = await deletePaymentMethod(paymentMethodId)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, paymentMethodRemove: false },
          response: { ...get().response, paymentMethodRemove: res },
          errors: { ...get().errors, paymentMethodRemove: null },
        })
        return res
      } else {
        set({
          isLoading: { ...get().isLoading, paymentMethodRemove: false },
          response: { ...get().response, paymentMethodRemove: null },
          errors: { ...get().errors, paymentMethodRemove: res.data.errors },
        })
        return res
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, paymentMethodRemove: false },
        response: { ...get().response, paymentMethodRemove: null },
        errors: {
          ...get().errors,
          paymentMethodRemove: err && err.response && err.response.data,
        },
      })
      return err?.response?.data
    }
  },
  submitDefaultPaymentMethod: async (countryCode, paymentMethodId) => {
    try {
      set({
        isLoading: { ...get().isLoading, defaultPaymentMethodSubmit: true },
      })
      const res = await postDefaultPaymentMethod(countryCode, paymentMethodId)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, defaultPaymentMethodSubmit: false },
          response: { ...get().response, defaultPaymentMethodSubmit: res.data },
          errors: { ...get().errors, defaultPaymentMethodSubmit: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, defaultPaymentMethodSubmit: false },
          response: { ...get().response, defaultPaymentMethodSubmit: null },
          errors: { ...get().errors, defaultPaymentMethodSubmit: res.error },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, defaultPaymentMethodSubmit: false },
        response: { ...get().response, defaultPaymentMethodSubmit: null },
        errors: {
          ...get().errors,
          defaultPaymentMethodSubmit: err && err.response && err.response.data.message,
        },
      })
    }
  },
  fetchUpcomingPayments: async countryCode => {
    try {
      set({ isLoading: { ...get().isLoading, upcomingPayments: true } })
      const res = await getUpcomingPayments(countryCode)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, upcomingPayments: false },
          response: { ...get().response, upcomingPayments: res.data },
          errors: { ...get().errors, upcomingPayments: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, upcomingPayments: false },
          response: { ...get().response, upcomingPayments: null },
          errors: { ...get().errors, upcomingPayments: res.data.errors },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, upcomingPayments: false },
        response: { ...get().response, upcomingPayments: null },
        errors: {
          ...get().errors,
          upcomingPayments: err && err.response && err.response.data,
        },
      })
    }
  },
  fetchNotifications: async countryCode => {
    try {
      set({ isLoading: { ...get().isLoading, notifications: true } })
      const res = await getNotifications(countryCode)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, notifications: false },
          response: { ...get().response, notifications: res.data },
          errors: { ...get().errors, notifications: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, notifications: false },
          response: { ...get().response, notifications: null },
          errors: { ...get().errors, notifications: res.error },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, notifications: false },
        response: { ...get().response, notifications: null },
        errors: {
          ...get().errors,
          notifications: err && err.response && err.response.data.message,
        },
      })
    }
  },
  submitNotificationRead: async id => {
    try {
      set({ isLoading: { ...get().isLoading, notificationRead: true } })
      const res = await postNotificationRead(id)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, notificationRead: false },
          response: { ...get().response, notificationRead: res.data },
          errors: { ...get().errors, notificationRead: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, notificationRead: false },
          response: { ...get().response, notificationRead: null },
          errors: { ...get().errors, notificationRead: res.data.errors },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, notificationRead: false },
        response: { ...get().response, notificationRead: null },
        errors: {
          ...get().errors,
          notificationRead: err && err.response && err.response.data.errors,
        },
      })
    }
  },
  submitNotificationReadAll: async () => {
    try {
      set({ isLoading: { ...get().isLoading, notificationReadAll: true } })
      const res = await postNotificationReadAll()

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, notificationReadAll: false },
          response: { ...get().response, notificationReadAll: res.data },
          errors: { ...get().errors, notificationReadAll: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, notificationReadAll: false },
          response: { ...get().response, notificationReadAll: null },
          errors: { ...get().errors, notificationReadAll: res.data.errors },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, notificationReadAll: false },
        response: { ...get().response, notificationReadAll: null },
        errors: {
          ...get().errors,
          notificationReadAll: err && err.response && err.response.data.errors,
        },
      })
    }
  },
  fetchContactUsTopics: async () => {
    try {
      set({ isLoading: { ...get().isLoading, contactUsTopics: true } })
      const res = await getContactUsTopics()

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, contactUsTopics: false },
          response: { ...get().response, contactUsTopics: res.data },
          errors: { ...get().errors, contactUsTopics: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, contactUsTopics: false },
          response: { ...get().response, contactUsTopics: null },
          errors: { ...get().errors, contactUsTopics: res.error },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, contactUsTopics: false },
        response: { ...get().response, contactUsTopics: null },
        errors: {
          ...get().errors,
          contactUsTopics: err && err.response && err.response.data.message,
        },
      })
    }
  },
  submitContactUs: async data => {
    try {
      set({ isLoading: { ...get().isLoading, contactUsSubmit: true } })
      const res = await postContactUs(data)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, contactUsSubmit: false },
          response: { ...get().response, contactUsSubmit: res },
          errors: { ...get().errors, contactUsSubmit: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, contactUsSubmit: false },
          response: { ...get().response, contactUsSubmit: null },
          errors: { ...get().errors, contactUsSubmit: res.data.errors },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, contactUsSubmit: false },
        response: { ...get().response, contactUsSubmit: null },
        errors: {
          ...get().errors,
          contactUsSubmit: err && err.response && err.response.data.errors,
        },
      })
    }
  },
  fetchSettings: async () => {
    try {
      set({ isLoading: { ...get().isLoading, settings: true } })
      const res = await getSettings()

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, settings: false },
          response: { ...get().response, settings: res.data },
          errors: { ...get().errors, settings: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, settings: false },
          response: { ...get().response, settings: null },
          errors: { ...get().errors, settings: res.error },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, settings: false },
        response: { ...get().response, settings: null },
        errors: {
          ...get().errors,
          settings: err && err.response && err.response.data.message,
        },
      })
    }
  },
  updateSettings: async data => {
    try {
      set({ isLoading: { ...get().isLoading, settingsUpdate: true } })
      const res = await putSettings(data)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, settingsUpdate: false },
          response: { ...get().response, settingsUpdate: res.data },
          errors: { ...get().errors, settingsUpdate: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, settingsUpdate: false },
          response: { ...get().response, settingsUpdate: null },
          errors: { ...get().errors, settingsUpdate: res.error },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, settingsUpdate: false },
        response: { ...get().response, settingsUpdate: null },
        errors: {
          ...get().errors,
          settingsUpdate: err && err.response && err.response.data.errors,
        },
      })
    }
  },
  submitBuyerInteraction: async action => {
    try {
      set({ isLoading: { ...get().isLoading, buyerInteraction: true } })
      const res = await postBuyerInteraction(action)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, buyerInteraction: false },
          response: { ...get().response, buyerInteraction: res },
          errors: { ...get().errors, buyerInteraction: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, buyerInteraction: false },
          response: { ...get().response, buyerInteraction: null },
          errors: { ...get().errors, buyerInteraction: res.data.errors },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, buyerInteraction: false },
        response: { ...get().response, buyerInteraction: null },
        errors: {
          ...get().errors,
          buyerInteraction: err && err.response && err.response.data.errors,
        },
      })
    }
  },
  submitAdditionalData: async data => {
    try {
      set({ isLoading: { ...get().isLoading, additionalData: true } })
      const res = await postAdditionalData(data)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, additionalData: false },
          response: { ...get().response, additionalData: res },
          errors: { ...get().errors, additionalData: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, additionalData: false },
          response: { ...get().response, additionalData: null },
          errors: { ...get().errors, additionalData: res.data.errors },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, additionalData: false },
        response: { ...get().response, additionalData: null },
        errors: {
          ...get().errors,
          additionalData: err && err.response && err.response.data,
        },
      })
    }
  },
  acquireRedirectUrl: async checkoutId => {
    try {
      const res = await postAcquireRedirectUrl(checkoutId)
      return res
    } catch (err) {
      return err.response.data
    }
  },
  fetchWebsocketUrl: async () => {
    try {
      const res = await getWebsocketUrl()
      return res
    } catch (err) {
      return err.response.data
    }
  },
  fetchBuyerQr: async () => {
    try {
      const res = await getBuyerQr()
      return res
    } catch (err) {
      return err.response.data
    }
  },
}))

export default useBuyerStore
