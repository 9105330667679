import React from 'react'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'

import { Flex, Paragraph as P, RowDivider } from 'components'

const SettingsRow = ({ title, item, action, isLoading, loadingTextWidth }) => {
  return (
    <>
      <Flex my="20px" px={2} justifyContent="space-between" alignItems="center">
        {isLoading ? (
          <Skeleton height={23} width={loadingTextWidth} />
        ) : (
          <P style={{ fontWeight: 700 }}>{title}</P>
        )}
        {action}
      </Flex>
      {item && (
        <Flex flexDirection="column" mb="20px">
          {item}
        </Flex>
      )}
      <RowDivider style={{ margin: 0 }} />
    </>
  )
}

SettingsRow.propTypes = {
  title: PropTypes.string.isRequired,
  item: PropTypes.node,
  action: PropTypes.node.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadingTextWidth: PropTypes.number.isRequired,
}

export default SettingsRow
