import React from 'react'
import { useHistory } from 'react-router'
import { motion } from 'framer-motion'

import { PageWrapper, Flex, Heading as H, Paragraph as P, Spacer, Footer, Button } from 'components'
import { newCreditReportOutcome } from 'assets'
import { theme } from 'styles'
import { logUserInteractionGA } from 'utils'

const CbsReportOutcome = () => {
  const history = useHistory()

  // *Methods
  const handleRequestManualReview = () => {
    logUserInteractionGA('Click: Request Credit Limit Review')
    history.push('/credit')
    return history.push('/verify?type=manual')
  }

  const handleGoBack = () => {
    return history.push('/credit')
  }

  // *JSX
  return (
    <PageWrapper
      style={{
        background: theme.colors.buttons.marineBlue,
        minHeight: '100vh',
      }}
    >
      <Flex
        flex="1"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        color="white"
        pb={150}
      >
        <motion.img
          src={newCreditReportOutcome}
          alt="report_outcome"
          transition={{
            duration: 0.2,
            type: 'spring',
            stiffness: 100,
          }}
          initial={{ scale: 0.1 }}
          animate={{ scale: 1.0 }}
          width="240px"
          height="240px"
        />
        <H variant="h3" textAlign="center">
          Report Outcome
        </H>
        <Spacer height={10} />

        <P textAlign="center">
          Based on your credit report, we are unable to grant you additional credit.
        </P>
        <Spacer height={10} />

        <P textAlign="center">
          If you wish to appeal this decision, you may request to be reviewed manually.
        </P>
      </Flex>

      <Footer
        initial={{ y: 100 }}
        animate={{ y: 0 }}
        transition={{
          delay: 0.8,
          duration: 0.2,
          type: 'spring',
          stiffness: 150,
        }}
      >
        <Flex flexDirection="column" fitWidth>
          <Button variant="secondary" mb={0} onClick={handleRequestManualReview}>
            REQUEST CREDIT LIMIT REVIEW
          </Button>
          <Button mb={10} onClick={handleGoBack}>
            BACK
          </Button>
        </Flex>
      </Footer>
    </PageWrapper>
  )
}

export default CbsReportOutcome
