import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'

import { useBuyerStore } from 'stores'
import { getCountryCodeLocalDb, logUserInteractionGA } from 'utils'

import {
  PageWrapper,
  NavigateBack,
  Heading as H,
  Paragraph as P,
  Footer,
  Button,
  Flex,
  Spacer,
  PaymentMethodCard,
  PaymentMethodCardLoading,
  EMandateCard,
  Image,
} from 'components'
import { newAddPaymentMethod } from 'assets'
import { theme } from 'styles/theme'
import EDDARequestCard from 'components/EDDARequestCard/EDDARequestCard'

const PaymentMethods = () => {
  const history = useHistory()
  const countryCode = getCountryCodeLocalDb()

  const [optionsIsOpenFor, setOptionsIsOpenFor] = useState('')

  const {
    response: { paymentMethodFetch: paymentMethodFetchResponse },
    fetchPaymentMethod,
  } = useBuyerStore()

  // *Methods
  const onClickNewPaymentMethod = () => {
    logUserInteractionGA('Click: New Payment Method')
    history.push('/paymentmethods/new')
  }

  const renderPaymentMethods = () => {
    return paymentMethodFetchResponse.length > 0 ? (
      <Flex flexDirection="column" pb={180}>
        {paymentMethodFetchResponse.map(method => {
          const {
            id: paymentMethodId,
            mode: paymentMethodMode,
            status: paymentMethodStatus,
            is_default: isDefault,
            can_update: canUpdate,
            can_terminate: canTerminate,
            invalid_collection_payment_method_message: invalidMessage,
          } = method

          if (paymentMethodMode === 'card') {
            const { brand, ending_digits, funding } = method.card
            return (
              <PaymentMethodCard
                key={paymentMethodId}
                brand={brand}
                endingDigits={ending_digits}
                funding={funding}
                paymentMethodId={paymentMethodId}
                isDefault={isDefault}
                canTerminate={canTerminate}
                optionsIsOpenFor={optionsIsOpenFor}
                setOptionsIsOpenFor={setOptionsIsOpenFor}
                invalidMessage={invalidMessage}
              />
            )
          } else if (paymentMethodMode === 'my_emandate') {
            return (
              <EMandateCard
                key={paymentMethodId}
                data={method.my_emandate}
                paymentMethodId={paymentMethodId}
                paymentMethodStatus={paymentMethodStatus}
                isDefault={isDefault}
                canUpdate={canUpdate}
                canTerminate={canTerminate}
                optionsIsOpenFor={optionsIsOpenFor}
                setOptionsIsOpenFor={setOptionsIsOpenFor}
              />
            )
          } else if (paymentMethodMode === 'edda') {
            return (
              <EDDARequestCard
                key={paymentMethodId}
                data={method.edda_request}
                paymentMethodId={paymentMethodId}
                paymentMethodStatus={paymentMethodStatus}
                isDefault={isDefault}
                canUpdate={canUpdate}
                canTerminate={canTerminate}
                optionsIsOpenFor={optionsIsOpenFor}
                setOptionsIsOpenFor={setOptionsIsOpenFor}
              />
            )
          } else {
            return null
          }
        })}
      </Flex>
    ) : (
      <Flex stretch centered flexDirection="column" flex="1" margin="auto">
        <Image src={newAddPaymentMethod} alt="no_payment_method" width="240px" height="240px" />
        <H variant="h4" style={{ marginTop: -20 }}>
          No payment methods
        </H>
        <P color={theme.colors.typography.gray1} mt={1} px={4} textAlign="center">
          Add a default payment method to start making purchases
        </P>
      </Flex>
    )
  }

  // *Effects
  useEffect(() => {
    fetchPaymentMethod(countryCode)
  }, [])

  // *JSX
  return (
    <>
      <PageWrapper
        withTopNav
        paddingBottom={150}
        style={{
          height: '90vh',
        }}
      >
        <NavigateBack withBottomBorder>Payment Methods</NavigateBack>

        <Flex flexDirection="column" stretch flex="1">
          {paymentMethodFetchResponse ? (
            <>
              <Spacer height={10} />
              {renderPaymentMethods()}
            </>
          ) : (
            <>
              <Spacer height={10} />
              <PaymentMethodCardLoading />
            </>
          )}
        </Flex>
        <Footer>
          <Button onClick={onClickNewPaymentMethod}>NEW PAYMENT METHOD</Button>
        </Footer>
      </PageWrapper>
    </>
  )
}

export default PaymentMethods
