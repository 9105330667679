import React from 'react'

import { Image } from 'components'
import { helpCircle } from 'assets'

const HelpIcon = ({ onClick, ...rest }) => {
  return <Image src={helpCircle} alt="help_icon" width={24} onClick={onClick} {...rest} />
}

export default HelpIcon
