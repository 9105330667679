import create from 'zustand'

import { postMobile, postOtp, postExtendToken } from 'services'

const useAuthStore = create((set, get) => ({
  isLoading: { otpSend: false, otpVerify: false, tokenExtend: false },
  response: { otpSend: null, otpVerify: null, tokenExtend: null },
  errors: { otpSend: null, otpVerify: null, tokenExtend: null },
  resetStates: dataType => {
    set({
      isLoading: { ...get().isLoading, [dataType]: false },
      response: { ...get().response, [dataType]: null },
      errors: { ...get().errors, [dataType]: null },
    })
  },
  submitMobile: async (countryCode, mobileNo) => {
    try {
      set({ isLoading: { ...get().isLoading, otpSend: true } })
      const res = await postMobile(countryCode.toString(), mobileNo.toString())

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, otpSend: false },
          response: { ...get().response, otpSend: res.data },
          errors: { ...get().errors, otpSend: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, otpSend: false },
          response: { ...get().response, otpSend: null },
          errors: { ...get().errors, otpSend: res.errors },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, otpSend: false },
        response: { ...get().response, otpSend: null },
        errors: {
          ...get().errors,
          otpSend: err && err.response && err.response.data.errors,
        },
      })
    }
  },
  submitOtp: async (otp, sessionId) => {
    try {
      set({ isLoading: { ...get().isLoading, otpVerify: true } })
      const res = await postOtp(otp, sessionId)

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, otpVerify: false },
          response: { ...get().response, otpVerify: res.data },
          errors: { ...get().errors, otpVerify: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, otpVerify: false },
          response: { ...get().response, otpVerify: null },
          errors: { ...get().errors, otpVerify: res.message },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, otpVerify: false },
        response: { ...get().response, otpVerify: null },
        errors: {
          ...get().errors,
          otpVerify: err && err.response && err.response.data.message,
        },
      })
    }
  },
  submitExtendToken: async () => {
    try {
      set({ isLoading: { ...get().isLoading, tokenExtend: true } })
      const res = await postExtendToken()

      if (res.success) {
        set({
          isLoading: { ...get().isLoading, tokenExtend: false },
          response: { ...get().response, tokenExtend: res.data },
          errors: { ...get().errors, tokenExtend: null },
        })
      } else {
        set({
          isLoading: { ...get().isLoading, tokenExtend: false },
          response: { ...get().response, tokenExtend: null },
          errors: { ...get().errors, tokenExtend: res.error.message },
        })
      }
    } catch (err) {
      set({
        isLoading: { ...get().isLoading, tokenExtend: false },
        response: { ...get().response, tokenExtend: null },
        errors: {
          ...get().errors,
          tokenExtend: err && err.response && err.response.data,
        },
      })
    }
  },
}))

export default useAuthStore
