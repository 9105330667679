import React from 'react'
import PropTypes from 'prop-types'

import { StyledLabel } from './formLabelStyle'

const FormLabel = ({ children, doNotCapitalize, ...rest }) => {
  return (
    <StyledLabel doNotCapitalize={doNotCapitalize} {...rest}>
      {children}
    </StyledLabel>
  )
}

FormLabel.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FormLabel
