import { useState, useEffect } from 'react'

import { useBuyerStore } from 'stores'
import { checkOnboardingStatus, getCountryCodeLocalDb } from 'utils'

const useOrderEligibilityCheck = () => {
  const countryCode = getCountryCodeLocalDb()

  const [eligibleForOrder, setEligibleForOrder] = useState(false)

  const {
    isLoading: { credits: creditsIsLoading },
    response: { credits: creditsResponse },
    fetchCredits,
  } = useBuyerStore()

  // *Effects
  useEffect(() => {
    fetchCredits(countryCode)
  }, [])

  useEffect(() => {
    if (creditsResponse && creditsResponse.kycs) {
      const status = checkOnboardingStatus(creditsResponse.kycs)

      if (
        status &&
        status.identityKyc === 'data_available' &&
        status.paymentMethodKyc === 'data_available'
      ) {
        setEligibleForOrder(true)
      }
    }
  }, [creditsResponse])

  //  *Returns
  return { eligibleForOrder, creditsIsLoading }
}

export default useOrderEligibilityCheck
