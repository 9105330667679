import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import StripePaymentForm from './StripePaymentForm/StripePaymentForm'
import { STRIPE_PUBLIC_KEY, STRIPE_PUBLIC_KEY_MY } from 'const'
import { getCountryCodeLocalDb } from 'utils'

const countryCode = getCountryCodeLocalDb()
const stripePublicKey = countryCode === 'my' ? STRIPE_PUBLIC_KEY_MY : STRIPE_PUBLIC_KEY
const stripePromise = loadStripe(stripePublicKey)

const StripeContainer = ({ children }) => {
  return (
    <Elements
      stripe={stripePromise}
      fonts={[
        {
          cssSrc:
            'https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap',
        },
      ]}
    >
      {children ? children : <StripePaymentForm />}
    </Elements>
  )
}

export default StripeContainer
