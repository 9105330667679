import React from 'react'
import BankMandateForm from './BankMandateForm'
import { useHistory } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import { useBuyerStore } from 'stores'

const ManageBankMandate = () => {
  const history = useHistory()
  const { data, paymentMethodId } = history.location.state

  const methods = useForm({ mode: 'all' })
  const { updatePaymentMethod } = useBuyerStore()

  const editableFields = [
    'buyer_phone_number',
    'buyer_email',
    'max_amount',
    'max_frequency',
    'frequency_mode',
  ]

  const handleSubmit = async () => {
    if (!paymentMethodId) return

    let res
    await methods.handleSubmit(async formData => {
      const emandate = {}
      editableFields.map(field => {
        emandate[field] = formData[field]
      })
      res = await updatePaymentMethod({ fpx: { emandate } }, paymentMethodId)
    })()
    return res
  }

  return (
    <FormProvider {...methods}>
      <BankMandateForm
        backButtonText="Manage mandate"
        buttonText="CONFIRM CHANGES"
        path="manage"
        editableFields={editableFields}
        handleSubmit={handleSubmit}
        defaultData={data}
        paymentMethodId={paymentMethodId}
      />
    </FormProvider>
  )
}

export default ManageBankMandate
