import React from 'react'
import PropTypes from 'prop-types'

import { StyledFlex } from './flexStyle'

const Flex = ({ children, stretch, fitWidth, centered, full, ...rest }) => {
  return (
    <StyledFlex stretch={stretch} fitWidth={fitWidth} centered={centered} full={full} {...rest}>
      {children}
    </StyledFlex>
  )
}

Flex.propTypes = {
  children: PropTypes.node,
  stretch: PropTypes.bool,
  centered: PropTypes.bool,
  full: PropTypes.bool,
}

Flex.defaultProps = {
  stretch: false,
  centered: false,
  full: false,
}

export default Flex
