import React from 'react'
import PropTypes from 'prop-types'

import { Flex } from 'components'
import { useWindowSize } from 'hooks'

const Footer = ({ children, withTopBorder, ...rest }) => {
  const { height } = useWindowSize()

  if (withTopBorder)
    return (
      <Flex
        position={height < 500 ? 'absolute' : 'fixed'}
        left="0"
        bottom="0"
        margin="auto"
        stretch
        pb={3}
        px={3}
        zIndex={10}
        {...rest}
        style={{
          borderTop: '1px solid #e8e8e8',
          backgroundColor: 'white',
        }}
      >
        {children}
      </Flex>
    )

  return (
    <Flex
      position={height < 500 ? 'absolute' : 'fixed'}
      left="0"
      bottom="0"
      margin="auto"
      stretch
      pb={3}
      px={3}
      {...rest}
    >
      {children}
    </Flex>
  )
}

Footer.propTypes = {
  withTopBorder: PropTypes.bool,
}

Footer.defaultProps = {
  withTopBorder: false,
}

export default Footer
