import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { Flex, RoundedContainer, RowDivider } from 'components'

const ResultRowLoading = () => {
  return (
    <>
      <Flex justifyContent="space-between" width="100%">
        <Flex>
          <RoundedContainer
            width="55px"
            height="55px"
            padding="5px"
            alignItems="center"
            justifyContent="center"
          >
            <Skeleton style={{ borderRadius: 10 }} width={30} height={30} />
          </RoundedContainer>
          <Flex flexDirection="column" justifyContent="center" ml={3}>
            <Skeleton width={80} height={17} />
            <Skeleton width={100} height={15} />
          </Flex>
        </Flex>
      </Flex>

      <RowDivider />
    </>
  )
}

export default ResultRowLoading
