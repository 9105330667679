import React, { useEffect } from 'react'

import {
  PageWrapper,
  Flex,
  Heading as H,
  Paragraph as P,
  NavigateBack,
  Footer,
  Image,
} from 'components'
import { newAppError } from 'assets'
import { theme } from 'styles/theme'
import { logUserInteractionGA, getCountryCodeLocalDb } from 'utils'

const ErrorFallback = () => {
  const countryCode = getCountryCodeLocalDb()

  useEffect(() => {
    logUserInteractionGA('Error: User Arrived at Error Page')
  }, [])

  return (
    <PageWrapper>
      <NavigateBack
        onClick={() => {
          window.location.assign(`${window.location.origin}/${countryCode}/home`)
        }}
      >
        Are you lost?
      </NavigateBack>
      <Flex
        pt={5}
        mx={3}
        centered
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        flex="1"
      >
        <Flex flexDirection="column" px={3} textAlign="center" margin="auto">
          <Image src={newAppError} alt="error_image" width={240} style={{ margin: 'auto' }} />

          <H variant="h4">Oops, something went wrong</H>

          <P color={theme.colors.typography.gray1}>
            What you&apos;re looking for doesn&apos;t exist. Let&apos;s take you back to the home
            page, shall we?
          </P>
        </Flex>
      </Flex>

      <Footer
        style={{
          height: 200,
          zIndex: -1,
        }}
      />
    </PageWrapper>
  )
}

export default ErrorFallback
