import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router'

import {
  Flex,
  PageWrapper,
  Heading as H,
  Paragraph as P,
  Button,
  Footer,
  NavigateBack,
  Modal,
  PrivacyPolicy,
  Image,
} from 'components'
import { theme } from 'styles/theme'
import { useBuyerStore } from 'stores'
import {
  getCountryCodeLocalDb,
  removeIdentityFormStorage,
  getMyinfoDataLocalDb,
  getSgVerifyIdentitySavedFormLocalDb,
  setApprovedCreditLocalDb,
  setShouldRedirectToCredit,
  findIdentityKyc,
  setKycCreatedAt,
  findPaymentMethodKyc,
  findCbsKyc,
  logUserInteractionGA,
} from 'utils'
import {
  newVerifyIdentity,
  newCreditReportIntro,
  newPaymentMethodIntro,
  newManualReview,
} from 'assets'

const Verify = () => {
  const location = useLocation().search
  const type = new URLSearchParams(location).get('type')
  const isInitial = new URLSearchParams(location).get('initial')
  const next = new URLSearchParams(location).get('next')

  const countryCode = getCountryCodeLocalDb()
  const nextDestination = useRef('/')

  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [showCbsWarningModal, setShowCbsWarningModal] = useState(false)
  const [showSavedFormModal, setShowSavedFormModal] = useState(false)
  const [showCPFHomeOwnershipWarningModal, setShowCPFHomeOwnershipWarningModal] = useState(false)

  const {
    response: { myInfoUrl: myInfoUrlResponse, credits: creditsResponse },
    fetchCredits,
    fetchMyinfoUrl,
    resetStates: resetStatesBuyer,
  } = useBuyerStore()

  if (myInfoUrlResponse && myInfoUrlResponse.url) {
    window.location.replace(myInfoUrlResponse.url)
  }

  // *Methods
  const handleVerifyIdentity = destination => {
    const identityKyc = findIdentityKyc(creditsResponse.kycs)
    setKycCreatedAt(identityKyc.kyc_type, identityKyc) // !credit assessment checkpoint
    setShouldRedirectToCredit()

    if (countryCode === 'sg') {
      if (getMyinfoDataLocalDb() || getSgVerifyIdentitySavedFormLocalDb()) {
        nextDestination.current = destination
        setShowSavedFormModal(true) // check if user wants to continue from saved form state
      } else if (destination === 'myinfo') {
        fetchMyinfoUrl()
        setIsLoading(true)
      } else if (destination === 'identity') {
        resetStatesBuyer('credits')
        history.push('/verify/identity?page=1')
      }
    } else if (countryCode === 'my') {
      history.push('/verify/ctos?status=pending')
    }
  }

  const handleAddPaymentMethod = () => {
    const paymentMethodKyc = findPaymentMethodKyc(creditsResponse.kycs)
    setKycCreatedAt(paymentMethodKyc.kyc_type, paymentMethodKyc) // !credit assessment checkpoint
    setShouldRedirectToCredit()
    logUserInteractionGA('Onboarding: Add Payment Method')
    return history.push('/paymentmethods/new')
  }

  const handlePurchaseCbs = () => {
    const cbsKyc = findCbsKyc(creditsResponse.kycs)
    setKycCreatedAt(cbsKyc.kyc_type, cbsKyc) // !credit assessment checkpoint
    setShouldRedirectToCredit()
    setShowCbsWarningModal(true)
    logUserInteractionGA('Onboarding: Retrieve CBS Report')
  }

  const handleUploadCbs = () => {
    const cbsKyc = findCbsKyc(creditsResponse.kycs)
    setKycCreatedAt(cbsKyc.kyc_type, cbsKyc) // !credit assessment checkpoint
    setShouldRedirectToCredit()
    logUserInteractionGA('Onboarding: Upload CBS Manually')
    history.push('/verify/cbs/manual')
  }

  const handleManualReview = () => {
    setShouldRedirectToCredit()
    logUserInteractionGA('Onboarding: Credit Limit Review')
    history.push('/verify/manual')
  }

  const handleVerifyCPFHomeOwnership = () => {
    const cpfHomeOwnershipKyc = creditsResponse.kycs.find(
      kyc => kyc.kyc_type === 'sg_home_ownership',
    )
    setKycCreatedAt(cpfHomeOwnershipKyc.kyc_type, cpfHomeOwnershipKyc) // !credit assessment checkpoint
    setShouldRedirectToCredit()
    setShowCPFHomeOwnershipWarningModal(true)
    logUserInteractionGA('Onboarding: Verify CPF Home Ownership')
  }

  const handleNavigateBack = () => {
    if (next) {
      history.push(next)
    } else {
      history.goBack()
    }
  }

  // *Effects
  useEffect(() => {
    fetchCredits(countryCode)

    return () => resetStatesBuyer('credits')
  }, [])

  useEffect(() => {
    // !credit assessment checkpoint start
    // mark the current credit amount so we can compare post-submission
    if (
      creditsResponse &&
      creditsResponse.amount &&
      creditsResponse.assessment &&
      creditsResponse.assessment.completed_at
    ) {
      const amount = creditsResponse.amount
      const completedAt = creditsResponse.assessment.completed_at

      setApprovedCreditLocalDb(amount, completedAt)
    }
    // !credit assessment checkpoint end
  }, [creditsResponse])

  // *JSX
  if (type === 'paymentmethod') {
    return (
      <PageWrapper withTopNav>
        {isInitial !== 'true' && <NavigateBack noBackground onClick={handleNavigateBack} />}
        <Flex flexDirection="column" centered alignSelf="center" pb={180}>
          <Image src={newPaymentMethodIntro} alt="credit_card_icon" width="75%" height="75%" />
          <Flex flexDirection="column" textAlign="center">
            <H variant="h1" pb="10px">
              Payment Method
            </H>
            <P color={theme.colors.typography.gray1}>
              You need to have at least one payment method configured in order to proceed with a
              purchase
            </P>
          </Flex>
        </Flex>
        <Footer flexDirection="column">
          <Button
            variant="primary"
            onClick={handleAddPaymentMethod}
            isLoading={isLoading || !creditsResponse}
          >
            START
          </Button>
          <PrivacyPolicy />
        </Footer>
      </PageWrapper>
    )
  }

  if (type === 'cbs') {
    return (
      <PageWrapper withTopNav>
        {/* Modal */}
        {showCbsWarningModal && (
          <Modal
            messageArray={[
              'You have requested to check your credit.',
              'QR code will appear shortly to continue the workflow.',
              'Please do not refresh this page.',
            ]}
            primaryButtonText="OKAY"
            primaryButtonAction={() => history.push('/verify/cbs/auto')}
          />
        )}

        {isInitial !== 'true' && <NavigateBack noBackground />}
        <Flex flexDirection="column" centered alignSelf="center" pb={180}>
          <Image src={newCreditReportIntro} alt="report_card" width="70%" height="70%" />
          <Flex flexDirection="column" textAlign="center">
            <H variant="h1" py="10px">
              {countryCode === 'my' ? 'CCRIS Report' : 'Credit Bureau Report'}
            </H>
            <P color={theme.colors.typography.gray1}>
              The financial information provided in this report will allow us to better evaluate an
              increase in your credit for a larger purchase, or for more purchases
            </P>
          </Flex>
        </Flex>
        {countryCode === 'sg' && (
          <Footer flexDirection="column" style={{ background: 'white' }}>
            <Button
              mb={0}
              variant="primary"
              onClick={handlePurchaseCbs}
              isLoading={isLoading || !creditsResponse}
            >
              RETRIEVE CREDIT REPORT
            </Button>

            <Button
              variant="secondary"
              onClick={handleUploadCbs}
              isLoading={isLoading || !creditsResponse}
            >
              UPLOAD MANUALLY
            </Button>
            <PrivacyPolicy />
          </Footer>
        )}
        {countryCode === 'my' && (
          <Footer flexDirection="column">
            <Button
              variant="primary"
              onClick={handleVerifyIdentity}
              isLoading={isLoading || !creditsResponse}
            >
              FILL WITH CTOS
            </Button>
            <PrivacyPolicy />
          </Footer>
        )}
      </PageWrapper>
    )
  }

  if (type === 'cpf_homeownership') {
    return (
      <PageWrapper withTopNav>
        {/* Modal */}
        {showCPFHomeOwnershipWarningModal && (
          <Modal
            messageArray={[
              'You have requested to verify your home ownership.',
              'QR code will appear shortly to continue the workflow.',
              'Please do not refresh this page.',
            ]}
            primaryButtonText="OKAY"
            primaryButtonAction={() => history.push('/verify/cpf_homeownership')}
          />
        )}

        {isInitial !== 'true' && <NavigateBack noBackground />}
        <Flex flexDirection="column" centered alignSelf="center" pb={180}>
          <Image src={newCreditReportIntro} alt="report_card" width="70%" height="70%" />
          <Flex flexDirection="column" textAlign="center">
            <H variant="h1" py="10px">
              Home Ownership
            </H>
            <P color={theme.colors.typography.gray1}>
              The information to be retrieved from CPF Member Dashboard will allow us to better
              understand your home ownership situation.
            </P>
          </Flex>
        </Flex>
        {countryCode === 'sg' && (
          <Footer flexDirection="column" style={{ background: 'white' }}>
            <Button
              mb={0}
              variant="primary"
              onClick={handleVerifyCPFHomeOwnership}
              isLoading={isLoading || !creditsResponse}
            >
              VERIFY HOME OWNERSHIP
            </Button>

            <PrivacyPolicy />
          </Footer>
        )}
      </PageWrapper>
    )
  }

  if (type === 'manual') {
    return (
      <PageWrapper withTopNav>
        {isInitial !== 'true' && <NavigateBack noBackground />}
        <Flex flexDirection="column" centered alignSelf="center" pb={180}>
          <Image src={newManualReview} alt="report_card" width="75%" height="75%" />
          <Flex flexDirection="column" textAlign="center" px={3}>
            <H variant="h1" py="10px">
              Credit Limit Review
            </H>
            <P color={theme.colors.typography.gray1}>
              If you still require additional credit, a manual evaluation can be requested for by
              submitting additional documents
            </P>
          </Flex>
        </Flex>

        <Footer flexDirection="column" style={{ background: 'white' }}>
          <Button
            variant="primary"
            onClick={handleManualReview}
            isLoading={isLoading || !creditsResponse}
          >
            START
          </Button>

          <PrivacyPolicy />
        </Footer>
      </PageWrapper>
    )
  }

  // return verify identity page by default
  return (
    <PageWrapper withTopNav>
      {/* Modals */}
      {showSavedFormModal && (
        <Modal
          messageArray={[
            'We found a saved copy of your previously filled form.',
            'Do you want to continue from where you left off?',
          ]}
          primaryButtonText="CONTINUE"
          primaryButtonAction={() => {
            resetStatesBuyer('credits')
            history.push(`/verify/identity?page=1`)
          }}
          secondaryButtonText="DISCARD"
          secondaryButtonAction={() => {
            removeIdentityFormStorage()
            setShowSavedFormModal(false)
            resetStatesBuyer('credits')

            if (nextDestination.current === 'myinfo') {
              fetchMyinfoUrl()
              setIsLoading(true)
            } else {
              history.push(`/verify/identity?page=1`)
            }
          }}
        />
      )}

      {isInitial !== 'true' && <NavigateBack noBackground />}
      <Flex flexDirection="column" centered alignSelf="center" pb={180}>
        <Image src={newVerifyIdentity} alt="identity_card" width="200px" height="200px" />
        <Flex flexDirection="column" textAlign="center" pt="30px">
          <H variant="h1" py="10px">
            Verify Identity
          </H>
          <P color={theme.colors.typography.gray1}>
            We need to perform this step in accordance with regulations, and in order to keep our
            platform safe and secure
          </P>
        </Flex>
      </Flex>
      <Footer flexDirection="column" style={{ background: 'white' }}>
        {type === 'identity' && (
          <Button
            mb={countryCode === 'sg' && '0'}
            variant="primary"
            onClick={() => {
              logUserInteractionGA(
                `Onboarding - ${countryCode === 'sg' ? 'Fill with MyInfo' : 'Fill with Ctos'}`,
              )
              handleVerifyIdentity('myinfo')
            }}
            isLoading={isLoading || !creditsResponse}
          >
            {countryCode === 'sg'
              ? 'FILL INSTANTLY WITH MYINFO'
              : countryCode === 'my'
              ? 'VERIFY WITH CTOS'
              : ''}
          </Button>
        )}
        {countryCode === 'my' && <PrivacyPolicy />}
        {countryCode === 'sg' && (
          <>
            <Button
              variant="secondary"
              onClick={() => {
                logUserInteractionGA(`Onboarding - Fill Manually`)
                handleVerifyIdentity('identity')
              }}
              disabled={isLoading || !creditsResponse}
            >
              FILL MANUALLY
            </Button>
            <PrivacyPolicy type="verify" />
          </>
        )}
      </Footer>
    </PageWrapper>
  )
}

export default Verify
