import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'

import {
  Button,
  Flex,
  Heading as H,
  Paragraph as P,
  ImageCarousel,
  Spacer,
  CustomIndicator,
} from 'components'
import { theme } from 'styles/theme'
import { onboarding as content } from 'contents'
import { newOnboarding1, newOnboarding2, newOnboarding3 } from 'assets'
import { useWindowSize } from 'hooks'
import { setIsRepeatUser } from 'utils'

const Onboarding = () => {
  const windowSize = useWindowSize()
  const history = useHistory()
  const images = [newOnboarding1, newOnboarding2, newOnboarding3]
  const totalSteps = images.length - 1

  const [onboardingStep, setOnboardingStep] = useState(0)

  // *Methods
  const handleSwipe = index => {
    if (onboardingStep <= totalSteps) {
      setOnboardingStep(index)
    }
  }

  const handleClick = () => {
    if (onboardingStep < totalSteps) {
      setOnboardingStep(step => step + 1)
    } else {
      history.push('/register')
    }
  }

  // *Effects
  useEffect(() => {
    setIsRepeatUser()
  }, [])

  // *JSX
  return (
    <Flex full flexDirection="column" backgroundColor="pastelBlue">
      <Flex flexDirection="column" style={{ height: `${windowSize.height - 330}px` }}>
        <P
          color={theme.colors.buttons.marineBlue}
          style={{
            position: 'absolute',
            top: 20,
            right: 20,
            zIndex: 10,
            fontWeight: 600,
            letterSpacing: 2,
          }}
          onClick={() => history.push('/register')}
        >
          SKIP
        </P>
        <ImageCarousel
          images={images}
          carouselHeight="auto"
          imageHeight={`${windowSize.height - 330}px`}
          swipeable={true}
          backgroundColor="white"
          selectedItem={onboardingStep}
          showIndicators={false}
          objectFit="contain"
          style={{
            height: `${windowSize.height - 330}px`,
            // backgroundColor: '#E9E9F1',
          }}
          onChange={handleSwipe}
        />
        <Flex stretch centered pt={20}>
          <CustomIndicator steps={onboardingStep} totalSteps={totalSteps} />
        </Flex>
      </Flex>

      <Flex height="330px">
        <Flex
          justifyContent="space-between"
          flexDirection="column"
          alignItems="flex-start"
          alignSelf="center"
          width="100%"
          height="100%"
          maxWidth={500}
          p={30}
          flex={1}
        >
          <Flex
            flexDirection="column"
            centered
            mb={10}
            height="250px"
            style={{ justifyContent: 'flex-start' }}
          >
            <H centered variant="h1" my={20}>
              {content[onboardingStep].title}
            </H>
            <P color={theme.colors.typography.gray1} centered minHeight={70}>
              {content[onboardingStep].content}
            </P>
          </Flex>
          <Flex stretch justifyContent="space-between">
            <Spacer />
            <Button
              variant="round"
              icon={onboardingStep === totalSteps ? 'check' : 'forward'}
              onClick={handleClick}
            >
              NEXT
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Onboarding
