import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'

import {
  TriggerPopup,
  Loading,
  Paragraph as P,
  Spacer,
  Flex,
  Footer,
  Button,
  FormError,
} from 'components'
import { postPaymentMethod, patchPaymentMethod } from 'services'
import {
  getPaymentMethodRedirectLocalDb,
  removePaymentMethodRedirectLocalDb,
  pushPaymentMethodsHistory,
  getShouldRedirectToCredit,
  getCountryCodeLocalDb,
  logUserInteractionGA,
} from 'utils'

import { CARD_STYLES, InputContainer } from './stripePaymentFormStyles'
import { StyledLabel } from 'components/FormLabel/formLabelStyle'
import { StyledInput } from 'components/FormInput/formInputStyle'

const StripePaymentForm = () => {
  const history = useHistory()
  const countryCode = getCountryCodeLocalDb()
  const stripe = useStripe()
  const elements = useElements()

  const [success, setSuccess] = useState(false)
  const [formDisabled, setFormDisabled] = useState(false)
  const [error, setError] = useState(null)
  const [nameOnCard, setNameOnCard] = useState('')
  const [nameOnCardError, setNameOnCardError] = useState('')

  // *Methods
  const handleAddPaymentMethodSuccess = () => {
    const redirectUrl = getPaymentMethodRedirectLocalDb()
    if (redirectUrl) {
      // if user is coming from an order
      removePaymentMethodRedirectLocalDb()
      if (redirectUrl.split('?').length === 1) {
        window.location.replace(`${redirectUrl}?use_new_payment_method=true`)
      } else {
        window.location.replace(`${redirectUrl}&use_new_payment_method=true`)
      }
    } else {
      // if user should return to credit screen
      if (getShouldRedirectToCredit()) {
        return history.push('/verify/success?type=paymentmethod')
      } else {
        pushPaymentMethodsHistory(history, '/verify/success?type=paymentmethod')
      }
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    logUserInteractionGA('Submit: Create Payment Method')
    if (!stripe || !elements) return
    if (!nameOnCard || nameOnCard.length === 0) return setNameOnCardError('This field is required')

    const numberElement = elements.getElement(CardNumberElement)
    const expiryElement = elements.getElement(CardExpiryElement)
    const cvcElement = elements.getElement(CardCvcElement)

    numberElement.update({ disabled: true })
    expiryElement.update({ disabled: true })
    cvcElement.update({ disabled: true })

    setFormDisabled(true)

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: numberElement,
      billing_details: {
        name: nameOnCard,
      },
    })

    if (!error) {
      console.log('paymentmethod', paymentMethod)
      try {
        const response = await postPaymentMethod({
          third_party_name: 'stripe',
          stripe: { ...paymentMethod, country: countryCode },
        })
        console.log('postPaymentMethod', response)

        if (response.success) {
          const clientSecret = response.data.stripe.client_secret
          const response2 = await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
              card: numberElement, // using just number element will automatically fetch other information from expiry and cvc
              billing_details: {
                name: nameOnCard,
              },
            },
          })
          console.log('stripe confirmCardSetup', response2)
          if (response2 && response2.error && response2.error.message) {
            setError(response2.error.message)
          }

          const setupIntentId = response2.setupIntent.id
          const response3 = await patchPaymentMethod({
            third_party_name: 'stripe',
            stripe: {
              country: countryCode,
              setup_intent_id: setupIntentId,
            },
          })
          console.log('patchPaymentMethod', response3)
          if (response3.success) {
            setSuccess(true)
          }
        }
      } catch (error) {
        console.log('error', error)
        numberElement.update({ disabled: false })
        expiryElement.update({ disabled: false })
        cvcElement.update({ disabled: false })
        setFormDisabled(false)
      }
    } else {
      console.log('error in createPaymentMethod', error)
      numberElement.update({ disabled: false })
      expiryElement.update({ disabled: false })
      cvcElement.update({ disabled: false })
      setFormDisabled(false)
    }
  }

  // *Effect
  useEffect(() => {
    if (success) {
      handleAddPaymentMethodSuccess()
    }
  }, [success])

  useEffect(() => {
    if (error) {
      logUserInteractionGA(`Error - ${error}`)
    }
  }, [error])

  // *JSX
  return (
    <>
      {/* error handling */}
      <TriggerPopup
        showPopup={!!error}
        message={error}
        buttonText="OKAY"
        callback={() => setError(null)}
        stayAtCurrentPage
      />

      {!success ? (
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <fieldset className="FormGroup" style={{ border: 'none' }}>
            <P variant="small" style={{ fontFamily: 'Poppins', fontWeight: 600 }}>
              Card number
            </P>
            <InputContainer>
              <CardNumberElement options={{ style: CARD_STYLES }} />
            </InputContainer>
            <Spacer height={20} />

            <Flex fitWidth justifyContent="space-between">
              <Flex flexDirection="column" fitWidth>
                <P
                  variant="small"
                  style={{
                    fontFamily: 'Poppins',
                    fontWeight: 600,
                  }}
                >
                  Expiry date
                </P>
                <InputContainer>
                  <CardExpiryElement options={{ style: CARD_STYLES }} />
                </InputContainer>
              </Flex>

              <Spacer width={15} />

              <Flex flexDirection="column" fitWidth>
                <P
                  variant="small"
                  style={{
                    fontFamily: 'Poppins',
                    fontWeight: 600,
                  }}
                >
                  CCV/CVC
                </P>
                <InputContainer>
                  <CardCvcElement options={{ style: CARD_STYLES }} />
                </InputContainer>
              </Flex>
            </Flex>
            <Spacer height={20} />

            <Flex flexDirection="column">
              <StyledLabel>Name on Card</StyledLabel>
              <StyledInput
                required
                placeholder="J.Smith"
                value={nameOnCard}
                onChange={e => {
                  setNameOnCardError('')
                  setNameOnCard(e.target.value)
                }}
                style={{
                  border: '1px solid #9c9d9d',
                  paddingTop: 9,
                  paddingBottom: 9,
                }}
                disabled={formDisabled}
              />
              {nameOnCardError && <FormError>{nameOnCardError}</FormError>}
            </Flex>
          </fieldset>
          <button disabled={formDisabled} style={{ display: 'none' }}>
            Save Card
          </button>
          <Footer withTopBorder>
            <Button onClick={handleSubmit} disabled={formDisabled} isLoading={formDisabled}>
              CREATE
            </Button>
          </Footer>
        </form>
      ) : (
        <Loading variant="fullscreen" />
      )}
    </>
  )
}

export default StripePaymentForm
