import styled from 'styled-components'
import { variant } from 'styled-system'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

export const StyledFormDatePicker = styled(DatePicker)`
  &.form_datepicker {
    display: block;
    padding: 11px;
    width: 100%;
    background-color: white;
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 500;
    border: 1px solid #cdd0d1;
    border-radius: 8px;

    ::placeholder {
      color: #d8d8d8;
      opacity: 1;
    }

    ::-ms-input-placeholder {
      color: #d8d8d8;
    }

    ${variant({
      variants: {
        autofill: {
          border: '1px solid #9c9d9d',
          boxShadow: '0 0 0 30px #F0F3F5 inset !important',
          backgroundClip: 'content-box !important',
        },
      },
    })}
  }
`
