import axios from 'axios'
import * as Sentry from '@sentry/react'

import { BASE_API_URL } from 'const'

export const postValidatePublicUrl = async publicUrl => {
  try {
    const url = `${BASE_API_URL}/v2/magic/validatepublicurl/`
    const request = await axios.post(url, {
      url: publicUrl,
    })
    return request.data
  } catch (err) {
    if (err.response.status < 400) Sentry.captureException(err)
    throw err
  }
}
