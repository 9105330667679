import React from 'react'
import PropTypes from 'prop-types'

import { StyledSpan } from './spanStyle'

const Span = ({ centered, variant, children, ...rest }) => {
  return (
    <StyledSpan centered={centered} variant={variant} {...rest}>
      {children}
    </StyledSpan>
  )
}

Span.propTypes = {
  centered: PropTypes.bool,
  variant: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Span.defaultProps = {
  centered: false,
  variant: 'normal',
}

export default Span
