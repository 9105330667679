import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'

import { Flex, Heading as H, Paragraph as P, Spacer, Span, Button, Footer } from 'components'
import { theme } from 'styles/theme'
import { formatAsCurrency, logUserInteractionGA, setShouldCheckDraftAtCreditScreen } from 'utils'

const InsufficientCredit = ({
  minCredit,
  kycInfo,
  allKycsCompleted,
  allowAdditionalDataKycCheck,
}) => {
  const history = useHistory()

  // *Methods
  const handleClickButton = () => {
    const action = allKycsCompleted
      ? 'Contact Us'
      : allowAdditionalDataKycCheck
      ? 'Request Credit Limit Review'
      : 'Apply for More Credit?'
    logUserInteractionGA(`Click: ${action}`)

    if (!allKycsCompleted) {
      if (allowAdditionalDataKycCheck) {
        history.push('/verify?type=manual')
      } else {
        history.push('/verify?type=cbs')
      }
    } else {
      history.push('/contactus')
    }
  }

  // *Effects
  useEffect(() => {
    if (!allKycsCompleted) {
      setShouldCheckDraftAtCreditScreen()
    }
  }, [allKycsCompleted])

  // *JSX
  if (
    kycInfo &&
    (kycInfo.sg_additional_data === 'reviewing_for_credit' ||
      kycInfo.my_additional_data === 'reviewing_for_credit')
  ) {
    return (
      <Flex alignItems="center" width="100%" flexDirection="column" px={3} pb={90}>
        <H variant="h4" textAlign="center">
          Credit limit review pending
        </H>
        <Spacer height={10} />

        <P variant="small" color={theme.colors.typography.gray1}>
          Your application for credit increase is still being reviewed. If you have not received a
          response after 1-2 working days, please contact us.
        </P>

        <Footer>
          <Flex flexDirection="column" fitWidth>
            <Button onClick={handleClickButton}>CONTACT US</Button>
          </Flex>
        </Footer>
      </Flex>
    )
  }

  return (
    <Flex width="100%" flexDirection="column" px={3} pb={90}>
      <H variant="h4" textAlign="center">
        Your purchase requires more credit
      </H>
      <Spacer height={10} />

      {minCredit ? (
        <P variant="small" color={theme.colors.typography.gray1}>
          Payment plans for this purchase require at least{' '}
          <Span style={{ fontWeight: 600 }}>{formatAsCurrency(minCredit)}</Span> of credit.
        </P>
      ) : (
        <P variant="small" color={theme.colors.typography.gray1}>
          This purchase requires a higher credit limit.
        </P>
      )}
      <Spacer height={10} />

      {!allKycsCompleted && (
        <P variant="small" color={theme.colors.typography.gray1}>
          You may apply for an increase in credit to proceed.
        </P>
      )}

      {allKycsCompleted && (
        <P variant="small" color={theme.colors.typography.gray1}>
          Please contact us if you still need additional credit.
        </P>
      )}

      <Footer>
        <Flex flexDirection="column" fitWidth>
          <Button onClick={handleClickButton}>{`${
            allKycsCompleted
              ? 'CONTACT US'
              : allowAdditionalDataKycCheck
              ? 'REQUEST CREDIT LIMIT REVIEW'
              : 'APPLY FOR MORE CREDIT'
          }`}</Button>
        </Flex>
      </Footer>
    </Flex>
  )
}

InsufficientCredit.propTypes = {
  minCredit: PropTypes.string,
  kycInfo: PropTypes.object.isRequired,
  allKycsCompleted: PropTypes.bool.isRequired,
  allowAdditionalDataKycCheck: PropTypes.bool.isRequired,
}

export default InsufficientCredit
