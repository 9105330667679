import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import DOMPurify from 'dompurify'

import { useBuyerStore } from 'stores'
import {
  AnimatedEllipses,
  Button,
  Flex,
  Image,
  Loading,
  Modal,
  PageWrapper,
  Paragraph as P,
  RoundedContainer,
  Spacer,
  TriggerPopup,
} from 'components'
import { newCreditReportIntro, successCircleGreen } from 'assets'
import { theme } from 'styles'
import { findCbsKyc, getCountryCodeLocalDb, getKycCreatedAt } from 'utils'
import { GENERIC_ERR_MESSAGE } from 'const'

const CPFHomeOwnership = () => {
  const history = useHistory()
  const countryCode = getCountryCodeLocalDb()

  const [qrCode, setQrCode] = useState(null)
  const [purchaseState, setPurchaseState] = useState(null)
  const [qrCodeClicked, setQrCodeClicked] = useState(false)
  const [error, setError] = useState('')

  const purchaseId = useRef(null)
  const shouldClearStates = useRef(true)

  const {
    response: {
      cpfHomeOwnership: cpfHomeOwnershipResponse,
      cpfHomeOwnershipById: cpfHomeOwnershipByIdResponse,
      credits: creditsResponse,
    },
    errors: { cpfHomeOwnership: cpfHomeOwnershipError },
    resetStates: resetStatesBuyer,
    submitCPFHomeOwnershipCheck,
    fetchCPFHomeOwnershipCheckById,
    fetchCredits,
  } = useBuyerStore()

  // *Methods
  const clearCPFHomeOwnershipStates = () => {
    resetStatesBuyer('cpfHomeOwnership')
    resetStatesBuyer('cpfHomeOwnershipById')
    shouldClearStates.current = false
    localStorage.removeItem('cpf_homeownership_job_id')
  }

  const renderStepItem = (status, text) => {
    return (
      <Flex alignItems="center" mb={3}>
        {status === 'complete' ? (
          <Image src={successCircleGreen} alt="tick" height="25px" width="25px" />
        ) : (
          <Loading style={{ height: 25, width: 25 }} />
        )}

        <Spacer width={5} />
        <P
          variant="small"
          color={
            status === 'complete'
              ? theme.colors.progressbar.barGreen1
              : theme.colors.typography.gray1
          }
        >
          {text}
        </P>
      </Flex>
    )
  }

  const renderHeader = () => {
    return (
      <>
        <Spacer height={5} />

        <Image src={newCreditReportIntro} alt="identity_card_icon" width="70px" height="70px" />

        <P variant="h4" style={{ fontWeight: 700 }} textAlign="center">
          Verifying CPF Home Ownership
          <AnimatedEllipses />
        </P>
        <Spacer height={15} />
      </>
    )
  }

  const renderCurrentstate = () => {
    if (purchaseState === 'init') {
      return (
        <Flex flexDirection="column" textAlign="center">
          <Flex>{renderStepItem('incomplete', 'Initialising')}</Flex>
          <Flex>{renderStepItem('incomplete', 'Obtaining authorisation')}</Flex>
          <Flex>{renderStepItem('incomplete', 'Completing session')}</Flex>
        </Flex>
      )
    }

    if (purchaseState === 'singpass_login_required') {
      return (
        <Flex flexDirection="column" textAlign="center">
          {qrCodeClicked ? (
            <P>Please wait while we initialize the CPF home ownership check session.</P>
          ) : (
            <P>Click on the QR code below to authenticate with SingPass application.</P>
          )}

          <Spacer height={15} />
          <div
            dangerouslySetInnerHTML={{ __html: qrCode }}
            style={{ width: '100%', margin: 'auto' }}
            onClick={() => setQrCodeClicked(true)}
          />
          <Spacer height={15} />
        </Flex>
      )
    }

    if (purchaseState === 'singpass_login_completed') {
      return (
        <Flex flexDirection="column" textAlign="center">
          <Flex>{renderStepItem('complete', 'Initialising')}</Flex>
          <Flex>{renderStepItem('complete', 'Obtaining authorisation')}</Flex>
          <Flex>{renderStepItem('incomplete', 'Completing session')}</Flex>
        </Flex>
      )
    }

    if (purchaseState === 'failed' || purchaseState === 'data_extraction_failed') {
      if (shouldClearStates.current) {
        clearCPFHomeOwnershipStates()
      }
      return (
        <Flex flexDirection="column">
          <P>{GENERIC_ERR_MESSAGE}</P>
          <Flex>
            <Button
              onClick={() => {
                window.location.reload()
              }}
            >
              RETRY
            </Button>
            <Spacer width={15} />
            <Button variant="secondary" onClick={() => history.goBack()}>
              EXIT
            </Button>
          </Flex>
        </Flex>
      )
    }

    if (purchaseState === 'singpass_login_abandoned') {
      if (shouldClearStates.current) {
        clearCPFHomeOwnershipStates()
      }
      return (
        <Flex flexDirection="column">
          <P>SingPass QR code has timed-out. Please try again.</P>
          <Flex>
            <Button
              onClick={() => {
                window.location.reload()
              }}
            >
              RETRY
            </Button>
            <Spacer width={15} />
            <Button variant="secondary" onClick={() => history.goBack()}>
              EXIT
            </Button>
          </Flex>
        </Flex>
      )
    }

    return (
      <Flex flexDirection="column">
        <P>
          We are unable to verify your CPF Home Ownership, please contact us if you need to increase
          your credit.
        </P>
        <Button mb={0} onClick={() => history.push('/contactus')}>
          CONTACT US
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            history.push('/home')
            history.push('/credit')
            return history.push('/verify?type=cpf_homeownership')
          }}
        >
          GO BACK
        </Button>
      </Flex>
    )
  }

  // *Effects
  useEffect(() => {
    const existingJobId = localStorage.getItem('cpf_homeownership_job_id')
    if (existingJobId) {
      purchaseId.current = setInterval(() => {
        fetchCPFHomeOwnershipCheckById(existingJobId)
      }, 3000)
    } else {
      submitCPFHomeOwnershipCheck()
    }

    return () => {
      clearInterval(purchaseId.current)
      resetStatesBuyer('cpfHomeOwnership')
      resetStatesBuyer('cpfHomeOwnershipById')
      resetStatesBuyer('credits')
    }
  }, [])

  useEffect(() => {
    if (cpfHomeOwnershipResponse && cpfHomeOwnershipResponse.job_id) {
      const jobId = cpfHomeOwnershipResponse.job_id
      localStorage.setItem('cpf_homeownership_job_id', jobId)
      purchaseId.current = setInterval(() => {
        fetchCPFHomeOwnershipCheckById(jobId)
      }, 3000)

      return () => clearInterval(purchaseId.current)
    }
  }, [cpfHomeOwnershipResponse])

  useEffect(() => {
    if (cpfHomeOwnershipByIdResponse && cpfHomeOwnershipByIdResponse.state) {
      const { state, authorise_qrcode_html } = cpfHomeOwnershipByIdResponse

      if (state === 'singpass_login_required') {
        const cleanQrCodeHtml = DOMPurify.sanitize(authorise_qrcode_html)
        setQrCode(cleanQrCodeHtml)
      }

      setPurchaseState(state)
    }
  }, [cpfHomeOwnershipByIdResponse])

  // !credit assessment checkpoint start
  useEffect(() => {
    if (purchaseState && purchaseState === 'success') {
      clearCPFHomeOwnershipStates()
      fetchCredits(countryCode)
    }
  }, [purchaseState])

  useEffect(() => {
    if (
      creditsResponse &&
      creditsResponse.amount &&
      creditsResponse.kycs &&
      creditsResponse.assessment &&
      creditsResponse.assessment.completed_at &&
      purchaseState === 'success'
    ) {
      const cbsKyc = findCbsKyc(creditsResponse.kycs)

      // handle data_error
      if (cbsKyc && cbsKyc.status === 'data_error') {
        const previousCbsKyc = getKycCreatedAt('sg_home_ownership')
        if (
          previousCbsKyc &&
          previousCbsKyc.created_at &&
          previousCbsKyc.created_at === cbsKyc.created_at
        ) {
          return
        }

        let message = GENERIC_ERR_MESSAGE
        if (cbsKyc.error_message) {
          message = cbsKyc.error_message
        }
        return setError(message)
      }

      // handle redirection if no data_error
      history.push('/home')
      history.push('/credit')
      return history.push('/verify/cbs/outcome')
    }
  }, [creditsResponse])
  // !credit assessment checkpoint end

  console.log('purchaseState', purchaseState)
  console.log('cpfHomeOwnershipResponse', cpfHomeOwnershipResponse)
  console.log('cpfHomeOwnershipByIdResponse', cpfHomeOwnershipByIdResponse)
  console.log('errors', cpfHomeOwnershipError)

  // *JSX
  if (cpfHomeOwnershipError) {
    return (
      <Modal
        messageArray={cpfHomeOwnershipError ? [cpfHomeOwnershipError] : null}
        primaryButtonText="Exit"
        primaryButtonAction={() => {
          clearCPFHomeOwnershipStates()
          history.goBack()
        }}
      />
    )
  }

  if (error && error.length > 0) {
    return (
      <TriggerPopup
        message={error}
        showPopup={!!error}
        buttonText="GO BACK"
        stayAtCurrentPage
        callback={() => {
          history.push('/home')
          history.push('/credit')
          return history.push('/verify?type=cpf_homeownership')
        }}
      />
    )
  }

  if (!purchaseState) return <Loading variant="fullscreen" />

  return (
    <PageWrapper px={3} style={{ justifyContent: 'center' }}>
      <RoundedContainer alignItems="center">
        {(purchaseState === 'init' || purchaseState === 'singpass_login_completed') &&
          renderHeader()}
        {purchaseState && renderCurrentstate()}
      </RoundedContainer>
    </PageWrapper>
  )
}

export default CPFHomeOwnership
