import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { useForm } from 'react-hook-form'

import {
  PageWrapper,
  NavigateBack,
  Flex,
  Span,
  CategoryCard,
  Paragraph as P,
  Spacer,
  FormInput,
  Footer,
  Button,
  TriggerPopup,
  FileUploadButton,
  FileUploadItem,
} from 'components'
import { getCountryCodeLocalDb, logUserInteractionGA } from 'utils'
import { useBuyerStore } from 'stores'
import { theme } from 'styles'
import { GENERIC_ERR_MESSAGE } from 'const'

const ManualReview = () => {
  const history = useHistory()
  const countryCode = getCountryCodeLocalDb()
  const categories = ['Full-time', 'Part-time', 'Unemployed']

  const [selectedFileNames, setSelectedFileNames] = useState([])
  const [activeCategory, setActiveCategory] = useState('')
  const [generalError, setGeneralError] = useState('')

  const {
    isLoading: { additionalData: additionalDataIsLoading },
    response: { additionalData: additionalDataResponse },
    errors: { additionalData: additionalDataError },
    resetStates: resetStatesBuyer,
    submitAdditionalData,
  } = useBuyerStore()

  // *Form settings
  const {
    register,
    setError,
    setValue,
    watch,
    formState: { errors: formErrors },
  } = useForm({
    mode: 'all',
  })

  const selectedFiles = watch('upload-documents')

  // *Methods
  const renderEmployed = () => {
    return (
      <Flex flexDirection="column" width="100%" mb={100}>
        <P variant="small" style={{ fontWeight: 600 }}>
          Bank statements
        </P>
        <Spacer height={5} />

        <P variant="small" color={theme.colors.lockGray}>
          Please provide the bank statement of your salary/income crediting account for the{' '}
          <Span style={{ fontWeight: 700 }}>past 3 months</Span>. The statements should clearly
          indicate your:
        </P>
        <Spacer height={5} />

        <P variant="small" color={theme.colors.lockGray}>
          1. Name (per NRIC) <br />
          2. Address (per NRIC) <br />
          3. Monthly salary/income
        </P>
        <Spacer height={20} />

        <FileUploadButton
          topText="Click this box to select files to upload"
          bottomText="Accepted formats: jpeg, pdf"
          handleClick={() => document.querySelector('input').click()}
        />

        {!!formErrors?.file?.message && (
          <P variant="subcopy" color="red" mb={2}>
            {formErrors.file.message}
          </P>
        )}

        <Flex flexDirection="column">
          <P variant="subcopy" marginBottom={2}>{`${selectedFileNames.length} file${
            selectedFileNames.length > 0 ? 's' : ''
          } selected${selectedFileNames.length > 0 ? ':' : ''}`}</P>

          {selectedFileNames?.map((name, i) => (
            <FileUploadItem key={name} name={name} handleRemoveFile={() => handleRemoveFile(i)} />
          ))}
        </Flex>

        {/* hidden input, actual file picker */}
        <Flex display="none">
          <FormInput
            name="upload-documents"
            label="Upload PDF documents"
            register={register}
            id="upload-documents"
            type="file"
            accept=".jpeg,.jpg,.pdf"
            errorMessage={formErrors?.file?.message}
            multiple
            required
            onChange={e => {
              const files = e.currentTarget.files
              if (files?.length === 0) return
              if (files && files.length > 0) {
                const selected = Array.from(files)
                if (selectedFileNames?.length > 0) {
                  // prevent adding the same file twice to upload list
                  const filtered = selected.filter(file => !selectedFileNames.includes(file.name))
                  setValue('upload-documents', selectedFiles.concat(filtered))
                } else {
                  setValue('upload-documents', selected)
                }
              }
            }}
          />
        </Flex>
      </Flex>
    )
  }

  const renderUnemployed = () => {
    return (
      <P variant="small" color={theme.colors.lockGray}>
        Unfortunately, we currently cannot grant additional credit to unemployed users. However, you
        may get a family member with employment income to make the purchase on your behalf.
      </P>
    )
  }

  const handleRemoveFile = index => {
    const updatedFileList = [...selectedFiles].filter((_, i) => i !== index)
    setValue('upload-documents', updatedFileList)
  }

  const submitForm = () => {
    logUserInteractionGA('Submit: Credit Limit Review')

    const data = new FormData()
    const employmentStatus =
      activeCategory === 'Full-time'
        ? 'full_time'
        : activeCategory === 'Part-time'
        ? 'part_time'
        : 'unemployed'

    data.append('country', countryCode)
    data.append('employment_status', employmentStatus)

    ;[...selectedFiles].forEach((file, i) => {
      data.append(`documents[${i}]file`, file, file.name)
      data.append(`documents[${i}]doc_type`, 'BANK_STATEMENT')
    })

    submitAdditionalData(data)
  }

  const handleCleanUp = () => {
    setGeneralError('')
    resetStatesBuyer('additionalData')
  }

  // *Effects
  useEffect(() => {
    return () => resetStatesBuyer('additionalData')
  }, [])

  useEffect(() => {
    if (selectedFiles?.length > 0) {
      const files = []
      ;[...selectedFiles].forEach(file => files.push(file.name))
      setSelectedFileNames(files)
    } else {
      setSelectedFileNames([])
    }
  }, [selectedFiles])

  useEffect(() => {
    const container = document.getElementById('category-container')
    if (categories.indexOf(activeCategory) === 0) {
      container.scrollTo({
        left: 0,
        behavior: 'smooth',
      })
    }

    if (categories.indexOf(activeCategory) === categories.length - 1) {
      container.scrollTo({
        left: 1000,
        behavior: 'smooth',
      })
    }
  }, [activeCategory])

  useEffect(() => {
    if (additionalDataResponse) {
      history.push('/verify/success?type=documents&manual=true')
    }
  }, [additionalDataResponse])

  useEffect(() => {
    if (
      additionalDataError &&
      additionalDataError.documents &&
      additionalDataError.documents.length > 0
    ) {
      return setError('file', { message: additionalDataError.documents[0] }, true)
    }

    if (additionalDataError) {
      if (additionalDataError.message) {
        if (additionalDataError.message === 'Additional data is under review.')
          return setGeneralError(
            'Additional data is already under review. Please wait for review outcome.',
          )
      }
      return setGeneralError(GENERIC_ERR_MESSAGE)
    }
  }, [additionalDataError])

  // *JSX
  return (
    <PageWrapper withTopNav>
      {/* Error handling */}
      <TriggerPopup
        showPopup={!!generalError}
        message={generalError}
        stayAtCurrentPage
        buttonText="OKAY"
        callback={handleCleanUp}
      />

      <NavigateBack>Credit Limit Review</NavigateBack>

      <Flex flexDirection="column">
        <Spacer height={5} />
        <P variant="small" style={{ fontWeight: 600 }}>
          Employment status
        </P>
        <Spacer height={5} />

        <Flex
          overflowX="scroll"
          style={{
            maxWidth: 'calc(100vw - 32px)',
            paddingLeft: 5,
            scrollbarWidth: 'none',
          }}
          id="category-container"
        >
          {categories.length > 0 &&
            categories.map(category => {
              return (
                <CategoryCard
                  category={category}
                  activeCategory={activeCategory}
                  setActiveCategory={setActiveCategory}
                  key={category}
                />
              )
            })}
        </Flex>

        {activeCategory === '' ? (
          <div />
        ) : activeCategory === 'Unemployed' ? (
          renderUnemployed()
        ) : (
          renderEmployed()
        )}

        <Footer style={{ backgroundColor: 'white' }}>
          <Button
            isLoading={additionalDataIsLoading}
            onClick={() => submitForm()}
            type="button"
            disabled={
              selectedFileNames.length <= 0 || !activeCategory || activeCategory === 'Unemployed'
            }
          >
            SUBMIT
          </Button>
        </Footer>
      </Flex>
    </PageWrapper>
  )
}

export default ManualReview
