import React from 'react'

import { Flex } from 'components'
import { theme } from 'styles/theme'

import { StyledOptionItem } from './dropDownOptionsStyle'

const DropDownOptions = ({ options, activeValue, onClickOption, setIsExpanded }) => {
  return (
    <Flex
      style={{
        alignSelf: 'flex-end',
        top: 50,
        position: 'absolute',
        width: '100%',
        zIndex: 1,
        border: `1px solid ${theme.colors.borderGray2}`,
      }}
      flexDirection="column"
    >
      {options.length > 0 &&
        options.map(option => {
          return (
            <StyledOptionItem
              active={option.value === activeValue.value}
              onClick={() => {
                onClickOption(option)
                setIsExpanded(false)
              }}
              key={option.value}
            >
              {option.name}
            </StyledOptionItem>
          )
        })}
    </Flex>
  )
}

export default DropDownOptions
