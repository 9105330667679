import React from 'react'

import { RoundedContainer, Flex, Heading as H, Paragraph as P, Spacer } from 'components'
import { theme } from 'styles/theme'
import { formatAsCurrency } from 'utils'

const PaymentPlanCard = ({ id, onClick, active, data, simplified = false }) => {
  const {
    instalment_amount,
    instalment_count,
    instalment_frequency,
    downpayment_amount,
    instalment_processing_fee_amount,
    total_amount,
  } = data

  // *Methods
  const convertInstalmentFrequencyText = instalment_frequency => {
    switch (instalment_frequency) {
      case 'monthly':
        return 'months'
      case 'weekly':
        return 'weeks'
      case 'biweekly':
        return 'times every 2 weeks'
      case 'end_of_month':
        return 'Pay Later'
      default:
        return ''
    }
  }

  const convertRepaymentFrequencyText = instalment_frequency => {
    switch (instalment_frequency) {
      case 'monthly':
        return '/mth'
      case 'weekly':
        return '/week'
      case 'biweekly':
        return '/2 weeks'
      default:
        return ''
    }
  }

  // *JSX
  return (
    <RoundedContainer
      borderRadius={12}
      boxShadow="0px 2px 6px 0px #0000002A"
      py={20}
      my={12}
      border={active ? `2px solid ${theme.colors.buttons.marineBlue}` : 'none'}
      bg={active ? theme.colors.actions.lightBlue : 'white'}
      onClick={() => onClick(data)}
      initial={{ scale: 1 }}
      animate={{ scale: active ? 1.0 : 1.0 }}
      transition={{ duration: 0.05, type: 'spring', stiffness: 300 }}
      id={id}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Flex alignItems="flex-end">
          <H variant="h1" fontSize={28} id={id ? 'payment_anchor_1' : ''}>
            {formatAsCurrency(
              instalment_frequency === 'end_of_month'
                ? total_amount
                : parseFloat(instalment_amount) + parseFloat(instalment_processing_fee_amount),
            )}
          </H>
          <P style={{ fontWeight: 500, paddingBottom: 2 }}>
            {convertRepaymentFrequencyText(instalment_frequency)}
          </P>
        </Flex>
        <Spacer width={5} />

        {instalment_frequency === 'end_of_month' ? (
          <P
            color={theme.colors.actions.yellowWarning1}
            style={{ fontWeight: 600 }}
            textAlign="right"
            id={id ? 'payment_anchor_2' : ''}
          >
            {`${convertInstalmentFrequencyText(instalment_frequency)}`}
          </P>
        ) : (
          <P
            color={theme.colors.lockGray}
            style={{ fontWeight: 600 }}
            textAlign="right"
            id={id ? 'payment_anchor_2' : ''}
          >
            {`${instalment_count} ${convertInstalmentFrequencyText(instalment_frequency)}`}
          </P>
        )}
      </Flex>
      {!simplified && (
        <>
          <Spacer height={10} />
          <Flex justifyContent="space-between">
            <Flex flexDirection="column" mr={15}>
              <P variant="subcopy" style={{ fontWeight: 500 }}>
                Downpayment
              </P>
              <P variant="subcopy" color={theme.colors.lockGray} style={{ fontWeight: 400 }}>
                {formatAsCurrency(downpayment_amount)}
              </P>
              <Spacer width={20} id={id ? 'payment_anchor_3' : ''} />
            </Flex>
            <Flex flexDirection="column" mr={15}>
              <P variant="subcopy" style={{ fontWeight: 500 }}>
                {`Processing fee${convertRepaymentFrequencyText(instalment_frequency)}`}
              </P>
              <P variant="subcopy" color={theme.colors.lockGray} style={{ fontWeight: 400 }}>
                {`${formatAsCurrency(instalment_processing_fee_amount)}`}
              </P>
              <Spacer width={20} id={id ? 'payment_anchor_4' : ''} />
            </Flex>
            <Flex flexDirection="column">
              <P variant="subcopy" style={{ fontWeight: 500 }}>
                Total
              </P>
              <P variant="subcopy" color={theme.colors.lockGray} style={{ fontWeight: 400 }}>
                {formatAsCurrency(total_amount)}
              </P>
              <Spacer width={20} id={id ? 'payment_anchor_5' : ''} />
            </Flex>
          </Flex>
        </>
      )}
    </RoundedContainer>
  )
}

export default PaymentPlanCard
