import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { Flex, RoundedContainer } from 'components'

const FeaturedCardSkeleton = () => {
  return (
    <RoundedContainer flex="1 0 40%" mx={10} overflow="hidden" backgroundColor="transparent">
      <Flex flexDirection="column" stretch centered>
        <Flex width="100px" height="100px" stretch centered>
          <Skeleton width={80} height={80} />
        </Flex>
        <Flex flexDirection="column" alignSelf="flex-start" mt={3} maxWidth="100%">
          <Skeleton height={15} width={80} />
          <Skeleton height={15} width={90} />
        </Flex>
      </Flex>
    </RoundedContainer>
  )
}

export default FeaturedCardSkeleton
