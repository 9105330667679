import React, { useState, useEffect } from 'react'

import { Paragraph as P } from 'components'
import { advancedDayjs, checkIsToday } from 'utils'
import { theme } from 'styles'

const ScheduleRowPaymentDate = ({
  paymentStatus,
  isCanPay,
  isDeactivated,
  isOverdue,
  isEarlyRepayment,
  due_at,
  collected_at,
}) => {
  const [isToday, setIsToday] = useState(false)

  // *Effects
  useEffect(() => {
    if (collected_at) {
      setIsToday(checkIsToday(collected_at))
    } else {
      setIsToday(checkIsToday(due_at))
    }
  }, [])

  // *JSX
  return (
    <P
      variant="small"
      style={{
        fontWeight: 600,
        textDecoration: isDeactivated ? 'line-through' : 'none',
      }}
      color={
        (isCanPay && !isOverdue) || isEarlyRepayment
          ? theme.colors.buttons.marineBlue
          : isOverdue
          ? theme.colors.typography.red1
          : paymentStatus === 'collect_success' || isDeactivated
          ? theme.colors.typography.gray7
          : isToday
          ? theme.colors.buttons.marineBlue
          : '#191919'
      }
    >
      {isToday
        ? 'Today'
        : isOverdue
        ? `${advancedDayjs(due_at).format('Do MMM YYYY')} (Overdue)`
        : collected_at
        ? advancedDayjs(collected_at).format('Do MMM YYYY')
        : advancedDayjs(due_at).format('Do MMM YYYY')}
    </P>
  )
}

export default ScheduleRowPaymentDate
