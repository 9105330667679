import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router'

import { PageWrapper, Modal, Loading, TriggerPopup } from 'components'
import { useBuyerStore } from 'stores'
import { setMyinfoDataLocalDb } from 'utils'
import { GENERIC_ERR_MESSAGE } from 'const'

const FillWithMyInfo = () => {
  const history = useHistory()

  const location = useLocation().search
  const code = new URLSearchParams(location).get('code')
  const state = new URLSearchParams(location).get('state')
  const error = new URLSearchParams(location).get('error')

  const [myInfoUrlLoading, setMyInfoUrlLoading] = useState(false)
  const [showErrorModal, setShowFormErrorModal] = useState(false)
  const {
    response: { myInfoDetails: myInfoDetailsResponse },
    errors: { myInfoDetails: myInfoDetailsErrors },
    resetStates: resetStatesBuyer,
    fetchMyinfoDetails,
  } = useBuyerStore()

  // *Methods
  const handleAcknowledgeError = () => {
    setShowFormErrorModal(false)
    resetStatesBuyer('myInfoDetails')

    return history.push('/home')
  }

  // *Effects
  useEffect(() => {
    fetchMyinfoDetails(code, state)
    setMyInfoUrlLoading(true)

    return () => resetStatesBuyer('myInfoDetails')
  }, [])

  useEffect(() => {
    if (myInfoDetailsErrors) {
      setShowFormErrorModal(true)
      setMyInfoUrlLoading(false)
    }
  }, [myInfoDetailsErrors])

  useEffect(() => {
    if (myInfoDetailsResponse) {
      setMyinfoDataLocalDb(myInfoDetailsResponse)
      history.push('/verify?type=identity')
      return history.push(`/verify/identity?page=1`)
    }
  }, [myInfoDetailsResponse])

  // *JSX
  if (error)
    return (
      <TriggerPopup
        showPopup
        message={
          error === 'access_denied' ? 'Access denied. Please try again.' : GENERIC_ERR_MESSAGE
        }
        buttonText="OKAY"
        primaryButtonAction={() => history.push('/verify?type=identity')}
      />
    )

  if (myInfoUrlLoading) return <Loading variant="fullscreen" />

  return (
    <PageWrapper>
      {/* Modals */}
      {showErrorModal && (
        <Modal
          messageArray={
            myInfoDetailsErrors && myInfoDetailsErrors.message
              ? [myInfoDetailsErrors.message]
              : ['An error occured, please try again.']
          }
          primaryButtonText="OKAY"
          primaryButtonAction={handleAcknowledgeError}
        />
      )}
    </PageWrapper>
  )
}

export default FillWithMyInfo
