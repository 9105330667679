import styled from 'styled-components'
import {
  space,
  color,
  typography,
  layout,
  flexbox,
  border,
  position,
  compose,
  variant,
} from 'styled-system'
import { motion } from 'framer-motion'

import { theme } from 'styles/theme'

export const StyledHeading = styled(motion.h1)`
  font-family: ${theme.typography.fontFamily};
  text-align: ${props => props.centered && 'center'};

  ${variant({
    variants: {
      h1: {
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '36px',
      },
      h2: {
        fontWeight: 700,
        fontSize: '22px',
        lineHeight: '33px',
      },
      h3: {
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '30px',
      },
      h4: {
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '27px',
      },
    },
  })};

  ${compose(space, color, typography, layout, flexbox, border, position)}
`
