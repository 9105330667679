import React from 'react'

import { Flex, Paragraph as P, Spacer } from 'components'
import { theme } from 'styles'
import { formatAsCurrency } from 'utils'

const PayRemainingAmountRow = ({
  title,
  value,
  color = 'black',
  editButton = null,
  isNegativeValue = false,
  tutorial_id = '',
}) => {
  return (
    <Flex justifyContent="space-between" mb={2} alignItems="center" width="100%">
      <Flex>
        <P style={{ fontWeight: 500 }} color={theme.colors.typography.gray10}>
          {title}
        </P>
        {editButton && editButton}
      </Flex>
      <Spacer width={20} />
      <P
        style={{
          fontWeight: 700,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          color,
        }}
        textAlign="right"
        id={tutorial_id}
      >
        {isNegativeValue ? '-' : ''}
        {formatAsCurrency(value)}
      </P>
    </Flex>
  )
}

export default PayRemainingAmountRow
