import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import Skeleton from 'react-loading-skeleton'
import * as dayjs from 'dayjs'

import { CREDIT_TUTORIAL, GENERIC_ERR_MESSAGE } from 'const'
import { useBuyerStore, useOrderStore } from 'stores'
import {
  checkForInsufficientCredit,
  formatAsCurrency,
  getCountryCodeLocalDb,
  getDraftOrderLocalDb,
  getShouldCheckDraftAtCreditScreen,
  getShouldRedirectToAddPayment,
  removeShouldRedirectToAddPayment,
  setShouldRedirectToCredit,
  logUserInteractionGA,
  removeTokenLocalDb,
} from 'utils'
import {
  newPaymentMethodIntro,
  newVerifyIdentity,
  newCreditReportIntro,
  circleSuccess,
  newManualReview,
} from 'assets'
import {
  NavigateBack,
  Button,
  PageWrapper,
  RoundedContainer,
  RoundedContainerItem,
  Flex,
  Paragraph as P,
  Heading as H,
  ProgressBar,
  Loading,
  Image,
  RowDivider,
  TutorialWrapper,
  Spacer,
  DraggableOverlay,
  Span,
  HelpIcon,
  TriggerPopup,
} from 'components'
import { theme } from 'styles/theme'

import ReturnToPurchase from './ReturnToPurchase/ReturnToPurchase'

const Credit = () => {
  const history = useHistory()
  const countryCode = getCountryCodeLocalDb()
  const draftOrder = getDraftOrderLocalDb()

  let draftOrderCode
  if (draftOrder) draftOrderCode = draftOrder.code

  const [kycInfo, setKycInfo] = useState(null)
  const [userCredits, setUserCredits] = useState(null)
  const [tutorialStep, setTutorialStep] = useState(0)
  const [shouldCallInteractionApi, setShouldCallInteractionApi] = useState(false)
  const [creditIsInsufficient, setCreditIsInsufficient] = useState(true)
  const [showOverlay, setShowOverlay] = useState(false)

  // *Stores
  const {
    response: { credits: creditsResponse, getOrCreate: getOrCreateResponse },
    errors: { getOrCreate: getOrCreateError },
    resetStates: resetStatesBuyer,
    fetchCredits,
    submitGetOrCreate,
  } = useBuyerStore()

  const {
    response: { order: orderResponse },
    fetchOrder,
  } = useOrderStore()

  // *Methods
  const handleClickContactUs = () => {
    logUserInteractionGA('Click: Contact Us')
    history.push('/contactus')
  }

  const renderContactUs = () => {
    return (
      <Flex fitWidth centered>
        <Spacer height={30} />
        <P
          variant="subcopy"
          color={theme.colors.typography.gray1}
          textAlign="center"
          style={{ fontWeight: 600 }}
        >
          Having trouble with credit details?{' '}
          <Span color={theme.colors.buttons.marineBlue} onClick={handleClickContactUs}>
            Contact us
          </Span>
        </P>
      </Flex>
    )
  }

  const renderStatusButton = (status, canAttempt = false) => {
    if (!canAttempt) return null

    if (status === 'data_available') {
      return (
        <Button variant="extrasmall" backgroundColor="progressbar.barGreen1">
          <Image src={circleSuccess} alt="item-status" />
        </Button>
      )
    }

    if (
      status === 'incomplete' ||
      status === 'data_error' ||
      status === 'expired' ||
      status === 'created'
    ) {
      return (
        <Button variant="extrasmall" backgroundColor="buttons.darkTangerine" color="white">
          Start
        </Button>
      )
    }

    if (status === 'reviewing_for_credit' || status === 'retrieving_data') {
      return null
    }
  }

  const renderStatusText = (status, validityDate, withId, canAttempt) => {
    if (status === 'data_available' && !canAttempt) {
      return (
        <P
          variant="small"
          color={theme.colors.progressbar.barGreen1}
          style={{ fontWeight: 500 }}
          id={withId ? 'credit_3' : ''}
        >
          Completed
        </P>
      )
    }

    if (status === 'data_available' && validityDate) {
      return (
        <P
          variant="small"
          color={theme.colors.lockGray}
          style={{ fontWeight: 500 }}
          id={withId ? 'credit_3' : ''}
        >
          {`Valid until ${dayjs(validityDate).format('DD MMM YYYY')}`}
        </P>
      )
    }

    if (status === 'incomplete' || status === 'created') {
      return (
        <P
          variant="small"
          color={theme.colors.lockGray}
          style={{ fontWeight: 500 }}
          id={withId ? 'credit_3' : ''}
        >
          Not complete
        </P>
      )
    }

    if (status === 'data_error') {
      return (
        <P
          variant="small"
          color={theme.colors.actions.failureRed}
          style={{ fontWeight: 500 }}
          id={withId ? 'credit_3' : ''}
        >
          Error retrieving data
        </P>
      )
    }

    if (status === 'expired') {
      return (
        <P
          variant="small"
          color={theme.colors.buttons.darkTangerine}
          style={{ fontWeight: 500 }}
          id={withId ? 'credit_3' : ''}
        >
          Validity expired
        </P>
      )
    }

    if (status === 'retrieving_data') {
      return (
        <P
          variant="small"
          color={theme.colors.buttons.darkTangerine}
          style={{ fontWeight: 500 }}
          id={withId ? 'credit_3' : ''}
        >
          Retrieving data
        </P>
      )
    }

    if (status === 'reviewing_for_credit') {
      return (
        <P
          variant="small"
          color={theme.colors.buttons.darkTangerine}
          style={{ fontWeight: 500 }}
          id={withId ? 'credit_3' : ''}
        >
          Under review
        </P>
      )
    }
  }

  const handleTutorial = () => {
    if (tutorialStep === CREDIT_TUTORIAL.length - 1) {
      setTutorialStep(0)
    } else {
      setTutorialStep(tutorialStep => tutorialStep + 1)
    }
  }

  const shouldNavigate = (status, canAttempt = false) => {
    if (!canAttempt) return false

    return status !== 'reviewing_for_credit'
  }

  // *Effects
  useEffect(() => {
    fetchCredits(countryCode)
    submitGetOrCreate()

    // !User journey checkpoints start
    // redirect to add payment method if user is first hasn't added
    if (getShouldRedirectToAddPayment()) {
      removeShouldRedirectToAddPayment()
      return history.push('/verify?type=paymentmethod')
    }

    // check for existing draft orders
    if (getShouldCheckDraftAtCreditScreen() && draftOrderCode) {
      fetchOrder(draftOrderCode)
    }
    // !User journey checkpoints end

    return () => {
      resetStatesBuyer('credits')
      resetStatesBuyer('getOrCreate')
    }
  }, [])

  useEffect(() => {
    if (creditsResponse) {
      setUserCredits({
        total: creditsResponse.amount,
        balance: creditsResponse.balance,
      })
    }

    if (creditsResponse && creditsResponse.kycs.length > 0) {
      creditsResponse.kycs.map(kyc => {
        const { kyc_type, status, expired_at, can_attempt } = kyc
        return setKycInfo(prev => {
          return {
            ...prev,
            [kyc_type]: status,
            [`${kyc_type}_validity`]: expired_at,
            [`${kyc_type}_can_attempt`]: can_attempt,
          }
        })
      })
    }
  }, [creditsResponse])

  useEffect(() => {
    if (
      getOrCreateResponse &&
      getOrCreateResponse.interactions &&
      getOrCreateResponse.interactions.length >= 0
    ) {
      const interaction = getOrCreateResponse.interactions.filter(
        item => item.action === 'view_credit',
      )

      if (interaction.length === 0) {
        setTutorialStep(1)
        setShouldCallInteractionApi(true)
      }
    }
  }, [getOrCreateResponse])

  useEffect(() => {
    if (
      orderResponse &&
      orderResponse.available_payment_plans &&
      orderResponse.available_payment_plans.length > 0
    ) {
      const { insufficientCredit } = checkForInsufficientCredit(
        orderResponse.available_payment_plans,
      )
      setCreditIsInsufficient(insufficientCredit)
      setShowOverlay(true)
    }
  }, [orderResponse])

  // *JSX
  return (
    <TutorialWrapper
      tutorial={CREDIT_TUTORIAL}
      currentStep={tutorialStep}
      showTutorial={tutorialStep > 0}
      handleClick={handleTutorial}
      shouldCallInteractionApi={shouldCallInteractionApi}
      setShouldCallInteractionApi={setShouldCallInteractionApi}
    >
      <TriggerPopup
        message={(getOrCreateError && getOrCreateError.message) || GENERIC_ERR_MESSAGE}
        showPopup={!!getOrCreateError}
        buttonText="Return"
        callback={() => {
          resetStatesBuyer('getOrCreate')
          removeTokenLocalDb()
        }}
        nextDestination="/register"
        replaceHistory
      />

      <PageWrapper withTopNav withBottomBar>
        {/* User journey checkpoints start */}
        {/* Sufficient credit, can return to purchase */}
        {showOverlay &&
          !creditIsInsufficient &&
          orderResponse &&
          orderResponse.grand_total_amount &&
          orderResponse.store &&
          getShouldCheckDraftAtCreditScreen() &&
          tutorialStep === 0 && (
            <DraggableOverlay cancel={() => setShowOverlay(false)}>
              <ReturnToPurchase
                store={orderResponse.store}
                amount={orderResponse.grand_total_amount}
                setShowOverlay={setShowOverlay}
              />
            </DraggableOverlay>
          )}
        {/* User journey checkpoints end */}

        <NavigateBack
          noBackground
          style={{ position: 'absolute' }}
          rightComponent={
            <HelpIcon onClick={handleTutorial} style={{ position: 'absolute', right: '20px' }} />
          }
        />
        <Flex flexDirection="column" stretch centered pb={5}>
          <Flex
            flexDirection="column"
            centered
            backgroundColor="white"
            px={4}
            pt={2}
            borderRadius={12}
            id="credit_1"
          >
            <P variant="small" color={theme.colors.typography.darkGray} style={{ fontWeight: 700 }}>
              Remaining Credit
            </P>
            {userCredits ? (
              <H
                variant="h1"
                textAlign="center"
                fontSize="48px"
                lineHeight="72px"
                style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {formatAsCurrency(userCredits.balance)}
              </H>
            ) : (
              <Skeleton height={60} width={200} />
            )}
          </Flex>

          {!userCredits && <Skeleton height={15} width={300} />}

          {userCredits && tutorialStep === 1 && <Spacer height={10} />}
          {userCredits && tutorialStep !== 1 && (
            <ProgressBar
              px="20px"
              initial={tutorialStep > 0 ? userCredits.balance : 0}
              current={userCredits.balance}
              total={userCredits.total}
              bottomRightLabel={
                <P variant="subcopy" color="typography.gray6" pt="5px">
                  out of {formatAsCurrency(userCredits.total)}
                </P>
              }
            />
          )}
        </Flex>

        <RoundedContainer id="credit_2">
          <H variant="h3">Onboarding</H>

          <RoundedContainerItem
            onClick={() => {
              let continueNavigation = true
              logUserInteractionGA('Click: Verify Identity')
              if (kycInfo && kycInfo.sg_identity) {
                continueNavigation = shouldNavigate(
                  kycInfo.sg_identity,
                  kycInfo.sg_identity_can_attempt,
                )
              }
              if (kycInfo && kycInfo.my_identity) {
                continueNavigation = shouldNavigate(
                  kycInfo.my_identity,
                  kycInfo.my_identity_can_attempt,
                )
              }

              if (continueNavigation) history.push('/verify?type=identity')
            }}
            left={<Image src={newVerifyIdentity} alt="identity-card" width="60px" height="60px" />}
            middleTop={
              <P variant="small" style={{ fontWeight: '700' }}>
                Verify Identity
              </P>
            }
            middleBottom={
              !kycInfo ? (
                <Skeleton />
              ) : (
                <>
                  {kycInfo &&
                    (kycInfo.sg_identity || kycInfo.sg_identity_validity) &&
                    renderStatusText(
                      kycInfo.sg_identity,
                      kycInfo.sg_identity_validity,
                      true,
                      kycInfo.sg_identity_can_attempt,
                    )}

                  {kycInfo &&
                    (kycInfo.my_identity || kycInfo.my_identity_validity) &&
                    renderStatusText(
                      kycInfo.my_identity,
                      kycInfo.my_identity_validity,
                      true,
                      kycInfo.my_identity_can_attempt,
                    )}
                </>
              )
            }
            right={
              kycInfo ? (
                renderStatusButton(
                  kycInfo.sg_identity || kycInfo.my_identity,
                  kycInfo.sg_identity_can_attempt || kycInfo.my_identity_can_attempt,
                )
              ) : (
                <Loading size={20} style={{ marginRight: 20 }} />
              )
            }
          />

          {kycInfo && (kycInfo.sg_payment_method || kycInfo.my_payment_method) && (
            <>
              <RowDivider />

              <RoundedContainerItem
                onClick={() => {
                  const continueNavigation = shouldNavigate(
                    true,
                    kycInfo.sg_payment_method_can_attempt || kycInfo.my_payment_method_can_attempt,
                  )
                  if (!continueNavigation) return

                  logUserInteractionGA('Click: Payment Method')
                  if (
                    kycInfo &&
                    (kycInfo.sg_payment_method === 'incomplete' ||
                      kycInfo.my_payment_method === 'incomplete')
                  ) {
                    history.push('/verify?type=paymentmethod')
                  } else {
                    setShouldRedirectToCredit()
                    history.push('/paymentmethods')
                  }
                }}
                left={
                  <Image src={newPaymentMethodIntro} alt="home-icon" width="60px" height="60px" />
                }
                middleTop={
                  <P variant="small" style={{ fontWeight: '700' }}>
                    Payment Method
                  </P>
                }
                middleBottom={
                  !kycInfo ? (
                    <Skeleton />
                  ) : kycInfo.sg_payment_method === 'incomplete' ||
                    kycInfo.my_payment_method === 'incomplete' ? (
                    <P variant="small" color={theme.colors.lockGray} style={{ fontWeight: 500 }}>
                      Not complete
                    </P>
                  ) : (
                    <P variant="small" color={theme.colors.lockGray} style={{ fontWeight: 500 }}>
                      {``}
                    </P>
                  )
                }
                right={
                  kycInfo ? (
                    kycInfo.sg_payment_method === 'incomplete' ||
                    kycInfo.my_payment_method === 'incomplete' ? (
                      renderStatusButton(
                        'incomplete',
                        kycInfo.sg_payment_method_can_attempt ||
                          kycInfo.my_payment_method_can_attempt,
                      )
                    ) : (
                      renderStatusButton(
                        'data_available',
                        kycInfo.sg_payment_method_can_attempt ||
                          kycInfo.my_payment_method_can_attempt,
                      )
                    )
                  ) : (
                    <Loading size={20} style={{ marginRight: 20 }} />
                  )
                }
                style={{ paddingBottom: 0 }}
              />
            </>
          )}
        </RoundedContainer>

        {kycInfo &&
          (kycInfo.sg_credit_bureau_report ||
            kycInfo.my_enqws ||
            kycInfo.sg_additional_data ||
            kycInfo.my_additional_data) && (
            <RoundedContainer>
              <H variant="h3">Additional Information</H>
              {(kycInfo.sg_credit_bureau_report || kycInfo.my_enqws) && (
                <RoundedContainerItem
                  onClick={() => {
                    logUserInteractionGA(
                      `Click: ${countryCode === 'sg' ? 'Credit Bureau Report' : 'CCRIS Report'}`,
                    )
                    let continueNavigation = true
                    if (kycInfo && kycInfo.sg_credit_bureau_report) {
                      continueNavigation = shouldNavigate(
                        kycInfo.sg_credit_bureau_report,
                        kycInfo.sg_credit_bureau_report_can_attempt,
                      )
                    }
                    if (kycInfo && kycInfo.my_enqws) {
                      continueNavigation = shouldNavigate(
                        kycInfo.my_enqws,
                        kycInfo.my_enqws_can_attempt,
                      )
                    }

                    if (continueNavigation) {
                      history.push('/verify?type=cbs')
                    }
                  }}
                  left={
                    <Image
                      src={newCreditReportIntro}
                      alt="credit-card"
                      width="60px"
                      height="60px"
                    />
                  }
                  middleTop={
                    <P variant="small" style={{ fontWeight: '700' }}>
                      {countryCode === 'my' ? 'CCRIS Report' : 'Credit Bureau Report'}
                    </P>
                  }
                  middleBottom={
                    !kycInfo ? (
                      <Skeleton />
                    ) : (
                      <>
                        {kycInfo &&
                          (kycInfo.sg_credit_bureau_report ||
                            kycInfo.sg_credit_bureau_report_validity) &&
                          renderStatusText(
                            kycInfo.sg_credit_bureau_report,
                            kycInfo.sg_credit_bureau_report_validity,
                            false,
                            kycInfo.sg_credit_bureau_report_can_attempt,
                          )}

                        {kycInfo &&
                          (kycInfo.my_enqws || kycInfo.my_enqws_validity) &&
                          renderStatusText(
                            kycInfo.my_enqws,
                            kycInfo.my_enqws_validity,
                            false,
                            kycInfo.my_enqws_can_attempt,
                          )}
                      </>
                    )
                  }
                  right={
                    kycInfo ? (
                      renderStatusButton(
                        kycInfo.sg_credit_bureau_report || kycInfo.my_enqws,
                        kycInfo.sg_credit_bureau_report_can_attempt || kycInfo.my_enqws_can_attempt,
                      )
                    ) : (
                      <Loading size={20} style={{ marginRight: 20 }} />
                    )
                  }
                  style={{ paddingBottom: 0 }}
                />
              )}

              {kycInfo.sg_home_ownership && (
                <RoundedContainerItem
                  onClick={() => {
                    logUserInteractionGA(`Click: Home Ownership'}`)
                    const continueNavigation = shouldNavigate(
                      kycInfo.sg_home_ownership,
                      kycInfo.sg_home_ownership_can_attempt,
                    )
                    if (continueNavigation) {
                      history.push('/verify?type=cpf_homeownership')
                    }
                  }}
                  left={
                    <Image
                      src={newCreditReportIntro}
                      alt="credit-card"
                      width="60px"
                      height="60px"
                    />
                  }
                  middleTop={
                    <P variant="small" style={{ fontWeight: '700' }}>
                      Home Ownership
                    </P>
                  }
                  middleBottom={
                    !kycInfo ? (
                      <Skeleton />
                    ) : (
                      <>
                        {kycInfo &&
                          (kycInfo.sg_home_ownership || kycInfo.sg_home_ownership_validity) &&
                          renderStatusText(
                            kycInfo.sg_home_ownership,
                            kycInfo.sg_home_ownership_validity,
                            false,
                            kycInfo.sg_home_ownership_can_attempt,
                          )}
                      </>
                    )
                  }
                  right={
                    kycInfo ? (
                      renderStatusButton(
                        kycInfo.sg_home_ownership,
                        kycInfo.sg_home_ownership_can_attempt,
                      )
                    ) : (
                      <Loading size={20} style={{ marginRight: 20 }} />
                    )
                  }
                  style={{ paddingBottom: 0 }}
                />
              )}

              {kycInfo && (kycInfo.sg_additional_data || kycInfo.my_additional_data) && (
                <>
                  {(kycInfo.sg_credit_bureau_report || kycInfo.my_enqws) && <RowDivider />}

                  <RoundedContainerItem
                    onClick={() => {
                      logUserInteractionGA('Click: Credit Limit Review')
                      if (creditsResponse && creditsResponse.can_allow_additional_data_kyc_check) {
                        return history.push('/verify?type=manual')
                      }

                      if (
                        kycInfo.my_additional_data &&
                        (kycInfo.my_additional_data === 'incomplete' ||
                          kycInfo.my_additional_data === 'expired')
                      ) {
                        return history.push('/verify?type=manual')
                      }
                    }}
                    left={
                      <Image src={newManualReview} alt="manual-review" width="60px" height="60px" />
                    }
                    middleTop={
                      <P
                        variant="small"
                        style={{
                          fontWeight: '700',
                        }}
                      >
                        Credit Limit Review
                      </P>
                    }
                    middleBottom={
                      !kycInfo ? (
                        <Skeleton />
                      ) : (
                        <>
                          {kycInfo &&
                            (kycInfo.sg_additional_data || kycInfo.sg_additional_data_validity) &&
                            renderStatusText(
                              kycInfo.sg_additional_data,
                              kycInfo.sg_additional_data_validity,
                              false,
                              kycInfo.sg_additional_data_can_attempt,
                            )}

                          {kycInfo &&
                            (kycInfo.my_additional_data || kycInfo.my_additional_data_validity) &&
                            renderStatusText(
                              kycInfo.my_additional_data,
                              kycInfo.my_additional_data_validity,
                              false,
                              kycInfo.my_additional_data_can_attempt,
                            )}
                        </>
                      )
                    }
                    right={
                      !kycInfo ? (
                        <Loading
                          size={20}
                          style={{
                            marginRight: 20,
                          }}
                        />
                      ) : creditsResponse && creditsResponse.can_allow_additional_data_kyc_check ? (
                        kycInfo &&
                        (kycInfo.sg_additional_data === 'data_available' ||
                          kycInfo.my_additional_data === 'data_available') ? (
                          renderStatusButton(
                            'data_available',
                            kycInfo.sg_additional_data_can_attempt,
                            kycInfo.my_additional_data_can_attempt,
                          )
                        ) : (
                          renderStatusButton(
                            kycInfo.sg_additional_data || kycInfo.my_additional_data,
                            kycInfo.sg_additional_data_can_attempt ||
                              kycInfo.my_additional_data_can_attempt,
                          )
                        )
                      ) : (
                        <div />
                      )
                    }
                    style={{ paddingBottom: 0 }}
                  />
                </>
              )}
            </RoundedContainer>
          )}

        {renderContactUs()}
      </PageWrapper>
    </TutorialWrapper>
  )
}

export default Credit
