import React from 'react'

import { Flex, RoundedContainer, Image, Paragraph as P } from 'components'
import { DetailRowLoading } from 'web/buyers/pages'
import { dashedLine } from 'assets'

const PayNextDetails = () => {
  return (
    <Flex mt={110} flexDirection="column" width="100%" zIndex={1}>
      <P style={{ fontWeight: 700 }}>Today&apos;s payment</P>
      <RoundedContainer boxShadow="0px 2px 6px 0px #0000002A" borderRadius={12}>
        <DetailRowLoading titleWidth={190} amountWidth={56} />
        <DetailRowLoading titleWidth={180} amountWidth={56} />

        <Image src={dashedLine} alt="dashed_line" style={{ margin: '10px 0' }} />

        <DetailRowLoading titleWidth={130} amountWidth={60} />
      </RoundedContainer>
    </Flex>
  )
}

export default PayNextDetails
