import React from 'react'

import { Flex, Image, Paragraph as P, RowDivider } from 'components'
import { rightCaretGray } from 'assets'

const AddPaymentMethodRow = ({ icon, text, onClick }) => {
  return (
    <>
      <Flex py={3} px={2} stretch justifyContent="space-between" onClick={onClick}>
        <Flex>
          <Image src={icon} alt="payment_method_icon" width={24} />
          <P ml={2} style={{ fontWeight: 600 }}>
            {text}
          </P>
        </Flex>
        <Image src={rightCaretGray} alt="right_caret" />
      </Flex>
      <RowDivider width="100%" show />
    </>
  )
}

export default AddPaymentMethodRow
