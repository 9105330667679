import styled from 'styled-components'
import {
  space,
  color,
  typography,
  layout,
  flexbox,
  border,
  position,
  compose,
  variant,
} from 'styled-system'

import { theme } from 'styles/theme'

export const StyledParagraph = styled.p`
  font-family: ${theme.typography.fontFamily};
  text-align: ${props => props.centered && 'center'};

  ${compose(space, color, typography, layout, flexbox, border, position)};

  ${variant({
    variants: {
      normal: {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
      },
      small: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '21px',
      },
      subcopy: {
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '18px',
      },
      tag: {
        fontWeight: 700,
        fontSize: '10px',
        lineHeight: '15px',
      },
    },
  })}
`
