import styled from 'styled-components'
import { theme } from 'styles/theme'

export const StyledDropdownOptionContainer = styled.div`
  margin-top: -10px;
  padding-top: 10px;
  border: 1px solid ${theme.colors.borderGray2};
  border-top: none;

  &:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
`

export const StyledDropdownOption = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${theme.colors.borderGray2};
  width: 100%;
  border-radius: 12px;
  border-bottom-left-radius: ${props => (props.showOptions ? '0px' : '12px')};
  border-bottom-right-radius: ${props => (props.showOptions ? '0px' : '12px')};
  background-color: white;
  padding: 12px;
`

export const StyledCardIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  height: 47px;
  width: 47px;
  padding: 5px;
  background-color: ${theme.colors.buttons.marineBlue};
`
