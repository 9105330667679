import { useState, useEffect } from 'react'

import { getCountryCodeLocalDb } from 'utils'
import { useBuyerStore } from 'stores'

const usePaymentThirdParty = () => {
  const countryCode = getCountryCodeLocalDb()
  const [thirdParty, setThirdParty] = useState('')

  const {
    response: { paymentThirdParty: paymentThirdPartyResponse },
    resetStates: resetStatesBuyer,
    fetchPaymentThirdParty,
  } = useBuyerStore()

  useEffect(() => {
    fetchPaymentThirdParty(countryCode)

    return () => resetStatesBuyer('paymentThirdParty')
  }, [])

  useEffect(() => {
    if (paymentThirdPartyResponse) {
      setThirdParty(paymentThirdPartyResponse)
    }
  }, [paymentThirdPartyResponse])

  return { thirdParty }
}

export default usePaymentThirdParty
